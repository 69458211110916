import { AxiosError, AxiosResponse, AxiosStatic, CancelToken } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { AXIOS_SLICES, SLICE_PIE } from "_react/shared/_constants/axios";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { IApiResponse } from "_react/shared/data_models/_types";

export type TValue = string | number | boolean | null | undefined | Dayjs;
export type TValueWithNested = TValue | TDict[];
export type TDict = { [index: string]: TValue };
export type TDictWithNested = { [index: string]: TValueWithNested };
// This type extends AxiosResponse with our standardized API response properties
// Use our internal representation of the `data` prop
export interface IAxiosResponseCustom<T> extends Omit<AxiosResponse<T>, "data">, IApiResponse<T> {}

export function isAxiosError(error: Error | AxiosError): error is AxiosError {
	return (error as AxiosError).response !== undefined;
}

export const handleAxiosError = (error: Error | AxiosError): Promise<undefined> => {
	if ((AXIOS_SLICES[SLICE_PIE] as AxiosStatic).isCancel(error)) return Promise.resolve(undefined);
	throw error;
};

export const displayAxiosErrorToast = (
	data: AxiosError | Error | undefined,
	toast: CreateToastFnReturn | undefined,
	errorMessageTitle: string,
	defaultErrorMessage: string
) => {
	// Given the data from an xState errored event, parse any error messages returned from the API to be displayed
	// to the user.  Otherwise, display a default error message.
	const parsedErrorMessage =
		data && isAxiosError(data) && data.response?.data?.clientErrorMessage
			? data.response.data.clientErrorMessage
			: defaultErrorMessage;
	if (toast) {
		toast({
			title: errorMessageTitle,
			description: parsedErrorMessage,
			...DEFAULT_TOAST_ERROR_PROPS
		});
	}
};

export function buildPathWithQueryParams<T extends TDict | undefined>(basePath: string, queryParams?: T): string {
	if (queryParams === undefined) return basePath;

	let isFirstQueryParam = true;
	let queryParamsString = "?";
	Object.entries(queryParams).forEach((param: [string, TValue]) => {
		let paramValue = param[1];
		if (paramValue != null) {
			if (dayjs.isDayjs(paramValue)) paramValue = paramValue.format("YYYY-MM-DD HH:mm:ss");
			queryParamsString =
				queryParamsString + `${isFirstQueryParam ? "" : "&"}${param[0]}=${encodeURIComponent(paramValue)}`;
			isFirstQueryParam = false;
		}
	});
	return `${basePath}${queryParamsString}`;
}

//
// Utils for API requests where we want to return just the response data
//

export function fetchResource<TQueryParam extends TDict | undefined, TResponse>(
	basePath: string,
	queryParams: TQueryParam,
	cancelToken?: CancelToken,
	slice: string = SLICE_PIE
) {
	const axios = AXIOS_SLICES[slice];
	const path = buildPathWithQueryParams<TQueryParam>(basePath, queryParams);
	return axios
		.get(path, { cancelToken })
		.then((response: AxiosResponse<TResponse>) => response.data)
		.catch(handleAxiosError);
}

export function postResource<TPostBody extends TDictWithNested, TResponse, TQueryParam extends TDict = TDict>(
	basePath: string,
	body: TPostBody,
	queryParams?: TQueryParam,
	cancelToken?: CancelToken,
	slice: string = SLICE_PIE
) {
	const axios = AXIOS_SLICES[slice];
	const path = buildPathWithQueryParams<TQueryParam>(basePath, queryParams);
	return axios
		.post(path, body, { cancelToken })
		.then((response: AxiosResponse<TResponse>) => response.data)
		.catch(handleAxiosError);
}

export function putResource<TPutBody extends TDictWithNested, TResponse, TQueryParam extends TDict = TDict>(
	basePath: string,
	body: TPutBody,
	queryParams?: TQueryParam,
	cancelToken?: CancelToken,
	slice: string = SLICE_PIE
) {
	const axios = AXIOS_SLICES[slice];
	const path = buildPathWithQueryParams<TQueryParam>(basePath, queryParams);
	return axios
		.put(path, body, { cancelToken })
		.then((response: AxiosResponse<TResponse>) => response.data)
		.catch(handleAxiosError);
}

export function deleteResource<TQueryParam extends TDict, TResponse>(
	basePath: string,
	queryParams?: TQueryParam,
	cancelToken?: CancelToken,
	slice: string = SLICE_PIE
) {
	const axios = AXIOS_SLICES[slice];
	const path = buildPathWithQueryParams<TQueryParam>(basePath, queryParams);
	return axios
		.delete(path, { cancelToken })
		.then((response: AxiosResponse<TResponse>) => response.data)
		.catch(handleAxiosError);
}

//
// Utils for API requests where we want to return the entire response
//
