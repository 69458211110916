import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";

import {
	ISwingMetricsReferenceApiResponse,
	ISwingMetricsInferredReferenceApiResponse,
	ISeasonalSwingMetrics,
	ISeasonalSwingMetricsInferredApiResponse
} from "_react/shared/data_models/seasonal_grades/_types";

// swing_metrics_reference

export type TSwingMetricsReferenceGetQueryParams = {
	season?: number;
	level?: string;
	qualifiedHitters?: number;
	meanBatSpeed?: number;
	stdBatSpeed?: number;
	meanAttackAngleVertical?: number;
	stdAttackAngleVertical?: number;
	meanAttackAngleHorizontal?: number;
	stdAttackAngleHorizontal?: number;
	meanBatAngleVertical?: number;
	stdBatAngleVertical?: number;
	meanBatAngleHorizontal?: number;
	stdBatAngleHorizontal?: number;
	meanTimeToContact?: number;
	stdTimeToContact?: number;
	meanRelPoc?: number;
	stdRelPoc?: number;
	meanBatSpeedEvent?: number;
	stdBatSpeedEvent?: number;
	meanAttackAngleVerticalEvent?: number;
	stdAttackAngleVerticalEvent?: number;
	meanAttackAngleHorizontalEvent?: number;
	stdAttackAngleHorizontalEvent?: number;
	meanBatAngleVerticalEvent?: number;
	stdBatAngleVerticalEvent?: number;
	meanBatAngleHorizontalEvent?: number;
	stdBatAngleHorizontalEvent?: number;
	meanTimeToContactEvent?: number;
	stdTimeToContactEvent?: number;
	meanRelPocEvent?: number;
	stdRelPocEvent?: number;
	isPriorSeason?: boolean;
	includeAttackAngleVerticalNormalDistribution?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchSwingMetricsReference = (
	queryParams: TSwingMetricsReferenceGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TSwingMetricsReferenceGetQueryParams, Array<ISwingMetricsReferenceApiResponse>>(
		"/swing_metrics_reference",
		queryParams,
		cancelToken
	);

export type TSwingMetricsInferredReferenceGetQueryParams = {
	season?: number;
	level?: string;
	qualifiedHitters?: number;
	meanBatSpeedInferred?: number;
	stdBatSpeedInferred?: number;
	meanAttackAngleVerticalInferred?: number;
	stdAttackAngleVerticalInferred?: number;
	meanAttackAngleHorizontalInferred?: number;
	stdAttackAngleHorizontalInferred?: number;
	meanBatAngleVerticalInferred?: number;
	stdBatAngleVerticalInferred?: number;
	meanClosureRateInferred?: number;
	stdClosureRateInferred?: number;
	isPriorSeason?: boolean;
	includeAttackAngleVerticalNormalDistribution?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchSwingMetricsInferredReference = (
	queryParams: TSwingMetricsInferredReferenceGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TSwingMetricsInferredReferenceGetQueryParams, Array<ISwingMetricsInferredReferenceApiResponse>>(
		"/swing_metrics_inferred_reference",
		queryParams,
		cancelToken
	);

// swing_metrics
export type TSeasonalSwingMetricsGetQueryParams = {
	playerId?: number;
	season?: number;
	playingLevel?: string;
	gameType?: string;
	bats?: string;
	"bats[in]"?: string;
	throws?: string;
	pa?: number;
	"pa[gte]"?: number;
	"pa[lte]"?: number;
	pitches?: number;
	"pitches[gte]"?: number;
	"pitches[lte]"?: number;
	swings?: number;
	"swings[gte]"?: number;
	"swings[lte]"?: number;
	nBip?: number;
	"nBip[gte]"?: number;
	"nBip[lte]"?: number;
	swingSpeedGrade?: number;
	"swingSpeedGrade[gte]"?: number;
	"swingSpeedGrade[lte]"?: number;
	batToBallGrade?: number;
	"batToBallGrade[gte]"?: number;
	"batToBallGrade[lte]"?: number;
	swingDecisionGrade?: number;
	"swingDecisionGrade[gte]"?: number;
	"swingDecisionGrade[lte]"?: number;
	attackAngle?: number;
	"attackAngle[gte]"?: number;
	"attackAngle[lte]"?: number;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchSeasonalSwingMetrics = (
	queryParams: TSeasonalSwingMetricsGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TSeasonalSwingMetricsGetQueryParams, Array<ISeasonalSwingMetrics>>(
		"/seasonal_swing_metrics",
		queryParams,
		cancelToken
	);

// seasonal_swing_metrics_inferred
export type TSeasonalSwingMetricsInferredGetQueryParams = {
	playerId?: number;
	season?: number;
	playingLevel?: string;
	bats?: string;
	"bats[in]"?: string;
	throws?: string;
	"throws[in]"?: string;
	batSpeedInferred?: number;
	"batSpeedInferred[gte]"?: number;
	"batSpeedInferred[lte]"?: number;
	attackAngleVerticalInferred?: number;
	"attackAngleVerticalInferred[gte]"?: number;
	"attackAngleVerticalInferred[lte]"?: number;
	attackAngleHorizontalInferred?: number;
	"attackAngleHorizontalInferred[gte]"?: number;
	"attackAngleHorizontalInferred[lte]"?: number;
	batAngleVerticalInferred?: number;
	"batAngleVerticalInferred[gte]"?: number;
	"batAngleVerticalInferred[lte]"?: number;
	closureRateInferred?: number;
	"closureRateInferred[gte]"?: number;
	"closureRateInferred[lte]"?: number;
	battedBalls?: number;
	"battedBalls[gte]"?: number;
	"battedBalls[lte]"?: number;
	isRegressed?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
	schema?: string;
};

export const fetchSeasonalSwingMetricsInferred = (
	queryParams: TSeasonalSwingMetricsInferredGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TSeasonalSwingMetricsInferredGetQueryParams, Array<ISeasonalSwingMetricsInferredApiResponse>>(
		"/seasonal_swing_metrics_inferred",
		queryParams,
		cancelToken
	);
