import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
	useToast,
	VStack,
	HStack,
	Box,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	MenuDivider,
	Portal,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Tooltip,
	Spacer,
	Text,
	Flex,
	SystemStyleObject
} from "@chakra-ui/react";

import { useMachine } from "@xstate/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { PITCH_TYPES } from "_react/shared/_constants/pitch_types";
import { ILkPitchType } from "_react/shared/_types/phil_data/lk_pitch_type";
import { getAmaLevelDisplayText, getSeasonFilters } from "_react/shared/_helpers/stats";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import { updateFilters, getMinAndMaxSeason } from "_react/shared/_helpers/stats";
import { IPlayerSeasonArsenalScoresLkLevel } from "_react/shared/data_models/arsenal_scores/_types";
import { BATS_L, BATS_OVERALL, BATS_R, PITCH_TYPE_OVERALL } from "_react/shared/data_models/arsenal_scores/_constants";
import {
	transformMetricReliability,
	TMetricReliabilityThresholds
} from "_react/shared/data_models/metric_reliability/_helpers";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";
import { IPlayerSeasonArsenalScoresByTeamSchema } from "_react/shared/data_models/arsenal_scores/_types";
import { isDefaultFilters, DEFAULT_STATS_TABLE_FILTERS } from "_react/shared/ui/data/tables/shared/Filters";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import { VALID_AMA_LEVELS } from "_react/shared/data_models/team/_constants";
import DataSourceBadges, {
	DATA_SOURCE_HAWKEYE,
	DATA_SOURCE_TRACKMAN
} from "_react/shared/ui/presentation/components/DataSourceBadges/DataSourceBadges";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import {
	PITCHER_FOUNDATIONAL_SKILLS_COLUMNS,
	PITCHER_FOUNDATIONAL_SKILLS_PARENT_COLUMNS,
	NUM_DISPLAY_SEASONS,
	SHAPE,
	STUFF
} from "_react/shared/ui/data/tables/AmaPitcherFoundationalSkillsTable/_constants";
import AmaPitcherFoundationalSkillsTableMachine, {
	TAmaPitcherFoundationalSkillsTableContext,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM,
	SET_PLAYER_ID,
	SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM,
	SET_FILTERS,
	SET_DISPLAY_STUFF_OR_SHAPE,
	FETCHING_METRIC_RELIABILITY,
	SET_METRIC_RELIABILITY
} from "_react/shared/ui/data/tables/AmaPitcherFoundationalSkillsTable/_machine";
import {
	TStuffOrShapeValue,
	TAmaPitcherFoundationalSkillsTableData,
	TAmaPitcherFoundationalSkillsRow
} from "_react/shared/ui/data/tables/AmaPitcherFoundationalSkillsTable/_types";

type TAmaPitcherFoundationalSkillsTableStyle = {
	container?: SystemStyleObject;
	tableContainer?: SystemStyleObject;
};

type TAmaPitcherFoundationalSkillsTableProps = {
	title?: string;
	playerId?: number;
	data?: TAmaPitcherFoundationalSkillsTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<TAmaPitcherFoundationalSkillsRow, keyof TAmaPitcherFoundationalSkillsRow>;
	style?: TAmaPitcherFoundationalSkillsTableStyle;
};

const AmaPitcherFoundationalSkillsTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TAmaPitcherFoundationalSkillsTableProps) => {
	const toast = useToast();
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const [current, send] = useMachine(
		AmaPitcherFoundationalSkillsTableMachine(playerIdProp, data, shouldFetchData, toast)
	);
	const isFetchingPlayerSeasonArsenalScoresByTeam = current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM);
	const isFetchingMetricReliability = current.matches(FETCHING_METRIC_RELIABILITY);
	const isLoading = isFetchingPlayerSeasonArsenalScoresByTeam || isFetchingMetricReliability;

	const context = current.context as TAmaPitcherFoundationalSkillsTableContext;
	const { playerId, filters, playerSeasonArsenalScoresByTeam, metricReliability, displayStuffOrShape } = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonArsenalScoresByTeam !== playerSeasonArsenalScoresByTeam && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM, value: data?.playerSeasonArsenalScoresByTeam });
	}, [send, data?.playerSeasonArsenalScoresByTeam, playerSeasonArsenalScoresByTeam, shouldFetchData]);
	useEffect(() => {
		if (data?.metricReliability !== metricReliability && shouldFetchData === false)
			send({ type: SET_METRIC_RELIABILITY, value: data?.metricReliability });
	}, [send, data?.metricReliability, metricReliability, shouldFetchData]);

	// Get max and min season
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<IPlayerSeasonArsenalScoresByTeamSchema>(playerSeasonArsenalScoresByTeam ?? []),
		[playerSeasonArsenalScoresByTeam]
	);

	//
	// Season filter options
	//
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IPlayerSeasonArsenalScoresLkLevel> = useMemo(
		() =>
			playerSeasonArsenalScoresByTeam
				?.reduce(
					(acc: Array<IPlayerSeasonArsenalScoresLkLevel>, curr: IPlayerSeasonArsenalScoresByTeamSchema) => {
						const levelRel: IPlayerSeasonArsenalScoresLkLevel | undefined = curr.team?.levelRel;
						if (levelRel && levelRel.value && !acc.some(val => val.value === levelRel.value))
							acc.push(levelRel);
						return acc;
					},
					[]
				)
				?.sort(
					(a: IPlayerSeasonArsenalScoresLkLevel, b: IPlayerSeasonArsenalScoresLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[playerSeasonArsenalScoresByTeam]
	);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: IPlayerSeasonArsenalScoresLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Pitch type options
	//

	// Compute the pitch type options
	const pitchTypeOptions: Array<ILkPitchType> = useMemo(
		() =>
			playerSeasonArsenalScoresByTeam
				?.reduce((acc: Array<ILkPitchType>, curr: IPlayerSeasonArsenalScoresByTeamSchema) => {
					const pitchType: ILkPitchType | undefined = curr.lkPitchType;
					if (
						pitchType &&
						pitchType.abbreviation &&
						!acc.some(val => val.abbreviation === pitchType.abbreviation) &&
						PITCH_TYPES.includes(pitchType.abbreviation as TPitchTypes)
					)
						acc.push(pitchType);
					return acc;
				}, [])
				?.sort((a: ILkPitchType, b: ILkPitchType) => a.sortOrder - b.sortOrder) ?? [],
		[playerSeasonArsenalScoresByTeam]
	);

	// Once the pitch type options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.pitchTypes === undefined && pitchTypeOptions.length > 0) {
			const pitchTypeAbbreviations = pitchTypeOptions.map(
				(pitchTypeOption: ILkPitchType) => pitchTypeOption.abbreviation ?? ""
			);
			const newFilters = {
				...filters,
				pitchTypes: [...pitchTypeAbbreviations, PITCH_TYPE_OVERALL]
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, pitchTypeOptions]);

	//
	// Filter data for table
	//

	// Combine bats filters into "OVR" when possible
	const batsFilter: string = useMemo(() => (filters.bats.length === 2 ? BATS_OVERALL : filters.bats?.[0]), [
		filters.bats
	]);

	const filteredPlayerSeasonArsenalScoresByTeamData:
		| Array<IPlayerSeasonArsenalScoresByTeamSchema>
		| undefined
		| null = useMemo(() => {
		if (isLoading) return undefined;
		if (!playerSeasonArsenalScoresByTeam) return playerSeasonArsenalScoresByTeam;
		return playerSeasonArsenalScoresByTeam.filter(
			(scores: IPlayerSeasonArsenalScoresByTeamSchema) =>
				scores.season <= seasonFilters.maxSeason &&
				scores.season >= seasonFilters.minSeason &&
				scores.bats === batsFilter &&
				(filters.levels === undefined || filters.levels.includes(scores.team?.level ?? ""))
		);
	}, [isLoading, seasonFilters, batsFilter, filters.levels, playerSeasonArsenalScoresByTeam]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		let availablePitchTypes = pitchTypeOptions.map(
			(pitchTypeOption: ILkPitchType) => pitchTypeOption.abbreviation ?? ""
		);
		availablePitchTypes = [...availablePitchTypes, PITCH_TYPE_OVERALL];
		const availableLevels = levelFilterOptions.map((option: IPlayerSeasonArsenalScoresLkLevel) => option.value);

		return isDefaultFilters(filters, availablePitchTypes, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, pitchTypeOptions, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: DEFAULT_STATS_TABLE_FILTERS });
	}, [send]);

	//
	// Combine data for table
	//

	const combinedTableData: Array<TAmaPitcherFoundationalSkillsRow> | undefined = useMemo(() => {
		if (!filteredPlayerSeasonArsenalScoresByTeamData) return undefined;
		const combinedData: TAmaPitcherFoundationalSkillsRow[] = [];
		filteredPlayerSeasonArsenalScoresByTeamData?.forEach((gradesByTeam: IPlayerSeasonArsenalScoresByTeamSchema) => {
			combinedData.push({
				playerSeasonArsenalScoresByTeam: gradesByTeam
			});
		});

		// Cache totals for usage calculations
		// Mapping: season-teamId -> total pitches thrown for PITCH_TYPE_OVERALL
		const usageDict: Record<string, number> = {};
		combinedData.forEach((row: TAmaPitcherFoundationalSkillsRow) => {
			// Add data from top level row
			if (
				row.playerSeasonArsenalScoresByTeam.total != null &&
				row.playerSeasonArsenalScoresByTeam.pitchType === PITCH_TYPE_OVERALL
			)
				usageDict[
					`${row.playerSeasonArsenalScoresByTeam.season}-${
						"teamId" in row.playerSeasonArsenalScoresByTeam
							? row.playerSeasonArsenalScoresByTeam.teamId
							: undefined
					}`
				] = row.playerSeasonArsenalScoresByTeam.total;
		});

		// Instead of just returning the rows, filter pitch types and augment each row with the usage % calculation
		return combinedData.reduce(
			(acc: TAmaPitcherFoundationalSkillsRow[], data: TAmaPitcherFoundationalSkillsRow) => {
				// Filter pitch types
				if (!filters.pitchTypes?.includes(data.playerSeasonArsenalScoresByTeam.pitchType)) return acc;

				//
				// Augment parent row with usage calculation
				//

				// Create the key for total pitch lookup based on season and team id
				const key = `${data.playerSeasonArsenalScoresByTeam.season}-${
					(data.playerSeasonArsenalScoresByTeam as IPlayerSeasonArsenalScoresByTeamSchema).teamId
				}`;
				// Lookup total in cache
				const usageTotal = usageDict[key];
				// Check if this is an overall row (for which, don't display since it's always 100%)
				const isOverallRow = data.playerSeasonArsenalScoresByTeam.pitchType === PITCH_TYPE_OVERALL;
				acc.push({
					playerSeasonArsenalScoresByTeam: {
						...data.playerSeasonArsenalScoresByTeam,
						// Augment with usage calculation (total pitches for this row divided by total pitches for season-teamId)
						usage: isOverallRow
							? null
							: usageTotal
							? (data.playerSeasonArsenalScoresByTeam.total ?? 0) / usageTotal
							: null
					}
				});
				return acc;
			},
			[]
		);
	}, [filters.pitchTypes, filteredPlayerSeasonArsenalScoresByTeamData]);

	// Transform metric reliability thresholds
	const transformedMetricReliability: { [index: string]: TMetricReliabilityThresholds } = useMemo(() => {
		return transformMetricReliability(metricReliability);
	}, [metricReliability]);

	// Handle shape vs. score column toggle
	// only display toggle if columns aren't specified or specified columns includes both stuff and shape
	const isColumnsIncludeStuffAndShape =
		!columns || (columns.find(col => col.includes(STUFF)) && columns.find(col => col.includes(SHAPE)));
	const handleSetDisplayStuffOrShape = (value: TStuffOrShapeValue) => {
		send({ type: SET_DISPLAY_STUFF_OR_SHAPE, value: value });
	};

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns)
			return PITCHER_FOUNDATIONAL_SKILLS_COLUMNS(batsFilter, displayStuffOrShape, transformedMetricReliability);
		return PITCHER_FOUNDATIONAL_SKILLS_COLUMNS(
			batsFilter,
			isColumnsIncludeStuffAndShape ? displayStuffOrShape : undefined,
			transformedMetricReliability
		).filter((col: TColumn<TAmaPitcherFoundationalSkillsRow, keyof TAmaPitcherFoundationalSkillsRow>) =>
			columns.includes(col.value)
		);
	}, [columns, batsFilter, displayStuffOrShape, isColumnsIncludeStuffAndShape, transformedMetricReliability]);

	// Filtering
	const handleBatsSelect = (value: string) => {
		const newFilters = {
			...filters,
			bats: updateFilters(filters.bats, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handlePitchTypesSelect = (value: string) => {
		const newFilters = {
			...filters,
			pitchTypes: updateFilters(filters.pitchTypes ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
					<DataSourceBadges dataSources={[DATA_SOURCE_TRACKMAN, DATA_SOURCE_HAWKEYE]} />
				</HStack>
				<HStack gap={1}>
					{isColumnsIncludeStuffAndShape && (
						<ToggleButton<TStuffOrShapeValue>
							toggleOptions={[
								{ value: STUFF, label: "Stuff" },
								{ value: SHAPE, label: "Shape" }
							]}
							value={displayStuffOrShape}
							onSelect={handleSetDisplayStuffOrShape}
							isDisabled={isLoading}
						/>
					)}
					{isShowFilters && (
						<Menu closeOnSelect={false} placement="left-start">
							<ButtonGroup
								isAttached
								variant={defaultFiltersSet ? "outline" : "solid"}
								colorScheme={defaultFiltersSet ? undefined : "blue"}
							>
								{!defaultFiltersSet && (
									<IconButton
										aria-label="Close"
										icon={<CloseIcon fill="white" />}
										onClick={resetFilters}
									/>
								)}
								<MenuButton
									as={IconButton}
									aria-label="Options"
									icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
								>
									MenuItem
								</MenuButton>
							</ButtonGroup>
							<Portal>
								<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
									<MenuOptionGroup title="Bats" type="checkbox" value={filters.bats}>
										<MenuItemOption value={BATS_L} onClick={() => handleBatsSelect(BATS_L)}>
											Left
										</MenuItemOption>
										<MenuItemOption value={BATS_R} onClick={() => handleBatsSelect(BATS_R)}>
											Right
										</MenuItemOption>
									</MenuOptionGroup>
									<MenuDivider />
									<MenuOptionGroup title="Seasons">
										<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
											{minSeason === maxSeason && (
												<Tooltip
													hasArrow
													placement="top"
													label="Only one season of data exists"
												>
													<HStack>
														<OutlineInfo color="gray.500" />
														<Text>{minSeason}</Text>
													</HStack>
												</Tooltip>
											)}
											{minSeason !== maxSeason && (
												<VStack>
													<RangeSlider
														value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
														min={minSeason}
														max={maxSeason}
														step={1}
														onChange={(seasons: number[]) => {
															send({
																type: SET_FILTERS,
																value: {
																	...filters,
																	minSeason: seasons[0],
																	maxSeason: seasons[1]
																}
															});
														}}
														onMouseEnter={() => setShowSeasonRangeTooltip(true)}
														onMouseLeave={() => setShowSeasonRangeTooltip(false)}
													>
														<RangeSliderTrack>
															<RangeSliderFilledTrack bg="black" />
														</RangeSliderTrack>
														<Tooltip
															hasArrow
															placement="top"
															isOpen={showSeasonRangeTooltip}
															label={seasonFilters.minSeason}
														>
															<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
														</Tooltip>
														<Tooltip
															hasArrow
															placement="top"
															isOpen={showSeasonRangeTooltip}
															label={seasonFilters.maxSeason}
														>
															<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
														</Tooltip>
													</RangeSlider>
													<Flex sx={{ width: "100%" }}>
														<Text fontSize="sm">{minSeason}</Text>
														<Spacer />
														<Text fontSize="sm">{maxSeason}</Text>
													</Flex>
												</VStack>
											)}
										</VStack>
									</MenuOptionGroup>
									<MenuOptionGroup
										title="Pitch Types"
										type="checkbox"
										value={filters.pitchTypes ?? [PITCH_TYPE_OVERALL, ...PITCH_TYPES]}
									>
										<MenuItemOption
											value={PITCH_TYPE_OVERALL}
											onClick={() => handlePitchTypesSelect(PITCH_TYPE_OVERALL)}
										>
											Overall
										</MenuItemOption>
										{pitchTypeOptions.map((pitchType: ILkPitchType) => (
											<MenuItemOption
												value={pitchType.abbreviation ?? ""}
												onClick={() => handlePitchTypesSelect(pitchType.abbreviation ?? "")}
												key={`${pitchType.abbreviation}`}
											>
												<PitchTypeLabel
													label={pitchType.label ?? ""}
													abbreviation={pitchType.abbreviation ?? ""}
													shape={ICON_CIRCLE}
												/>
											</MenuItemOption>
										))}
									</MenuOptionGroup>
									<MenuOptionGroup
										title="Levels"
										type="checkbox"
										value={filters.levels ?? VALID_AMA_LEVELS}
									>
										{levelFilterOptions.map((option: IPlayerSeasonArsenalScoresLkLevel) => (
											<MenuItemOption
												value={option.value}
												onClick={() => handleLevelSelect(option.value)}
												key={`${option.value}`}
											>
												<TeamLevelBadge
													level={option.value}
													displayName={getAmaLevelDisplayText(option.value)}
												/>
											</MenuItemOption>
										))}
									</MenuOptionGroup>
								</MenuList>
							</Portal>
						</Menu>
					)}
				</HStack>
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<TAmaPitcherFoundationalSkillsRow, keyof TAmaPitcherFoundationalSkillsRow>
					columns={filteredColumns}
					parentColumns={PITCHER_FOUNDATIONAL_SKILLS_PARENT_COLUMNS(displayStuffOrShape)}
					data={combinedTableData}
					emptyDataDisplayText={"No Foundational Skills Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					isExpandableRows
					getCustomRowKeyFunction={(row: TAmaPitcherFoundationalSkillsRow) => {
						return `${row.playerSeasonArsenalScoresByTeam.season}-${row.playerSeasonArsenalScoresByTeam.teamId}-${row.playerSeasonArsenalScoresByTeam.pitchType}`;
					}}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: DESC
						},
						{
							columnValue: "level",
							sortDirection: ASC
						},
						{
							columnValue: "team",
							sortDirection: ASC
						},
						{
							columnValue: "pitchType",
							sortDirection: ASC
						},
						{
							columnValue: "pitches",
							sortDirection: DESC
						}
					]}
					getRowStyleFunction={(
						obj: TAmaPitcherFoundationalSkillsRow,
						index: number,
						data: Array<TAmaPitcherFoundationalSkillsRow>
					) => {
						if (
							index < data.length - 1 &&
							obj.playerSeasonArsenalScoresByTeam.season !==
								data[index + 1].playerSeasonArsenalScoresByTeam.season
						) {
							return {
								borderBottom: "1px solid !important",
								borderBottomColor: "gray.300 !important"
							};
						}
						return {};
					}}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default AmaPitcherFoundationalSkillsTable;
