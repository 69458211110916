import React, { useEffect } from "react";
import { useToast, SystemStyleObject } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import { round10 } from "_react/shared/_helpers/numbers";
import { NULL_FILLER_TEXT_PROJECTIONS_TAB } from "_react/playerpage/_constants";
import { SV_COLOR_GRADIENT_TEXT } from "_react/playerpage/_constants";
import { IPrimaryStatProps } from "_react/shared/ui/presentation/components/stat/PrimaryStat/PrimaryStat";
import { ISecondaryStatProps } from "_react/shared/ui/presentation/components/stat/SecondaryStat/SecondaryStat";
import { FORMAT_CURRENCY_MILLIONS, SM } from "_react/shared/ui/presentation/components/stat/shared/_constants";
import StatCard from "_react/shared/ui/presentation/components/stat/StatCard/StatCard";
import DollarSign from "_react/shared/ui/icons/DollarSign";
import { IAmaProspectValue } from "_react/shared/data_models/phred/_types";
import { MODEL_NAME_V2, PREDICTION_TYPE_FOL } from "_react/shared/data_models/phred/_constants";
import { TOOLTIP_AMA_PROSPECT_VALUE, TOOLTIP_AMA_PREDICTION_TYPE_FOL } from "_react/shared/_constants/tooltips";
import { AMA_PLAYER_TYPE_ICON_MAP, AMA_PLAYER_TYPE_LABEL_MAP } from "_react/shared/data_models/player/_constants";

import createAmaProspectValuesStatCardMachine, {
	TAmaProspectValuesStatCardContext,
	SET_PLAYER_ID,
	SET_AMA_PROSPECT_VALUES,
	FETCHING_AMA_PROSPECT_VALUES
} from "_react/shared/ui/data/cards/AmaProspectValuesStatCard/_machine";

export type TAmaProspectValuesStatCardData = {
	amaProspectValues?: Array<IAmaProspectValue> | null;
	isLoading?: boolean;
};

type TAmaProspectValueSectionProps = {
	playerId?: number;
	playerType?: string;
	teamLevel?: string | null;
	year?: number;
	shouldFetchData?: boolean;
	data?: TAmaProspectValuesStatCardData;
	style?: SystemStyleObject;
};

const AmaProspectValuesStatCard = ({
	playerId,
	playerType,
	teamLevel,
	year,
	data,
	shouldFetchData = true,
	style
}: TAmaProspectValueSectionProps) => {
	const toast = useToast();
	const [current, send] = useMachine(createAmaProspectValuesStatCardMachine(playerId, shouldFetchData, data, toast));
	const { amaProspectValues } = current.context as TAmaProspectValuesStatCardContext;

	const fetchingAmaProspectValues = current.matches(FETCHING_AMA_PROSPECT_VALUES);
	const isLoading = shouldFetchData ? fetchingAmaProspectValues : data?.isLoading;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.amaProspectValues !== amaProspectValues && !shouldFetchData) {
			send({ type: SET_AMA_PROSPECT_VALUES, data: data?.amaProspectValues });
		}
	}, [data?.amaProspectValues, shouldFetchData, send, amaProspectValues]);

	const currentAmaProspectValue = amaProspectValues?.find(
		(pv: IAmaProspectValue) => pv.modelName === MODEL_NAME_V2 && pv.r4Year === year && pv.playerType === playerType
	);

	const primaryStatApv: IPrimaryStatProps = {
		label: "SV",
		value: round10(currentAmaProspectValue?.xsurplusMillions, -1),
		nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
		colorGradientInfo: SV_COLOR_GRADIENT_TEXT,
		format: FORMAT_CURRENCY_MILLIONS,
		isLoading: isLoading
	};
	const secondaryStatsApv: Array<ISecondaryStatProps> =
		currentAmaProspectValue?.predictionType === PREDICTION_TYPE_FOL
			? [
					{
						label: "Pred Type",
						value: "Follow",
						size: SM,
						tooltipLabel: TOOLTIP_AMA_PREDICTION_TYPE_FOL,
						isLoading: isLoading
					}
			  ]
			: [
					{
						label: "OVR Rank",
						value: currentAmaProspectValue?.xsurplusRank,
						nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
						size: SM,
						tooltipLabel: "Overall Rank",
						isLoading: isLoading
					},
					{
						label: "Rank",
						icon: playerType ? AMA_PLAYER_TYPE_ICON_MAP[playerType] : undefined,
						value: currentAmaProspectValue?.xsurplusRankBytype,
						nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
						size: SM,
						tooltipLabel: `${playerType ? AMA_PLAYER_TYPE_LABEL_MAP[playerType] : "Player Type"} Rank`,
						isLoading: isLoading
					},
					{
						label: `${teamLevel ?? "Level"} Rank`,
						icon: playerType ? AMA_PLAYER_TYPE_ICON_MAP[playerType] : undefined,
						value: currentAmaProspectValue?.xsurplusRankBytypeBylevel,
						nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
						size: SM,
						tooltipLabel: `${
							playerType ? AMA_PLAYER_TYPE_LABEL_MAP[playerType] : "Player Type"
						} and Level Rank`,
						isLoading: isLoading
					}
			  ];
	return (
		<>
			<StatCard
				statIcon={<DollarSign color="gray.600" boxSize={6} />}
				primaryStat={primaryStatApv}
				secondaryStats={secondaryStatsApv}
				tooltipLabel={TOOLTIP_AMA_PROSPECT_VALUE}
				style={style}
			/>
		</>
	);
};

export default AmaProspectValuesStatCard;
