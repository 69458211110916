import React from "react";
import { SystemStyleObject, HStack, Tooltip } from "@chakra-ui/react";

import { capitalizeFirstLetter, createTextList } from "utils/_helpers";
import Badge from "_react/shared/ui/presentation/components/Badge/Badge";

export const DATA_SOURCE_TRACKMAN = "trackman";
export const DATA_SOURCE_SYNERGY = "synergy";
export const DATA_SOURCE_HAWKEYE = "hawkeye";
export const DATA_SOURCE_COLLEGE_SPLITS = "collegesplits";

const DATA_SOURCE_MAP = {
	[DATA_SOURCE_COLLEGE_SPLITS]: "CS",
	[DATA_SOURCE_HAWKEYE]: "HE",
	[DATA_SOURCE_SYNERGY]: "SY",
	[DATA_SOURCE_TRACKMAN]: "TR"
};

type TDataSources =
	| typeof DATA_SOURCE_COLLEGE_SPLITS
	| typeof DATA_SOURCE_HAWKEYE
	| typeof DATA_SOURCE_SYNERGY
	| typeof DATA_SOURCE_TRACKMAN;

type TTeamBadgeProps = {
	dataSources?: Array<TDataSources>;
	nullFillerText?: string;
	style?: SystemStyleObject;
};

const DataSourceBadges = ({ dataSources, nullFillerText = "", style }: TTeamBadgeProps) => {
	const dataSourcesText = dataSources ? createTextList(dataSources.map(str => capitalizeFirstLetter(str))) : "";
	return (
		<Tooltip
			hasArrow
			placement="top"
			label={dataSources ? `Includes data from ${dataSourcesText} which may not represent all games played` : ""}
			whiteSpace="pre-wrap"
		>
			<HStack>
				{dataSources?.map((dataSource: TDataSources) => (
					<Badge
						text={DATA_SOURCE_MAP[dataSource]}
						nullFillerText={nullFillerText}
						style={style}
						color={{ color: "gray.200", text: "gray.600" }}
						key={`${dataSource}`}
					/>
				))}
			</HStack>
		</Tooltip>
	);
};

export default DataSourceBadges;
