import React, { useMemo } from "react";
import {
	useBoolean,
	Button,
	Flex,
	Text,
	IconButton,
	Tooltip,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	HStack
} from "@chakra-ui/react";
import dayjs from "dayjs";

import { $TSFixMeEval } from "_react/evals/shared/_types";
import {
	EVENT_TYPE_SELECT_CONTENT,
	CONTENT_TYPE_PLAYER_PAGES_TAB,
	CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION
} from "_react/shared/_constants/google_analytics";
import { sendReactGAEvent } from "_react/shared/_helpers/google_analytics";
import ManualPitchTypeMapBySeasonModal from "_react/shared/ui/data/modals/ManualPitchTypeMapBySeasonModal/ManualPitchTypeMapBySeasonModal";
import EntityPage from "_react/shared/ui/presentation/layouts/EntityPage/EntityPage";
import { TTab } from "_react/shared/ui/presentation/layouts/TabsWithSidebar/_types";
import { useIsGroupMember } from "_react/app/AppContents";
import { getAgeFromBirthDate } from "utils/helpers";
import { formatHeight } from "utils/functions";
import MergeType from "_react/shared/ui/icons/MergeType";
import ExpandMore from "_react/shared/ui/icons/ExpandMore";
import Tag from "_react/shared/ui/icons/Tag";
import {
	VIEW_SCOUTING_REPORTS,
	VIEW_BIO,
	VIEW_VIDEO,
	VIEW_NOTES,
	VIEW_PROJECTIONS,
	VIEW_OBSERVED,
	VIEW_INJURIES
} from "_react/shared/_constants/query_strings";
import MyListsMenu from "_react/shared/ui/data/other/MyListsMenu/MyListsMenu";
import { TOOLTIP_INTL_OBSERVED } from "_react/shared/_constants/tooltips";

import { PRO, AMA, INTL, NULL_FILLER_TEXT_HEADER } from "_react/playerpage/_constants";
import { getPlayerFullName, getIntlPosition } from "_react/shared/data_models/player/_helpers";
import { TPlayerPageCombinedPlayer, TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import { MERGE_PLAYER_ADMIN_IAM_GROUP, getAgentName } from "_react/playerpage/_helpers";
import PlayerHeadshot from "_react/shared/ui/presentation/components/PlayerHeadshot/PlayerHeadshot";
import MergePlayersModal from "_react/playerpage/shared/header/MergePlayersModal";
import CreateReportMenu from "_react/playerpage/shared/header/CreateReportMenu";
import EditBioButton from "_react/playerpage/shared/header/EditBioButton";
// TODO: Fill this out
import PlayerPageIntlProfile from "_react/playerpage/intl/profile/PlayerPageIntlProfile";
import ProjectionsTab from "_react/playerpage/intl/content/projections/ProjectionsTab";
import ObservedTab from "_react/playerpage/intl/content/observed/ObservedTab";
import InjuriesTab from "_react/playerpage/intl/content/injuries/InjuriesTab";
import ScoutingReportsTab from "_react/playerpage/shared/content/scouting_reports/ScoutingReportsTab";
import BioTab from "_react/playerpage/intl/content/bio/BioTab";
import VideoTab from "_react/playerpage/intl/content/video/VideoTab";
import NotesTab from "_react/playerpage/shared/content/notes/NotesTab";
import { TIntlBioDocument } from "_react/playerpage/bio/_types";

type PlayerPageIntlContentProps = {
	player?: TPlayerPageCombinedPlayer | null;
	evals?: $TSFixMeEval[] | null;
	setViewClassification?: (viewClassification?: TPlayerPagePlayerClassification) => void;
	viewClassification: string | null;
	setView?: (key: string, isReplaceState?: boolean) => void;
	view?: string;
	hasProjectionsPermissions?: boolean;
	hasIntlBioEditPermissions?: boolean;
	hasPitchTypeMappingEditPermissions?: boolean;
	bioDisplayOnly: boolean;
	intlBioDocument: TIntlBioDocument | null;
	setIntlBioDocument: (newDocument: TIntlBioDocument) => void;
	setBioDisplayOnly: (bioDisplayOnly: boolean) => void;
	isBioSaving: boolean;
	submitIntlBioUpdate: () => Promise<void>;
};

const PlayerPageIntlContent = ({
	player,
	evals,
	setViewClassification,
	setView,
	view,
	hasProjectionsPermissions = false,
	hasIntlBioEditPermissions = false,
	hasPitchTypeMappingEditPermissions = false,
	bioDisplayOnly,
	intlBioDocument,
	setIntlBioDocument,
	setBioDisplayOnly,
	isBioSaving,
	submitIntlBioUpdate
}: PlayerPageIntlContentProps) => {
	const [isMergeModalOpen, setIsMergeModalOpen] = useBoolean(false);
	const [isPitchRetagModalOpen, setIsPitchRetagModalOpen] = useBoolean(false);
	const canMergePlayers = useIsGroupMember(MERGE_PLAYER_ADMIN_IAM_GROUP);
	//
	// Header Content
	//

	// Player's Content

	const playerFullName = getPlayerFullName(player, false, INTL);
	const playerHeadshot = (
		<PlayerHeadshot
			playerClassification={INTL}
			dplId={player?.dplId}
			alt={playerFullName}
			style={{ zIndex: "1" }}
		/>
	);

	const birthDate = player?.intlProfile?.dateOfBirth ?? player?.birthDate;
	const height = player?.intlProfile?.height ?? player?.height;
	const weight = player?.intlProfile?.weight ?? player?.weight;
	const playerAttributesText = (
		<>
			{birthDate ? (
				<HStack>
					<Text>
						{`${dayjs(birthDate).format("M/D/YYYY")}${
							birthDate ? ` (${getAgeFromBirthDate(birthDate)})` : ""
						}`}
					</Text>
				</HStack>
			) : (
				<Text>{NULL_FILLER_TEXT_HEADER}</Text>
			)}
			<Text>|</Text>
			<Text>{height ? formatHeight(height) : NULL_FILLER_TEXT_HEADER}</Text>
			<Text>|</Text>
			<Text>{weight ? `${weight}lb` : NULL_FILLER_TEXT_HEADER}</Text>
			<Text>|</Text>
			<Text>{player?.intlProfile?.eligibleYear ?? NULL_FILLER_TEXT_HEADER}</Text>
		</>
	);

	// Player's Playing Content

	const playingDetails = (
		<>
			<>
				<Text fontSize="sm" fontWeight="bold">
					Agent
				</Text>
				<Text>{getAgentName(player?.intlProfile, NULL_FILLER_TEXT_HEADER)}</Text>
			</>
			<Text>|</Text>
			<Text>{getIntlPosition(player) ?? NULL_FILLER_TEXT_HEADER}</Text>
			<Text>|</Text>
			<Text>
				{player?.intlProfile?.bats ?? player?.bats ?? NULL_FILLER_TEXT_HEADER}/
				{player?.intlProfile?.throws ?? player?.throws ?? NULL_FILLER_TEXT_HEADER}
			</Text>
		</>
	);

	//
	// Tab and Sidebar Content
	//

	const intlTabs: Array<TTab> = useMemo(() => {
		// Tabs for which all users have permissions
		const newIntlTabs: Array<TTab> = [
			{
				label: "Observed",
				content: player ? (
					<ObservedTab player={player} evals={evals} isSelected={view === VIEW_OBSERVED} />
				) : (
					<></>
				),
				tooltip: TOOLTIP_INTL_OBSERVED,
				queryStringValue: VIEW_OBSERVED
			},
			{
				label: "Scouting",
				content: <ScoutingReportsTab evals={evals} playerId={player?.id} />,
				queryStringValue: VIEW_SCOUTING_REPORTS
			},
			{
				label: "Bio",
				content: (
					<BioTab
						bioDisplayOnly={bioDisplayOnly}
						intlBioDocument={intlBioDocument}
						setIntlBioDocument={setIntlBioDocument}
					/>
				),
				queryStringValue: VIEW_BIO
			},
			{
				label: "Video",
				content: player ? <VideoTab player={player} /> : <></>,
				queryStringValue: VIEW_VIDEO
			},
			{
				label: "Injuries",
				content: player ? <InjuriesTab player={player} /> : <></>,
				queryStringValue: VIEW_INJURIES
			},
			{
				label: "Notes",
				content: player ? <NotesTab player={player} /> : <></>,
				queryStringValue: VIEW_NOTES
			}
		];

		// Add Projections tab if user has permissions
		if (hasProjectionsPermissions) {
			newIntlTabs.splice(0, 0, {
				label: "Projections",
				content: player ? <ProjectionsTab player={player} /> : <></>,
				queryStringValue: VIEW_PROJECTIONS
			});
		}

		return newIntlTabs;
	}, [player, evals, hasProjectionsPermissions, bioDisplayOnly, intlBioDocument, setIntlBioDocument, view]);

	const intlTabsHeaderChildren = useMemo(
		() => (
			<Flex
				sx={{
					justifyContent: "center",
					flexDirection: "row",
					paddingRight: {
						base: undefined,
						md: "12"
					},
					paddingBottom: "1",
					paddingTop: "6",
					gap: "3"
				}}
			>
				<MyListsMenu playerId={player?.id} />
				<Menu>
					<MenuButton
						as={Button}
						rightIcon={<ExpandMore />}
						h="8"
						bg="transparent"
						color="gray.700"
						borderColor="gray.700"
						border="1px solid"
						fontSize="sm"
						fontWeight="semibold"
						paddingInlineStart="3"
						paddingInlineEnd="3"
					>
						INTL
					</MenuButton>
					<MenuList>
						{player?.playerProId && (
							<MenuItem
								onClick={() => {
									setViewClassification ? setViewClassification(PRO) : null;
									sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
										contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
										contentId: `${PRO}`
									});
								}}
							>
								PRO
							</MenuItem>
						)}
						{player?.playerAmaId && (
							<MenuItem
								onClick={() => {
									setViewClassification ? setViewClassification(AMA) : null;
									sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
										contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
										contentId: `${AMA}`
									});
								}}
							>
								AMA
							</MenuItem>
						)}
					</MenuList>
				</Menu>
			</Flex>
		),
		[player?.id, player?.playerAmaId, player?.playerProId, setViewClassification]
	);

	const activeTabIndex = useMemo(() => {
		const activeIndex = intlTabs.findIndex((tab: TTab) => view && tab.queryStringValue === view);
		if (activeIndex === -1) return undefined;
		return activeIndex;
	}, [intlTabs, view]);

	const handleSetTabIndex = (index: number) => {
		if (setView) setView(intlTabs[index].queryStringValue ?? "");
		sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
			contentType: CONTENT_TYPE_PLAYER_PAGES_TAB,
			contentId: intlTabs[index].queryStringValue
		});
	};

	// Header actions

	const bioTabIndex = useMemo(() => {
		const bioTabIndex = intlTabs.findIndex((tab: TTab) => view && tab.queryStringValue === VIEW_BIO);
		if (bioTabIndex === -1) return undefined;
		return bioTabIndex;
	}, [intlTabs, view]);

	const redirectToBioTab = () => {
		if (view !== VIEW_BIO && bioTabIndex) handleSetTabIndex(bioTabIndex);
	};

	const headerActions = (
		<>
			{canMergePlayers && (
				<Tooltip hasArrow placement="top" label="Merge Players">
					<IconButton
						colorScheme="gray"
						aria-label="Merge Players"
						borderRadius="full"
						icon={<MergeType boxSize={5} />}
						onClick={() => setIsMergeModalOpen.on()}
						size="sm"
					/>
				</Tooltip>
			)}
			{player && hasIntlBioEditPermissions && (
				<EditBioButton
					redirectToBioTab={redirectToBioTab}
					setBioDisplayOnly={setBioDisplayOnly}
					isBioSaving={isBioSaving}
					submitBioUpdate={submitIntlBioUpdate}
					bioDisplayOnly={bioDisplayOnly}
				/>
			)}
			{player && hasPitchTypeMappingEditPermissions && (
				<Tooltip hasArrow placement="top" label="Retag Pitch Types">
					<>
						<IconButton
							colorScheme="gray"
							aria-label="Retag Pitch Types"
							borderRadius="full"
							icon={<Tag boxSize={5} />}
							onClick={() => setIsPitchRetagModalOpen.on()}
							size="sm"
						/>
					</>
				</Tooltip>
			)}
			{player && <CreateReportMenu player={player} />}
		</>
	);

	//
	// Custom styles for layout
	//

	// If you change the height here, make sure to update `tabsWithSidebarStyle` below
	const entityHeaderStyle = { container: { height: { md: "24", lg: "16" } } };

	// Manually account for header height for vertical scrolling and footer
	// Not an optimal way to do this...
	const tabsWithSidebarStyle = { tabs: { height: { base: "calc(100% - 91px)", lg: "calc(100% - 64px)" } } };

	return (
		<>
			<EntityPage
				entityHeaderProps={{
					image: playerHeadshot,
					startContent: {
						primaryContent: playerFullName,
						secondaryContent: playerAttributesText
					},
					middleContent: {
						primaryContent: undefined,
						secondaryContent: playingDetails
					},
					endContent: headerActions,
					style: entityHeaderStyle
				}}
				tabsWithSidebarProps={{
					tabsHeaderChildren: intlTabsHeaderChildren,
					tabs: intlTabs,
					tabIndex: activeTabIndex,
					handleSetTabIndex: handleSetTabIndex,
					sidebarChildren: player ? (
						<PlayerPageIntlProfile
							player={player}
							evals={evals}
							hasProjectionsPermissions={hasProjectionsPermissions}
						/>
					) : (
						undefined
					),
					style: tabsWithSidebarStyle
				}}
				isLoading={player == null}
			/>
			{player && <MergePlayersModal player={player} isOpen={isMergeModalOpen} setIsOpen={setIsMergeModalOpen} />}
			{player && (
				<ManualPitchTypeMapBySeasonModal
					playerId={player.id}
					minSeason={player.intlProfile?.eligibleYear ? player.intlProfile.eligibleYear - 2 : undefined}
					isOpen={isPitchRetagModalOpen}
					setIsOpen={setIsPitchRetagModalOpen}
				/>
			)}
		</>
	);
};

export default PlayerPageIntlContent;
