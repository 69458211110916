import React, { CSSProperties, useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { MultiValue, SingleValue } from "react-select";

import LkSelect from "_react/inputs/lks/LkSelect";
import { TOption } from "_react/inputs/_types";
import { TChangeEvent, TextField } from "_react/shared/legacy/ui/TextField";
import { Button } from "_react/shared/legacy/ui/Button";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import Info from "_react/shared/legacy/ui/icons/Info";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { StyledSelect } from "_react/shared/selects";

import { DISABLED_OPACITY } from "_react/playerplan/shared/_constants";
import {
	SmallCircularProgressRightStyle,
	PaddedDiv,
	FloatRightDiv,
	LeftButtonStyle,
	FlexCenteredDiv,
	AddGoalTable,
	AddGoalTr,
	AddGoalTd,
	MaxWidthSelectStyle,
	NoteTextFieldStyle,
	SeasonContents,
	SeasonSelectContainer,
	TitleSpan,
	FlexVerticalCenteredDiv,
	ADD_TARGET_SEASON_WRAPPER_CLASS_NAME,
	DATEPICKER_STYLE,
	DATEPICKER_CLOSE_ICON_STYLE
} from "_react/playerplan/shared/_styles";
import { PERCENTAGE_FORMAT } from "_react/playerplan/shared/_constants";
import { TPlayerPlanGoal, TTargetForm } from "_react/playerplan/shared/_types";
import { createGoalStatement, getMostRecentActiveTarget, isValidFormat } from "_react/playerplan/shared/_helpers";

export type TAddSeasonsModalStyle = {
	checkbox?: CSSProperties;
};

export type TAddSeasonsModalProps = {
	goal: TPlayerPlanGoal;
	secondaryGoal?: TPlayerPlanGoal;
	addTarget?: (targetForm: TTargetForm) => void;
	isAddingTargets: boolean;
	additionalSeasonOpts: Array<number>;
	isGoalActionsDisabled: boolean;
	setIsSeasonsModalOpen: (isSeasonsModalOpen: boolean) => void;
	colorSchemeGroup?: ColorSchemeGroup;
};

export const AddTargetSeasonsModal = ({
	goal,
	secondaryGoal,
	addTarget,
	isAddingTargets,
	additionalSeasonOpts,
	isGoalActionsDisabled,
	setIsSeasonsModalOpen,
	colorSchemeGroup = defaultColorScheme
}: TAddSeasonsModalProps) => {
	const [isConfirmingSave, setIsConfirmingSave] = useState<boolean>(false);

	const activeTarget = getMostRecentActiveTarget(goal.playerPlanTargets);
	const activeSecondaryTarget = secondaryGoal
		? getMostRecentActiveTarget(secondaryGoal.playerPlanTargets)
		: undefined;

	// Manage Target Form state
	const defaultTargetForm: TTargetForm = useMemo(() => {
		return {
			playerPlanGoalId: goal.id,
			value: activeTarget?.value?.toString() ?? null,
			directionLk: activeTarget?.directionLk ?? null,
			directionLabel: activeTarget?.direction?.label ?? null,
			description: activeTarget?.description ?? null,
			season: additionalSeasonOpts[0],
			startDate: null,
			endDate: null,
			secondaryGoalTarget:
				secondaryGoal && activeSecondaryTarget
					? {
							playerPlanGoalId: secondaryGoal.id,
							value: activeSecondaryTarget.value?.toString() ?? null,
							directionLk: activeSecondaryTarget?.directionLk ?? null,
							directionLabel: activeSecondaryTarget?.direction?.label ?? null,
							description: activeSecondaryTarget?.description ?? null
					  }
					: undefined
		};
	}, [goal, activeTarget, additionalSeasonOpts, secondaryGoal, activeSecondaryTarget]);

	const [targetForm, setTargetForm] = useState<TTargetForm>(defaultTargetForm);

	useEffect(() => {
		setTargetForm(defaultTargetForm);
	}, [goal, defaultTargetForm]);

	return (
		<>
			<style>{`.${ADD_TARGET_SEASON_WRAPPER_CLASS_NAME} ${DATEPICKER_STYLE}`}</style>
			<style>{`.${ADD_TARGET_SEASON_WRAPPER_CLASS_NAME} ${DATEPICKER_CLOSE_ICON_STYLE}`}</style>
			<AddGoalTable>
				<colgroup>
					<col />
					<col />
					<col />
				</colgroup>
				<tbody>
					<AddGoalTr>
						<AddGoalTd colSpan={3}>
							<b>Add New Season</b>
						</AddGoalTd>
					</AddGoalTr>
					<AddGoalTr>
						<AddGoalTd colSpan={3}>
							Tier 1:{" "}
							{createGoalStatement(
								goal.playerPlanMetric.label ?? undefined,
								goal.playerPlanMetric.metricSubtype?.label ?? undefined,
								goal.playerPlanSplits,
								targetForm.directionLabel ?? undefined,
								targetForm.value ? parseFloat(targetForm.value) : undefined,
								goal.playerPlanMetric.format,
								false,
								goal.playerPlanMetric.metricType.abbreviation,
								goal.isStrength
							)}
						</AddGoalTd>
					</AddGoalTr>
					<AddGoalTr>
						<AddGoalTd>
							<LkSelect<string>
								lkName={"lk_player_plan_target_direction"}
								onChange={(opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null) =>
									setTargetForm({
										...targetForm,
										directionLk: (opt as TOption<string>)?.value,
										directionLabel: (opt as TOption<string>)?.label
									})
								}
								valueOnlyValue={targetForm.directionLk ?? null}
								isClearable
								placeholder={"Select direction..."}
								styles={MaxWidthSelectStyle}
							/>
						</AddGoalTd>
						<AddGoalTd>
							<FlexCenteredDiv>
								<TextField
									fullWidth
									type={"number"}
									onChange={(e: TChangeEvent) =>
										setTargetForm({
											...targetForm,
											value: e.target.value === "" ? null : e.target.value
										})
									}
									value={targetForm.value}
									placeholder={"Enter target value..."}
									style={NoteTextFieldStyle}
								/>
								{goal.playerPlanMetric.format === PERCENTAGE_FORMAT && (
									<Tooltip
										placement="left"
										title={"Must be a number between 0 and 1"}
										colorScheme={colorSchemeGroup.primary}
									>
										<Info />
									</Tooltip>
								)}
							</FlexCenteredDiv>
						</AddGoalTd>
					</AddGoalTr>
					<AddGoalTr>
						<AddGoalTd colSpan={3}>
							<TextField
								fullWidth
								onChange={(e: TChangeEvent) =>
									setTargetForm({ ...targetForm, description: e.target.value })
								}
								value={targetForm.description}
								placeholder={"Enter description..."}
								style={NoteTextFieldStyle}
							/>
						</AddGoalTd>
					</AddGoalTr>
					{secondaryGoal && activeSecondaryTarget && (
						<>
							<AddGoalTr>
								<AddGoalTd colSpan={3}>
									Tier 2:{" "}
									{createGoalStatement(
										secondaryGoal.playerPlanMetric.label ?? undefined,
										secondaryGoal.playerPlanMetric.metricSubtype?.label ?? undefined,
										secondaryGoal.playerPlanSplits,
										targetForm.secondaryGoalTarget?.directionLabel ?? undefined,
										targetForm.secondaryGoalTarget?.value
											? parseFloat(targetForm.secondaryGoalTarget.value)
											: undefined,
										secondaryGoal.playerPlanMetric.format,
										false,
										secondaryGoal.playerPlanMetric.metricType.abbreviation,
										secondaryGoal.isStrength
									)}
								</AddGoalTd>
							</AddGoalTr>
							<AddGoalTr>
								<AddGoalTd>
									<LkSelect<string>
										lkName={"lk_player_plan_target_direction"}
										onChange={(
											opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null
										) =>
											setTargetForm({
												playerPlanGoalId: targetForm.playerPlanGoalId,
												value: targetForm.value,
												directionLk: targetForm.directionLk,
												directionLabel: targetForm.directionLabel,
												description: targetForm.description,
												season: targetForm.season,
												startDate: targetForm.startDate,
												endDate: targetForm.endDate,
												secondaryGoalTarget: {
													...targetForm.secondaryGoalTarget!,
													directionLk: (opt as TOption<string>)?.value,
													directionLabel: (opt as TOption<string>)?.label
												}
											})
										}
										valueOnlyValue={targetForm?.secondaryGoalTarget?.directionLk ?? null}
										isClearable
										placeholder={"Select direction..."}
										styles={MaxWidthSelectStyle}
									/>
								</AddGoalTd>
								<AddGoalTd>
									<FlexCenteredDiv>
										<TextField
											fullWidth
											type={"number"}
											onChange={(e: TChangeEvent) =>
												setTargetForm({
													playerPlanGoalId: targetForm.playerPlanGoalId,
													value: targetForm.value,
													directionLk: targetForm.directionLk,
													directionLabel: targetForm.directionLabel,
													description: targetForm.description,
													season: targetForm.season,
													startDate: targetForm.startDate,
													endDate: targetForm.endDate,
													secondaryGoalTarget: {
														...targetForm.secondaryGoalTarget!,
														value: e.target.value === "" ? null : e.target.value
													}
												})
											}
											value={targetForm.secondaryGoalTarget?.value}
											placeholder={"Enter target value..."}
											style={NoteTextFieldStyle}
										/>
										{secondaryGoal.playerPlanMetric.format === PERCENTAGE_FORMAT && (
											<Tooltip
												placement="left"
												title={"Must be a number between 0 and 1"}
												colorScheme={colorSchemeGroup.primary}
											>
												<Info />
											</Tooltip>
										)}
									</FlexCenteredDiv>
								</AddGoalTd>
							</AddGoalTr>
							<AddGoalTr>
								<AddGoalTd colSpan={3}>
									<TextField
										fullWidth
										onChange={(e: TChangeEvent) =>
											setTargetForm({
												playerPlanGoalId: targetForm.playerPlanGoalId,
												value: targetForm.value,
												directionLk: targetForm.directionLk,
												directionLabel: targetForm.directionLabel,
												description: targetForm.description,
												season: targetForm.season,
												startDate: targetForm.startDate,
												endDate: targetForm.endDate,
												secondaryGoalTarget: {
													...targetForm.secondaryGoalTarget!,
													description: e.target.value
												}
											})
										}
										value={targetForm.secondaryGoalTarget?.description}
										placeholder={"Enter description..."}
										style={NoteTextFieldStyle}
									/>
								</AddGoalTd>
							</AddGoalTr>
						</>
					)}
				</tbody>
			</AddGoalTable>

			<PaddedDiv>
				<SeasonContents>
					<TitleSpan>Season</TitleSpan>
					<SeasonSelectContainer>
						<StyledSelect
							options={additionalSeasonOpts.map(season => {
								return { value: season, label: `${season}` };
							})}
							isClearable={false}
							value={
								targetForm.season ? { label: targetForm.season, value: targetForm.season } : undefined
							}
							onChange={(opt: SingleValue<TOption<number>>) => {
								setTargetForm({
									...targetForm,
									season: opt?.value ?? dayjs().year(),
									startDate:
										targetForm.startDate && dayjs(targetForm.startDate).year() === opt?.value
											? targetForm.startDate
											: null,
									endDate:
										targetForm.endDate && dayjs(targetForm.endDate).year() === opt?.value
											? targetForm.endDate
											: null
								});
							}}
							placeholder="Select season..."
						/>
					</SeasonSelectContainer>
					{targetForm.season && (
						<FlexVerticalCenteredDiv>
							<TitleSpan style={{ marginLeft: "20px", marginRight: "5px" }}>From</TitleSpan>
							<DatePicker
								id="add_goal_modal_start_date_date_picker"
								openToDate={
									dayjs().year() === targetForm.season
										? undefined
										: new Date(`${targetForm.season}/01/01`)
								}
								filterDate={(date: Date) => {
									return targetForm.endDate
										? dayjs(date).year() === targetForm.season &&
												dayjs(targetForm.endDate).isAfter(dayjs(date))
										: dayjs(date).year() === targetForm.season;
								}}
								onChange={(value: Date) => {
									setTargetForm({
										...targetForm,
										startDate: value ? dayjs(value).format("YYYY-MM-DD") : null
									});
								}}
								selected={targetForm.startDate ? dayjs(targetForm.startDate).toDate() : undefined}
								isClearable
								wrapperClassName={ADD_TARGET_SEASON_WRAPPER_CLASS_NAME}
							/>
							<TitleSpan style={{ marginLeft: "5px", marginRight: "5px" }}>to</TitleSpan>
							<DatePicker
								id="add_goal_modal_end_date_date_picker"
								openToDate={
									dayjs().year() === targetForm.season
										? undefined
										: new Date(`${targetForm.season}/12/31`)
								}
								filterDate={(date: Date) => {
									return targetForm.startDate
										? dayjs(date).year() === targetForm.season &&
												dayjs(targetForm.startDate).isBefore(dayjs(date))
										: dayjs(date).year() === targetForm.season;
								}}
								onChange={(value: Date) => {
									setTargetForm({
										...targetForm,
										endDate: value ? dayjs(value).format("YYYY-MM-DD") : null
									});
								}}
								selected={targetForm.endDate ? dayjs(targetForm.endDate).toDate() : undefined}
								isClearable
								wrapperClassName={ADD_TARGET_SEASON_WRAPPER_CLASS_NAME}
							/>
						</FlexVerticalCenteredDiv>
					)}
				</SeasonContents>
				<PaddedDiv>
					{isAddingTargets ? (
						<CircularProgress center={false} style={SmallCircularProgressRightStyle} />
					) : isConfirmingSave && addTarget ? (
						<FloatRightDiv>
							<Button
								style={LeftButtonStyle}
								disabled={
									isGoalActionsDisabled ||
									// Disabled if there is no season
									!targetForm.season ||
									// PRIMARY GOAL VALIDATION
									// If there was value set previously, disable if no value set now
									(defaultTargetForm.value && !targetForm.value) ||
									// If there was a direction set previously, disable if no direction set now
									(defaultTargetForm.directionLk && !targetForm.directionLk) ||
									// Value must be valid format if it exists
									(targetForm.value
										? !isValidFormat(targetForm.value, goal.playerPlanMetric.format)
										: false) ||
									// If value set, must also have a direction set
									(targetForm.value ? !targetForm.directionLk : false) ||
									// SECONDARY GOAL VALIDATION
									// If there was value set previously, disable if no value set now
									(defaultTargetForm?.secondaryGoalTarget?.value &&
										!targetForm?.secondaryGoalTarget?.value) ||
									// If there was a direction set previously, disable if no direction set now
									(defaultTargetForm?.secondaryGoalTarget?.directionLk &&
										!targetForm?.secondaryGoalTarget?.directionLk) ||
									// Value must be valid format if it exists
									(targetForm?.secondaryGoalTarget?.value
										? !isValidFormat(
												targetForm?.secondaryGoalTarget?.value,
												secondaryGoal?.playerPlanMetric?.format ?? null
										  )
										: false) ||
									// If value set, must also have a direction set
									(targetForm.secondaryGoalTarget?.value
										? !targetForm.secondaryGoalTarget?.directionLk
										: false)
								}
								onClick={() => {
									setIsConfirmingSave(false);
									addTarget(targetForm);
									setIsSeasonsModalOpen(false);
								}}
								title="Confirm"
								colorScheme={colorSchemeGroup.secondary}
								disabledOpacity={DISABLED_OPACITY}
							/>
							<Button
								disabled={false}
								onClick={() => setIsConfirmingSave(false)}
								title="Cancel"
								colorScheme={colorSchemeGroup.primary}
							/>
						</FloatRightDiv>
					) : (
						<FloatRightDiv>
							<Button
								disabled={false}
								onClick={() => setIsConfirmingSave(true)}
								title="Save"
								colorScheme={colorSchemeGroup.secondary}
							/>
						</FloatRightDiv>
					)}
				</PaddedDiv>
			</PaddedDiv>
		</>
	);
};
