import React from "react";
import { VStack } from "@chakra-ui/react";
import AmaPositionPlayerFoundationalSkillsTable from "_react/shared/ui/data/tables/AmaPositionPlayerFoundationalSkillsTable/AmaPositionPlayerFoundationalSkillsTable";
import AmaSwingMetricsTable from "_react/shared/ui/data/tables/AmaSwingMetricsTable/AmaSwingMetricsTable";
import PositionPlayerCollegesplitsTable from "_react/shared/ui/data/tables/PositionPlayerCollegesplitsTable/PositionPlayerCollegesplitsTable";

type TPositionPlayerStatisticsTablesProps = {
	playerId: number;
};

const PositionPlayerStatisticsTables = ({ playerId }: TPositionPlayerStatisticsTablesProps) => {
	return (
		<VStack paddingTop="2" marginBottom="10" gap="10">
			<AmaPositionPlayerFoundationalSkillsTable
				title="Foundational Skills"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<AmaSwingMetricsTable
				title="Inferred Swing Metrics"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<PositionPlayerCollegesplitsTable
				title="College Splits"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
		</VStack>
	);
};

export default PositionPlayerStatisticsTables;
