import React, { ReactNode } from "react";
import { HStack, Text } from "@chakra-ui/react";

import { IReportField, IReportLk } from "_react/shared/data_models/reports/_types";
import {
	TProbabilityField,
	TReportProbabilityValue,
	TReportProbabilityValues
} from "_react/reports/fields/custom/probability/_types";

export const extractProbabilityFields = (reportField: IReportField): TProbabilityField[] => {
	return (
		reportField.options?.map((option: IReportLk) => ({
			probabilityCode: option.value,
			label: option.label,
			value: extractProbabilityFieldValue(reportField, option.value)?.value
		})) ?? []
	);
};

export const extractProbabilityFieldValue = (
	reportField: IReportField,
	probabilityCode: string,
	value: string | null = null
): TReportProbabilityValue | null => {
	// Parse Value
	const probabilityValues: TReportProbabilityValues = parseProbabilityFieldValue(reportField, value);
	return probabilityValues[probabilityCode];
};

export const parseProbabilityFieldValue = (
	reportField: IReportField,
	value: string | null = null
): TReportProbabilityValues => {
	// Parse Values
	return JSON.parse(value ?? reportField.value ?? "{}") ?? {};
};

export const generateProbabilityFieldDisplayValue = (reportField: IReportField): ReactNode => {
	// Parse Values
	const probabilityFields: TProbabilityField[] = extractProbabilityFields(reportField);
	// Sort/Filter Values
	const sorted: TProbabilityField[] = Object.values(probabilityFields)
		.sort((a: TProbabilityField, b: TProbabilityField) => (b.value ?? 0) - (a.value ?? 0))
		.filter((field: TProbabilityField) => field.value != null);

	// Null Case
	if (sorted.length === 0) return <Text margin="0 !important">-</Text>;

	// Format
	return (
		<HStack gap="2">
			{sorted.map((field: TProbabilityField) => (
				<HStack key={field.probabilityCode} gap="1">
					<Text fontWeight={700}>{field.label}</Text>
					<Text margin="0 !important">{field.value ?? "0"}%</Text>
				</HStack>
			))}
		</HStack>
	);
};
