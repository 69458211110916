import { CancelToken } from "axios";

import { fetchResource, postResource, deleteResource, putResource } from "_react/shared/_helpers/axios";
import { SLICE_REPORTS } from "_react/shared/_constants/axios";
import { IApiResponse } from "_react/shared/data_models/_types";

import {
	IReport,
	IReportValue,
	IReportClassification,
	IReportDesignation,
	IReportType
} from "_react/shared/data_models/reports/_types";

export type ReportQueryParams = {
	// TODO: Add proper parsing in FastAPI to allow us to write "param[operator]" etc.
	id?: string;
	classification?: string;
	classification__In?: string;
	designation?: string;
	designation__In?: string;
	type?: string;
	type__In?: string;
	scoutId?: string;
	playerId?: string;
	publishDate?: string;
	publishDate__Gte?: string;
	publishDate__Lte?: string;
	createDate__Gte?: string;
	createDate__Lte?: string;
	sort?: string;
	isSortDescending?: boolean;
};

export const fetchReport = (queryParams: ReportQueryParams, cancelToken?: CancelToken) =>
	fetchResource<ReportQueryParams, IApiResponse<IReport>>("/reports/report", queryParams, cancelToken, SLICE_REPORTS);

// These will be updated to constants/the actual schema after the alpha
export type ReportPostBody = {
	id: string;
	playerId: string;
	designation: IReportDesignation;
	classification: IReportClassification;
	type: IReportType;
	versionId: string;
	scoutId: string;
	recordStatus: string;
};

export const createReport = (postBody: ReportPostBody, cancelToken?: CancelToken) =>
	postResource<ReportPostBody, IApiResponse<IReport>>(
		"/reports/report",
		postBody,
		undefined,
		cancelToken,
		SLICE_REPORTS
	);

export type ReportValuePostBody = {
	values: ReportValuePost[];
};

export type ReportValuePost = {
	reportId: string;
	fieldId: string;
	collectionIndex: number;
	fieldValue: string | null;
};

export type PreviousReportValue = ReportValuePost & {
	fieldCode: string;
};

export const updateReportValue = (postBody: ReportValuePostBody, cancelToken?: CancelToken) =>
	postResource<ReportValuePostBody, IApiResponse<IReportValue>>(
		"/reports/report/value",
		postBody,
		undefined,
		cancelToken,
		SLICE_REPORTS
	);

export type DeleteCollectionEntrySchema = {
	reportId: string;
	parentFieldId: string;
	collectionIndex: number;
};

export const deleteCollectionEntry = (queryParams: DeleteCollectionEntrySchema, cancelToken?: CancelToken) =>
	deleteResource<DeleteCollectionEntrySchema, IApiResponse<DeleteCollectionEntrySchema>>(
		"/reports/report/value",
		queryParams,
		cancelToken,
		SLICE_REPORTS
	);

export type ReportPublishPut = {
	id?: string;
	fieldsToKeep: ReportPublishFieldToKeep[];
};

export type ReportPublishFieldToKeep = {
	fieldId: string;
	collectionIndex: number;
};

export const publishReport = (putBody: ReportPublishPut, cancelToken?: CancelToken) =>
	putResource<ReportPublishPut, IApiResponse<IReport>>(
		"/reports/report/publish",
		putBody,
		undefined,
		cancelToken,
		SLICE_REPORTS
	);

export type ReportUpdatePut = {
	id?: string;
	publishDate?: string | null;
	recordStatus?: string | null;
};

export const updateReport = (putBody: ReportUpdatePut, cancelToken?: CancelToken) =>
	putResource<ReportUpdatePut, IApiResponse<IReport>>(
		"/reports/report",
		putBody,
		undefined,
		cancelToken,
		SLICE_REPORTS
	);
