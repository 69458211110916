// NOTE: This is just a demo component to let the beta group see the reports they've created. This will entirely be trashed once we create the aggregate view

import React, { useEffect, useMemo, useState } from "react";
import { Box, VStack, HStack } from "@chakra-ui/react";
import dayjs from "dayjs";

import { IApiResponse } from "_react/shared/data_models/_types";
import { IReport } from "_react/shared/data_models/reports/_types";

import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";

import { getField } from "_react/reports/_helpers";

import { fetchReport } from "_react/shared/data_models/reports/_network";

type IReportListDisplay = IReport & {
	scoutName: string;
	seenDate?: string | null;
	ofp?: string | null;
	updated: string;
	published: string | null;
};

type ReportsTableProps = {
	playerId: number;
};

export const ReportsTable = ({ playerId }: ReportsTableProps) => {
	const [loading, setLoading] = useState(false);
	const [reports, setReports] = useState<IReport[] | undefined>(undefined);
	useEffect(() => {
		// Load reports
		setLoading(true);
		fetchReport({ playerId: `${playerId}` }).then((e?: IApiResponse<IReport>) => {
			if (e?.data != null) {
				setReports(e.data);
			}
			setLoading(false);
		});
	}, [playerId]);

	const data = useMemo(() => {
		return reports?.map((report: IReport) => ({
			...report,
			scoutName: `${report.scout?.firstName} ${report.scout?.lastName}`,
			seenDate: getField(report, "seen_date", true)?.value
				? dayjs(`${getField(report, "seen_date", true)?.value}`).format("MMMM D, YYYY")
				: null,
			ofp: getField(report, "ofp", true)?.value,
			updated: dayjs(`${report.valueLastChangeDate}Z`).format("MMMM D, YYYY h:mm A"),
			published: report.publishDate ? dayjs(`${report.publishDate}Z`).format("MMMM D, YYYY h:mm A") : null
		}));
	}, [reports]);

	const columns = [
		{
			value: "scoutName",
			label: "Scout",
			valueType: VALUE_TYPE_STRING as TValueType,
			isMobile: true
		},
		{
			value: "seenDate",
			label: "Seen Date",
			valueType: VALUE_TYPE_STRING as TValueType,
			isMobile: true,
			getSortValueFunction: (report: IReportListDisplay) => dayjs(report.seenDate).unix()
		},
		{
			value: "ofp",
			label: "OFP",
			valueType: VALUE_TYPE_STRING as TValueType,
			isMobile: true,
			getSortValueFunction: (report: IReportListDisplay) => parseInt(report.ofp ?? "0", 10)
		},
		{
			value: "published",
			label: "Published",
			valueType: VALUE_TYPE_STRING as TValueType,
			isMobile: true,
			getValueFunction: (report: IReportListDisplay) => report.published ?? "Unpublished",
			getSortValueFunction: (report: IReportListDisplay) =>
				report.published ? dayjs(report.updated).unix() : dayjs(report.published).unix() * 1000
		},
		{
			value: "updated",
			label: "Last Updated",
			valueType: VALUE_TYPE_STRING as TValueType,
			isMobile: true,
			getSortValueFunction: (report: IReportListDisplay) => dayjs(report.updated).unix()
		}
	];

	// Body Rendering
	return (
		<VStack alignItems="start" width="100%">
			<HStack w="100%" justify="space-between">
				<Box fontFamily="heading" fontSize="md" fontWeight="bold">
					2025 Pro Reports
				</Box>
			</HStack>
			<Table<IReportListDisplay, keyof IReportListDisplay>
				columns={columns as Array<TColumn<IReportListDisplay, keyof IReportListDisplay>>}
				data={data}
				isLoadingData={loading}
				emptyDataDisplayText={"No Reports"}
				defaultSortColumns={[
					{
						columnValue: "updated",
						sortDirection: DESC
					}
				]}
				onRowClickFunction={(report: IReportListDisplay) => window.open(`/report?id=${report.id}`, "_blank")}
			/>
		</VStack>
	);
};

export default ReportsTable;
