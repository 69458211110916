import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	SPLIT_TYPE_TOTAL,
	SPLIT_TYPE_V_LEFT,
	SPLIT_TYPE_V_RIGHT
} from "_react/shared/data_models/ama_stats/_constants";
import { ICollegeSplitsPlayerPitchingStatsByTeam } from "_react/shared/data_models/ama_stats/_types";
import { BATS_L, BATS_R, GAME_TYPE_OVERALL } from "_react/shared/data_models/arsenal_scores/_constants";
import { fetchCollegeSplitsPlayerPitchingStatsByTeam } from "_react/shared/data_models/ama_stats/_network";

import { TPitcherCollegesplitsTableData } from "_react/shared/ui/data/tables/PitcherCollegesplitsTable/_types";

export const COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_CANCEL_SOURCE = "collegeSplitsPlayerPitchingStatsByTeam";

export type TPitcherCollegesplitsTableCancelSource = {
	[COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPitcherCollegesplitsTableFilters = {
	bats: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPitcherCollegesplitsTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TPitcherCollegesplitsTableFilters;
	collegeSplitsPlayerPitchingStatsByTeam?: Array<ICollegeSplitsPlayerPitchingStatsByTeam> | null;
	cancelSources: TPitcherCollegesplitsTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherCollegesplitsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all collegesplits pitching stats by player season team
				collegeSplitsPlayerPitchingStatsByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM = {
	initialized: { collegeSplitsPlayerPitchingStatsByTeam: "fetching" }
};

const FETCH_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_DONE =
	"done.invoke.fetchCollegeSplitsPlayerPitchingStatsByTeam:invocation[0]";
const FETCH_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_ERROR =
	"error.platform.fetchCollegeSplitsPlayerPitchingStatsByTeam:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM = "SET_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM";
export const SET_FILTERS = "SET_FILTERS";

type TFetchCollegeSplitsPlayerPitchingStatsByTeamEvent = {
	type: typeof FETCH_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_DONE;
	data: Array<ICollegeSplitsPlayerPitchingStatsByTeam> | undefined;
};
type TFetchCollegeSplitsPlayerPitchingStatsByTeamErrorEvent = {
	type: typeof FETCH_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetCollegeSplitsPlayerPitchingStatsByTeamEvent = {
	type: typeof SET_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM;
	value?: Array<ICollegeSplitsPlayerPitchingStatsByTeam>;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TPitcherCollegesplitsTableFilters;
};

type TPitcherCollegesplitsTableEvent =
	| TFetchCollegeSplitsPlayerPitchingStatsByTeamEvent
	| TFetchCollegeSplitsPlayerPitchingStatsByTeamErrorEvent
	| TSetPlayerIdEvent
	| TSetCollegeSplitsPlayerPitchingStatsByTeamEvent
	| TSetFiltersEvent;

export type TPitcherCollegesplitsTableSend = Interpreter<
	TPitcherCollegesplitsTableContext,
	IPitcherCollegesplitsTableStateSchema,
	TPitcherCollegesplitsTableEvent
>["send"];

const PitcherCollegesplitsTableMachine = (
	playerIdProp?: number,
	data?: TPitcherCollegesplitsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPitcherCollegesplitsTableContext, IPitcherCollegesplitsTableStateSchema, TPitcherCollegesplitsTableEvent>(
		{
			id: "PitcherCollegesplitsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					bats: [BATS_L, BATS_R],
					levels: undefined
				},
				collegeSplitsPlayerPitchingStatsByTeam: data?.collegeSplitsPlayerPitchingStatsByTeam,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: {
							actions: ["setPlayerId", "clearCollegeSplitsPlayerPitchingStatsByTeam"],
							cond: "shouldSetPlayerId"
						},
						SET_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM: {
							actions: "setCollegeSplitsPlayerPitchingStatsByTeam"
						},
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						collegeSplitsPlayerPitchingStatsByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchCollegeSplitsPlayerPitchingStatsByTeam",
												cond: "shouldFetchCollegeSplitsPlayerPitchingStatsByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchCollegeSplitsPlayerPitchingStatsByTeam",
									entry: ["refreshCollegeSplitsPlayerPitchingStatsByTeamCancelSource"],
									invoke: {
										src: "fetchCollegeSplitsPlayerPitchingStatsByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchCollegeSplitsPlayerPitchingStatsByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchCollegeSplitsPlayerPitchingStatsByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (
					context: TPitcherCollegesplitsTableContext,
					event: TPitcherCollegesplitsTableEvent
				) => event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchCollegeSplitsPlayerPitchingStatsByTeam: (
					context: TPitcherCollegesplitsTableContext,
					_event: TPitcherCollegesplitsTableEvent
				) =>
					context.collegeSplitsPlayerPitchingStatsByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearCollegeSplitsPlayerPitchingStatsByTeam: assign<
					TPitcherCollegesplitsTableContext,
					TPitcherCollegesplitsTableEvent
				>({
					collegeSplitsPlayerPitchingStatsByTeam: (
						_context: TPitcherCollegesplitsTableContext,
						_event: TPitcherCollegesplitsTableEvent
					) => undefined,
					cancelSources: (
						context: TPitcherCollegesplitsTableContext,
						_event: TPitcherCollegesplitsTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TPitcherCollegesplitsTableContext, TPitcherCollegesplitsTableEvent>({
					playerId: (context: TPitcherCollegesplitsTableContext, event: TPitcherCollegesplitsTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					}
				}),
				setCollegeSplitsPlayerPitchingStatsByTeam: assign<
					TPitcherCollegesplitsTableContext,
					TPitcherCollegesplitsTableEvent
				>({
					collegeSplitsPlayerPitchingStatsByTeam: (
						context: TPitcherCollegesplitsTableContext,
						event: TPitcherCollegesplitsTableEvent
					) => {
						if (event.type !== SET_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM)
							return context.collegeSplitsPlayerPitchingStatsByTeam;
						return event.value;
					},
					cancelSources: (
						context: TPitcherCollegesplitsTableContext,
						event: TPitcherCollegesplitsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM) return cancelSources;
						cancelSources[COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TPitcherCollegesplitsTableContext, TPitcherCollegesplitsTableEvent>({
					filters: (context: TPitcherCollegesplitsTableContext, event: TPitcherCollegesplitsTableEvent) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshCollegeSplitsPlayerPitchingStatsByTeamCancelSource: assign<
					TPitcherCollegesplitsTableContext,
					TPitcherCollegesplitsTableEvent
				>({
					cancelSources: (
						context: TPitcherCollegesplitsTableContext,
						_event: TPitcherCollegesplitsTableEvent
					) => {
						if (context.cancelSources[COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[
							COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_CANCEL_SOURCE
						] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchCollegeSplitsPlayerPitchingStatsByTeamSuccess: assign<
					TPitcherCollegesplitsTableContext,
					TPitcherCollegesplitsTableEvent
				>({
					collegeSplitsPlayerPitchingStatsByTeam: (
						context: TPitcherCollegesplitsTableContext,
						event: TPitcherCollegesplitsTableEvent
					) => {
						if (event.type !== FETCH_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_DONE)
							return context.collegeSplitsPlayerPitchingStatsByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchCollegeSplitsPlayerPitchingStatsByTeamErrored: (
					context: TPitcherCollegesplitsTableContext,
					event: TPitcherCollegesplitsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Pitcher CollegSplits",
						"Error fetching CollegeSplits pitching by player, season, and team"
					);
				}
			},
			services: {
				fetchCollegeSplitsPlayerPitchingStatsByTeam: (
					context: TPitcherCollegesplitsTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchCollegeSplitsPlayerPitchingStatsByTeam(
							{
								playerId: playerId,
								gameType: GAME_TYPE_OVERALL,
								"splitType[in]": `${SPLIT_TYPE_TOTAL},${SPLIT_TYPE_V_LEFT},${SPLIT_TYPE_V_RIGHT}`,
								isUseCache: true
							},
							context.cancelSources[COLLEGESPLITS_PLAYER_PITCHING_STATS_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherCollegesplitsTableMachine;
