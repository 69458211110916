import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
	useToast,
	VStack,
	HStack,
	Box,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	MenuDivider,
	Portal,
	Text,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Tooltip,
	Spacer,
	Flex,
	SystemStyleObject
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import { isDefaultFilters, DEFAULT_STATS_TABLE_FILTERS } from "_react/shared/ui/data/tables/shared/Filters";
import { getMinAndMaxSeason, getSeasonFilters, updateFilters } from "_react/shared/_helpers/stats";
import DataSourceBadges, {
	DATA_SOURCE_HAWKEYE,
	DATA_SOURCE_TRACKMAN
} from "_react/shared/ui/presentation/components/DataSourceBadges/DataSourceBadges";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { ISeasonalSwingMetricsInferredApiResponse } from "_react/shared/data_models/seasonal_grades/_types";
import {
	THROWS_L,
	THROWS_OVERALL,
	THROWS_R,
	BATS_L,
	BATS_R
} from "_react/shared/data_models/seasonal_grades/_constants";

import {
	NUM_DISPLAY_SEASONS,
	getAmaSwingMetricsColumns
} from "_react/shared/ui/data/tables/AmaSwingMetricsTable/_constants";
import AmaSwingMetricsTableMachine, {
	TAmaSwingMetricsTableContext,
	FETCHING_SEASONAL_SWING_METRICS_INFERRED,
	SET_PLAYER_ID,
	SET_SEASONAL_SWING_METRICS_INFERRED,
	SET_FILTERS
} from "_react/shared/ui/data/tables/AmaSwingMetricsTable/_machine";
import { TAmaSwingMetricsTableData } from "_react/shared/ui/data/tables/AmaSwingMetricsTable/_types";

type TAmaSwingMetricsTableStyle = {
	container?: SystemStyleObject;
	tableContainer?: SystemStyleObject;
};

type TAmaSwingMetricsTableProps = {
	title?: string;
	playerId?: number;
	data?: TAmaSwingMetricsTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<ISeasonalSwingMetricsInferredApiResponse, keyof ISeasonalSwingMetricsInferredApiResponse>;
	style?: TAmaSwingMetricsTableStyle;
};

const AmaSwingMetricsTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TAmaSwingMetricsTableProps) => {
	const toast = useToast();
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const [current, send] = useMachine(AmaSwingMetricsTableMachine(playerIdProp, data, shouldFetchData, toast));
	const isFetchingSeasonalSwingMetrics = current.matches(FETCHING_SEASONAL_SWING_METRICS_INFERRED);
	const isLoading = shouldFetchData ? isFetchingSeasonalSwingMetrics : data?.isLoading;

	const context = current.context as TAmaSwingMetricsTableContext;
	const { playerId, filters, seasonalSwingMetricsInferred } = context;

	//
	// Update machine context when props change
	//

	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.seasonalSwingMetricsInferred !== seasonalSwingMetricsInferred && shouldFetchData === false)
			send({ type: SET_SEASONAL_SWING_METRICS_INFERRED, value: data?.seasonalSwingMetricsInferred });
	}, [send, data?.seasonalSwingMetricsInferred, seasonalSwingMetricsInferred, shouldFetchData]);

	//
	// Season filter options
	//

	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<ISeasonalSwingMetricsInferredApiResponse>(seasonalSwingMetricsInferred ?? []),
		[seasonalSwingMetricsInferred]
	);
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Filter data for table
	//

	// Get columns based on throws filter
	const throwsFilter: string = useMemo(() => (filters.throws.length === 2 ? THROWS_OVERALL : filters.throws?.[0]), [
		filters.throws
	]);
	const swingMetricsColumns = useMemo(() => getAmaSwingMetricsColumns(throwsFilter), [throwsFilter]);

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return swingMetricsColumns;
		return swingMetricsColumns.filter(
			(col: TColumn<ISeasonalSwingMetricsInferredApiResponse, keyof ISeasonalSwingMetricsInferredApiResponse>) =>
				columns.includes(col.value)
		);
	}, [columns, swingMetricsColumns]);

	// Filter data
	const filteredSeasonalSwingMetricsInferred: ISeasonalSwingMetricsInferredApiResponse[] | undefined = useMemo(() => {
		if (isLoading || !throwsFilter) return undefined;
		return seasonalSwingMetricsInferred?.filter(
			(s: ISeasonalSwingMetricsInferredApiResponse) =>
				s.season <= seasonFilters.maxSeason &&
				s.season >= seasonFilters.minSeason &&
				s.throws === throwsFilter &&
				filters.bats.includes(s.bats)
		);
	}, [isLoading, seasonFilters, throwsFilter, filters.bats, seasonalSwingMetricsInferred]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(
		() => isDefaultFilters(filters, undefined, undefined, maxSeason, NUM_DISPLAY_SEASONS),
		[filters, maxSeason]
	);

	const resetFilters = useCallback(() => {
		// Remove `gameTypes` from the filters
		const { gameTypes: _, ...defaultFilters } = DEFAULT_STATS_TABLE_FILTERS;
		send({ type: SET_FILTERS, value: defaultFilters });
	}, [send]);

	//
	// Filter change handlers
	//

	const handleBatsSelect = (value: string) => {
		const newFilters = {
			...filters,
			bats: updateFilters(filters.bats, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleThrowsSelect = (value: string) => {
		const newFilters = {
			...filters,
			throws: updateFilters(filters.throws, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
					<DataSourceBadges dataSources={[DATA_SOURCE_TRACKMAN, DATA_SOURCE_HAWKEYE]} />
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false} placement="left-start">
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
								<MenuOptionGroup title="Player Bats" type="checkbox" value={filters.bats}>
									<MenuItemOption value={BATS_L} onClick={() => handleBatsSelect(BATS_L)}>
										Left
									</MenuItemOption>
									<MenuItemOption value={BATS_R} onClick={() => handleBatsSelect(BATS_R)}>
										Right
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuDivider />
								<MenuOptionGroup title="Pitcher Throws" type="checkbox" value={filters.throws}>
									<MenuItemOption value={THROWS_L} onClick={() => handleThrowsSelect(THROWS_L)}>
										Left
									</MenuItemOption>
									<MenuItemOption value={THROWS_R} onClick={() => handleThrowsSelect(THROWS_R)}>
										Right
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuDivider />
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<ISeasonalSwingMetricsInferredApiResponse, keyof ISeasonalSwingMetricsInferredApiResponse>
					columns={filteredColumns}
					data={filteredSeasonalSwingMetricsInferred}
					emptyDataDisplayText={"No Swing Metrics Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: DESC
						},
						{
							columnValue: "bats",
							sortDirection: ASC
						},
						{
							columnValue: "level",
							sortDirection: DESC
						}
					]}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default AmaSwingMetricsTable;
