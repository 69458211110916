import { lazy, ComponentType } from "react";

import {
	ADMIN,
	GROUP_PERMISSIONS,
	BATTING_ADVANCE,
	MATCHUP_MATRIX,
	SIGN_CARDS,
	LINEUPS,
	DRAFT_BOARD,
	LISTS,
	EVAL_SEARCH,
	ST_REPORTS,
	MERGE,
	INTL_PLAYER_LIST,
	POSITIONING,
	PLAYER_PLAN,
	PLAYER_PLAN_APPROVAL,
	PLAYER_PLAN_CALL_LOG,
	PLAYER_PLAN_DRILLS,
	ORG_LISTS,
	POE,
	PRO_PROJECTIONS_LEADERBOARD,
	AMA_PROJECTIONS_LEADERBOARD,
	AMA_STATISTICS_LEADERBOARD,
	PLAYER_PLAN_METRICS,
	PRO_MODEL_LEADERBOARD,
	MEDICAL_WORKBOOK,
	PLAYER_PLAN_RECENT_CHANGES,
	PLAYER_POPULATION
} from "utils/constants";
import { promiseWRetry } from "utils/helpers";
import { ELinkType, TLink } from "_react/app/_types";
import AdminIcon from "_react/shared/legacy/ui/icons/AdminIcon";
import LeaderboardIcon from "_react/shared/legacy/ui/icons/Leaderboard";
import GameplanIcon from "_react/shared/legacy/ui/icons/Notebook";
import ScoutingIcon from "_react/shared/legacy/ui/icons/Description";
import GridOn from "_react/shared/legacy/ui/icons/GridOn";
import CreateTeamIcon from "_react/shared/legacy/ui/icons/GroupAdd";
import HistoryEdu from "_react/shared/legacy/ui/icons/HistoryEdu";
import HomeIcon from "_react/shared/legacy/ui/icons/Home";
import ListIcon from "_react/shared/legacy/ui/icons/List";
import PersonIcon from "_react/shared/legacy/ui/icons/Person";
import CreatePlayerIcon from "_react/shared/legacy/ui/icons/PersonAdd";
import EvalSearchIcon from "_react/shared/legacy/ui/icons/Search";
import SwapVertical from "_react/shared/legacy/ui/icons/SwapVertical";
import Videocam from "_react/shared/legacy/ui/icons/Videocam";
import ViewComfy from "_react/shared/legacy/ui/icons/ViewComfy";
import Assignment from "_react/shared/legacy/ui/icons/Assignment";
import PlayerPage from "_react/playerpage";
import ViewList from "_react/shared/legacy/ui/icons/ViewList";
import MergeTypeIcon from "_react/shared/legacy/ui/icons/MergeType";
import WbSunnyIcon from "_react/shared/legacy/ui/icons/WbSunny";
import SignalWifi2Bar from "_react/shared/legacy/ui/icons/SignalWifi2Bar";
import FactCheckIcon from "_react/shared/legacy/ui/icons/FactCheck";
import AssessmentIcon from "_react/shared/legacy/ui/icons/Assessment";
import Baseball from "_react/shared/legacy/ui/icons/Baseball";
import ThumbsUp from "_react/shared/legacy/ui/icons/ThumbsUp";
import Phone from "_react/shared/legacy/ui/icons/Phone";
import SportsHandball from "_react/shared/legacy/ui/icons/SportsHandball";
import MedicalInformationIcon from "_react/shared/legacy/ui/icons/MedicalInformationIcon";
import TableChart from "_react/shared/legacy/ui/icons/TableChart";
import BubblePlot from "_react/shared/ui/icons/BubblePlot";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TLazyImport<T extends ComponentType<any>> = () => Promise<{ default: T }>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function lazyWRetry<T extends ComponentType<any>>(lazyImport: TLazyImport<T>) {
	return lazy(() => promiseWRetry(lazyImport, 4, 50, true, 4000));
}

// NOTE: DO NOT CHANGE THE ORDERING OF THIS ARRAY OR REMOVE THE REVERSE CALL UNLESS YOU ABSOLUTELY KNOW WHAT YOU ARE DOING
// The sidebar is upside-down, yes, but the links are searched eagerly, so eventually we need to separate this array into a display array and route search array, or add a priority to each entry to ensure eagerly searching works
export const LINKS: Array<TLink> = [
	{
		type: ELinkType.sidebar,
		text: "Home",
		route: `/`,
		comp: lazyWRetry(() => import("_react/home")),
		icon: HomeIcon
	},
	// Hidden Links
	{
		type: ELinkType.hidden,
		route: `/report`,
		comp: lazyWRetry(() => import("_react/reports"))
	},
	{
		type: ELinkType.hidden,
		route: `/eval`,
		comp: lazyWRetry(() => import("_react/evals/shared"))
	},
	{
		type: ELinkType.hidden,
		route: `/eval/list`,
		comp: lazyWRetry(() => import("_react/evals/list"))
	},
	{
		type: ELinkType.hidden,
		route: `/game`,
		comp: lazyWRetry(() => import("_react/gamesummary"))
	},
	{
		type: ELinkType.hidden,
		route: `/poe`,
		comp: lazyWRetry(() => import("_react/poestandings/legacy"))
	},
	{
		type: ELinkType.hidden,
		route: `/playerguesser`,
		comp: lazyWRetry(() => import("_react/playerguesser"))
	},
	{
		type: ELinkType.hidden,
		route: `/player`,
		comp: PlayerPage
	},
	{
		type: ELinkType.hidden,
		route: `/team`,
		comp: lazyWRetry(() => import("_react/teampage"))
	},
	{
		type: ELinkType.hidden,
		route: `/stdailyreport`,
		comp: lazyWRetry(() => import("_react/streports/stdailyreportpage"))
	},
	{
		type: ELinkType.hidden,
		route: `/stteamreport`,
		comp: lazyWRetry(() => import("_react/streports/stteamreportpage"))
	},
	{
		type: ELinkType.hidden,
		route: `/streport`,
		comp: lazyWRetry(() => import("_react/streports/streportpage"))
	},
	{
		type: ELinkType.hidden,
		route: `/list`,
		comp: lazyWRetry(() => import("_react/list/view/List"))
	},
	{
		type: ELinkType.hidden,
		route: `/gcsredirect`,
		comp: lazyWRetry(() => import("_react/gcsredirect"))
	},
	{
		type: ELinkType.hidden,
		route: `/datavizsandbox`,
		comp: lazyWRetry(() => import("_react/shared/dataviz/sandbox"))
	},
	{
		type: ELinkType.sidebar,
		text: ADMIN,
		icon: AdminIcon,
		children: [
			{
				type: ELinkType.sidebar,
				text: GROUP_PERMISSIONS,
				route: `/permissions`,
				comp: lazyWRetry(() => import("_react/permissions")),
				icon: CreateTeamIcon
			}
		]
	},
	{
		type: ELinkType.sidebar,
		text: "BBOps",
		icon: Baseball,
		children: [
			{
				type: ELinkType.sidebar,
				text: ORG_LISTS,
				route: `/org_lists`,
				comp: lazyWRetry(() => import("_react/list/org_view")),
				icon: ListIcon
			},
			{
				type: ELinkType.sidebar,
				text: PLAYER_POPULATION,
				route: `/playerpopulation`,
				comp: lazyWRetry(() => import("_react/playerpopulation")),
				icon: BubblePlot
			},
			{
				type: ELinkType.sidebar,
				text: POE,
				route: `/poe`,
				comp: lazyWRetry(() => import("_react/poestandings/legacy")),
				icon: LeaderboardIcon
			},
			{
				type: ELinkType.sidebar,
				text: PRO_PROJECTIONS_LEADERBOARD,
				route: `/projections/pro/leaderboard`,
				comp: lazyWRetry(() => import("_react/projections/pro/leaderboard")),
				icon: TableChart
			},
			{
				type: ELinkType.sidebar,
				text: PRO_MODEL_LEADERBOARD,
				route: `/promodel/leaderboard`,
				comp: lazyWRetry(() => import("_react/promodel/leaderboard")),
				icon: AssessmentIcon
			},
			{
				type: ELinkType.sidebar,
				text: AMA_PROJECTIONS_LEADERBOARD,
				route: `/projections/ama/leaderboard`,
				comp: lazyWRetry(() => import("_react/projections/ama/leaderboard")),
				icon: TableChart
			},
			{
				type: ELinkType.sidebar,
				text: AMA_STATISTICS_LEADERBOARD,
				route: `/statistics/ama/leaderboard`,
				comp: lazyWRetry(() => import("_react/statistics/ama/leaderboard")),
				icon: TableChart
			}
		]
	},
	{
		type: ELinkType.sidebar,
		text: "Player Development",
		icon: ScoutingIcon,
		children: [
			{
				type: ELinkType.sidebar,
				text: LISTS,
				route: `/lists`,
				comp: lazyWRetry(() => import("_react/list/search")),
				icon: ListIcon
			},
			{
				type: ELinkType.sidebar,
				text: PLAYER_PLAN_CALL_LOG,
				route: `/playerplancalllog`,
				comp: lazyWRetry(() => import("_react/playerplan/call_log")),
				icon: Phone
			},
			{
				type: ELinkType.sidebar,
				text: PLAYER_PLAN,
				route: `/playerplan`,
				comp: lazyWRetry(() => import("_react/playerplan")),
				icon: FactCheckIcon
			},
			{
				type: ELinkType.sidebar,
				text: PLAYER_PLAN_APPROVAL,
				route: `/playerplanapproval`,
				comp: lazyWRetry(() => import("_react/playerplan/approval")),
				icon: ThumbsUp
			},
			{
				type: ELinkType.sidebar,
				text: PLAYER_PLAN_METRICS,
				route: `/playerplanmetrics`,
				comp: lazyWRetry(() => import("_react/playerplan/metrics")),
				icon: AssessmentIcon
			},
			{
				type: ELinkType.sidebar,
				text: PLAYER_PLAN_DRILLS,
				route: `/playerplandrills`,
				comp: lazyWRetry(() => import("_react/playerplan/drills")),
				icon: SportsHandball
			},
			{
				type: ELinkType.sidebar,
				text: PLAYER_PLAN_RECENT_CHANGES,
				route: `/playerplanrecentchanges`,
				comp: lazyWRetry(() => import("_react/playerplan/recent_changes")),
				icon: HistoryEdu
			},
			{
				type: ELinkType.sidebar,
				text: "New Game Report",
				route: `/gamereport`,
				comp: lazyWRetry(() => import("_react/gamereport")),
				icon: ScoutingIcon
			}
		]
	},
	{
		type: ELinkType.sidebar,
		text: "Game Plans",
		icon: GameplanIcon,
		children: [
			{
				type: ELinkType.sidebar,
				text: LINEUPS,
				route: `/gameplan/lineup_beta`,
				comp: lazyWRetry(() => import("_react/gameplan/lineup")),
				icon: SwapVertical
			},
			{
				type: ELinkType.sidebar,
				text: MATCHUP_MATRIX,
				route: `/gameplan/matrix`,
				comp: lazyWRetry(() => import("_react/gameplan/matrix")),
				icon: GridOn
			},
			{
				type: ELinkType.sidebar,
				text: SIGN_CARDS,
				route: `/gameplan/catcher`,
				comp: lazyWRetry(() => import("_react/gameplan/sign_code")),
				icon: ViewList
			},
			{
				type: ELinkType.sidebar,
				text: BATTING_ADVANCE,
				route: `/advance/batting`,
				comp: lazyWRetry(() => import("_react/advance/batting")),
				icon: Assignment
			},
			{
				type: ELinkType.sidebar,
				text: POSITIONING,
				route: `/positioning`,
				comp: lazyWRetry(() => import("_react/gameplan/positioning")),
				icon: SignalWifi2Bar
			}
		]
	},
	{
		type: ELinkType.sidebar,
		text: "Scouting",
		icon: ScoutingIcon,
		children: [
			{
				type: ELinkType.sidebar,
				text: LISTS,
				route: `/lists`,
				comp: lazyWRetry(() => import("_react/list/search")),
				icon: ListIcon
			},
			{
				type: ELinkType.sidebar,
				text: DRAFT_BOARD,
				route: `/draftboard`,
				comp: lazyWRetry(() => import("_react/list/draftboard")),
				icon: ViewComfy
			},
			{
				type: ELinkType.sidebar,
				text: MEDICAL_WORKBOOK,
				route: "/medicalworkbook",
				icon: MedicalInformationIcon,
				comp: lazyWRetry(() => import("_react/medicalworkbook"))
			},
			{
				type: ELinkType.sidebar,
				text: EVAL_SEARCH,
				route: `/eval/search`,
				comp: lazyWRetry(() => import("_react/evals/search")),
				icon: EvalSearchIcon
			},
			{
				type: ELinkType.sidebar,
				text: INTL_PLAYER_LIST,
				route: `/playerlist/intl`,
				icon: PersonIcon,
				comp: lazyWRetry(() => import("_react/intlplayerlist"))
			},
			{
				type: ELinkType.modal,
				text: "Upload Video",
				route: `/uploadVideo`,
				comp: lazyWRetry(() => import("_react/playerpage/video/VideoUploadDialog")),
				icon: Videocam
			},
			{
				type: ELinkType.sidebar,
				text: ST_REPORTS,
				route: `/streports`,
				icon: WbSunnyIcon,
				comp: lazyWRetry(() => import("_react/streports"))
			}
		]
	},
	{
		type: ELinkType.hidden,
		route: `/playerplan/list`,
		comp: lazyWRetry(() => import("_react/playerplan/list"))
	},
	{
		type: ELinkType.modal,
		text: "Create Player",
		route: `/createPlayer`,
		comp: lazyWRetry(() => import("_react/playerpage/PlayerCreateDialog")),
		icon: CreatePlayerIcon
	},
	{
		type: ELinkType.sidebar,
		text: MERGE,
		route: `/merge`,
		icon: MergeTypeIcon,
		comp: lazyWRetry(() => import("_react/merge"))
	},
	{
		type: ELinkType.sidebar,
		text: "Team Search",
		route: `/teamssearch`,
		comp: lazyWRetry(() => import("_react/teamssearch")),
		icon: EvalSearchIcon
	},
	{
		type: ELinkType.modal,
		text: "Create Team",
		route: `/createTeam`,
		comp: lazyWRetry(() => import("_react/teampage/TeamCreateDialog")),
		icon: CreateTeamIcon
	},
	{
		type: ELinkType.hidden,
		route: `/demoform`,
		comp: lazyWRetry(() => import("_react/shared/forms/demo"))
	}
].reverse() as Array<TLink>;
// NOTE: DO NOT CHANGE THE ORDERING OF THIS ARRAY OR REMOVE THE REVERSE CALL UNLESS YOU ABSOLUTELY KNOW WHAT YOU ARE DOING
// The sidebar is upside-down, yes, but the links are searched eagerly, so eventually we need to separate this array into a display array and route search array, or add a priority to each entry to ensure eagerly searching works
