import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import {
	getScoutLatestValues,
	getPlayerTypeFromEvalType,
	getLabelFromType,
	correctOFPLabel,
	getIsEvalIntlFollow
} from "_react/playerpage/shared_js/evals/_helpers";
import { getColorFromClassification } from "utils/PlayerTypeHelpers";
import BlockLabel from "_react/playerpage/shared_js/evals/BlockLabel";
import AvgOFP from "_react/playerpage/shared_js/evals/AvgOFP";

const StyledScouts = styled("div")`
	flex-grow: 1;
	font-style: italic;
	color: gray;
	text-align: center;
	@media (max-width: 991px) {
		display: none;
	}
`;

const StyledBox = styled("div")`
	margin-left: 10px;
	@media (max-width: 991px) {
		display: none;
	}
`;

const StyledSeparator = styled("div")`
	@media (max-width: 991px) {
		flex-grow: 1;
	}
`;

const EvalTableGroupHeader = ({ year, evaluations, last, expanded, type, avgOFP, change, toggleExpanded, hover }) => {
	const isGroup =
		evaluations &&
		evaluations.length > 0 &&
		(evaluations[0].eval_report_type === "FRE" || evaluations[0].eval_report_type === "LVR") &&
		dayjs(evaluations[0].seen_date).year() >= 2020;
	const scoutOFPs = getScoutLatestValues(evaluations, isGroup ? "group" : "ofp", true);

	// Get scout display names
	const scoutsUnique = {};
	const scoutsLastNameCount = {};
	evaluations
		.toSorted((a, b) =>
			(b.seen_date ? dayjs(b.seen_date) : dayjs()).diff(a.seen_date ? dayjs(a.seen_date) : dayjs())
		)
		.forEach(e => {
			if (e.scout.last_name) {
				const ofpLabel =
					scoutOFPs[e.eval_create_user_id] || getIsEvalIntlFollow(e)
						? ` (${correctOFPLabel(e.eval_report_type, scoutOFPs[e.eval_create_user_id], e) ?? "-"})`
						: "";
				// Keep track of occurrences of last names
				scoutsLastNameCount[e.scout.last_name]
					? scoutsLastNameCount[e.scout.last_name].add(e.eval_create_user_id)
					: (scoutsLastNameCount[e.scout.last_name] = new Set([e.eval_create_user_id]));
				// Get unique scouts
				if (!scoutsUnique[`${e.eval_create_user_id}-${ofpLabel}`]) {
					scoutsUnique[`${e.eval_create_user_id}-${ofpLabel}`] = {
						...e.scout,
						ofpLabel: ofpLabel,
						seen_date: e.seen_date
					};
				}
			}
		});
	const scoutDisplayNames = Object.values(scoutsUnique)
		.reverse()
		.map(scout => {
			const lastName =
				scoutsLastNameCount[scout.last_name].size > 1
					? `${scout.first_name.charAt(0)}. ${scout.last_name}`
					: scout.last_name;
			return `${lastName}${scout.ofpLabel}`;
		});

	return (
		<div
			onClick={() => toggleExpanded()}
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				padding: "2px 10px",
				// borderTop: "1px solid gray",
				borderBottom: expanded ? "1px solid #ADADAD" : last ? "none" : "1px solid gray",
				backgroundColor: hover ? "#FFE2DF" : expanded ? "#F2F2F2" : "#c3c3c3",
				cursor: "pointer"
			}}
		>
			<div
				style={{
					fontSize: "1.4em",
					fontWeight: "900"
				}}
			>
				{year}
			</div>
			<div
				style={{
					backgroundColor: getColorFromClassification(getPlayerTypeFromEvalType(type)),
					color: "white",
					borderRadius: "2px",
					fontSize: "0.7em",
					fontWeight: "700",
					marginLeft: "10px",
					padding: "3px"
				}}
			>
				{getLabelFromType(type)}
			</div>
			<StyledScouts>{scoutDisplayNames.join(", ")}</StyledScouts>
			<StyledBox>
				<BlockLabel content={scoutDisplayNames.length} label={"SCOUTS"} />
			</StyledBox>
			<StyledBox>
				<BlockLabel content={evaluations.length} label={"REPORTS"} />
			</StyledBox>
			<StyledSeparator />
			<div style={{ marginLeft: "10px" }}>
				<AvgOFP change={change} ofp={avgOFP} type={isGroup ? "group" : "ofp"} />
			</div>
		</div>
	);
};

EvalTableGroupHeader.propTypes = {
	avgOFP: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	change: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	evaluations: PropTypes.array,
	expanded: PropTypes.bool,
	hover: PropTypes.bool,
	last: PropTypes.bool,
	toggleExpanded: PropTypes.func,
	type: PropTypes.string,
	year: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EvalTableGroupHeader;
