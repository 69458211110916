import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";

import {
	IArsenalScoresParamsApiResponse,
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema,
	IPlayerSeasonArsenalScoresByTeamSchema
} from "_react/shared/data_models/arsenal_scores/_types";

// arsenal_scores_params

export type TArsenalScoresParamsGetQueryParams = {
	season?: number;
	pitchTypeGrouping?: string;
	meanStuffRuns?: number;
	sdStuffRuns?: number;
	meanLocationRuns?: number;
	sdLocationRuns?: number;
	meanIntrinsicRuns?: number;
	sdIntrinsicRuns?: number;
	meanStuffRunsEvent?: number;
	sdStuffRunsEvent?: number;
	meanLocationRunsEvent?: number;
	sdLocationRunsEvent?: number;
	meanIntrinsicRunsEvent?: number;
	sdIntrinsicRunsEvent?: number;
	isPriorSeason?: boolean;
	createDate?: string;
	lastChangeDate?: string;
	includeRa9?: boolean;
	includeRa9NormalDistribution?: boolean;
	includeEventRa9NormalDistribution?: boolean;
	includePitchTypes?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
	version?: string;
};

export const fetchArsenalScoresParams = (queryParams: TArsenalScoresParamsGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TArsenalScoresParamsGetQueryParams, Array<IArsenalScoresParamsApiResponse>>(
		"/arsenal_scores_params",
		queryParams,
		cancelToken
	);

// arsenal_scores_threshold

export const fetchArsenalScoresThreshold = (cancelToken?: CancelToken) =>
	fetchResource<undefined, Array<IArsenalScoresThresholdApiResponse>>(
		"/arsenal_scores_threshold",
		undefined,
		cancelToken
	);

// `player_season` Arsenal Scores

export type TPlayerSeasonArsenalScoresGetQueryParams = {
	playerId?: number;
	season?: number;
	playingLevel?: string;
	throws?: string;
	bats?: string;
	gameType?: string;
	countSplit?: string;
	pitchType?: string;
	"pitchType[neq]"?: string;
	"pitchType[in]"?: string;
	total?: number;
	releaseVelo?: number;
	breakXDragless?: number;
	breakZDragless?: number;
	pfxXDragless?: number;
	pfxZDragless?: number;
	releaseX?: number;
	releaseZ?: number;
	extension?: number;
	stuffGrade?: number;
	"stuffGrade[gte]"?: number;
	"stuffGrade[lte]"?: number;
	locationGrade?: number;
	"locationGrade[gte]"?: number;
	"locationGrade[lte]"?: number;
	intrinsicGrade?: number;
	"intrinsicGrade[gte]"?: number;
	"intrinsicGrade[lte]"?: number;
	stuffRa9?: number;
	"stuffRa9[gte]"?: number;
	"stuffRa9[lte]"?: number;
	locationRa9?: number;
	"locationRa9[gte]"?: number;
	"locationRa9[lte]"?: number;
	intrinsicRa9?: number;
	"intrinsicRa9[gte]"?: number;
	"intrinsicRa9[lte]"?: number;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
	version?: string;
	schema?: string;
};

export const fetchPlayerSeasonArsenalScores = (
	queryParams: TPlayerSeasonArsenalScoresGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPlayerSeasonArsenalScoresGetQueryParams, Array<IPlayerSeasonArsenalScoresSchema>>(
		"/arsenal_scores/player_season",
		queryParams,
		cancelToken
	);

export type TPlayerSeasonArsenalScoresByTeamGetQueryParams = {
	playerId?: number;
	season?: number;
	playingLevel?: string;
	teamId?: number;
	"teamId[in]"?: string;
	throws?: string;
	bats?: string;
	gameType?: string;
	countSplit?: string;
	pitchType?: string;
	"pitchType[neq]"?: string;
	"pitchType[in]"?: string;
	total?: number;
	releaseVelo?: number;
	breakXDragless?: number;
	breakZDragless?: number;
	pfxXDragless?: number;
	pfxZDragless?: number;
	releaseX?: number;
	releaseZ?: number;
	extension?: number;
	stuffGrade?: number;
	"stuffGrade[gte]"?: number;
	"stuffGrade[lte]"?: number;
	locationGrade?: number;
	"locationGrade[gte]"?: number;
	"locationGrade[lte]"?: number;
	intrinsicGrade?: number;
	"intrinsicGrade[gte]"?: number;
	"intrinsicGrade[lte]"?: number;
	stuffRa9?: number;
	"stuffRa9[gte]"?: number;
	"stuffRa9[lte]"?: number;
	locationRa9?: number;
	"locationRa9[gte]"?: number;
	"locationRa9[lte]"?: number;
	intrinsicRa9?: number;
	"intrinsicRa9[gte]"?: number;
	"intrinsicRa9[lte]"?: number;
	schema?: string;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchPlayerSeasonArsenalScoresByTeam = (
	queryParams: TPlayerSeasonArsenalScoresByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPlayerSeasonArsenalScoresByTeamGetQueryParams, Array<IPlayerSeasonArsenalScoresByTeamSchema>>(
		"/arsenal_scores/player_season/team",
		queryParams,
		cancelToken
	);
