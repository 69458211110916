import { TMetricReliabilityThresholds } from "_react/shared/data_models/metric_reliability/_helpers";
import {
	TAmaPitcherFoundationalSkillsRow,
	TStuffOrShapeValue
} from "_react/shared/ui/data/tables/AmaPitcherFoundationalSkillsTable/_types";
import { STUFF } from "_react/shared/ui/data/tables/AmaPitcherFoundationalSkillsTable/_constants";

export const getMeetsReliabilityThreshold = (
	row: TAmaPitcherFoundationalSkillsRow,
	displayStuffOrShape?: TStuffOrShapeValue,
	thresholds?: { [index: string]: TMetricReliabilityThresholds }
): { meetsAllThresholds: boolean; tooltip: string } => {
	const pitchType = row.playerSeasonArsenalScoresByTeam.pitchType;
	const thresholdInfo: { meetsAllThresholds: boolean; tooltip: string } = {
		meetsAllThresholds: true,
		tooltip: ""
	};
	const tooltipArray = [];
	if (thresholds && Object.keys(thresholds).includes(pitchType)) {
		const pitchTypeThreshold = thresholds[pitchType];
		const sampleSize = row.playerSeasonArsenalScoresByTeam.total;
		if (
			displayStuffOrShape === STUFF &&
			pitchTypeThreshold.stuffGrade &&
			sampleSize &&
			sampleSize < pitchTypeThreshold.stuffGrade
		) {
			tooltipArray.push(
				`Stuff grades are not reliable when there are less than ${pitchTypeThreshold.stuffGrade} pitches.`
			);
			thresholdInfo.meetsAllThresholds = false;
		}
		if (
			displayStuffOrShape !== STUFF &&
			pitchTypeThreshold.shapeGrade &&
			sampleSize &&
			sampleSize < pitchTypeThreshold.shapeGrade
		) {
			tooltipArray.push(
				`Shape grades are not reliable when there are less than ${pitchTypeThreshold.shapeGrade} pitches.`
			);
			thresholdInfo.meetsAllThresholds = false;
		}
		if (pitchTypeThreshold.locationGrade && sampleSize && sampleSize < pitchTypeThreshold.locationGrade) {
			tooltipArray.push(
				`Location grades are not reliable when there are less than ${pitchTypeThreshold.locationGrade} pitches.`
			);
			thresholdInfo.meetsAllThresholds = false;
		}
	}
	thresholdInfo.tooltip = tooltipArray.join(" \n\n");

	return thresholdInfo;
};
