import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function BubblePlot(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M2.61694 21.383V0.19149H2.1063V21.383H0.191406V21.8936H2.1063V23.8085H2.61694V21.8936H5.93609V22.7234H6.44673V21.8936H10.7872V22.7234H11.2978V21.8936H15.6382V22.7234H16.1489V21.8936H20.4893V22.7234H20.9999V21.8936H23.8084V21.383H2.61694Z M6.76605 17.1064C7.6109 17.1064 8.29796 16.4193 8.29796 15.5745C8.29796 14.7296 7.6109 14.0426 6.76605 14.0426C5.92119 14.0426 5.23413 14.7296 5.23413 15.5745C5.23413 16.4193 5.92119 17.1064 6.76605 17.1064Z M8.61713 11.6809C10.0954 11.6809 11.298 10.4783 11.298 9C11.298 7.52171 10.0954 6.31915 8.61713 6.31915C7.13883 6.31915 5.93628 7.52171 5.93628 9C5.93628 10.4783 7.13883 11.6809 8.61713 11.6809Z M16.7234 11.6808C17.6737 11.6808 18.4468 10.9077 18.4468 9.95744C18.4468 9.00715 17.6737 8.23404 16.7234 8.23404C15.7731 8.23404 15 9.00715 15 9.95744C15 10.9077 15.7731 11.6808 16.7234 11.6808Z M18.1916 4.46808C19.1066 4.46808 19.8511 3.72357 19.8511 2.80851C19.8511 1.89344 19.1066 1.14893 18.1916 1.14893C17.2765 1.14893 16.532 1.89344 16.532 2.80851C16.532 3.72357 17.2765 4.46808 18.1916 4.46808Z"
			/>
		</Icon>
	);
}
