import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";

import {
	ICollegeSplitsPlayerPitchingStatsByTeam,
	ICollegeSplitsPlayerBattingStatsByTeam
} from "_react/shared/data_models/ama_stats/_types";

// collegesplits_player_pitching_stats_byteam

export type TCollegeSplitsPlayerPitchingStatsByTeamGetQueryParams = {
	playerId?: number;
	teamId?: number;
	season?: number;
	splitType?: string;
	"splitType[in]"?: string;
	gameType?: string;
	throws?: string;
	bats?: string;
	g?: number;
	createDate?: string;
	lastChangeDate?: string;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
	version?: string;
};

export const fetchCollegeSplitsPlayerPitchingStatsByTeam = (
	queryParams: TCollegeSplitsPlayerPitchingStatsByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<
		TCollegeSplitsPlayerPitchingStatsByTeamGetQueryParams,
		Array<ICollegeSplitsPlayerPitchingStatsByTeam>
	>("/collegesplits_player_pitching_stats_byteam", queryParams, cancelToken);

// collegesplits_player_batting_stats_byteam

export type TCollegeSplitsPlayerBattingStatsByTeamGetQueryParams = {
	playerId?: number;
	teamId?: number;
	season?: number;
	splitType?: string;
	"splitType[in]"?: string;
	gameType?: string;
	throws?: string;
	bats?: string;
	g?: number;
	createDate?: string;
	lastChangeDate?: string;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
	version?: string;
};

export const fetchCollegeSplitsPlayerBattingStatsByTeam = (
	queryParams: TCollegeSplitsPlayerBattingStatsByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TCollegeSplitsPlayerBattingStatsByTeamGetQueryParams, Array<ICollegeSplitsPlayerBattingStatsByTeam>>(
		"/collegesplits_player_batting_stats_byteam",
		queryParams,
		cancelToken
	);
