import { PITCH_TYPE_OVERALL } from "_react/shared/data_models/arsenal_scores/_constants";
import { TMetricReliabilityThresholds } from "_react/shared/data_models/metric_reliability/_helpers";
import { TAmaPositionPlayerFoundationalSkillsRow } from "_react/shared/ui/data/tables/AmaPositionPlayerFoundationalSkillsTable/_types";

// Used to get the level to display for a row
export const getLevelsFromRow = (row: TAmaPositionPlayerFoundationalSkillsRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("team" in row.combinedPlayerSeasonHitterGradesData)
		return row.combinedPlayerSeasonHitterGradesData.team?.level
			? [row.combinedPlayerSeasonHitterGradesData.team?.level]
			: [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TAmaPositionPlayerFoundationalSkillsRow) => {
					if (
						"team" in childRow.combinedPlayerSeasonHitterGradesData &&
						childRow.combinedPlayerSeasonHitterGradesData.team?.level
					)
						acc.push(childRow.combinedPlayerSeasonHitterGradesData.team?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};

export const getMeetsReliabilityThreshold = (
	row: TAmaPositionPlayerFoundationalSkillsRow,
	thresholds?: { [index: string]: TMetricReliabilityThresholds }
): { meetsAllThresholds: boolean; tooltip: string } => {
	const thresholdInfo: { meetsAllThresholds: boolean; tooltip: string } = {
		meetsAllThresholds: true,
		tooltip: ""
	};
	const tooltipArray = [];
	const pitchTypeThreshold = thresholds ? thresholds[PITCH_TYPE_OVERALL] : undefined;
	if (pitchTypeThreshold) {
		const bips = row.combinedPlayerSeasonHitterGradesData.bips;
		const swings = row.combinedPlayerSeasonHitterGradesData.swings;
		const pitches = row.combinedPlayerSeasonHitterGradesData.pitches;
		if (pitchTypeThreshold.exitVelo && bips && bips < pitchTypeThreshold.exitVelo) {
			tooltipArray.push(
				`Bat Speed and VAA are not reliable when there are less than ${pitchTypeThreshold.exitVelo} BIP.`
			);
			thresholdInfo.meetsAllThresholds = false;
		}
		if (pitchTypeThreshold.batToBallGrade && swings && swings < pitchTypeThreshold.batToBallGrade) {
			tooltipArray.push(
				`Bat to ball grades are not reliable when there are less than ${pitchTypeThreshold.batToBallGrade} swings.`
			);
			thresholdInfo.meetsAllThresholds = false;
		}
		if (pitchTypeThreshold.swingDecisionsGrade && pitches && pitches < pitchTypeThreshold.swingDecisionsGrade) {
			tooltipArray.push(
				`Swing decisions grades are not reliable when there are less than ${pitchTypeThreshold.swingDecisionsGrade} pitches.`
			);
			thresholdInfo.meetsAllThresholds = false;
		}
	}
	thresholdInfo.tooltip = tooltipArray.join(" \n\n");

	return thresholdInfo;
};
