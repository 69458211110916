import React, { Fragment } from "react";
import dayjs from "dayjs";
import { Text } from "@chakra-ui/react";

import { separateFirstSentence } from "_react/shared/_helpers/strings";
import { IReport, IReportField, IReportLk, IReportFieldVariant } from "_react/shared/data_models/reports/_types";
import {
	FIELD_TYPE_NUMERIC,
	FIELD_VARIANT_RANGE,
	FIELD_TYPE_DATE,
	FIELD_VARIANT_WITH_TIME,
	FIELD_TYPE_CHECKBOX,
	FIELD_TYPE_CUSTOM,
	FIELD_VARIANT_TYPE_PROBABILITY,
	FIELD_VARIANT_TYPE_TEAM,
	FIELD_VARIANT_TYPE_ORG,
	FIELD_VARIANT_FIRST_SENTENCE_BOLD
} from "_react/shared/data_models/reports/_constants";
import { generateProbabilityFieldDisplayValue } from "_react/reports/fields/custom/probability/_helpers";
import { generateTeamOrgFieldDisplayValue } from "_react/reports/fields/custom/team/_helpers";
import { showField } from "_react/reports/directives/display";
import { FIELD_DISPLAY_REVIEW } from "_react/reports/_constants";
import { TFieldDisplayPurpose } from "_react/reports/_types";

type ReportReviewFieldValueProps = {
	report: IReport;
	field: IReportField;
	purpose?: TFieldDisplayPurpose;
};

export const ReportReviewFieldValue = ({
	report,
	field,
	purpose = FIELD_DISPLAY_REVIEW
}: ReportReviewFieldValueProps) => {
	const show = showField(report, field, field.value, field.collectionIndex, purpose);
	if (!show) return <Text>-</Text>;
	const valueLabel = field.options
		?.filter((option: IReportLk) => {
			if (field.multi) {
				return field.value?.split(",").includes(option.value);
			}
			return option.value === field.value;
		})
		.map((option: IReportLk) => option.label)
		.join(", ");

	let valueDisplay = valueLabel ? valueLabel : field.value ?? "-";
	const variants: IReportFieldVariant[] = (field.variant?.split(",") as IReportFieldVariant[]) ?? [];

	// Special Cases
	if (field.type === FIELD_TYPE_NUMERIC && variants.includes(FIELD_VARIANT_RANGE) && valueDisplay !== "-") {
		const components = valueDisplay.split(",");
		valueDisplay = `${components[0]} - ${components[1]}`;
	} else if (field.type === FIELD_TYPE_DATE && variants.includes(FIELD_VARIANT_WITH_TIME)) {
		valueDisplay = dayjs(valueDisplay).format("MMMM D, YYYY h:mm A");
	} else if (field.type === FIELD_TYPE_DATE) {
		valueDisplay = dayjs(valueDisplay).format("MMMM D, YYYY");
	} else if (field.type === FIELD_TYPE_CHECKBOX) {
		valueDisplay = valueDisplay === "1" ? "Checked" : "Unchecked";
	} else if (variants.includes(FIELD_VARIANT_FIRST_SENTENCE_BOLD)) {
		const [firstSentence, remainingText] = separateFirstSentence(valueDisplay);
		return (
			<Text whiteSpace="pre-line">
				<Text as="span" fontWeight="bold">
					{firstSentence}
				</Text>{" "}
				{remainingText}
			</Text>
		);
	} else if (field.type === FIELD_TYPE_CUSTOM) {
		if (variants.includes(FIELD_VARIANT_TYPE_PROBABILITY)) {
			return <Fragment>{generateProbabilityFieldDisplayValue(field)}</Fragment>;
		} else if (variants.includes(FIELD_VARIANT_TYPE_TEAM)) {
			return <Fragment>{generateTeamOrgFieldDisplayValue(field)}</Fragment>;
		} else if (variants.includes(FIELD_VARIANT_TYPE_ORG)) {
			return <Fragment>{generateTeamOrgFieldDisplayValue(field)}</Fragment>;
		}
	}

	return <Text whiteSpace="pre-line">{valueDisplay}</Text>;
};

export default ReportReviewFieldValue;
