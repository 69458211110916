import { TFieldDisplayPurpose } from "_react/reports/_types";

// Review Step Constants
export const REVIEW_STEP = "review";

// Range Field Constants
export const RANGE_FIELD_LOWER = "lower";
export const RANGE_FIELD_UPPER = "upper";

// Field State Constants
export const FIELD_STATE_READ_ONLY = "readonly";
export const FIELD_STATE_DISABLED = "disabled";

// Field Display Purposes
export const FIELD_DISPLAY_INPUT = "input";
export const FIELD_DISPLAY_REVIEW = "review";
export const FIELD_DISPLAY_REPORT_HEADER = "reportHeader";
export const FIELD_DISPLAY_COLLECTION_HEADER = "collectionHeader";
export const FIELD_DISPLAY_PURPOSES: TFieldDisplayPurpose[] = [
	FIELD_DISPLAY_INPUT,
	FIELD_DISPLAY_REVIEW,
	FIELD_DISPLAY_REPORT_HEADER,
	FIELD_DISPLAY_COLLECTION_HEADER
];
