import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	Text,
	VStack,
	HStack,
	IconButton,
	Tooltip,
	useToast,
	useBoolean
} from "@chakra-ui/react";

import axios from "_redux/_utils/_axios";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { PlayerSearch, TPlayerSearchOption } from "_react/shared/searches/PlayerSearch";
import SwapVert from "_react/shared/ui/icons/SwapVert";
import ExternalLinkIcon from "_react/shared/ui/icons/ExternalLinkIcon";
import { TUseBooleanReturn } from "_react/shared/_types/chakra";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { getPlayerPhilId } from "_react/playerpage/_helpers";
import { getPlayerFullName } from "_react/shared/data_models/player/_helpers";
import { DeleteText, KeepText } from "_react/playerpage/pro/shared/_styles";

type MergePlayersModalProps = {
	player: TPlayerPageCombinedPlayer;
	isOpen: boolean;
	setIsOpen: TUseBooleanReturn[1];
};

const MergePlayersModal = ({ player, isOpen, setIsOpen }: MergePlayersModalProps) => {
	const toast = useToast();
	const history = useHistory();

	// All of this is legacy logic from `_react/playerpage/Footer.tsx`
	// Keep for now to keep logic in sync, but potentially move to xState when old pages are deprecated
	const [isCurrentPlayerPrimary, setIsCurrentPlayerPrimary] = useBoolean(true);
	const [selectedMergePlayer, setSelectedMergePlayer] = useState<TPlayerSearchOption | undefined>();
	const [isMerging, setIsMerging] = useBoolean(false);

	const playerClassification = player.playerClassification?.toLowerCase() ?? null;
	const playerPhilId = getPlayerPhilId(player, playerClassification);

	const handleMergePlayers = () => {
		if (!selectedMergePlayer) {
			toast({
				title: "Merge Players",
				description: "REQUIRED Select a player to merge with.",
				status: "warning",
				duration: 10000,
				isClosable: true,
				position: "bottom-left"
			});
			return;
		}

		const requestPlayers = {
			primary_phil_id: selectedMergePlayer
				? !isCurrentPlayerPrimary
					? selectedMergePlayer.philId
					: playerPhilId
				: undefined,
			primary_player_classification: selectedMergePlayer
				? !isCurrentPlayerPrimary
					? selectedMergePlayer.playerClassification
					: playerClassification
				: undefined,
			secondary_phil_id: selectedMergePlayer
				? isCurrentPlayerPrimary
					? selectedMergePlayer.philId
					: playerPhilId
				: undefined,
			secondary_player_classification: selectedMergePlayer
				? isCurrentPlayerPrimary
					? selectedMergePlayer.playerClassification
					: playerClassification
				: undefined
		};

		if (requestPlayers.primary_phil_id === requestPlayers.secondary_phil_id) {
			toast({
				title: "Merge Players",
				description: "Cannot merge the same player into itself.",
				...DEFAULT_TOAST_ERROR_PROPS
			});
			return;
		}
		setIsMerging.on();
		axios
			.post("/players/merge", requestPlayers)
			.then(() => {
				axios.get("/search/refresh?token=DeepDishPizza").then(() => {
					setIsMerging.off();
					setIsOpen.off();
					if (isCurrentPlayerPrimary) history.push(`player?philId=${requestPlayers.primary_phil_id}`);
					else window.location.reload();
				});
			})
			.catch(() => {
				toast({
					title: "Merge Players",
					description: "Error merging player.",
					...DEFAULT_TOAST_ERROR_PROPS
				});
				setIsMerging.off();
			});
	};

	return (
		<Modal isOpen={isOpen} onClose={() => setIsOpen.off()}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Merge Players</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<HStack>
						<Tooltip hasArrow placement="top" label="Swap Primary Player">
							<IconButton
								colorScheme="gray"
								aria-label="Swap Primary Player"
								borderRadius="full"
								icon={<SwapVert boxSize={6} />}
								onClick={() => setIsCurrentPlayerPrimary.toggle()}
								isDisabled={isMerging}
							/>
						</Tooltip>
						<VStack align="start" paddingLeft="3">
							{isCurrentPlayerPrimary ? (
								<>
									<KeepText>Keep</KeepText>
									<DeleteText>Delete</DeleteText>
								</>
							) : (
								<>
									<DeleteText>Delete</DeleteText>
									<KeepText>Keep</KeepText>
								</>
							)}
						</VStack>
						<VStack paddingLeft="3">
							<Text>{getPlayerFullName(player, false)} (Current Page)</Text>
							<HStack>
								<PlayerSearch
									isStyledSelectStyles
									isClearable
									isMulti={false}
									placeholder={"Search for a player to merge with..."}
									onSelect={(p: TPlayerSearchOption) => setSelectedMergePlayer(p)}
									value={selectedMergePlayer}
									style={{ width: "100%" }}
								/>
								<Box
									sx={{ cursor: "pointer", position: "relative", top: -0.5 }}
									onClick={() =>
										window.open(`/player?philId=${selectedMergePlayer?.philId}`, "_blank")
									}
								>
									<ExternalLinkIcon sx={{ color: "gray.600", strokeWidth: 4, stroke: "gray.600" }} />
								</Box>
							</HStack>
						</VStack>
					</HStack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="blue"
						marginRight="3"
						onClick={handleMergePlayers}
						isDisabled={!selectedMergePlayer}
						isLoading={isMerging}
					>
						Merge Players
					</Button>
					<Button variant="ghost" onClick={() => setIsOpen.off()}>
						Close
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default MergePlayersModal;
