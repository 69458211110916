//
// Our axios configuration for the operations API slice to be reused throughout the application
//
import axios from "axios";

import { cookies } from "utils/redux_constants";

export const _axiosOperations = axios.create({
	baseURL: process.env.REACT_APP_OPERATIONS_API_URL,
	headers: {
		Authorization: cookies.get("token")
	}
});

_axiosOperations.defaults.headers.common["Authorization"] = cookies.get("token");

_axiosOperations.interceptors.request.use(
	config => {
		if (cookies.get("token")) {
			config.headers.common.Authorization = cookies.get("token");
			config.headers.Authorization = cookies.get("token");
		}
		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
);

export default _axiosOperations;
