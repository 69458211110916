import React from "react";
import { Badge as ChakraBadge, SystemStyleObject } from "@chakra-ui/react";

import { RED } from "_react/shared/legacy/ui/Colors";

type TBadgeProps = {
	text?: string | null;
	color?: { color: string; text: string };
	nullFillerText?: string;
	style?: SystemStyleObject;
};

const Badge = ({ text = "", color = { color: RED, text: "white" }, nullFillerText = "", style }: TBadgeProps) => {
	return (
		<ChakraBadge variant="solid" borderRadius="lg" bg={color.color} color={color.text} sx={style}>
			{text ?? nullFillerText}
		</ChakraBadge>
	);
};

export default Badge;
