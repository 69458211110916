import { CH, FF, SI, SL, CT, CB, SP, SW, SV, KN } from "_react/shared/_constants/pitch_types";

export type TPitchTypes =
	| typeof CH
	| typeof FF
	| typeof SI
	| typeof SL
	| typeof CT
	| typeof CB
	| typeof SP
	| typeof SW
	| typeof SV
	| typeof KN;

export const FB_PITCH_TYPES = [FF, SI];
