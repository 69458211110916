import React from "react";
import { Box, Stat as ChakraStat, StatLabel, StatNumber, StatHelpText, Tooltip, Text } from "@chakra-ui/react";

import { getColorGradientBinary } from "_react/shared/dataviz/_helpers";

import {
	FORMAT_FUNCTIONS,
	LOADING_HEIGHT,
	MD,
	STAT_LABEL_TARGET,
	STAT_VALUE_TARGET
} from "_react/shared/ui/presentation/components/stat/shared/_constants";
import { IStatProps, TStatTooltipTarget } from "_react/shared/ui/presentation/components/stat/shared/_types";

export interface IPrimaryStatProps extends IStatProps {
	valueTextColor?: string;
	tooltipTarget?: TStatTooltipTarget;
}

const PrimaryStat = ({
	label,
	helpText,
	helpTextPlacement = "bottom",
	value,
	format,
	valueTextColor = "gray.600",
	colorGradientInfo = [undefined, undefined, undefined],
	tooltipLabel,
	tooltipPlacement = "top",
	tooltipTarget = STAT_LABEL_TARGET,
	nullFillerText = "",
	isLoading = false,
	size = MD,
	style
}: IPrimaryStatProps) => {
	const valueNumber = typeof value === "string" ? parseFloat(value) : value;

	return (
		<ChakraStat sx={style?.stat} size={size}>
			<Tooltip
				isDisabled={tooltipLabel == null || tooltipTarget !== STAT_LABEL_TARGET}
				hasArrow
				placement={tooltipPlacement}
				label={tooltipLabel}
				whiteSpace="pre-wrap"
				sx={style?.tooltip}
			>
				<StatLabel fontWeight="semibold" color="gray.900" whiteSpace="nowrap" sx={style?.statLabel}>
					{label}
				</StatLabel>
			</Tooltip>
			<Tooltip
				isDisabled={tooltipLabel == null || tooltipTarget !== STAT_VALUE_TARGET}
				hasArrow
				placement={tooltipPlacement}
				label={tooltipLabel}
				whiteSpace="pre-wrap"
				sx={style?.tooltip}
			>
				<StatNumber display="flex" alignItems="center" sx={style?.statNumber}>
					{isLoading && (
						<Box
							className="loading-item-transparent"
							h={LOADING_HEIGHT[size]}
							w="14"
							bg="gray.300"
							marginRight="1"
						/>
					)}
					{!isLoading && (
						<Box paddingRight="1" marginRight="1" borderRadius="md" display="flex" alignItems="baseline">
							{helpText != null && helpTextPlacement === "left" && (
								<StatHelpText>{helpText}</StatHelpText>
							)}
							<Text color={getColorGradientBinary(valueNumber, colorGradientInfo) ?? valueTextColor}>
								{value != null && value !== ""
									? format
										? FORMAT_FUNCTIONS[format](value)
										: value
									: nullFillerText}
							</Text>
							{helpText != null && helpTextPlacement === "right" && (
								<StatHelpText>{helpText}</StatHelpText>
							)}
						</Box>
					)}
				</StatNumber>
			</Tooltip>
			{helpText != null && helpTextPlacement === "bottom" && <StatHelpText>{helpText}</StatHelpText>}
		</ChakraStat>
	);
};

export default PrimaryStat;
