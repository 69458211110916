import { TEAM_COLORS } from "_react/shared/legacy/ui/Colors";

const semanticTokens = {
	colors: {
		primary: "maroon",
		secondary: "powderBlue",

		success: "green.500",
		warning: "orange.600",
		error: "red.500",

		highlight: "yellow.200",

		// Used for colors of team level badges
		// PRO
		bgMLB: "teal.200",
		bgAAA: "orange.200",
		bgAA: "blue.200",
		"bgA+": "purple.200",
		bgA: "pink.200",
		"bgA-": "green.200",
		bgRK: "cyan.200",
		bgRk: "cyan.200", // Adding additional entry for Rk to accomodate BAM data
		bgFRk: "gray.200",
		colorMLB: "teal.700",
		colorAAA: "orange.700",
		colorAA: "blue.700",
		"colorA+": "purple.700",
		colorA: "pink.700",
		"colorA-": "green.700",
		colorRK: "cyan.700",
		colorRk: "cyan.700", // Adding additional entry for Rk to accomodate BAM data
		colorFRk: "gray.700",
		// AMA
		bgINDY: "teal.200",
		bg4YR: "blue.200",
		bgJC: "green.200",
		bgHS: "purple.200",
		bgAMA_SUMMER: "pink.200",
		bgAMA_SHOWCASE: "cyan.200",
		bgAMA_OTH: "orange.200",
		colorINDY: "teal.700",
		color4YR: "blue.700",
		colorJC: "green.700",
		colorHS: "purple.700",
		colorAMA_SUMMER: "pink.700",
		colorAMA_SHOWCASE: "cyan.700",
		colorAMA_OTH: "orange.700",
		// INTL
		bgMEX: "green.200",
		colorMEX: "green.700",
		bgNPB: "blue.200",
		colorNPB: "blue.700",
		bgNPB_MIN: "teal.200",
		colorNPB_MIN: "teal.700",
		bgCPBL: "red.200",
		colorCPBL: "red.700",
		bgINTL_AMA: "orange.200",
		colorINTL_AMA: "orange.700",
		bgINTL_OTH: "cyan.200",
		colorINTL_OTH: "cyan.700",
		bgKBO: "purple.200",
		colorKBO: "purple.700",
		bgKBO_MIN: "pink.200",
		colorKBO_MIN: "pink.700",
		bgOLY: "gray.200",
		colorOLY: "gray.700",

		// Default
		bgNoLevel: "gray.600",
		colorNoLevel: "gray.200",

		// fastballs
		colorFF: "red.500", // #E53E3E
		colorSI: "pink.400", // #ED64A6
		// breaking balls
		colorCT: "purple.600", // #6B46C1
		colorSL: "blue.500", // #3182CE
		colorSW: "cyan.400", // #0BC5EA
		colorSV: "teal.600", // #2C7A7B
		colorCB: "green.300", // #68D391
		// offspeeds
		colorCH: "orange.400", // #ED8936
		colorSP: "yellow.300", // #F6E05E
		// other
		colorKN: "gray.400", // #A3A3A3
		// These two are the same thing but we call them different things in different places
		colorOVR: "gray.600",
		colorAll: "gray.600",
		colorUnknownPitchType: "gray.400",

		// Used for general data viz
		axisColor: "gray.800",
		backgroundColor: "gray.50",
		dataColorPrimaryBlue: "blue.400",
		dataColorPrimaryPurple: "purple.600",
		dataColorPrimaryGreen: "green.300",
		dataColorPrimaryRed: "red.500",
		dataColorPrimaryYellow: "yellow.300",
		dataColorPrimaryGray: "gray.900",
		dataColorSecondary: "gray.600",
		dataColorTertiary: "gray.400",
		dataColorQuaternary: "gray.300",
		gridStrokeColor: "gray.200",

		// Colors for each MLB Team
		mlbTeams: {
			ari: {
				primary: TEAM_COLORS.ari.primary.color,
				secondary: TEAM_COLORS.ari.secondary.color
			},
			ath: {
				primary: TEAM_COLORS.ath.primary.color,
				secondary: TEAM_COLORS.ath.secondary.color
			},
			atl: {
				primary: TEAM_COLORS.atl.primary.color,
				secondary: TEAM_COLORS.atl.secondary.color
			},
			bal: {
				primary: TEAM_COLORS.bal.primary.color,
				secondary: TEAM_COLORS.bal.secondary.color
			},
			bos: {
				primary: TEAM_COLORS.bos.primary.color,
				secondary: TEAM_COLORS.bos.secondary.color
			},
			chc: {
				primary: TEAM_COLORS.chc.primary.color,
				secondary: TEAM_COLORS.chc.secondary.color
			},
			cws: {
				primary: TEAM_COLORS.cws.primary.color,
				secondary: TEAM_COLORS.cws.secondary.color
			},
			cin: {
				primary: TEAM_COLORS.cin.primary.color,
				secondary: TEAM_COLORS.cin.secondary.color
			},
			cle: {
				primary: TEAM_COLORS.cle.primary.color,
				secondary: TEAM_COLORS.cle.secondary.color
			},
			col: {
				primary: TEAM_COLORS.col.primary.color,
				secondary: TEAM_COLORS.col.secondary.color
			},
			det: {
				primary: TEAM_COLORS.det.primary.color,
				secondary: TEAM_COLORS.det.secondary.color
			},
			hou: {
				primary: TEAM_COLORS.hou.primary.color,
				secondary: TEAM_COLORS.hou.secondary.color
			},
			kc: {
				primary: TEAM_COLORS.kc.primary.color,
				secondary: TEAM_COLORS.kc.secondary.color
			},
			laa: {
				primary: TEAM_COLORS.laa.primary.color,
				secondary: TEAM_COLORS.laa.secondary.color
			},
			lad: {
				primary: TEAM_COLORS.lad.primary.color,
				secondary: TEAM_COLORS.lad.secondary.color
			},
			mia: {
				primary: TEAM_COLORS.mia.primary.color,
				secondary: TEAM_COLORS.mia.secondary.color
			},
			mil: {
				primary: TEAM_COLORS.mil.primary.color,
				secondary: TEAM_COLORS.mil.secondary.color
			},
			min: {
				primary: TEAM_COLORS.min.primary.color,
				secondary: TEAM_COLORS.min.secondary.color
			},
			nym: {
				primary: TEAM_COLORS.nym.primary.color,
				secondary: TEAM_COLORS.nym.secondary.color
			},
			nyy: {
				primary: TEAM_COLORS.nyy.primary.color,
				secondary: TEAM_COLORS.nyy.secondary.color
			},
			oak: {
				// Old Oakland Athletics
				primary: TEAM_COLORS.oak.primary.color,
				secondary: TEAM_COLORS.oak.secondary.color
			},
			phi: {
				primary: TEAM_COLORS.phi.primary.color,
				secondary: TEAM_COLORS.phi.secondary.color
			},
			pit: {
				primary: TEAM_COLORS.pit.primary.color,
				secondary: TEAM_COLORS.pit.secondary.color
			},
			stl: {
				primary: TEAM_COLORS.stl.primary.color,
				secondary: TEAM_COLORS.stl.secondary.color
			},
			sd: {
				primary: TEAM_COLORS.sd.primary.color,
				secondary: TEAM_COLORS.sd.secondary.color
			},
			sf: {
				primary: TEAM_COLORS.sf.primary.color,
				secondary: TEAM_COLORS.sf.secondary.color
			},
			sea: {
				primary: TEAM_COLORS.sea.primary.color,
				secondary: TEAM_COLORS.sea.secondary.color
			},
			tb: {
				primary: TEAM_COLORS.tb.primary.color,
				secondary: TEAM_COLORS.tb.secondary.color
			},
			tex: {
				primary: TEAM_COLORS.tex.primary.color,
				secondary: TEAM_COLORS.tex.secondary.color
			},
			tor: {
				primary: TEAM_COLORS.tor.primary.color,
				secondary: TEAM_COLORS.tor.secondary.color
			},
			wsh: {
				primary: TEAM_COLORS.wsh.primary.color,
				secondary: TEAM_COLORS.wsh.secondary.color
			}
		}
	}
};

export default semanticTokens;
