import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
	useToast,
	VStack,
	HStack,
	Box,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	MenuDivider,
	Portal,
	Text,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Tooltip,
	Spacer,
	Flex,
	SystemStyleObject
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { isDefaultFilters, DEFAULT_STATS_TABLE_FILTERS } from "_react/shared/ui/data/tables/shared/Filters";
import { PITCH_GROUP_OVERALL, PITCH_TYPES } from "_react/shared/_constants/pitch_types";
import { ILkPitchType } from "_react/shared/_types/phil_data/lk_pitch_type";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import { BATS_L, BATS_OVERALL, BATS_R } from "_react/shared/data_models/baseline_hit_probs/_constants";
import {
	transformMetricReliability,
	TMetricReliabilityThresholds
} from "_react/shared/data_models/metric_reliability/_helpers";
import { VALID_AMA_LEVELS } from "_react/shared/data_models/team/_constants";
import { IPlayerSeasonPitchOutcomesObservedPitchByTeamLkLevel } from "_react/shared/data_models/pitch_outcome_observed/_types";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import {
	getAmaLevelDisplayText,
	getMinAndMaxSeason,
	getSeasonFilters,
	updateFilters
} from "_react/shared/_helpers/stats";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import DataSourceBadges, {
	DATA_SOURCE_HAWKEYE,
	DATA_SOURCE_TRACKMAN
} from "_react/shared/ui/presentation/components/DataSourceBadges/DataSourceBadges";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import {
	NUM_DISPLAY_SEASONS,
	getPitcherPitchOutcomesColumns,
	PITCHER_PITCH_OUTCOMES_PARENT_COLUMNS
} from "_react/shared/ui/data/tables/AmaPitcherPitchOutcomesTable/_constants";
import PitcherPitchOutcomesTableMachine, {
	TPitcherPitchOutcomesTableContext,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM,
	FETCHING_PLAYER_SEASON_POP_PITCH_BYTEAM,
	FETCHING_PLAYER_SEASON_POO_PITCH_BYTEAM,
	FETCHING_METRIC_RELIABILITY,
	SET_PLAYER_ID,
	SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM,
	SET_PLAYER_SEASON_POP_PITCH_BYTEAM,
	SET_PLAYER_SEASON_POO_PITCH_BYTEAM,
	SET_METRIC_RELIABILITY,
	SET_FILTERS
} from "_react/shared/ui/data/tables/AmaPitcherPitchOutcomesTable/_machine";
import {
	ICombinedPitcherPitchOutcomesData,
	TPitcherPitchOutcomesTableData
} from "_react/shared/ui/data/tables/AmaPitcherPitchOutcomesTable/_types";
import {
	createArsenalScoresByTeamRecord,
	createPitchOutcomeObservedByTeamRecord,
	createPitchOutcomeProbabilitiesByTeamRecord,
	mergePitchOutcomeAggregations
} from "_react/shared/ui/data/tables/AmaPitcherPitchOutcomesTable/_helpers";

type TPitcherPitchOutcomesTableStyle = {
	container?: SystemStyleObject;
	tableContainer?: SystemStyleObject;
};

type TPitcherPitchOutcomesTableProps = {
	title?: string;
	playerId?: number;
	data?: TPitcherPitchOutcomesTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<ICombinedPitcherPitchOutcomesData, keyof ICombinedPitcherPitchOutcomesData>;
	style?: TPitcherPitchOutcomesTableStyle;
};

const AmaPitcherPitchOutcomesTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TPitcherPitchOutcomesTableProps) => {
	const toast = useToast();
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const [current, send] = useMachine(PitcherPitchOutcomesTableMachine(playerIdProp, data, shouldFetchData, toast));
	const isFetchingPlayerSeasonArsenalScoresByTeam = current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM);
	const isFetchingPlayerSeasonPopPitchByTeam = current.matches(FETCHING_PLAYER_SEASON_POP_PITCH_BYTEAM);
	const isFetchingPlayerSeasonPooPitchByTeam = current.matches(FETCHING_PLAYER_SEASON_POO_PITCH_BYTEAM);
	const isFetchingMetricReliability = current.matches(FETCHING_METRIC_RELIABILITY);
	const isLoading =
		isFetchingPlayerSeasonArsenalScoresByTeam ||
		isFetchingPlayerSeasonPopPitchByTeam ||
		isFetchingPlayerSeasonPooPitchByTeam ||
		isFetchingMetricReliability;

	const context = current.context as TPitcherPitchOutcomesTableContext;
	const {
		playerId,
		filters,
		playerSeasonArsenalScoresByTeam,
		playerSeasonPopPitchByTeam,
		playerSeasonPooPitchByTeam,
		metricReliability
	} = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonArsenalScoresByTeam !== playerSeasonArsenalScoresByTeam && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM, value: data?.playerSeasonArsenalScoresByTeam });
	}, [send, data?.playerSeasonArsenalScoresByTeam, playerSeasonArsenalScoresByTeam, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonPopPitchByTeam !== playerSeasonPopPitchByTeam && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_POP_PITCH_BYTEAM, value: data?.playerSeasonPopPitchByTeam });
	}, [send, data?.playerSeasonPopPitchByTeam, playerSeasonPopPitchByTeam, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonPooPitchByTeam !== playerSeasonPooPitchByTeam && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_POO_PITCH_BYTEAM, value: data?.playerSeasonPooPitchByTeam });
	}, [send, data?.playerSeasonPooPitchByTeam, playerSeasonPooPitchByTeam, shouldFetchData]);
	useEffect(() => {
		if (data?.metricReliability !== metricReliability && shouldFetchData === false)
			send({ type: SET_METRIC_RELIABILITY, value: data?.metricReliability });
	}, [send, data?.metricReliability, metricReliability, shouldFetchData]);

	//
	// PREPROCESSING playerSeasonPopPitch, playerSeasonPooPitch
	//

	const combinedPitcherPitchOutcomesDataByTeam: Array<ICombinedPitcherPitchOutcomesData> | undefined = useMemo(() => {
		// Create record based on observed data
		if (
			playerSeasonArsenalScoresByTeam != null &&
			playerSeasonPooPitchByTeam != null &&
			playerSeasonPopPitchByTeam != null
		) {
			const arsenalScoresRecord = createArsenalScoresByTeamRecord(playerSeasonArsenalScoresByTeam);
			const observedRecord = createPitchOutcomeObservedByTeamRecord(playerSeasonPooPitchByTeam);
			const probabilitiesRecord = createPitchOutcomeProbabilitiesByTeamRecord(playerSeasonPopPitchByTeam);
			const mergedRecord = mergePitchOutcomeAggregations(
				arsenalScoresRecord,
				observedRecord,
				probabilitiesRecord
			);
			return Object.values(mergedRecord);
		}
	}, [playerSeasonPooPitchByTeam, playerSeasonPopPitchByTeam, playerSeasonArsenalScoresByTeam]);

	//
	// Season filter options
	//

	// Get list of seasons using byTeam data so that the min/max seasons have corresponding entries in the table,
	// as a season must have at least one associated team to be displayed
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<ICombinedPitcherPitchOutcomesData>(combinedPitcherPitchOutcomesDataByTeam ?? []),
		[combinedPitcherPitchOutcomesDataByTeam]
	);
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IPlayerSeasonPitchOutcomesObservedPitchByTeamLkLevel> = useMemo(
		() =>
			combinedPitcherPitchOutcomesDataByTeam
				?.reduce(
					(
						acc: Array<IPlayerSeasonPitchOutcomesObservedPitchByTeamLkLevel>,
						c: ICombinedPitcherPitchOutcomesData
					) => {
						const levelRel: IPlayerSeasonPitchOutcomesObservedPitchByTeamLkLevel | undefined =
							c.team?.levelRel;
						if (levelRel && levelRel.value && !acc.some(val => val.value === levelRel.value))
							acc.push(levelRel);
						return acc;
					},
					[]
				)
				?.sort(
					(
						a: IPlayerSeasonPitchOutcomesObservedPitchByTeamLkLevel,
						b: IPlayerSeasonPitchOutcomesObservedPitchByTeamLkLevel
					) => (a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[combinedPitcherPitchOutcomesDataByTeam]
	);

	//
	// Pitch type options
	//

	// Compute the pitch type options
	const pitchTypeOptions: Array<ILkPitchType> = useMemo(
		() =>
			combinedPitcherPitchOutcomesDataByTeam
				?.reduce((acc: Array<ILkPitchType>, curr: ICombinedPitcherPitchOutcomesData) => {
					const pitchType: ILkPitchType | undefined = curr.lkPitchType;
					if (
						pitchType &&
						pitchType.abbreviation &&
						!acc.some(val => val.abbreviation === pitchType.abbreviation) &&
						PITCH_TYPES.includes(pitchType.abbreviation as TPitchTypes)
					)
						acc.push(pitchType);
					return acc;
				}, [])
				?.sort((a: ILkPitchType, b: ILkPitchType) => a.sortOrder - b.sortOrder) ?? [],
		[combinedPitcherPitchOutcomesDataByTeam]
	);

	// Once the pitch type options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.pitchTypes === undefined && pitchTypeOptions.length > 0) {
			const pitchTypeAbbreviations = pitchTypeOptions.map(
				(pitchTypeOption: ILkPitchType) => pitchTypeOption.abbreviation ?? ""
			);
			const newFilters = {
				...filters,
				pitchTypes: [...pitchTypeAbbreviations, PITCH_GROUP_OVERALL]
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, pitchTypeOptions]);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map(
					(option: IPlayerSeasonPitchOutcomesObservedPitchByTeamLkLevel) => option.value
				)
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Filter data for table
	//

	const batsFilter: string = useMemo(() => (filters.bats.length === 2 ? BATS_OVERALL : filters.bats?.[0]), [
		filters.bats
	]);

	// Transform metric reliability thresholds
	const transformedMetricReliability: { [index: string]: TMetricReliabilityThresholds } = useMemo(() => {
		return transformMetricReliability(metricReliability);
	}, [metricReliability]);

	// Get columns based on handedness filter
	const pitcherPitchOutcomesColumns = useMemo(
		() => getPitcherPitchOutcomesColumns(batsFilter, transformedMetricReliability),
		[batsFilter, transformedMetricReliability]
	);

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return pitcherPitchOutcomesColumns;
		return pitcherPitchOutcomesColumns.filter(
			(col: TColumn<ICombinedPitcherPitchOutcomesData, keyof ICombinedPitcherPitchOutcomesData>) =>
				columns.includes(col.value)
		);
	}, [columns, pitcherPitchOutcomesColumns]);

	const filteredCombinedPitcherPitchOutcomesDataByTeam:
		| Array<ICombinedPitcherPitchOutcomesData>
		| undefined = useMemo(() => {
		if (isLoading || !batsFilter) return undefined;
		return combinedPitcherPitchOutcomesDataByTeam?.filter(
			(c: ICombinedPitcherPitchOutcomesData) =>
				c.season <= seasonFilters.maxSeason &&
				c.season >= seasonFilters.minSeason &&
				(!c.team || filters.levels?.includes(c.team?.level ?? "")) &&
				filters.pitchTypes?.includes(c.pitchType ?? "")
		);
	}, [
		isLoading,
		seasonFilters,
		batsFilter,
		filters.levels,
		filters.pitchTypes,
		combinedPitcherPitchOutcomesDataByTeam
	]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		let availablePitchTypes = pitchTypeOptions.map(
			(pitchTypeOption: ILkPitchType) => pitchTypeOption.abbreviation ?? ""
		);
		availablePitchTypes = [...availablePitchTypes, PITCH_GROUP_OVERALL];
		const availableLevels = levelFilterOptions.map(
			(option: IPlayerSeasonPitchOutcomesObservedPitchByTeamLkLevel) => option.value
		);

		return isDefaultFilters(filters, availablePitchTypes, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, pitchTypeOptions, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: DEFAULT_STATS_TABLE_FILTERS });
	}, [send]);

	// Filtering
	const handleBatsSelect = (value: string) => {
		const newFilters = {
			...filters,
			bats: updateFilters(filters.bats, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handlePitchTypesSelect = (value: string) => {
		const newFilters = {
			...filters,
			pitchTypes: updateFilters(filters.pitchTypes ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
					<DataSourceBadges dataSources={[DATA_SOURCE_TRACKMAN, DATA_SOURCE_HAWKEYE]} />
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false} placement="left-start">
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
								<MenuOptionGroup title="Bats" type="checkbox" value={filters.bats}>
									<MenuItemOption value={BATS_L} onClick={() => handleBatsSelect(BATS_L)}>
										Left
									</MenuItemOption>
									<MenuItemOption value={BATS_R} onClick={() => handleBatsSelect(BATS_R)}>
										Right
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuDivider />
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Pitch Types"
									type="checkbox"
									value={filters.pitchTypes ?? [PITCH_GROUP_OVERALL, ...PITCH_TYPES]}
								>
									<MenuItemOption
										value={PITCH_GROUP_OVERALL}
										onClick={() => handlePitchTypesSelect(PITCH_GROUP_OVERALL)}
									>
										Overall
									</MenuItemOption>
									{pitchTypeOptions.map((pitchType: ILkPitchType) => (
										<MenuItemOption
											value={pitchType.abbreviation ?? ""}
											onClick={() => handlePitchTypesSelect(pitchType.abbreviation ?? "")}
											key={`${pitchType.abbreviation}`}
										>
											<PitchTypeLabel
												label={pitchType.label ?? ""}
												abbreviation={pitchType.abbreviation ?? ""}
												shape={ICON_CIRCLE}
											/>
										</MenuItemOption>
									))}
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Levels"
									type="checkbox"
									value={filters.levels ?? VALID_AMA_LEVELS}
								>
									{levelFilterOptions.map(
										(option: IPlayerSeasonPitchOutcomesObservedPitchByTeamLkLevel) => (
											<MenuItemOption
												value={option.value}
												onClick={() => handleLevelSelect(option.value)}
												key={`${option.value}`}
											>
												<TeamLevelBadge
													level={option.value}
													displayName={getAmaLevelDisplayText(option.value)}
												/>
											</MenuItemOption>
										)
									)}
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<ICombinedPitcherPitchOutcomesData, keyof ICombinedPitcherPitchOutcomesData>
					columns={filteredColumns}
					parentColumns={PITCHER_PITCH_OUTCOMES_PARENT_COLUMNS}
					data={filteredCombinedPitcherPitchOutcomesDataByTeam}
					emptyDataDisplayText={"No Pitch Outcomes Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					isExpandableRows
					getCustomRowKeyFunction={(row: ICombinedPitcherPitchOutcomesData) => {
						return `${row.season}-${row.pitchType}-${row.teamId}`;
					}}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: DESC
						},
						{
							columnValue: "level",
							sortDirection: ASC
						},
						{
							columnValue: "team",
							sortDirection: ASC
						},
						{
							columnValue: "pitchType",
							sortDirection: ASC
						},
						{
							columnValue: "pitches",
							sortDirection: DESC
						}
					]}
					getRowStyleFunction={(
						obj: ICombinedPitcherPitchOutcomesData,
						index: number,
						data: Array<ICombinedPitcherPitchOutcomesData>
					) => {
						if (index < data.length - 1 && obj.season !== data[index + 1].season) {
							return {
								borderBottom: "1px solid !important",
								borderBottomColor: "gray.300 !important"
							};
						}
						return {};
					}}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default AmaPitcherPitchOutcomesTable;
