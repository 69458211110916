import React, { useState, useEffect } from "react";
import debounce from "debounce-promise";

import { Props } from "react-select";
import AsyncSelect from "react-select/async";

import { TOption } from "_react/inputs";
import { makeStyledSelectStyles, SelectStyles } from "_react/shared/selects";
import axios from "_redux/_utils/_axios";
import { $TSFixMe } from "utils/tsutils";
import { createReactSelectTeamOption } from "_react/shared/searches/_helpers";
import { TSearchResultTeamObject } from "_react/shared/searches/_types";

const PHILLIES_BAM_ID = 143;
const PHILLIES_OPTION_OBJECT = {
	value: 143,
	label: "Philadelphia Phillies - MLB (Philadelphia)",
	bamId: 143
};

export type TValue = TOption<number>;

export type TTeamResp = {
	value: number;
	label: string;
	playerClassification: string;
	level: string | null;
	state: string | null;
	synergyId: string | null;
	bamId: number | null;
	ebisClubId: number | null;
	ebisSchoolId: number | null;
	fangraphsId: string | null;
	fieldfxId: string | null;
	trackmanId: string | null;
	naiaId: number | null;
	njcaaId: string | null;
	cccaaId: string | null;
};

export interface TTeamSelectProps extends Props<TOption<number>> {
	controlledTeamId?: number | null;
	levels?: Array<string>;
	states?: Array<string>;
	type?: string;
	styles?: SelectStyles;
	disabled?: boolean;
}

export const buildQueryString = (
	query: string,
	playerClassification: string | null = null,
	levels: Array<string> | null = null,
	states: Array<string> | null = null,
	isActive: boolean | null = null
) => {
	let queryString = `?q=${query}`;
	if (playerClassification != null) queryString = `${queryString}&classifications=${playerClassification}`;
	if (levels != null && levels.length > 0)
		queryString = `${queryString}&levels=${encodeURIComponent(levels.join(","))}`;
	if (states != null && states.length > 0) queryString = `${queryString}&states=${states.join(",")}`;
	if (isActive != null) queryString = `${queryString}&isActive=${isActive}`;
	return queryString;
};

type TBamIdOption<T> = {
	label: string;
	value: T;
	bamId: number;
};

type TTeamSuggestionsResponse = {
	data: TSearchResultTeamObject[];
};

function TeamSelect(props: TTeamSelectProps) {
	const [selectValue, setSelectValue] = useState<TBamIdOption<number> | null>(null);
	const fetchTeamSuggestions = (query: string, callback: $TSFixMe) => {
		const queryString = buildQueryString(query, props.type, props.levels, props.states);
		return axios.get(`/search/team${queryString}`).then((suggestions: TTeamSuggestionsResponse) => {
			callback(suggestions.data.map(createReactSelectTeamOption));
		});
	};

	const debouncedFetchTeamSuggestions = debounce(fetchTeamSuggestions, 250);
	const { styles, controlledTeamId, onChange: _onChange, ...otherProps } = props;

	const teamPath = controlledTeamId ? `/team/bam/${controlledTeamId}` : null;

	function loadOptions(query: $TSFixMe, callback: $TSFixMe) {
		if (query) {
			debouncedFetchTeamSuggestions(query, callback);
		} else if (selectValue) {
			callback([selectValue]);
		} else if (teamPath) {
			axios.get(teamPath).then(response => {
				const item = response.data;
				const option = {
					value: item.bam_id,
					label: item.name_level_city,
					bamId: item.bam_id
				};
				setSelectValue(option);
				callback([option]);
			});
		} else {
			callback([]);
		}
	}

	useEffect(() => {
		if (teamPath != null)
			axios.get(teamPath).then(response => {
				const item = response.data;
				const option = {
					value: item.bam_id,
					label: item.name_level_city,
					bamId: item.bam_id
				};
				setSelectValue(option);
			});
	}, [teamPath]);

	const additionalProps =
		controlledTeamId === undefined || controlledTeamId === PHILLIES_BAM_ID
			? {
					...otherProps
			  }
			: {
					defaultOptions: [PHILLIES_OPTION_OBJECT],
					...otherProps
			  };

	return (
		<AsyncSelect
			isDisabled={props.disabled}
			closeMenuOnSelect={true}
			isClearable={true}
			isMulti={true}
			loadOptions={loadOptions}
			value={selectValue}
			styles={makeStyledSelectStyles({
				indicatorsContainer: {
					display: "none"
				},
				...styles
			})}
			onChange={(opt: $TSFixMe, action: $TSFixMe) => {
				setSelectValue(opt ? { value: opt.bamId, label: opt.label, bamId: opt.bamId } : null);
				if (props.onChange) props.onChange(opt, action);
			}}
			{...additionalProps}
		/>
	);
}

export default TeamSelect;
