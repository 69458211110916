import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
	useToast,
	VStack,
	HStack,
	Box,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	MenuDivider,
	Portal,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Tooltip,
	Spacer,
	Text,
	Flex,
	SystemStyleObject
} from "@chakra-ui/react";

import { useMachine } from "@xstate/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { getAmaLevelDisplayText, getSeasonFilters } from "_react/shared/_helpers/stats";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import { updateFilters, getMinAndMaxSeason } from "_react/shared/_helpers/stats";
import { ICollegesplitsPlayerLkLevel } from "_react/shared/data_models/ama_stats/_types";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { ICollegeSplitsPlayerBattingStatsByTeam } from "_react/shared/data_models/ama_stats/_types";
import { isDefaultFilters, DEFAULT_STATS_TABLE_FILTERS } from "_react/shared/ui/data/tables/shared/Filters";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import { THROWS_L, THROWS_OVERALL, THROWS_R } from "_react/shared/data_models/arsenal_scores/_constants";
import { VALID_AMA_LEVELS } from "_react/shared/data_models/team/_constants";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import {
	getPositionPlayerCollegesplitsColumns,
	NUM_DISPLAY_SEASONS
} from "_react/shared/ui/data/tables/PositionPlayerCollegesplitsTable/_constants";
import PositionPlayerCollegesplitsTableMachine, {
	TPositionPlayerCollegesplitsTableContext,
	FETCHING_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM,
	SET_PLAYER_ID,
	SET_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM,
	SET_FILTERS
} from "_react/shared/ui/data/tables/PositionPlayerCollegesplitsTable/_machine";
import { TPositionPlayerCollegesplitsTableData } from "_react/shared/ui/data/tables/PositionPlayerCollegesplitsTable/_types";

type TPositionPlayerCollegesplitsTableStyle = {
	container?: SystemStyleObject;
	tableContainer?: SystemStyleObject;
};

type TPositionPlayerCollegesplitsTableProps = {
	title?: string;
	playerId?: number;
	data?: TPositionPlayerCollegesplitsTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<ICollegeSplitsPlayerBattingStatsByTeam, keyof ICollegeSplitsPlayerBattingStatsByTeam>;
	style?: TPositionPlayerCollegesplitsTableStyle;
};

const PositionPlayerCollegesplitsTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TPositionPlayerCollegesplitsTableProps) => {
	const toast = useToast();
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const [current, send] = useMachine(
		PositionPlayerCollegesplitsTableMachine(playerIdProp, data, shouldFetchData, toast)
	);
	const isFetchingCollegeSplitsPlayerBattingStatsByTeam = current.matches(
		FETCHING_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM
	);
	const isLoading = isFetchingCollegeSplitsPlayerBattingStatsByTeam;

	const context = current.context as TPositionPlayerCollegesplitsTableContext;
	const { playerId, filters, collegeSplitsPlayerBattingStatsByTeam } = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (
			data?.collegeSplitsPlayerBattingStatsByTeam !== collegeSplitsPlayerBattingStatsByTeam &&
			shouldFetchData === false
		)
			send({
				type: SET_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM,
				value: data?.collegeSplitsPlayerBattingStatsByTeam
			});
	}, [send, data?.collegeSplitsPlayerBattingStatsByTeam, collegeSplitsPlayerBattingStatsByTeam, shouldFetchData]);

	// Get max and min season
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<ICollegeSplitsPlayerBattingStatsByTeam>(collegeSplitsPlayerBattingStatsByTeam ?? []),
		[collegeSplitsPlayerBattingStatsByTeam]
	);

	//
	// Season filter options
	//
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<ICollegesplitsPlayerLkLevel> = useMemo(
		() =>
			collegeSplitsPlayerBattingStatsByTeam
				?.reduce((acc: Array<ICollegesplitsPlayerLkLevel>, curr: ICollegeSplitsPlayerBattingStatsByTeam) => {
					const levelRel: ICollegesplitsPlayerLkLevel | undefined = curr.team?.levelRel;
					if (levelRel && levelRel.value && !acc.some(val => val.value === levelRel.value))
						acc.push(levelRel);
					return acc;
				}, [])
				?.sort(
					(a: ICollegesplitsPlayerLkLevel, b: ICollegesplitsPlayerLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[collegeSplitsPlayerBattingStatsByTeam]
	);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: ICollegesplitsPlayerLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Filter data for table
	//

	// Combine throws filters into "OVR" when possible
	const throwsFilter: string = useMemo(() => (filters.throws.length === 2 ? THROWS_OVERALL : filters.throws?.[0]), [
		filters.throws
	]);

	const filteredCollegeSplitsPlayerBattingStatsByTeamData:
		| Array<ICollegeSplitsPlayerBattingStatsByTeam>
		| undefined
		| null = useMemo(() => {
		if (isLoading) return undefined;
		if (!collegeSplitsPlayerBattingStatsByTeam) return collegeSplitsPlayerBattingStatsByTeam;
		return collegeSplitsPlayerBattingStatsByTeam.filter(
			(scores: ICollegeSplitsPlayerBattingStatsByTeam) =>
				scores.season <= seasonFilters.maxSeason &&
				scores.season >= seasonFilters.minSeason &&
				scores.throws === throwsFilter &&
				(filters.levels === undefined || filters.levels.includes(scores.team?.level ?? ""))
		);
	}, [isLoading, seasonFilters, throwsFilter, filters.levels, collegeSplitsPlayerBattingStatsByTeam]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		const availableLevels = levelFilterOptions.map((option: ICollegesplitsPlayerLkLevel) => option.value);

		return isDefaultFilters(filters, undefined, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: DEFAULT_STATS_TABLE_FILTERS });
	}, [send]);

	// Filter columns based on prop
	const positionPlayerCollegesplitsColumns = useMemo(() => {
		return getPositionPlayerCollegesplitsColumns(throwsFilter);
	}, [throwsFilter]);

	const filteredColumns = useMemo(() => {
		if (!columns) return positionPlayerCollegesplitsColumns;
		return positionPlayerCollegesplitsColumns.filter(
			(col: TColumn<ICollegeSplitsPlayerBattingStatsByTeam, keyof ICollegeSplitsPlayerBattingStatsByTeam>) =>
				columns.includes(col.value)
		);
	}, [columns, positionPlayerCollegesplitsColumns]);

	// Filtering
	const handleThrowsSelect = (value: string) => {
		const newFilters = {
			...filters,
			throws: updateFilters(filters.throws, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false} placement="left-start">
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
								<MenuOptionGroup title="Throws" type="checkbox" value={filters.throws}>
									<MenuItemOption value={THROWS_L} onClick={() => handleThrowsSelect(THROWS_L)}>
										Left
									</MenuItemOption>
									<MenuItemOption value={THROWS_R} onClick={() => handleThrowsSelect(THROWS_R)}>
										Right
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuDivider />
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Levels"
									type="checkbox"
									value={filters.levels ?? VALID_AMA_LEVELS}
								>
									{levelFilterOptions.map((option: ICollegesplitsPlayerLkLevel) => (
										<MenuItemOption
											value={option.value}
											onClick={() => handleLevelSelect(option.value)}
										>
											<TeamLevelBadge
												level={option.value}
												displayName={getAmaLevelDisplayText(option.value)}
											/>
										</MenuItemOption>
									))}
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<ICollegeSplitsPlayerBattingStatsByTeam, keyof ICollegeSplitsPlayerBattingStatsByTeam>
					columns={filteredColumns}
					data={filteredCollegeSplitsPlayerBattingStatsByTeamData}
					emptyDataDisplayText={"No PositionPlayer Collegesplits Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					isExpandableRows
					getCustomRowKeyFunction={(row: ICollegeSplitsPlayerBattingStatsByTeam) => {
						return `${row.season}-${row.teamId}-${row.splitType}`;
					}}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: DESC
						},
						{
							columnValue: "level",
							sortDirection: ASC
						},
						{
							columnValue: "team",
							sortDirection: ASC
						}
					]}
					getRowStyleFunction={(
						obj: ICollegeSplitsPlayerBattingStatsByTeam,
						index: number,
						data: Array<ICollegeSplitsPlayerBattingStatsByTeam>
					) => {
						if (index < data.length - 1 && obj.season !== data[index + 1].season) {
							return {
								borderBottom: "1px solid !important",
								borderBottomColor: "gray.300 !important"
							};
						}
						return {};
					}}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default PositionPlayerCollegesplitsTable;
