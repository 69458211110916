import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	PLAYING_LEVEL_AMA,
	BATS_L,
	BATS_R,
	THROWS_R,
	THROWS_L,
	THROWS_OVERALL
} from "_react/shared/data_models/seasonal_grades/_constants";
import { ISeasonalSwingMetricsInferredApiResponse } from "_react/shared/data_models/seasonal_grades/_types";
import { fetchSeasonalSwingMetricsInferred } from "_react/shared/data_models/seasonal_grades/_network";

import { TAmaSwingMetricsTableData } from "_react/shared/ui/data/tables/AmaSwingMetricsTable/_types";

export const SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE = "seasonalSwingMetricsInferred";

export type TAmaSwingMetricsTableCancelSource = {
	[SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TAmaSwingMetricsTableFilters = {
	throws: Array<string>;
	bats: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TAmaSwingMetricsTableContext = {
	playerId?: number;
	shouldFetchData?: boolean;
	filters: TAmaSwingMetricsTableFilters;
	seasonalSwingMetricsInferred?: Array<ISeasonalSwingMetricsInferredApiResponse> | null;
	cancelSources: TAmaSwingMetricsTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IAmaSwingMetricsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all swing metrics by player season
				seasonalSwingMetricsInferred: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_SEASONAL_SWING_METRICS_INFERRED = { initialized: { seasonalSwingMetricsInferred: "fetching" } };

const FETCH_SEASONAL_SWING_METRICS_INFERRED_DONE = "done.invoke.fetchSeasonalSwingMetricsInferred:invocation[0]";
const FETCH_SEASONAL_SWING_METRICS_INFERRED_ERROR = "error.platform.fetchSeasonalSwingMetricsInferred:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_SEASONAL_SWING_METRICS_INFERRED = "SET_SEASONAL_SWING_METRICS_INFERRED";
export const SET_FILTERS = "SET_FILTERS";

type TFetchSeasonalSwingMetricsInferredEvent = {
	type: typeof FETCH_SEASONAL_SWING_METRICS_INFERRED_DONE;
	data: Array<ISeasonalSwingMetricsInferredApiResponse> | undefined;
};

type TFetchSeasonalSwingMetricsInferredErrorEvent = {
	type: typeof FETCH_SEASONAL_SWING_METRICS_INFERRED_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetSeasonalSwingMetricsInferredEvent = {
	type: typeof SET_SEASONAL_SWING_METRICS_INFERRED;
	value?: Array<ISeasonalSwingMetricsInferredApiResponse>;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TAmaSwingMetricsTableFilters;
};

type TAmaSwingMetricsTableEvent =
	| TFetchSeasonalSwingMetricsInferredEvent
	| TFetchSeasonalSwingMetricsInferredErrorEvent
	| TSetPlayerIdEvent
	| TSetSeasonalSwingMetricsInferredEvent
	| TSetFiltersEvent;

export type TAmaSwingMetricsTableSend = Interpreter<
	TAmaSwingMetricsTableContext,
	IAmaSwingMetricsTableStateSchema,
	TAmaSwingMetricsTableEvent
>["send"];

const AmaSwingMetricsTableMachine = (
	playerIdProp?: number,
	data?: TAmaSwingMetricsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TAmaSwingMetricsTableContext, IAmaSwingMetricsTableStateSchema, TAmaSwingMetricsTableEvent>(
		{
			id: "amaSwingMetricsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					throws: [THROWS_L, THROWS_R],
					bats: [BATS_L, BATS_R]
				},
				seasonalSwingMetricsInferred: data?.seasonalSwingMetricsInferred,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: {
							actions: ["setPlayerId", "clearSeasonalSwingMetricsInferred"],
							cond: "shouldSetPlayerId"
						},
						SET_SEASONAL_SWING_METRICS_INFERRED: { actions: "setSeasonalSwingMetricsInferred" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						seasonalSwingMetricsInferred: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchSeasonalSwingMetricsInferred",
												cond: "shouldFetchSeasonalSwingMetricsInferred"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchSeasonalSwingMetricsInferred",
									entry: ["refreshSeasonalSwingMetricsInferredCancelSource"],
									invoke: {
										src: "fetchSeasonalSwingMetricsInferred",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSeasonalSwingMetricsInferredSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSeasonalSwingMetricsInferredErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (context: TAmaSwingMetricsTableContext, event: TAmaSwingMetricsTableEvent) =>
					event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchSeasonalSwingMetricsInferred: (
					context: TAmaSwingMetricsTableContext,
					_event: TAmaSwingMetricsTableEvent
				) =>
					context.seasonalSwingMetricsInferred === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearSeasonalSwingMetricsInferred: assign<TAmaSwingMetricsTableContext, TAmaSwingMetricsTableEvent>({
					seasonalSwingMetricsInferred: (
						_context: TAmaSwingMetricsTableContext,
						_event: TAmaSwingMetricsTableEvent
					) => undefined,
					cancelSources: (context: TAmaSwingMetricsTableContext, _event: TAmaSwingMetricsTableEvent) => {
						if (context.cancelSources[SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE] != null)
							context.cancelSources[SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE].cancel();
						delete context.cancelSources[SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TAmaSwingMetricsTableContext, TAmaSwingMetricsTableEvent>({
					playerId: (context: TAmaSwingMetricsTableContext, event: TAmaSwingMetricsTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (context: TAmaSwingMetricsTableContext, _event: TAmaSwingMetricsTableEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setSeasonalSwingMetricsInferred: assign<TAmaSwingMetricsTableContext, TAmaSwingMetricsTableEvent>({
					seasonalSwingMetricsInferred: (
						context: TAmaSwingMetricsTableContext,
						event: TAmaSwingMetricsTableEvent
					) => {
						if (event.type !== SET_SEASONAL_SWING_METRICS_INFERRED)
							return context.seasonalSwingMetricsInferred;
						return event.value;
					},
					cancelSources: (context: TAmaSwingMetricsTableContext, event: TAmaSwingMetricsTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_SEASONAL_SWING_METRICS_INFERRED) return cancelSources;
						cancelSources[SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE]?.cancel();
						cancelSources[SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TAmaSwingMetricsTableContext, TAmaSwingMetricsTableEvent>({
					filters: (context: TAmaSwingMetricsTableContext, event: TAmaSwingMetricsTableEvent) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshSeasonalSwingMetricsInferredCancelSource: assign<
					TAmaSwingMetricsTableContext,
					TAmaSwingMetricsTableEvent
				>({
					cancelSources: (context: TAmaSwingMetricsTableContext, _event: TAmaSwingMetricsTableEvent) => {
						if (context.cancelSources[SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE] != null)
							context.cancelSources[SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE].cancel();
						context.cancelSources[SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchSeasonalSwingMetricsInferredSuccess: assign<
					TAmaSwingMetricsTableContext,
					TAmaSwingMetricsTableEvent
				>({
					seasonalSwingMetricsInferred: (
						context: TAmaSwingMetricsTableContext,
						event: TAmaSwingMetricsTableEvent
					) => {
						if (event.type !== FETCH_SEASONAL_SWING_METRICS_INFERRED_DONE)
							return context.seasonalSwingMetricsInferred;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchSeasonalSwingMetricsInferredErrored: (
					context: TAmaSwingMetricsTableContext,
					event: TAmaSwingMetricsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_SEASONAL_SWING_METRICS_INFERRED_ERROR ? event.data : undefined,
						context.toast,
						"Swing Metrics",
						"Error fetching seasonal inferred swing metrics."
					);
				}
			},
			services: {
				fetchSeasonalSwingMetricsInferred: (context: TAmaSwingMetricsTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchSeasonalSwingMetricsInferred(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_AMA,
								"bats[in]": [BATS_L, BATS_R].join(","),
								"throws[in]": [THROWS_L, THROWS_R, THROWS_OVERALL].join(","),
								isUseCache: true
							},
							context.cancelSources[SEASONAL_SWING_METRICS_INFERRED_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default AmaSwingMetricsTableMachine;
