import { POSITION_GROUPS_POSITION_PLAYER, POSITION_GROUPS_PITCHER } from "_react/shared/_constants/position_groups";
import { LEVEL_NS, NAME_NS } from "_react/shared/data_models/team/_constants";

// phred.ama_prospect_value values
export const MODEL_NAME_V2 = "ama_v2";
export const PREDICTION_TYPE_DY = "DY";
export const PREDICTION_TYPE_FOL = "FOL";

export const PLAYER_TYPE_BATTER = "batter";
export const PLAYER_TYPE_PITCHER = "pitcher";

export const PLAYER_TYPE_TO_POSITION_GROUP = {
	[PLAYER_TYPE_BATTER]: POSITION_GROUPS_POSITION_PLAYER,
	[PLAYER_TYPE_PITCHER]: POSITION_GROUPS_PITCHER
};

// Null filler value for IAmaProspectValueLeaderboardTeam
export const NO_SCHOOL_TEAM = {
	level: LEVEL_NS,
	nameDisplay: NAME_NS,
	name: NAME_NS,
	nameShort: NAME_NS,
	levelRel: null,
	scoutAssigned: null
};
