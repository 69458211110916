export const camelToSnake = (str: string): string => {
	return str.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`);
};

export const separateFirstSentence = (str: string): string[] => {
	const match = str.match(/^.*?[.!?](?:\s|$)/);
	const firstSentence = match ? match[0].trim() : str;
	const remainingText = match ? str.slice(firstSentence.length).trim() : "";
	return [firstSentence, remainingText];
};
