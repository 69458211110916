import { TMetricReliabilityThresholds } from "_react/shared/data_models/metric_reliability/_helpers";
import { IPlayerSeasonArsenalScoresByTeamSchema } from "_react/shared/data_models/arsenal_scores/_types";

export const getMeetsReliabilityThreshold = (
	row: IPlayerSeasonArsenalScoresByTeamSchema,
	thresholds?: { [index: string]: TMetricReliabilityThresholds }
): { meetsAllThresholds: boolean; tooltip: string } => {
	const pitchType = row.pitchType;
	const thresholdInfo: { meetsAllThresholds: boolean; tooltip: string } = {
		meetsAllThresholds: true,
		tooltip: ""
	};
	const tooltipArray = [];
	if (thresholds && Object.keys(thresholds).includes(pitchType)) {
		const pitchTypeThreshold = thresholds[pitchType];
		const sampleSize = row.total;
		if (pitchTypeThreshold.stuffGrade && sampleSize && sampleSize < pitchTypeThreshold.stuffGrade) {
			tooltipArray.push(
				`Stuff grades are not reliable when there are less than ${pitchTypeThreshold.stuffGrade} pitches.`
			);
			thresholdInfo.meetsAllThresholds = false;
		}
	}
	thresholdInfo.tooltip = tooltipArray.join(" \n\n");

	return thresholdInfo;
};
