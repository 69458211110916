import React from "react";

import { TOOLTIP_STUFF_GRADE } from "_react/shared/_constants/tooltips";
import { getAmaLevelDisplayText, getAmaTeamDisplay } from "_react/shared/_helpers/stats";
import { IPlayerSeasonArsenalScoresByTeamSchema } from "_react/shared/data_models/arsenal_scores/_types";
import { TMetricReliabilityThresholds } from "_react/shared/data_models/metric_reliability/_helpers";
import { TColumn, TParentColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { BATS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";
import {
	format2080Grade,
	formatReleaseVelo,
	formatSpinRate,
	formatSpinEfficiency,
	formatBreak,
	formatRelease
} from "_react/shared/data_models/seasonal_grades/_helpers";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import SampleSizeField from "_react/shared/ui/presentation/components/Table/SampleSizeField";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import { getMeetsReliabilityThreshold } from "_react/shared/ui/data/tables/AmaPitcherPitchCharacteristicsTable/_helpers";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => row.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => getAmaTeamDisplay(row.team),
	getSortValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) =>
		`${row.team?.name ?? row.team?.trackmanTeam?.level}-${row.team?.id}`
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => (
		<TeamLevelBadge
			level={row.team?.level}
			displayName={getAmaLevelDisplayText(row.team?.level)}
			nullFillerText={NULL_FILLER_TEXT}
		/>
	),
	getSortValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) =>
		row.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER
};

const BATS_COLUMN = {
	id: "bats",
	value: "bats",
	label: "Bats",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => row.bats ?? NULL_FILLER_TEXT
};

const PITCH_TYPE_COLUMN = {
	id: "pitchType",
	value: "pitchType",
	label: "Type",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) =>
		row.lkPitchType?.label && row.lkPitchType?.abbreviation ? (
			<PitchTypeLabel
				label={row.lkPitchType.abbreviation}
				abbreviation={row.lkPitchType.abbreviation}
				shape={ICON_CIRCLE}
			/>
		) : (
			"Overall"
		),
	getSortValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => row.lkPitchType?.sortOrder ?? 0
};

const PITCHES_COLUMN = (thresholds?: { [index: string]: TMetricReliabilityThresholds }) => {
	return {
		id: "pitches",
		value: "pitches",
		label: "Total",
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => {
			const thresholdInfo = getMeetsReliabilityThreshold(row, thresholds);
			return (
				<SampleSizeField
					sampleSize={row.total ?? NULL_FILLER_TEXT}
					tooltipText={thresholdInfo.tooltip}
					isDisplayWarningSymbol={!thresholdInfo.meetsAllThresholds}
				/>
			);
		}
	};
};

const AVG_VELO_GRADE_COLUMN = {
	id: "releaseVelo",
	value: "releaseVelo",
	label: "Avg",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) =>
		formatReleaseVelo(row.releaseVelo, NULL_FILLER_TEXT)
};

const MAX_VELO_GRADE_COLUMN = {
	id: "maxReleaseVelo",
	value: "maxReleaseVelo",
	label: "Max",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) =>
		formatReleaseVelo(row.maxReleaseVelo, NULL_FILLER_TEXT)
};

const SPIN_COLUMN = {
	id: "spinRate",
	value: "spinRate",
	label: "Spin",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => formatSpinRate(row.spinRate, NULL_FILLER_TEXT)
};

const EFFICIENCY_COLUMN = {
	id: "spinEfficiencyModeled",
	value: "spinEfficiencyModeled",
	label: "Eff.",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) =>
		formatSpinEfficiency(row.spinEfficiencyModeled, NULL_FILLER_TEXT)
};

const TILT_COLUMN = {
	id: "tiltInferred",
	value: "tiltInferred",
	label: "Tilt",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => row.tiltInferred ?? NULL_FILLER_TEXT
};

const HB_COLUMN = {
	id: "horizontalBreak",
	value: "breakXDragless",
	label: "HB",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => formatBreak(row.breakXDragless, NULL_FILLER_TEXT)
};

const IVB_COLUMN = {
	id: "verticalBreak",
	value: "breakZDragless",
	label: "IVB",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => formatBreak(row.breakZDragless, NULL_FILLER_TEXT)
};

const RELEASE_HEIGHT_COLUMN = {
	id: "releaseZ",
	value: "releaseZ",
	label: "Height",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => formatRelease(row.releaseZ, NULL_FILLER_TEXT)
};

const RELEASE_SIDE_COLUMN = {
	id: "releaseX",
	value: "releaseX",
	label: "Side",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => formatRelease(row.releaseX, NULL_FILLER_TEXT)
};

const EXTENSION_COLUMN = {
	id: "extension",
	value: "extension",
	label: "Ext.",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => formatRelease(row.extension, NULL_FILLER_TEXT)
};

const STUFF_GRADE_COLUMN = {
	id: "stuffGrade",
	value: "stuffGrade",
	label: "Grade",
	tooltip: TOOLTIP_STUFF_GRADE,
	isMobile: false,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: IPlayerSeasonArsenalScoresByTeamSchema) => format2080Grade(row.stuffGrade, NULL_FILLER_TEXT)
};

export const getAmaPitcherPitchCharacteristicsColumns = (
	batsFilter: string,
	thresholds?: { [index: string]: TMetricReliabilityThresholds }
) => {
	const columns: Array<TColumn<
		IPlayerSeasonArsenalScoresByTeamSchema,
		keyof IPlayerSeasonArsenalScoresByTeamSchema
	>> = [SEASON_COLUMN, TEAM_COLUMN, LEVEL_COLUMN];
	if (batsFilter !== BATS_OVERALL) {
		columns.push(BATS_COLUMN);
	}
	return columns.concat([
		PITCH_TYPE_COLUMN,
		PITCHES_COLUMN(thresholds),
		AVG_VELO_GRADE_COLUMN,
		MAX_VELO_GRADE_COLUMN,
		SPIN_COLUMN,
		EFFICIENCY_COLUMN,
		TILT_COLUMN,
		HB_COLUMN,
		IVB_COLUMN,
		RELEASE_HEIGHT_COLUMN,
		RELEASE_SIDE_COLUMN,
		EXTENSION_COLUMN,
		STUFF_GRADE_COLUMN
	]);
};

export const PITCHER_PITCH_CHARACTERISTICS_PARENT_COLUMNS: Array<TParentColumn> = [
	{ label: "", id: "general", childColumnIds: ["season", "team", "level", "bats"] },
	{ label: "Pitches", id: "pitches", childColumnIds: ["pitchType", "pitches"] },
	{ label: "Velocity", id: "velocity", childColumnIds: ["releaseVelo", "maxReleaseVelo"] },
	{
		label: "Movement",
		id: "movement",
		childColumnIds: ["spinRate", "spinEfficiencyModeled", "tiltInferred", "horizontalBreak", "verticalBreak"]
	},
	{ label: "Release Point", id: "release", childColumnIds: ["releaseZ", "releaseX", "extension"] },
	{ label: "Stuff", id: "stuff", childColumnIds: ["stuffGrade"] }
];
