import React from "react";
import { VStack } from "@chakra-ui/react";

import PositionPlayerFoundationalSkillsTable from "_react/shared/ui/data/tables/PositionPlayerFoundationalSkillsTable/PositionPlayerFoundationalSkillsTable";
import PositionPlayerPerformanceTable from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/PositionPlayerPerformanceTable";
import PositionPlayerValueTable from "_react/shared/ui/data/tables/PositionPlayerValueTable/PositionPlayerValueTable";
import SwingMetricsTable from "_react/shared/ui/data/tables/SwingMetricsTable/SwingMetricsTable";

type TPositionPlayerStatisticsTablesProps = {
	playerId: number;
};

const PositionPlayerStatisticsTables = ({ playerId }: TPositionPlayerStatisticsTablesProps) => {
	return (
		<VStack marginBottom="10" gap="10">
			<PositionPlayerValueTable
				title="Value"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<PositionPlayerFoundationalSkillsTable
				title="Foundational Skills"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<PositionPlayerPerformanceTable
				title="Performance"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<SwingMetricsTable
				title="Swing Metrics"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
		</VStack>
	);
};

export default PositionPlayerStatisticsTables;
