import React, { useContext, useEffect, useMemo, useCallback } from "react";
import styled from "@emotion/styled";
import { useSelector } from "@xstate/react";

import { useQueryString } from "utils/url_helpers";
import { useIsGroupMember, useUserId } from "_react/app/AppContents";
import { ROSEN_USER_ID } from "_react/shared/_constants/ids";
import {
	PLAYER_PAGES_MANUELS_IAM_GROUPS,
	PLAYER_PAGES_OBSERVED_IAM_GROUPS,
	PLAYER_PAGES_PRO_PROJECTIONS_IAM_GROUPS,
	PLAYER_PAGES_SCOUTING_REPORTS_IAM_GROUPS,
	PLAYER_PAGES_AMA_PROJECTIONS_IAM_GROUPS,
	PLAYER_PAGES_INTL_PROJECTIONS_IAM_GROUPS,
	RND_IAM_GROUP,
	AMA_ADMIN_IAM_GROUP,
	INTL_SCOUTING_IAM_GROUP,
	PITCH_TYPE_MAPPING_IAM_GROUPS
} from "_react/shared/data_models/iam_new/_constants";
import { AMA_TABS, INTL_TABS, PRO_TABS, VIEW_MANUEL, VIEW_PROJECTIONS } from "_react/shared/_constants/query_strings";
import { PlayerClassificationDialog } from "_react/playerpage/shared/PlayerClassificationDialog";

import { AMA, INTL, PRO } from "_react/playerpage/_constants";
import {
	PlayerPageProvider as PlayerPageProviderLegacy,
	PlayerPageContext as PlayerPageContextLegacy
} from "_react/playerpage/_context";
import { PlayerPageContext } from "_react/playerpage/PlayerPageProvider";
import { TPlayerPageState, SET_VIEW_CLASSIFICATION } from "_react/playerpage/_machine";
import { TPlayerPageCombinedPlayer, TPlayer, TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import PlayerPageAma from "_react/playerpage/ama";
import PlayerPagePro from "_react/playerpage/pro";
import PlayerPageIntl from "_react/playerpage/intl";
import { countCurrentYearNotes, getUserDefaultView } from "_react/playerpage/_helpers";

const ContainerDiv = styled.div({
	flex: 1,
	display: "flex",
	flexDirection: "column",
	height: "calc(100% - 32px)",
	overflowY: "scroll"
});

export const PlayerPageContent = () => {
	const [view, setView] = useQueryString("view", undefined) as [
		string | undefined,
		(view: string | null, isReplaceState?: boolean) => void
	];

	const playerPageContext = useContext(PlayerPageContext);

	const philId: number | null | undefined = useSelector(
		playerPageContext.playerPageService,
		(state: TPlayerPageState) => state.context.playerLegacyId
	);
	const playerCombined: TPlayerPageCombinedPlayer | null | undefined = useSelector(
		playerPageContext.playerPageService,
		(state: TPlayerPageState) => state.context.player
	);
	const player: TPlayer | null | undefined = useSelector(
		playerPageContext.playerPageService,
		(state: TPlayerPageState) => state.context.playerLegacy
	);
	const viewClassification: TPlayer | undefined = useSelector(
		playerPageContext.playerPageService,
		(state: TPlayerPageState) => state.context.viewClassification
	);

	const setViewClassification = useCallback(
		(viewClassification: TPlayerPagePlayerClassification | undefined) => {
			playerPageContext.playerPageService.send({ type: SET_VIEW_CLASSIFICATION, value: viewClassification });
		},
		[playerPageContext]
	);

	const {
		setPlayer,
		setPlayerCombined,
		playerCombined: playerCombinedFromLegacyContext,
		player: playerFromLegacyContext,
		evals,
		notes,
		isNotesLoading,
		isNotesFailed,
		getEvals,
		getNotes,
		bioDisplayOnly,
		amaBioDocument,
		intlBioDocument,
		setAmaBioDocument,
		setIntlBioDocument,
		setBioDisplayOnly,
		isBioSaving,
		submitAmaBioUpdate,
		submitIntlBioUpdate
	} = useContext(PlayerPageContextLegacy);

	useEffect(() => {
		if (player == null && playerFromLegacyContext == null) return;
		if (player?.philId !== playerFromLegacyContext?.philId) setPlayer(player);
	}, [player, setPlayer, playerFromLegacyContext]);
	useEffect(() => {
		if (playerCombined == null && playerCombinedFromLegacyContext == null) return;
		if (playerCombined?.id !== playerCombinedFromLegacyContext?.id) setPlayerCombined(playerCombined);
	}, [playerCombined, setPlayerCombined, playerCombinedFromLegacyContext]);

	// Projections content and default tab permissions
	const hasProProjectionsPermissions = useIsGroupMember(PLAYER_PAGES_PRO_PROJECTIONS_IAM_GROUPS);
	const hasObservedPermissions = useIsGroupMember(PLAYER_PAGES_OBSERVED_IAM_GROUPS);
	const hasScoutingReportsPermissions = useIsGroupMember(PLAYER_PAGES_SCOUTING_REPORTS_IAM_GROUPS);
	const hasManuelsPermissions = useIsGroupMember(PLAYER_PAGES_MANUELS_IAM_GROUPS);
	const hasAmaProjectionsPermissions = useIsGroupMember(PLAYER_PAGES_AMA_PROJECTIONS_IAM_GROUPS);
	const hasIntlProjectionsPermissions = useIsGroupMember(PLAYER_PAGES_INTL_PROJECTIONS_IAM_GROUPS);

	const userId = useUserId();
	// A player's assigned scout can edit their Ama Bio
	// Everyone in R&D + Ama Bio Admins can edit all Ama Bios
	// Rosen can edit all Ama Bios
	const hasAmaBioEditPermissions =
		useIsGroupMember([RND_IAM_GROUP, AMA_ADMIN_IAM_GROUP]) ||
		userId === ROSEN_USER_ID ||
		playerCombined?.scoutAssigned?.userId === userId;
	// A player's assigned scout can edit their Intl Bio
	// Everyone in R&D + Intl Scouts can edit all Intl Bios
	// Rosen can edit all Intl Bios
	const hasIntlBioEditPermissions =
		useIsGroupMember([RND_IAM_GROUP, INTL_SCOUTING_IAM_GROUP]) ||
		playerCombined?.scoutAssigned?.userId === userId ||
		userId === ROSEN_USER_ID;
	const hasPitchTypeMappingEditPermissions = useIsGroupMember(PITCH_TYPE_MAPPING_IAM_GROUPS);

	// Only allow users to access tabs that exist on each view classification
	// Default to a specific tab based on user permissions
	useEffect(() => {
		if (
			(viewClassification === PRO &&
				(!PRO_TABS.includes(view ?? "") ||
					(!hasProProjectionsPermissions && view === VIEW_PROJECTIONS) ||
					(!hasManuelsPermissions && view === VIEW_MANUEL))) ||
			(viewClassification === AMA &&
				(!AMA_TABS.includes(view ?? "") ||
					// TODO this should be AMA projections access eventually
					(!hasAmaProjectionsPermissions && view === VIEW_PROJECTIONS))) ||
			(viewClassification === INTL && !INTL_TABS.includes(view ?? ""))
		) {
			const defaultView = getUserDefaultView(
				viewClassification,
				hasProProjectionsPermissions,
				hasAmaProjectionsPermissions,
				hasIntlProjectionsPermissions,
				hasObservedPermissions,
				hasScoutingReportsPermissions,
				hasManuelsPermissions
			);
			setView(defaultView, true);
		}
	}, [
		viewClassification,
		view,
		setView,
		hasProProjectionsPermissions,
		hasAmaProjectionsPermissions,
		hasIntlProjectionsPermissions,
		hasObservedPermissions,
		hasScoutingReportsPermissions,
		hasManuelsPermissions
	]);

	useMemo(() => {
		if (philId != null) {
			getEvals(philId);
		}
	}, [getEvals, philId]);

	useMemo(() => {
		if (playerCombined?.id) {
			getNotes(playerCombined.id);
		}
	}, [getNotes, playerCombined]);

	const noteCount = useMemo(() => {
		return countCurrentYearNotes(notes);
	}, [notes]);

	const availableClassifications: TPlayerPagePlayerClassification[] = useMemo(() => {
		const classifications: TPlayerPagePlayerClassification[] = [];
		if (playerCombined?.playerAmaId != null) classifications.push(AMA);
		if (playerCombined?.playerIntlId != null) classifications.push(INTL);
		if (playerCombined?.playerProId != null) classifications.push(PRO);
		return classifications;
	}, [playerCombined]);

	const showingInvalidPlayerClassification = useMemo(() => {
		if (viewClassification == null) return true;
		if (playerCombined?.playerAmaId == null && viewClassification === AMA) return true;
		if (playerCombined?.playerIntlId == null && viewClassification === INTL) return true;
		if (playerCombined?.playerProId == null && viewClassification === PRO) return true;
		return false;
	}, [viewClassification, playerCombined]);

	return (
		<ContainerDiv>
			{showingInvalidPlayerClassification && playerCombined != null && (
				<PlayerClassificationDialog
					availableClassifications={availableClassifications}
					onSelectClassification={setViewClassification}
				/>
			)}
			{/* New player pages. */}
			{viewClassification === PRO && (
				<PlayerPagePro
					player={playerCombined}
					playerLegacy={player}
					evals={evals}
					noteCount={noteCount}
					isNotesLoading={isNotesLoading}
					isNotesFailed={isNotesFailed}
					setViewClassification={setViewClassification}
					viewClassification={viewClassification as string | null}
					setView={setView}
					view={view as string | undefined}
					hasProjectionsPermissions={hasProProjectionsPermissions}
					hasPlayerPlanPermissions={hasManuelsPermissions}
					hasPitchTypeMappingEditPermissions={hasPitchTypeMappingEditPermissions}
				/>
			)}
			{viewClassification === AMA && (
				<PlayerPageAma
					player={playerCombined}
					evals={evals}
					setViewClassification={setViewClassification}
					viewClassification={viewClassification as string | null}
					setView={setView}
					view={view as string | undefined}
					hasProjectionsPermissions={hasAmaProjectionsPermissions}
					hasAmaBioEditPermissions={hasAmaBioEditPermissions}
					hasPitchTypeMappingEditPermissions={hasPitchTypeMappingEditPermissions}
					bioDisplayOnly={bioDisplayOnly}
					amaBioDocument={amaBioDocument}
					setAmaBioDocument={setAmaBioDocument}
					setBioDisplayOnly={setBioDisplayOnly}
					isBioSaving={isBioSaving}
					submitAmaBioUpdate={submitAmaBioUpdate}
				/>
			)}
			{viewClassification === INTL && (
				<PlayerPageIntl
					player={playerCombined}
					evals={evals}
					setViewClassification={setViewClassification}
					viewClassification={viewClassification as string | null}
					setView={setView}
					view={view as string | undefined}
					hasProjectionsPermissions={hasIntlProjectionsPermissions}
					hasIntlBioEditPermissions={hasIntlBioEditPermissions}
					hasPitchTypeMappingEditPermissions={hasPitchTypeMappingEditPermissions}
					bioDisplayOnly={bioDisplayOnly}
					intlBioDocument={intlBioDocument}
					setIntlBioDocument={setIntlBioDocument}
					setBioDisplayOnly={setBioDisplayOnly}
					isBioSaving={isBioSaving}
					submitIntlBioUpdate={submitIntlBioUpdate}
				/>
			)}
		</ContainerDiv>
	);
};

const PlayerPageWrapped = () => {
	return (
		<PlayerPageProviderLegacy>
			<PlayerPageContent />
		</PlayerPageProviderLegacy>
	);
};

export default PlayerPageWrapped;
