import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { Menu, MenuButton, MenuList, MenuItem, Button, Tooltip, IconButton, Spinner } from "@chakra-ui/react";
import uuid from "uuid/v4";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { $TSFixMe } from "utils/tsutils";
import axios from "_redux/_utils/_axios";
import { IApiResponse } from "_react/shared/data_models/_types";
import { IReport } from "_react/shared/data_models/reports/_types";
import { useFetch, useQueryString } from "utils/url_helpers";
import { useUserId } from "_react/app/AppContents";
import { RECORD_STATUS_INITIALIZING } from "_react/shared/data_models/reports/_constants";
import { fetchReport } from "_react/shared/data_models/reports/_network";
import { createReport } from "_react/shared/data_models/reports/_network";
import { useHasProReportAccess } from "_react/reports/_hooks";
import { EVAL_TYPE_TO_GENERATE } from "_react/evals/shared/_helpers";
import OutlineNoteAdd from "_react/shared/ui/icons/OutlineNoteAdd";

import { INTL, PRO, AMA } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { getPlayerPhilId } from "_react/playerpage/_helpers";

type CreateReportMenuProps = {
	player: TPlayerPageCombinedPlayer;
};

const CreateReportMenu = ({ player }: CreateReportMenuProps) => {
	const history = useHistory();
	const userId = useUserId();
	const [viewClassification, _] = useQueryString("viewClassification");

	// All of this is legacy logic from `_react/playerpage/Footer.tsx`
	// Keep for now to keep logic in sync, but potentially move to xState when old pages are deprecated
	const [generating, setGenerating] = useState("none");
	const [isProReportUpdate, setIsProReportUpdate] = useState(false);
	const [isNewProReportUpdate, setIsNewProReportUpdate] = useState(false);
	const [isFetchingNewProReports, setIsFetchingNewProReports] = useState(false);
	const [isIntlOFPReportUpdate, setIsIntlOFPReportUpdate] = useState(false);
	const [isIntlFollowReportUpdate, setIsIntlFollowReportUpdate] = useState(false);
	const [existingUpdateProId, setExistingUpdateProId] = useState<string>();
	const [existingUpdateNewProId, setExistingUpdateNewProId] = useState<string>();
	const [existingUpdateIntlOFPId, setExistingUpdateIntlOFPId] = useState<string>();
	const [existingUpdateIntlFollowId, setExistingUpdateIntlFollowId] = useState<string>();
	const playerClassification = player.playerClassification?.toLowerCase() ?? null;
	const playerPhilId = getPlayerPhilId(player, playerClassification);
	const { responseData: mostRecentEvalsRaw, setPath, isInProgress: isFetchingEvals } = useFetch(
		playerPhilId
			? `/evals?philId=${playerPhilId}&combinedPlayerEvals=True&beginSeenDate=${dayjs().year()}-01-01`
			: "",
		[]
	);
	useEffect(() => {
		if (playerPhilId)
			setPath(`/evals?philId=${playerPhilId}&combinedPlayerEvals=True&beginSeenDate=${dayjs().year()}-01-01`);
	}, [playerPhilId, setPath]);
	// Fetch new pro reports
	useEffect(() => {
		setIsFetchingNewProReports(true);
		fetchReport({
			playerId: `${player.id}`,
			classification: PRO,
			scoutId: `${userId}`,
			createDate__Gte: `${dayjs().year()}-01-01`,
			sort: "create_date",
			isSortDescending: true
		}).then((e?: IApiResponse<IReport>) => {
			if (e?.data?.length != null && e.data.length > 0) {
				const reports = e.data.sort((a: IReport, b: IReport) =>
					dayjs(a.createDate).isBefore(dayjs(b.createDate)) ? 1 : -1
				);
				if (reports[0].publishDate == null) {
					setExistingUpdateNewProId(e.data[0].id);
				} else {
					setIsNewProReportUpdate(true);
					setExistingUpdateNewProId(undefined);
				}
			} else {
				setIsNewProReportUpdate(false);
			}
			setIsFetchingNewProReports(false);
		});
	}, [player.id, userId]);

	useEffect(() => {
		// Overall eval editing logic:
		// User can edit their most recent unpublished intl and pro eval(s)
		// If the user's most recent pro eval was published in the last year,
		// make it look like that eval is being updated (but actually just copy the info into a new eval)
		const mostRecentEvals = mostRecentEvalsRaw
			.filter((e: $TSFixMe) => e.eval_create_user_id === userId)
			.sort((a: $TSFixMe, b: $TSFixMe) => {
				const aDate = a.eval_create_date;
				const bDate = b.eval_create_date;
				return dayjs(aDate).isBefore(dayjs(bDate)) ? 1 : -1;
			});
		const mostRecentProEval =
			mostRecentEvals && mostRecentEvals.length > 0
				? mostRecentEvals.find((e: $TSFixMe) => e.eval_type.includes("PRO"))
				: null;
		const mostRecentIntlOFPEval =
			mostRecentEvals && mostRecentEvals.length > 0
				? mostRecentEvals.find((e: $TSFixMe) => e.eval_type.includes("INTL") && e.eval_report_type === "OFP")
				: null;
		const mostRecentIntlFollowEval =
			mostRecentEvals && mostRecentEvals.length > 0
				? mostRecentEvals.find((e: $TSFixMe) => e.eval_type.includes("INTL") && e.eval_report_type === "FOL")
				: null;
		if (mostRecentProEval) {
			if (mostRecentProEval.publish_date != null) {
				// Published eval, check if it was this year
				const year = dayjs(mostRecentProEval.seen_date).year();
				// Per Ondo's 2021 request, this makes it look like the pro eval is being updated
				// But a new eval is actually being created that contains some relevant info from the last published eval
				if (year === dayjs().year()) setIsProReportUpdate(true);
			} else {
				// Unpublished pro eval, set id
				setExistingUpdateProId(mostRecentProEval.eval_id);
				setIsProReportUpdate(true);
			}
		}
		if (mostRecentIntlOFPEval && mostRecentIntlOFPEval.publish_date == null) {
			// Unpublished intl OFP eval, set id
			setExistingUpdateIntlOFPId(mostRecentIntlOFPEval.eval_id);
			setIsIntlOFPReportUpdate(true);
		}
		if (mostRecentIntlFollowEval && mostRecentIntlFollowEval.publish_date == null) {
			// Unpublished intl follow eval, set id
			setExistingUpdateIntlFollowId(mostRecentIntlFollowEval.eval_id);
			setIsIntlFollowReportUpdate(true);
		}
	}, [mostRecentEvalsRaw, userId]);

	const generateEval = (philId: number, reportType: string, evalReportType = "") => {
		if (existingUpdateProId != null && reportType === PRO) {
			setGenerating("none");
			history.push(`/eval?id=${existingUpdateProId}`);
		} else if (existingUpdateIntlOFPId != null && reportType === INTL && evalReportType === "OFP") {
			setGenerating("none");
			history.push(`/eval?id=${existingUpdateIntlOFPId}`);
		} else if (existingUpdateIntlFollowId != null && reportType === INTL && evalReportType === "FOL") {
			setGenerating("none");
			history.push(`/eval?id=${existingUpdateIntlFollowId}`);
		} else {
			axios.get(`/player/${philId}`).then((response: $TSFixMe) => {
				const player = response.data;
				const playerPosition = ["RHS", "LHS", "RHR", "LHR", "P", "RHP", "LHP", "SP", "RP"].includes(
					player.position
				)
					? "PITCHER"
					: "BATTER";
				axios
					.get(`/evals/recent_create/${player.phil_id}/${reportType}/${playerPosition}`)
					.then((response: $TSFixMe) => {
						const evals = response.data;
						// Select the most recent eval
						let mostRecentEval = null;
						// Loop through evals and select correct report type
						for (let i = 0; i < evals.length; i++) {
							// Select the first (most recent report), but as you loop through the evals check if
							//   the specific report type you're creating is present, and if so use that
							if (mostRecentEval == null || evalReportType === evals[i]["eval_report_type"]) {
								mostRecentEval = evals[i];
								if (i > 0) break;
							}
						}
						const generateEvalFunction = EVAL_TYPE_TO_GENERATE[reportType];
						const newEval = generateEvalFunction(
							player,
							mostRecentEval ? mostRecentEval : {},
							evalReportType
						);
						const level = newEval.eval_type.split("_")[0];
						const type = newEval.eval_type.split("_")[1];
						if (evalReportType === "AVR") newEval.ofp = null;
						axios.post(`/eval/${level.toLowerCase()}/${type.toLowerCase()}`, newEval).then(() => {
							setGenerating("none");
							history.push(`/eval?id=${newEval.eval_id}`);
						});
					});
			});
		}
	};

	// End of legacy code

	const isMenuButtonLoading = isFetchingEvals || isFetchingNewProReports || generating !== "none";
	const menuButton = useBreakpointValue({
		base: (
			<Tooltip hasArrow placement="top" label="Create Report" bg="blue.500">
				<MenuButton
					as={IconButton}
					colorScheme="blue"
					aria-label="Create Report"
					icon={<OutlineNoteAdd boxSize={6} />}
					isLoading={isMenuButtonLoading}
					w={isMenuButtonLoading ? 20 : undefined}
					spinner={
						<>
							<Spinner marginRight="2" />
							<OutlineNoteAdd boxSize={6} />
						</>
					}
				/>
			</Tooltip>
		),
		xl: (
			<MenuButton
				as={Button}
				colorScheme="blue"
				aria-label="Create Report"
				rightIcon={<OutlineNoteAdd boxSize={5} />}
				isLoading={isMenuButtonLoading}
				loadingText={isFetchingEvals ? "Loading Reports..." : "Redirecting to Report..."}
				size="sm"
			>
				Create Report
			</MenuButton>
		)
	});

	// Pro Report Alpha (this is all messy, just getting it to work at the last second for the alpha)
	const isProReportAlphaUser = useHasProReportAccess();

	const createProReportAlpha = useCallback(() => {
		if (existingUpdateNewProId != null) {
			history.push(`/report?id=${existingUpdateNewProId}`);
		} else {
			const id = uuid();

			const designation = ["RHS", "LHS", "RHR", "LHR", "P", "RHP", "LHP", "SP", "RP"].includes(
				player.position ?? "1B"
			)
				? "PITCH"
				: "HIT";
			const versionId = designation === "PITCH" ? "pro_pitch_full_2025_1" : "pro_hit_full_2025_1";

			createReport({
				id,
				playerId: `${player.id}`,
				classification: "PRO",
				designation,
				type: "FULL",
				versionId,
				scoutId: `${userId}`,
				recordStatus: RECORD_STATUS_INITIALIZING
			}).then((e?: IApiResponse<IReport>) => {
				if (e?.data?.length != null && e.data.length > 0) {
					const reportId = e.data[0].id;
					history.push(`/report?id=${reportId}`);
				}
				setGenerating("none");
			});
		}
	}, [history, player.id, player.position, userId, existingUpdateNewProId]);

	return (
		<>
			{playerPhilId && (
				<Menu>
					{menuButton}
					<MenuList color="black">
						{viewClassification === PRO && isProReportAlphaUser && (
							<MenuItem
								isDisabled={isFetchingEvals || generating === PRO}
								onClick={() => {
									setGenerating(PRO);
									createProReportAlpha();
								}}
								key="pro-report-pro-view"
							>
								{isFetchingNewProReports
									? "Loading..."
									: isNewProReportUpdate || existingUpdateNewProId
									? "Update Pro Report"
									: "Create Pro Report"}
							</MenuItem>
						)}
						{viewClassification === INTL && (
							<MenuItem
								isDisabled={isFetchingEvals || generating === PRO}
								onClick={() => {
									setGenerating(PRO);
									generateEval(playerPhilId, PRO);
								}}
								key="pro-report-intl-view"
							>
								{isFetchingEvals || generating === PRO
									? "Loading..."
									: isProReportUpdate
									? "Update Pro Report"
									: "Create Pro Report"}
							</MenuItem>
						)}

						{(viewClassification === PRO || viewClassification === INTL) && [
							<MenuItem
								isDisabled={isFetchingEvals || generating === INTL}
								onClick={() => {
									setGenerating(INTL);
									generateEval(playerPhilId, INTL, "FOL");
								}}
								key="intl-follow-report-intl-pro-view"
							>
								{isFetchingEvals || generating === INTL
									? "Loading..."
									: isIntlFollowReportUpdate
									? "Update Intl Follow Report"
									: "Create Intl Follow Report"}
							</MenuItem>,
							<MenuItem
								isDisabled={isFetchingEvals || generating === INTL}
								onClick={() => {
									setGenerating(INTL);
									generateEval(playerPhilId, INTL, "OFP");
								}}
								key="intl-ofp-report-intl-pro-view"
							>
								{isFetchingEvals || generating === INTL
									? "Loading..."
									: isIntlOFPReportUpdate
									? "Update Intl OFP Report"
									: "Create Intl OFP Report"}
							</MenuItem>
						]}
						{viewClassification === AMA && [
							<MenuItem
								isDisabled={generating === "id"}
								onClick={() => {
									setGenerating("id");
									generateEval(playerPhilId, AMA, "ID");
								}}
								key="ama-id-report-ama-view"
							>
								{generating === "id" ? "Creating ID Report..." : "Create ID Report"}
							</MenuItem>,
							<MenuItem
								isDisabled={generating === "follow"}
								onClick={() => {
									setGenerating("follow");
									generateEval(playerPhilId, AMA, "FOL");
								}}
								key="ama-follow-report-ama-view"
							>
								{generating === "follow" ? "Creating Follow Report..." : "Create Follow Report"}
							</MenuItem>,
							<MenuItem
								isDisabled={generating === "full"}
								onClick={() => {
									setGenerating("full");
									generateEval(playerPhilId, AMA, "FRE");
								}}
								key="ama-full-report-ama-view"
							>
								{generating === "full" ? "Creating Full Report..." : "Create Full Report"}
							</MenuItem>,
							<MenuItem
								isDisabled={generating === "lvr"}
								onClick={() => {
									setGenerating("lvr");
									generateEval(playerPhilId, AMA, "LVR");
								}}
								key="ama-lvr-report-ama-view"
							>
								{generating === "lvr"
									? "Creating Limited View Report..."
									: "Create Limited View Report"}
							</MenuItem>,
							<MenuItem
								isDisabled={generating === "avr"}
								onClick={() => {
									setGenerating("avr");
									generateEval(playerPhilId, AMA, "AVR");
								}}
								key="ama-avr-report-ama-view"
							>
								{generating === "avr" ? "Creating Video Report..." : "Create Video Report"}
							</MenuItem>
						]}
					</MenuList>
				</Menu>
			)}
		</>
	);
};

export default CreateReportMenu;
