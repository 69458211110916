export const PLAYER_TYPE_PITCHER = "pitcher";
export const PLAYER_TYPE_BATTER = "batter";

export const METRIC_STUFF_GRADE = "stuff_grade";
export const METRIC_SHAPE_GRADE = "shape_grade";
export const METRIC_LOCATION_GRADE = "location_grade";

export const METRIC_EXIT_VELO = "exit_velo";
export const METRIC_LAUNCH_ANGLE = "launch_angle";

export const METRIC_SWING_PCT = "swing_pct";
export const METRIC_SWSTR_PCT = "swstr_pct";
export const METRIC_CHASE_PCT = "chase_pct";
export const METRIC_CSW_PCT = "csw_pct";

export const METRIC_BAT_TO_BALL_GRADE = "bat_to_ball_grade";
export const METRIC_SWING_DECISIONS_GRADE = "swing_decisions_grade";

export const METRIC_MAP: { [index: string]: string } = {
	[METRIC_STUFF_GRADE]: "stuffGrade",
	[METRIC_LOCATION_GRADE]: "locationGrade",
	[METRIC_SHAPE_GRADE]: "shapeGrade",
	[METRIC_EXIT_VELO]: "exitVelo",
	[METRIC_LAUNCH_ANGLE]: "launchAngle",
	[METRIC_SWING_PCT]: "swingPct",
	[METRIC_SWSTR_PCT]: "swStrPct",
	[METRIC_CHASE_PCT]: "chasePct",
	[METRIC_CSW_PCT]: "cswPct",
	[METRIC_BAT_TO_BALL_GRADE]: "batToBallGrade",
	[METRIC_SWING_DECISIONS_GRADE]: "swingDecisionsGrade"
};
