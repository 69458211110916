import React from "react";
import { Box, Tooltip } from "@chakra-ui/react";
import OutlineWarning from "_react/shared/ui/icons/OutlineWarning";

type TSampleSizeFieldProps = {
	sampleSize: number | string;
	tooltipText: string;
	isDisplayWarningSymbol?: boolean;
};

const SampleSizeField = ({ sampleSize, tooltipText, isDisplayWarningSymbol = false }: TSampleSizeFieldProps) => {
	return (
		<Box>
			<Tooltip
				hasArrow
				label={tooltipText}
				sx={{
					position: "relative",
					visibility: isDisplayWarningSymbol ? undefined : "hidden",
					whiteSpace: "pre-wrap"
				}}
			>
				<Box as="span" marginRight="2">
					<OutlineWarning
						sx={{
							boxSize: 4,
							color: "gray.500",
							visibility: isDisplayWarningSymbol ? undefined : "hidden"
						}}
					></OutlineWarning>
				</Box>
			</Tooltip>
			{sampleSize}
		</Box>
	);
};

export default SampleSizeField;
