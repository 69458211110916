import React from "react";
import Add from "_react/shared/legacy/ui/icons/Add";
import CheckCircle from "_react/shared/legacy/ui/icons/CheckCircle";
import TrendingUp from "_react/shared/legacy/ui/icons/TrendingUp";
import TrendingFlat from "_react/shared/legacy/ui/icons/TrendingFlat";
import TrendingDown from "_react/shared/legacy/ui/icons/TrendingDown";
import Cancel from "_react/shared/legacy/ui/icons/Cancel";

import { OTH_PITCH_OPTS_ALL } from "_react/evals/_constants";
import { getScoutLatestValues } from "_react/playerpage/shared_js/evals/_helpers";
import HeatSlider from "_react/playerpage/shared_js/evals/HeatSlider";
import { Badge } from "_react/playerpage/shared_js/evals/Badge";

export function generateHeadSlider(evaluations, field, label) {
	const scoutValues = getScoutLatestValues(evaluations, field);
	const values = Object.values(scoutValues).filter(e => e != null);
	const high = Math.max(...values);
	const low = Math.min(...values);

	const avg = (values.reduce((a, b) => a + b, 0) / values.length).toFixed(0);

	if (values.length === 0) return null;

	return (
		<HeatSlider
			high={high}
			label={label}
			low={low}
			showHigh={false}
			showLow={false}
			toolTip={
				<div>
					{label}
					<br />
					Average: {avg}
					<br />
					High: {high}
					<br />
					Low: {low}
					<br />
				</div>
			}
			value={avg}
		/>
	);
}

export function generateOtherPitchCommandHeatSlider(otherPitch) {
	if (otherPitch.cmd == null) return null;
	return (
		<HeatSlider
			high={otherPitch.cmdHigh}
			label={`${otherPitch.pitch} Command`}
			low={otherPitch.cmdLow}
			showHigh={false}
			showLow={false}
			toolTip={
				<div>
					{`${otherPitch.pitch} Command`}
					<br />
					Average: {otherPitch.cmd}
					<br />
					High: {otherPitch.cmdHigh}
					<br />
					Low: {otherPitch.cmdLow}
					<br />
				</div>
			}
			value={otherPitch.cmd}
		/>
	);
}

export function getOthPitches(evaluations, isProEvalAdmin) {
	const usedEvals = Object.values(getScoutLatestValues(evaluations));

	const pitchTypeMap = OTH_PITCH_OPTS_ALL.reduce((dict, p) => {
		dict[p.value] = p.text;
		return dict;
	}, {});

	const pitches = {};
	for (let i = 0; i < usedEvals.length; i++) {
		if (usedEvals[i].oth_pitch_type) {
			if (pitches[usedEvals[i].oth_pitch_type] == null)
				pitches[usedEvals[i].oth_pitch_type] = {
					pitch: pitchTypeMap[usedEvals[i].oth_pitch_type]
						? pitchTypeMap[usedEvals[i].oth_pitch_type]
						: usedEvals[i].oth_pitch_type,
					grade: [],
					cmd: [],
					high: [],
					low: [],
					position: []
				};
			if (isProEvalAdmin) {
				if (usedEvals[i].oth_pitch_present)
					pitches[usedEvals[i].oth_pitch_type].grade.push(usedEvals[i].oth_pitch_present);
				if (usedEvals[i].oth_cmd_present)
					pitches[usedEvals[i].oth_pitch_type].cmd.push(usedEvals[i].oth_cmd_present);
			} else {
				if (usedEvals[i].oth_pitch_future)
					pitches[usedEvals[i].oth_pitch_type].grade.push(usedEvals[i].oth_pitch_future);
				if (usedEvals[i].oth_cmd_future)
					pitches[usedEvals[i].oth_pitch_type].cmd.push(usedEvals[i].oth_cmd_future);
			}

			if (usedEvals[i].oth_pitch_vel_high)
				pitches[usedEvals[i].oth_pitch_type].high.push(usedEvals[i].oth_pitch_vel_high);
			if (usedEvals[i].oth_pitch_vel_low)
				pitches[usedEvals[i].oth_pitch_type].low.push(usedEvals[i].oth_pitch_vel_low);
			pitches[usedEvals[i].oth_pitch_type].position.push(0);
		}
		if (usedEvals[i].oth2_pitch_type) {
			if (pitches[usedEvals[i].oth2_pitch_type] == null)
				pitches[usedEvals[i].oth2_pitch_type] = {
					pitch: pitchTypeMap[usedEvals[i].oth2_pitch_type]
						? pitchTypeMap[usedEvals[i].oth2_pitch_type]
						: usedEvals[i].oth2_pitch_type,
					grade: [],
					cmd: [],
					high: [],
					low: [],
					position: []
				};
			if (isProEvalAdmin) {
				if (usedEvals[i].oth2_pitch_present)
					pitches[usedEvals[i].oth2_pitch_type].grade.push(usedEvals[i].oth2_pitch_present);
				if (usedEvals[i].oth2_cmd_present)
					pitches[usedEvals[i].oth2_pitch_type].cmd.push(usedEvals[i].oth2_cmd_present);
			} else {
				if (usedEvals[i].oth2_pitch_future)
					pitches[usedEvals[i].oth2_pitch_type].grade.push(usedEvals[i].oth2_pitch_future);
				if (usedEvals[i].oth2_cmd_future)
					pitches[usedEvals[i].oth2_pitch_type].cmd.push(usedEvals[i].oth2_cmd_future);
			}

			if (usedEvals[i].oth2_pitch_vel_high)
				pitches[usedEvals[i].oth2_pitch_type].high.push(usedEvals[i].oth2_pitch_vel_high);
			if (usedEvals[i].oth2_pitch_vel_low)
				pitches[usedEvals[i].oth2_pitch_type].low.push(usedEvals[i].oth2_pitch_vel_low);
			pitches[usedEvals[i].oth2_pitch_type].position.push(1);
		}

		if (usedEvals[i].oth3_pitch_type) {
			if (pitches[usedEvals[i].oth3_pitch_type] == null)
				pitches[usedEvals[i].oth3_pitch_type] = {
					pitch: pitchTypeMap[usedEvals[i].oth3_pitch_type]
						? pitchTypeMap[usedEvals[i].oth3_pitch_type]
						: usedEvals[i].oth3_pitch_type,
					grade: [],
					cmd: [],
					high: [],
					low: [],
					position: []
				};
			if (isProEvalAdmin) {
				if (usedEvals[i].oth3_pitch_present)
					pitches[usedEvals[i].oth3_pitch_type].grade.push(usedEvals[i].oth3_pitch_present);
				if (usedEvals[i].oth3_cmd_present)
					pitches[usedEvals[i].oth3_pitch_type].cmd.push(usedEvals[i].oth3_cmd_present);
			} else {
				if (usedEvals[i].oth3_pitch_future)
					pitches[usedEvals[i].oth3_pitch_type].grade.push(usedEvals[i].oth3_pitch_future);
				if (usedEvals[i].oth3_cmd_future)
					pitches[usedEvals[i].oth3_pitch_type].cmd.push(usedEvals[i].oth3_cmd_future);
			}

			if (usedEvals[i].oth3_pitch_vel_high)
				pitches[usedEvals[i].oth3_pitch_type].high.push(usedEvals[i].oth3_pitch_vel_high);
			if (usedEvals[i].oth3_pitch_vel_low)
				pitches[usedEvals[i].oth3_pitch_type].low.push(usedEvals[i].oth3_pitch_vel_low);
			pitches[usedEvals[i].oth3_pitch_type].position.push(1);
		}
	}

	const otherPitches = Object.values(pitches).sort((a, b) => {
		const aAvg = (a.position.reduce((a, b) => a + b) * 1.0) / a.position.length;
		const bAvg = (b.position.reduce((a, b) => a + b) * 1.0) / b.position.length;
		return aAvg - bAvg;
	});

	return otherPitches.map(p => {
		const grade = (p.grade.reduce((a, b) => a + b, 0) * 1.0) / p.grade.length;
		const gradeHigh = Math.max(...p.grade);
		const gradeLow = Math.min(...p.grade);
		return {
			pitch: p.pitch,
			grade: !isNaN(grade) ? grade.toFixed(0) : "-",
			gradeHigh: p.grade.length ? gradeHigh : null,
			gradeLow: p.grade.length ? gradeLow : null,
			cmd: p.cmd.length > 0 ? ((p.cmd.reduce((a, b) => a + b, 0) * 1.0) / p.cmd.length).toFixed(0) : null,
			cmdHigh: p.cmd.length > 0 ? Math.max(...p.cmd) : null,
			cmdLow: p.cmd.length > 0 ? Math.min(...p.cmd) : null,
			high: p.high.length > 0 ? ((p.high.reduce((a, b) => a + b, 0) * 1.0) / p.high.length).toFixed(0) : null,
			low: p.low.length > 0 ? ((p.low.reduce((a, b) => a + b, 0) * 1.0) / p.low.length).toFixed(0) : null
		};
	});
}

export function generateBadges(evaluations) {
	const badges = [];

	// Check Injured
	if (evaluations.map(e => e.category === "INJ").filter(e => e).length > 0) {
		badges.push({
			color: "#FF0000",
			label: "Injured",
			textColor: "#FFFFFF",
			value: <Add style={{ marginLeft: "-2px" }} />,
			toolTip: "At least one scout reported this player as injured this year"
		});
	}

	// Check Acquire
	const acquireValues = Object.values(getScoutLatestValues(evaluations, "acquire"));
	const acquire = acquireValues.filter(e => e === "Y").length;
	const dontAcquire = acquireValues.filter(e => e === "N").length;
	const acquireScore = acquire - dontAcquire;
	if (acquireScore > 0) {
		badges.push({
			color: "#2977cc",
			label: "Acquire",
			subLabel: `${acquire}-${dontAcquire}`,
			value: <CheckCircle />,
			toolTip: (
				<div>
					{`Based on ${acquire + dontAcquire} report${acquire + dontAcquire > 1 ? "s" : ""}:`}
					<br />
					{acquire} marked Acquire
					<br />
					{`${dontAcquire} marked Don't Acquire`}
				</div>
			)
		});
	} else if (acquireScore < 0) {
		badges.push({
			color: "#2977cc",
			label: "Don't Acquire",
			subLabel: `${acquire}-${dontAcquire}`,
			value: <Cancel />,
			toolTip: (
				<div>
					{`Based on ${acquire + dontAcquire} report${acquire + dontAcquire > 1 ? "s" : ""}:`}
					<br />
					{acquire} marked Acquire
					<br />
					{`${dontAcquire} marked Don't Acquire`}
				</div>
			)
		});
	}

	// Check Trending
	const trendValues = Object.values(getScoutLatestValues(evaluations, "trend"));
	const up = trendValues.filter(e => e === "UP").length;
	const steady = trendValues.filter(e => e === "STEADY").length;
	const down = trendValues.filter(e => e === "DOWN").length;
	const trendScore = ((up - down) * 1.0) / ((up + steady + down) * 1.0);
	const trendSublabel = `${up}-${steady}-${down}`;
	if (up + down + steady > 0) {
		if (trendScore > 0.5) {
			badges.push({
				color: "#2e6a1c",
				label: "Trending Up",
				subLabel: trendSublabel,
				value: <TrendingUp />,
				toolTip: (
					<div>
						{`Based on ${up + down + steady} report${up + down + steady > 1 ? "s" : ""}:`}
						<br />
						{up} marked Trending Up
						<br />
						{steady} marked Trending Steady
						<br />
						{down} marked Trending Down
					</div>
				)
			});
		} else if (trendScore > -0.5) {
			badges.push({
				color: "#adadad",
				textColor: "white",
				label: "Trending Steady",
				subLabel: trendSublabel,
				value: <TrendingFlat style={{ marginLeft: "-2px" }} />,
				toolTip: (
					<div>
						{`Based on ${up + down + steady} report${up + down + steady > 1 ? "s" : ""}:`}
						<br />
						{up} marked Trending Up
						<br />
						{steady} marked Trending Steady
						<br />
						{down} marked Trending Down
					</div>
				)
			});
		} else {
			badges.push({
				color: "#FF0000",
				textColor: "white",
				label: "Trending Down",
				subLabel: trendSublabel,
				value: <TrendingDown style={{ marginLeft: "-2px" }} />,
				toolTip: (
					<div>
						{`Based on ${up + down + steady} report${up + down + steady > 1 ? "s" : ""}:`}
						<br />
						{up} marked Trending Up
						<br />
						{steady} marked Trending Steady
						<br />
						{down} marked Trending Down
					</div>
				)
			});
		}
	}

	if (badges.length === 0) return null;

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				padding: "5px",
				marginTop: "10px"
			}}
		>
			{badges.map((b, idx) => {
				return (
					<div key={idx} style={{ marginLeft: idx > 0 ? "10px" : "" }}>
						<Badge
							color={b.color}
							label={b.label}
							subLabel={b.subLabel}
							textColor={b.textColor}
							toolTip={b.toolTip}
							value={b.value}
						/>
					</div>
				);
			})}
		</div>
	);
}

export function generatePitchBadges(evaluations, isProEvalAdmin) {
	const badges = [];

	// Fastball
	const fastballGrades = Object.values(
		getScoutLatestValues(evaluations, isProEvalAdmin ? "fb_quality_present" : "fb_quality_future")
	).filter(e => e);
	const fastballVelGrades = Object.values(
		getScoutLatestValues(evaluations, isProEvalAdmin ? "fb_vel_present" : "fb_vel_future")
	).filter(e => e);
	const fastballGradeHigh = Math.max(...fastballGrades);
	const fastballGradeLow = Math.min(...fastballGrades);
	const fastballVelGradeHigh = Math.max(...fastballVelGrades);
	const fastballVelGradeLow = Math.min(...fastballVelGrades);
	let fastball = (fastballGrades.reduce((a, b) => a + b, 0) / fastballGrades.length).toFixed(0);
	const fastballVelocityHigh = Math.max(
		...Object.values(getScoutLatestValues(evaluations, "fb_vel_high")).filter(e => e)
	);
	const fastballVelocityLow = Math.min(
		...Object.values(getScoutLatestValues(evaluations, "fb_vel_low")).filter(e => e)
	);
	const fastballComfortHigh = Math.max(
		...Object.values(getScoutLatestValues(evaluations, "fb_vel_comfort_zone_high")).filter(e => e)
	);
	const fastballComfortLow = Math.min(
		...Object.values(getScoutLatestValues(evaluations, "fb_vel_comfort_zone_low")).filter(e => e)
	);
	const fastballSubLabel =
		isFinite(fastballVelocityHigh) && isFinite(fastballVelocityLow)
			? `${fastballVelocityLow}-${fastballVelocityHigh}mph`
			: null;
	const fastballComfortSubLabel =
		isFinite(fastballComfortHigh) && isFinite(fastballComfortLow)
			? `${fastballComfortLow}-${fastballComfortHigh}mph`
			: null;
	let fastballTooltip = (
		<div>
			Fastball Quality:
			<br />
			Average: {fastball}
			<br />
			High: {fastballGradeHigh}
			<br />
			Low: {fastballGradeLow}
			<br />
			Range: {fastballSubLabel}
			<br />
			Comfort Zone: {fastballComfortSubLabel}
		</div>
	);
	if (fastballGrades.length > 0)
		badges.push({
			color: "#2977cc",
			label: "Fastball",
			value: <div style={{ marginTop: "3px" }}>{fastball}</div>,
			subLabel: fastballSubLabel,
			toolTip: fastballTooltip
		});
	else if (fastballVelGrades.length > 0) {
		fastball = (fastballVelGrades.reduce((a, b) => a + b, 0) / fastballVelGrades.length).toFixed(0);
		fastballTooltip = (
			<div>
				Fastball:
				<br />
				Average: {fastball}
				<br />
				High: {fastballVelGradeHigh}
				<br />
				Low: {fastballVelGradeLow}
				<br />
				Range: {fastballSubLabel}
				<br />
				Comfort Zone: {fastballComfortSubLabel}
			</div>
		);
		badges.push({
			color: "#2977cc",
			label: "Fastball",
			value: <div style={{ marginTop: "3px" }}>{fastball}</div>,
			subLabel: fastballSubLabel,
			toolTip: fastballTooltip
		});
	}

	const curveballGrades = Object.values(
		getScoutLatestValues(evaluations, isProEvalAdmin ? "cb_present" : "cb_future")
	).filter(e => e);
	const curveball = (curveballGrades.reduce((a, b) => a + b, 0) / curveballGrades.length).toFixed(0);
	const curveballGradeHigh = Math.max(...curveballGrades);
	const curveballGradeLow = Math.min(...curveballGrades);
	const curveballVelocityHigh = Math.max(
		...Object.values(getScoutLatestValues(evaluations, "cb_vel_high")).filter(e => e)
	);
	const curveballVelocityLow = Math.min(
		...Object.values(getScoutLatestValues(evaluations, "cb_vel_low")).filter(e => e)
	);
	const curveballSubLabel =
		isFinite(curveballVelocityHigh) && isFinite(curveballVelocityLow)
			? `${curveballVelocityLow}-${curveballVelocityHigh}mph`
			: null;
	const curveballTooltip = (
		<div>
			Curveball:
			<br />
			Average: {curveball}
			<br />
			High: {curveballGradeHigh}
			<br />
			Low: {curveballGradeLow}
			<br />
			Range: {curveballSubLabel}
		</div>
	);
	if (curveballGrades.length > 0)
		badges.push({
			color: "#feff00",
			label: "Curveball",
			value: <div style={{ marginTop: "3px" }}>{curveball}</div>,
			subLabel: curveballSubLabel,
			toolTip: curveballTooltip
		});

	const sliderGrades = Object.values(
		getScoutLatestValues(evaluations, isProEvalAdmin ? "sl_present" : "sl_future")
	).filter(e => e);
	const slider = (sliderGrades.reduce((a, b) => a + b, 0) / sliderGrades.length).toFixed(0);
	const sliderGradeHigh = Math.max(...sliderGrades);
	const sliderGradeLow = Math.min(...sliderGrades);
	const sliderVelocityHigh = Math.max(
		...Object.values(getScoutLatestValues(evaluations, "sl_vel_high")).filter(e => e)
	);
	const sliderVelocityLow = Math.min(
		...Object.values(getScoutLatestValues(evaluations, "sl_vel_low")).filter(e => e)
	);
	const sliderSubLabel =
		isFinite(sliderVelocityHigh) && isFinite(sliderVelocityLow)
			? `${sliderVelocityLow}-${sliderVelocityHigh}mph`
			: null;
	const sliderTooltip = (
		<div>
			Slider:
			<br />
			Average: {slider}
			<br />
			High: {sliderGradeHigh}
			<br />
			Low: {sliderGradeLow}
			<br />
			Range: {sliderSubLabel}
		</div>
	);
	if (sliderGrades.length > 0)
		badges.push({
			color: "#2e6a1c",
			label: "Slider",
			value: <div style={{ marginTop: "3px" }}>{slider}</div>,
			subLabel: sliderSubLabel,
			toolTip: sliderTooltip
		});

	const changeupGrades = Object.values(
		getScoutLatestValues(evaluations, isProEvalAdmin ? "ch_present" : "ch_future")
	).filter(e => e);
	const changeup = (changeupGrades.reduce((a, b) => a + b, 0) / changeupGrades.length).toFixed(0);
	const changeupGradeHigh = Math.max(...changeupGrades);
	const changeupGradeLow = Math.min(...changeupGrades);
	const changeupVelocityHigh = Math.max(
		...Object.values(getScoutLatestValues(evaluations, "ch_vel_high")).filter(e => e)
	);
	const changeupVelocityLow = Math.min(
		...Object.values(getScoutLatestValues(evaluations, "ch_vel_low")).filter(e => e)
	);
	const changeupSubLabel =
		isFinite(changeupVelocityHigh) && isFinite(changeupVelocityLow)
			? `${changeupVelocityLow}-${changeupVelocityHigh}mph`
			: null;
	const changeupTooltip = (
		<div>
			Change Up:
			<br />
			Average: {changeup}
			<br />
			High: {changeupGradeHigh}
			<br />
			Low: {changeupGradeLow}
			<br />
			Range: {changeupSubLabel}
		</div>
	);
	if (changeupGrades.length > 0)
		badges.push({
			color: "#FF0000",
			textColor: "white",
			label: "Changeup",
			value: <div style={{ marginTop: "3px" }}>{changeup}</div>,
			subLabel: changeupSubLabel,
			toolTip: changeupTooltip
		});

	const othPitches = getOthPitches(evaluations);
	for (let i = 0; i < othPitches.length; i++) {
		const subLabel =
			othPitches[i].high != null &&
			isFinite(othPitches[i].high) &&
			othPitches[i].low != null &&
			isFinite(othPitches[i].low)
				? `${othPitches[i].low}-${othPitches[i].high}mph`
				: null;
		badges.push({
			color: "#810395",
			textColor: "#FFFFFF",
			label: othPitches[i].pitch,
			value: <div style={{ marginTop: "3px" }}>{othPitches[i].grade}</div>,
			subLabel,
			toolTip: (
				<div>
					{othPitches[i].pitch}:<br />
					Average: {othPitches[i].grade}
					<br />
					High: {othPitches[i].gradeHigh}
					<br />
					Low: {othPitches[i].gradeLow}
					<br />
					Range: {subLabel}
				</div>
			)
		});
	}

	if (badges.length === 0) return null;

	return (
		<div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					padding: "5px",
					marginTop: "10px",
					marginBottom: "10px",
					flexWrap: "wrap",
					alignContent: "space-between"
				}}
			>
				{badges.map((b, idx) => {
					return (
						<div
							key={idx}
							style={{
								marginLeft: idx > 0 ? "10px" : "",
								marginTop: "5px"
							}}
						>
							<Badge
								color={b.color}
								label={b.label}
								subLabel={b.subLabel}
								textColor={b.textColor}
								toolTip={b.toolTip}
								value={b.value}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}
