import React, { FC, useState, useEffect, useCallback } from "react";
import { RouterChildContext } from "react-router";
import { useHistory } from "react-router-dom";
import { Avatar, Image } from "@chakra-ui/react";
import { MultiValue, SingleValue } from "react-select";
import { Box } from "@chakra-ui/react";

import PlayerHeadshot from "_react/shared/ui/presentation/components/PlayerHeadshot/PlayerHeadshot";
import { ILinkSidebar } from "_react/app/_types";
import { logoURL } from "_react/shared/_helpers/team_logo";
import { getQueryStringValue } from "utils/url_helpers";

import { AMA, INTL, PRO } from "utils/tsutils";
import {
	SEARCH_RESULT_PLAYER,
	SEARCH_RESULT_TEAM,
	SEARCH_RESULT_ROUTES,
	SEARCH_RESULT_UNKNOWN
} from "_react/shared/searches/_constants";
import {
	TPlayerSearchResultStatus,
	TSearchResult,
	TSearchResultResponse,
	TSearchResultPlayerObject,
	TSearchResultTeamObject,
	TPlayerSearchResult
} from "_react/shared/searches/_types";
import {
	NavigationResultStyle,
	NavigationResultContainerStyle,
	SearchResultNavigationLabelStyle,
	SearchResultRouteIconContainerStyle,
	SearchResultRouteIconStyle,
	SearchResultRouteTextContainerStyle,
	FlexContainerStyle,
	SearchResultContainerStyle,
	SearchResultAvatarStyle,
	SearchResultMainLabelStyle,
	SearchResultTagStyle,
	SearchResultReducedLabelStyle,
	SearchResultReducedItalicLabelStyle,
	TeamImageStyle
} from "_react/shared/searches/_styles";
import { loadSearchResults } from "_react/shared/data_models/search/_network";

// Load search results from the API
export const getSearchResults = (routes: ILinkSidebar[]) => (
	searchText: string,
	classifications: string[],
	orgId?: number
) => {
	if (searchText.length < 2) {
		// Don't search if search text is less than 2 characters
		return Promise.resolve([]);
	}

	// Hit API
	return loadSearchResults(searchText, classifications, orgId)
		.then((response: TSearchResultResponse) => {
			// Search routes in memory
			const routeResults: ILinkSidebar[] = routes.filter((route: ILinkSidebar) => {
				// Simple text match search, eventually want to improve/fuzzify this
				return route.text.toLowerCase().includes(searchText.toLowerCase());
			});
			// Route search results are housed in one SearchResult object in order to achieve the horizontal row UI
			const routeResultsOption: TSearchResult = {
				type: SEARCH_RESULT_ROUTES,
				routes: routeResults
			};

			// Convert search results from the API
			const endpointResults: TSearchResult[] = response.data.map(
				(result: TSearchResultPlayerObject | TSearchResultTeamObject) => {
					if (result.type === SEARCH_RESULT_PLAYER) {
						return {
							type: SEARCH_RESULT_PLAYER,
							player: result
						};
					} else if (result.type === SEARCH_RESULT_TEAM) {
						return {
							type: SEARCH_RESULT_TEAM,
							team: result
						};
					}
					return {
						type: SEARCH_RESULT_UNKNOWN
					};
				}
			);

			// Prepend route search results
			const numberOfRoutes = routeResultsOption.routes?.length ?? 0;
			if (numberOfRoutes > 0) {
				return [routeResultsOption, ...endpointResults];
			} else {
				return endpointResults;
			}
		})
		.catch(() => {
			// Handle Error
			return Promise.reject();
		});
};

// Create the UI for the route search group
type RouteSearchResultsProps = {
	routes: ILinkSidebar[];
};

export const RouteSearchResults: FC<RouteSearchResultsProps> = ({ routes }) => {
	// Navigation
	const history = useHistory();

	// State
	const [selectedRouteIndex, setSelectedRouteIndex] = useState(0);
	useEffect(() => {
		setSelectedRouteIndex(0);
	}, [routes]);

	// Key Presses
	const handleKeyDown = useCallback(
		(ev: KeyboardEvent) => {
			if (ev.key === "ArrowRight" && selectedRouteIndex < routes.length - 1) {
				setSelectedRouteIndex(selectedRouteIndex + 1);
			} else if (ev.key === "ArrowLeft" && selectedRouteIndex > 0) {
				setSelectedRouteIndex(selectedRouteIndex - 1);
			} else if (ev.key === "Enter") {
				if (
					selectedRouteIndex < routes.length &&
					routes.length > 0 &&
					routes[selectedRouteIndex].route != null
				) {
					ev.preventDefault();
					history.push(routes[selectedRouteIndex].route!);
				}
			}
		},
		[selectedRouteIndex, history, routes]
	);
	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown);
		return () => document.removeEventListener("keydown", handleKeyDown);
	}, [handleKeyDown]);

	return (
		<NavigationResultContainerStyle key={SEARCH_RESULT_ROUTES}>
			<SearchResultNavigationLabelStyle>Jump to:</SearchResultNavigationLabelStyle>
			{routes.map((route: ILinkSidebar, routeIndex: number) => {
				const Icon = route.icon;
				return (
					<Box
						sx={NavigationResultStyle(routeIndex === selectedRouteIndex)}
						onClick={() => {
							if (route.route != null) history.push(route.route);
						}}
						key={route.text}
					>
						<FlexContainerStyle>
							<SearchResultRouteIconContainerStyle>
								<Icon style={SearchResultRouteIconStyle} fill="black" />
							</SearchResultRouteIconContainerStyle>
							<SearchResultRouteTextContainerStyle>{route.text}</SearchResultRouteTextContainerStyle>
						</FlexContainerStyle>
					</Box>
				);
			})}
		</NavigationResultContainerStyle>
	);
};

const determinePlayerClassification = (player?: TPlayerSearchResult) => {
	if (player?.classification) return player.classification;
	// Check for singular playerXXXId
	if (player?.ids.playerAmaId != null && player?.ids.playerProId == null && player?.ids.playerIntlId == null)
		return AMA;
	if (player?.ids.playerAmaId == null && player?.ids.playerProId != null && player?.ids.playerIntlId == null)
		return PRO;
	if (player?.ids.playerAmaId == null && player?.ids.playerProId == null && player?.ids.playerIntlId != null)
		return INTL;
	// Fallbacks
	if (player?.ids.playerProId != null) return PRO;
	if (player?.ids.playerIntlId != null) return INTL;
	if (player?.ids.playerAmaId != null) return AMA;
	// No IDs Fallback
	return PRO;
};

// Create the UI for a SearchResult
export const formatOptionLabel = (result: TSearchResult) => {
	switch (result.type) {
		case SEARCH_RESULT_ROUTES: {
			return <RouteSearchResults key={SEARCH_RESULT_ROUTES} routes={result.routes ?? []} />;
		}
		case SEARCH_RESULT_PLAYER: {
			const playerResult = result.player?.result;
			const classification = determinePlayerClassification(playerResult);
			return (
				<SearchResultContainerStyle key={playerResult?.id}>
					<PlayerHeadshot
						alt={playerResult?.lastName}
						bamId={playerResult?.ids.bamId}
						size="sm"
						style={SearchResultAvatarStyle(getColorFromClassification(classification))}
					/>
					<FlexContainerStyle>
						<SearchResultMainLabelStyle>
							{playerResult?.firstName} {playerResult?.lastName}
						</SearchResultMainLabelStyle>
						{playerResult?.position != null && (
							<SearchResultTagStyle>{playerResult?.position}</SearchResultTagStyle>
						)}
						{classification === PRO && (
							<SearchResultReducedLabelStyle>
								{playerResult?.team?.org != null ? `${playerResult?.team?.org}/` : ""}
								{playerResult?.team?.level}
							</SearchResultReducedLabelStyle>
						)}
						{classification === PRO && (
							<SearchResultReducedLabelStyle color={getRosterStatusColor(playerResult?.status)}>
								{getRosterStatusText(playerResult?.status)}
							</SearchResultReducedLabelStyle>
						)}
						{classification === PRO && (
							<SearchResultReducedItalicLabelStyle color={"black"}>
								{playerResult?.status.proFirstActiveYear}-{playerResult?.status.proLastActiveYear}
							</SearchResultReducedItalicLabelStyle>
						)}
						{(classification === AMA || classification === INTL) && playerResult?.team?.name != null && (
							<SearchResultReducedLabelStyle>{playerResult?.team?.name}</SearchResultReducedLabelStyle>
						)}
						{classification === AMA && (
							<SearchResultReducedItalicLabelStyle>
								{playerResult?.status.amaEligibleYear}
							</SearchResultReducedItalicLabelStyle>
						)}
						{classification === INTL && (
							<SearchResultReducedItalicLabelStyle>
								{playerResult?.status.intlEligibleYear}
							</SearchResultReducedItalicLabelStyle>
						)}
					</FlexContainerStyle>
				</SearchResultContainerStyle>
			);
		}
		case SEARCH_RESULT_TEAM: {
			const teamResult = result.team?.result;
			const bamId = teamResult?.ids.bamId;
			return (
				<SearchResultContainerStyle key={teamResult?.id}>
					{bamId != null && (
						<Image
							width="5"
							filter="drop-shadow(0px 0px 2px white)"
							src={logoURL(bamId)}
							fallback={
								<Avatar
									size="sm"
									name={teamResult?.nameCombinations.name}
									bg={"gray.300"}
									sx={SearchResultAvatarStyle(
										getColorFromClassification(teamResult?.classification ?? "")
									)}
								/>
							}
							style={TeamImageStyle}
						/>
					)}
					{bamId == null && (
						<Avatar
							size="sm"
							name={teamResult?.nameCombinations.nameNickname ?? teamResult?.nameCombinations.name}
							bg={"gray.300"}
							sx={SearchResultAvatarStyle(getColorFromClassification(teamResult?.classification ?? ""))}
						/>
					)}
					<FlexContainerStyle>
						<SearchResultMainLabelStyle>{teamResult?.nameCombinations.name}</SearchResultMainLabelStyle>
						{teamResult?.classification === PRO && (
							<SearchResultReducedLabelStyle>
								{teamResult?.org != null ? `${teamResult?.org}/` : ""}
								{teamResult?.level}
							</SearchResultReducedLabelStyle>
						)}
						{teamResult?.classification !== PRO && teamResult?.level != null && (
							<SearchResultReducedLabelStyle>{teamResult?.level}</SearchResultReducedLabelStyle>
						)}
						{teamResult?.classification !== PRO &&
							(teamResult?.nameCombinations.city != null ||
								teamResult?.nameCombinations.state != null) && (
								<SearchResultReducedLabelStyle>
									({teamResult?.nameCombinations.city ?? ""}
									{teamResult?.nameCombinations.state != null
										? `, ${teamResult?.nameCombinations.state}`
										: ""}
									)
								</SearchResultReducedLabelStyle>
							)}
					</FlexContainerStyle>
				</SearchResultContainerStyle>
			);
		}
	}
};

export const onSearchResultSelect = (history: RouterChildContext["router"]["history"], currentRoute: string) => (
	value: MultiValue<TSearchResult> | SingleValue<TSearchResult>
) => {
	const selectedValue = value as TSearchResult;
	if (selectedValue.type === SEARCH_RESULT_PLAYER) {
		let id = selectedValue.player?.result.ids.playerProId;
		let classification = selectedValue.player?.result.classification;
		if (classification === AMA) {
			id = selectedValue.player?.result.ids.playerAmaId;
		} else if (classification === INTL) {
			id = selectedValue.player?.result.ids.playerIntlId;
		} else if (classification == null) {
			// If no player classification, just pick the first ID that exists
			id =
				selectedValue.player?.result.ids.playerProId ??
				selectedValue.player?.result.ids.playerAmaId ??
				selectedValue.player?.result.ids.playerIntlId;
		}
		// If ID still doesn't exist, then their player classification is mismatched with their available IDs
		// So set classification to `undefined`, and let the frontend handle it
		if (id == null) classification = undefined;

		if (currentRoute === "/player") {
			const existingView = getQueryStringValue("view");
			const classificationLowercased = classification?.toLowerCase();
			history.push(`/player?philId=${id}&view=${existingView}&viewClassification=${classificationLowercased}`);
		} else {
			history.push(`/player?philId=${id}`);
		}
	} else if (selectedValue.type === SEARCH_RESULT_TEAM) {
		history.push(`/team?rockyId=${selectedValue.team?.result.id}`);
	}
}; // Troubleshooting this type issue isn't worth anyone's time

/*
	Legacy Helper Functions
	TODO: Update these to use constants
*/
export const getColorFromClassification = (type: string) => {
	if (type === PRO) return "#53B672";
	else if (type === AMA) return "#f1d602";
	else if (type === INTL) return "#9765B4";
	return "";
};

export const getRosterStatusText = (status?: TPlayerSearchResultStatus) => {
	let statusText = "";
	if (status?.rosterStatusMj != null) {
		statusText = status?.rosterStatusMjLabel;
		if (
			status?.rosterStatusMn != null &&
			status?.rosterStatusMn !== "ACT" &&
			["OPT", "OPTS"].includes(status?.rosterStatusMj)
		) {
			statusText = `${statusText} - ${status?.rosterStatusMnLabel}`;
		}
		return statusText;
	} else {
		return status?.rosterStatusMnLabel;
	}
};

export const getRosterStatusColor = (status?: TPlayerSearchResultStatus) => {
	const rsmj = status?.rosterStatusMj ?? "none";
	const rsmn = status?.rosterStatusMn ?? "none";
	const rsmjDisplay = status?.rosterStatusMjLabel ?? "none";
	const rsmnDisplay = status?.rosterStatusMnLabel ?? "none";
	if (rsmjDisplay.includes("IL") || rsmnDisplay.includes("IL") || rsmj.includes("REHAB")) {
		return "red";
	} else if (
		rsmjDisplay.includes("paternity") ||
		rsmnDisplay.includes("paternity") ||
		rsmj.includes("RES") ||
		rsmn.includes("RES") ||
		rsmj.includes("SUS") ||
		rsmn.includes("SUS")
	) {
		return "red";
	} else if (rsmjDisplay.includes("OPT")) {
		return "blue";
	} else if (rsmj.includes("FA") || rsmn.includes("FA") || rsmj.includes("REL") || rsmn.includes("REL")) {
		return "orange";
	}
	return "green";
};

export const formatPlayerLabelText = (result: TSearchResultPlayerObject) => {
	const classification = result.result.classification;
	if (classification === AMA) {
		return `${result.result.firstName} ${result.result.lastName} (${result.result.position}) ${result.result.team
			?.name ?? ""}/${result.result.team?.level ?? ""} - ${result.result.status?.amaEligibleYear ?? ""}`;
	}
	return `${result.result.firstName} ${result.result.lastName} (${result.result.position}) ${result.result.team
		?.org ?? ""}/${result.result.team?.level ?? ""} - ${getRosterStatusText(result.result.status)} (${
		result.result.status.proFirstActiveYear
	}-${result.result.status.proLastActiveYear})`;
};

export const createReactSelectPlayerOption = (keyIndex: string) => (result: TSearchResultPlayerObject) => {
	let key = result.result.id;
	const classification = result.result.classification;
	let philId = result.result.ids.playerProId;
	if (classification === AMA) {
		philId = result.result.ids.playerAmaId;
	} else if (classification === INTL) {
		philId = result.result.ids.playerIntlId;
	}
	if (keyIndex === "bam_id") {
		key = result.result.ids.bamId;
	} else if (keyIndex === "phil_id") {
		key = philId;
	}
	return {
		id: result.result.id,
		bamId: result.result.ids.bamId,
		label: formatPlayerLabelText(result),
		philId,
		value: key,
		playerClassification: classification ?? "PRO"
	};
};

export const formatTeamLabelText = (result: TSearchResultTeamObject) => {
	let label = `${result.result.nameCombinations.nameDisplay ?? result.result.nameCombinations.name}`;
	const classification = result.result.classification;
	if (classification === PRO) {
		label = `${label} (${result.result.org}/${result.result.level})`;
	} else if (result.result.level != null) {
		label = `${label} - ${result.result.level}`;
	}
	if (classification !== PRO && result.result.nameCombinations.state != null) {
		label = `${label} (${result.result.nameCombinations.city}, ${result.result.nameCombinations.state})`;
	}
	return label;
};

export const createReactSelectTeamOption = (result: TSearchResultTeamObject) => {
	return {
		bamId: result.result.ids.bamId,
		cccaaId: result.result.ids.cccaaId,
		ebisClubId: result.result.ids.ebisSchoolId,
		ebisSchoolId: result.result.ids.ebisSchoolId,
		fangraphsId: result.result.ids.fangraphsId,
		fieldfxId: result.result.ids.fieldfxId,
		isActive: result.result.isActive,
		label: formatTeamLabelText(result),
		level: result.result.level,
		naiaId: result.result.ids.naiaId,
		playerClassification: result.result.classification,
		njcaaId: result.result.ids.njcaaId,
		state: result.result.nameCombinations.state,
		synergyId: result.result.ids.synergyId,
		trackmanId: result.result.ids.trackmanId,
		value: result.result.id,
		orgId: result.result.orgId,
		org: result.result.org
	};
};
