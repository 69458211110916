import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import minMax from "dayjs/plugin/minMax";

import { MultiValue, SingleValue } from "react-select";
import { TOption } from "_react/inputs";

dayjs.extend(utc);
dayjs.extend(minMax);

export const STAT_METADATA = {
	uniqueFields: ["player_id"]
};

export const mobileMaxWidth = 1150;

export const isMobile = (width: number) => {
	return width <= mobileMaxWidth;
};

export const APP_ID_MAP = {
	1: "admin",
	2: "advance",
	3: "pro",
	4: "amateur",
	5: "trackman",
	6: "international"
};

export function capitalizeFirstLetter(word: string) {
	return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
}

export function capitalize(phrase: string) {
	return phrase
		.split(" ")
		.map(capitalizeFirstLetter)
		.join(" ");
}

export function createTextList(arr: Array<string>) {
	if (arr.length === 1) return arr[0];
	const firsts = arr.slice(0, arr.length - 1);
	const last = arr[arr.length - 1];
	return firsts.join(", ") + " and " + last;
}

export const stripHtml = (html: string | undefined | null) => {
	// strip HTML tags
	html = html ? html.replace(/<\/?[^>]+(>|$)/g, "") : html;
	// replace "&nbsp;" literals with a single space
	html = html ? html.replace(/&nbsp;/g, " ") : html;
	// replace "&rsquo;" and "&#39;" literals with an apostrophe
	html = html ? html.replace(/&rsquo;|&#39;/g, "'") : html;
	return html;
};

// Converts a multiselect array to a comma separated string (so that it can be used in the query string/API route)
export const handleMultiSelect = (
	selected: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null
): string | undefined => {
	let stringSelect: string | undefined = undefined;
	if (selected && Array.isArray(selected) && selected.length > 0) {
		const typeArray: Array<string> = [];
		selected.forEach(item => typeArray.push(item.value));
		stringSelect = typeArray.join(",");
	}
	return stringSelect;
};

// Converts a comma separated query string value to an array that can be used by the mutliselect
export const getMultiSelectFromQueryString = (
	queryStringValue: string | undefined,
	options: { [key: string]: { label: string; value: string } }
) => {
	if (queryStringValue) {
		const selectedOptions: Array<{ label: string; value: string }> = [];
		const optionArray = queryStringValue?.split(",");
		optionArray.forEach(option => selectedOptions.push(options[option]));
		return selectedOptions;
	}
	return undefined;
};

// Converts a utc date to Dayjs object
export const utcToDayjs = (utcDate: string): dayjs.Dayjs => {
	return dayjs
		.utc(utcDate.substring(0, 23))
		.local()
		.startOf("day");
};
