import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { IPlayerSeasonArsenalScoresByTeamSchema } from "_react/shared/data_models/arsenal_scores/_types";
import {
	BATS_L,
	BATS_R,
	COUNT_SPLIT_OVERALL,
	PLAYING_LEVEL_AMA,
	SCHEMA_TYPE_EXPANDED,
	GAME_TYPE_OVERALL,
	PITCH_TYPE_OVERALL
} from "_react/shared/data_models/arsenal_scores/_constants";
import { fetchPlayerSeasonArsenalScoresByTeam } from "_react/shared/data_models/arsenal_scores/_network";
import { METRIC_STUFF_GRADE, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/metric_reliability/_constants";
import { fetchMetricReliability } from "_react/shared/data_models/metric_reliability/_network";
import { IMetricReliability } from "_react/shared/data_models/metric_reliability/_types";

import { TAmaPitcherPitchCharacteristicsTableData } from "_react/shared/ui/data/tables/AmaPitcherPitchCharacteristicsTable/_types";

export const PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE = "playerSeasonArsenalScoresByTeam";
export const METRIC_RELIABILITY_CANCEL_SOURCE = "metricReliability";

export type TAmaPitcherPitchCharacteristicsTableCancelSource = {
	[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[METRIC_RELIABILITY_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TAmaPitcherPitchCharacteristicsTableFilters = {
	bats: Array<string>;
	pitchTypes?: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TAmaPitcherPitchCharacteristicsTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TAmaPitcherPitchCharacteristicsTableFilters;
	playerSeasonArsenalScoresByTeam?: Array<IPlayerSeasonArsenalScoresByTeamSchema> | null;
	metricReliability?: Array<IMetricReliability> | null;
	cancelSources: TAmaPitcherPitchCharacteristicsTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IAmaPitcherPitchCharacteristicsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all arsenal scores by player season team
				playerSeasonArsenalScoresByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches metric reliability thresholds
				metricReliability: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM = {
	initialized: { playerSeasonArsenalScoresByTeam: "fetching" }
};
export const FETCHING_METRIC_RELIABILITY = {
	initialized: { fetchMetricReliability: "fetching" }
};

const FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonArsenalScoresByTeam:invocation[0]";
const FETCH_METRIC_RELIABILITY_DONE = "done.invoke.fetchMetricReliability:invocation[0]";
const FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_ERROR =
	"error.platform.fetchPlayerSeasonArsenalScoresByTeam:invocation[0]";
const FETCH_METRIC_RELIABILITY_ERROR = "error.platform.fetchMetricReliability:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM = "SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM";
export const SET_METRIC_RELIABILITY = "SET_METRIC_RELIABILITY";
export const SET_FILTERS = "SET_FILTERS";

type TFetchPlayerSeasonArsenalScoresByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE;
	data: Array<IPlayerSeasonArsenalScoresByTeamSchema> | undefined;
};
type TFetchMetricReliabilityEvent = {
	type: typeof FETCH_METRIC_RELIABILITY_DONE;
	data: Array<IMetricReliability> | undefined;
};
type TFetchPlayerSeasonArsenalScoresByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchMetricReliabilityErrorEvent = {
	type: typeof FETCH_METRIC_RELIABILITY_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetPlayerSeasonArsenalScoresByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM;
	value?: Array<IPlayerSeasonArsenalScoresByTeamSchema>;
};
type TSetMetricReliabilityEvent = {
	type: typeof SET_METRIC_RELIABILITY;
	value?: Array<IMetricReliability> | null;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TAmaPitcherPitchCharacteristicsTableFilters;
};

type TAmaPitcherPitchCharacteristicsTableEvent =
	| TFetchPlayerSeasonArsenalScoresByTeamEvent
	| TFetchMetricReliabilityEvent
	| TFetchPlayerSeasonArsenalScoresByTeamErrorEvent
	| TFetchMetricReliabilityErrorEvent
	| TSetPlayerIdEvent
	| TSetPlayerSeasonArsenalScoresByTeamEvent
	| TSetMetricReliabilityEvent
	| TSetFiltersEvent;

export type TAmaPitcherPitchCharacteristicsTableSend = Interpreter<
	TAmaPitcherPitchCharacteristicsTableContext,
	IAmaPitcherPitchCharacteristicsTableStateSchema,
	TAmaPitcherPitchCharacteristicsTableEvent
>["send"];

const AmaPitcherPitchCharacteristicsTableMachine = (
	playerIdProp?: number,
	data?: TAmaPitcherPitchCharacteristicsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TAmaPitcherPitchCharacteristicsTableContext,
		IAmaPitcherPitchCharacteristicsTableStateSchema,
		TAmaPitcherPitchCharacteristicsTableEvent
	>(
		{
			id: "amaPitchCharacteristicsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					bats: [BATS_L, BATS_R],
					pitchTypes: undefined,
					levels: undefined
				},
				playerSeasonArsenalScoresByTeam: data?.playerSeasonArsenalScoresByTeam,
				metricReliability: data?.metricReliability,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: {
							actions: ["setPlayerId", "clearPlayerSeasonArsenalScoresByTeam"],
							cond: "shouldSetPlayerId"
						},
						SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM: { actions: "setPlayerSeasonArsenalScoresByTeam" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						playerSeasonArsenalScoresByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonArsenalScoresByTeam",
												cond: "shouldFetchPlayerSeasonArsenalScoresByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonArsenalScoresByTeam",
									entry: ["refreshPlayerSeasonArsenalScoresByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScoresByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresByTeamErrored"
										}
									}
								}
							}
						},
						metricReliability: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchMetricReliability",
												cond: "shouldFetchMetricReliability"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchMetricReliability",
									entry: ["refreshMetricReliabilityCancelSource"],
									invoke: {
										src: "fetchMetricReliability",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchMetricReliabilitySuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchMetricReliabilityErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (
					context: TAmaPitcherPitchCharacteristicsTableContext,
					event: TAmaPitcherPitchCharacteristicsTableEvent
				) => event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchPlayerSeasonArsenalScoresByTeam: (
					context: TAmaPitcherPitchCharacteristicsTableContext,
					_event: TAmaPitcherPitchCharacteristicsTableEvent
				) =>
					context.playerSeasonArsenalScoresByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchMetricReliability: (
					context: TAmaPitcherPitchCharacteristicsTableContext,
					_event: TAmaPitcherPitchCharacteristicsTableEvent
				) => context.metricReliability === undefined && context.shouldFetchData === true
			},
			actions: {
				clearPlayerSeasonArsenalScoresByTeam: assign<
					TAmaPitcherPitchCharacteristicsTableContext,
					TAmaPitcherPitchCharacteristicsTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						_context: TAmaPitcherPitchCharacteristicsTableContext,
						_event: TAmaPitcherPitchCharacteristicsTableEvent
					) => undefined,
					cancelSources: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						_event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				clearMetricReliability: assign<
					TAmaPitcherPitchCharacteristicsTableContext,
					TAmaPitcherPitchCharacteristicsTableEvent
				>({
					metricReliability: (
						_context: TAmaPitcherPitchCharacteristicsTableContext,
						_event: TAmaPitcherPitchCharacteristicsTableEvent
					) => undefined,
					cancelSources: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						_event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE]?.cancel();
						cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				// Set Context Actions
				setPlayerId: assign<
					TAmaPitcherPitchCharacteristicsTableContext,
					TAmaPitcherPitchCharacteristicsTableEvent
				>({
					playerId: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						_event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setPlayerSeasonArsenalScoresByTeam: assign<
					TAmaPitcherPitchCharacteristicsTableContext,
					TAmaPitcherPitchCharacteristicsTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM)
							return context.playerSeasonArsenalScoresByTeam;
						return event.value;
					},
					cancelSources: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setMetricReliability: assign<
					TAmaPitcherPitchCharacteristicsTableContext,
					TAmaPitcherPitchCharacteristicsTableEvent
				>({
					metricReliability: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						if (event.type !== SET_METRIC_RELIABILITY) return context.metricReliability;
						return event.value;
					},
					cancelSources: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_METRIC_RELIABILITY) return cancelSources;
						cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE]?.cancel();
						cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<
					TAmaPitcherPitchCharacteristicsTableContext,
					TAmaPitcherPitchCharacteristicsTableEvent
				>({
					filters: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonArsenalScoresByTeamCancelSource: assign<
					TAmaPitcherPitchCharacteristicsTableContext,
					TAmaPitcherPitchCharacteristicsTableEvent
				>({
					cancelSources: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						_event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshMetricReliabilityCancelSource: assign<
					TAmaPitcherPitchCharacteristicsTableContext,
					TAmaPitcherPitchCharacteristicsTableEvent
				>({
					cancelSources: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						_event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						if (context.cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE] != null)
							context.cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE].cancel();
						context.cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonArsenalScoresByTeamSuccess: assign<
					TAmaPitcherPitchCharacteristicsTableContext,
					TAmaPitcherPitchCharacteristicsTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE)
							return context.playerSeasonArsenalScoresByTeam;
						return event.data;
					}
				}),
				handleFetchMetricReliabilitySuccess: assign<
					TAmaPitcherPitchCharacteristicsTableContext,
					TAmaPitcherPitchCharacteristicsTableEvent
				>({
					metricReliability: (
						context: TAmaPitcherPitchCharacteristicsTableContext,
						event: TAmaPitcherPitchCharacteristicsTableEvent
					) => {
						if (event.type !== FETCH_METRIC_RELIABILITY_DONE) return context.metricReliability;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPlayerSeasonArsenalScoresByTeamErrored: (
					context: TAmaPitcherPitchCharacteristicsTableContext,
					event: TAmaPitcherPitchCharacteristicsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Pitch Characteristics by Team",
						"Error fetching pitch characteristics by player, season, and team"
					);
				},
				handleFetchMetricReliabilityErrored: (
					context: TAmaPitcherPitchCharacteristicsTableContext,
					event: TAmaPitcherPitchCharacteristicsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_METRIC_RELIABILITY_ERROR ? event.data : undefined,
						context.toast,
						"Pitch Characteristics - Metric Reliability",
						"Error fetching metric reliability thresholds"
					);
				}
			},
			services: {
				fetchPlayerSeasonArsenalScoresByTeam: (
					context: TAmaPitcherPitchCharacteristicsTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScoresByTeam(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_AMA,
								countSplit: COUNT_SPLIT_OVERALL,
								gameType: GAME_TYPE_OVERALL,
								"pitchType[neq]": PITCH_TYPE_OVERALL,
								schema: SCHEMA_TYPE_EXPANDED,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchMetricReliability: (
					context: TAmaPitcherPitchCharacteristicsTableContext,
					_event: AnyEventObject
				) => {
					const fetchFunc = () =>
						fetchMetricReliability(
							{
								"metric[in]": `${METRIC_STUFF_GRADE}`,
								playerType: PLAYER_TYPE_PITCHER,
								isUseCache: true
							},
							context.cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default AmaPitcherPitchCharacteristicsTableMachine;
