import React, { useEffect, useState, useContext, useMemo } from "react";
import { MultiValue, SingleValue } from "react-select";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	Spacer,
	Text,
	Flex,
	HStack,
	VStack,
	useToast
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import { PlayerPageContext } from "_react/playerpage/_context";
import { IManualPitchTypeMapBySeasonApiResponse } from "_react/shared/data_models/manual_pitch_type_map_byseason/_types";
import Edit from "_react/shared/ui/icons/Edit";
import Delete from "_react/shared/ui/icons/Delete";
import LkSelect from "_react/inputs/lks/LkSelect";
import { TUseBooleanReturn } from "_react/shared/_types/chakra";
import { StyledSelect } from "_react/shared/selects";
import { TOption } from "_react/inputs/_types";
import {
	parsePitchTypeLabel,
	getSeasonOptions
} from "_react/shared/ui/data/modals/ManualPitchTypeMapBySeasonModal/_helpers";
import {
	PitchTypeSelectContainer,
	SeasonSelectContainer
} from "_react/shared/ui/data/modals/ManualPitchTypeMapBySeasonModal/_styles";
import createManualPitchTypeMapBySeasonMachine, {
	FETCHING_MANUAL_PITCH_TYPE_MAPS_BYSEASON,
	SET_PLAYER_ID,
	TManualPitchTypeMapsBySeasonContext,
	UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON,
	POST_MANUAL_PITCH_TYPE_MAP_BYSEASON,
	DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON,
	SET_MANUAL_PITCH_TYPE_MAPS_BYSEASON
} from "_react/shared/ui/data/modals/ManualPitchTypeMapBySeasonModal/_machine";

export type TManualPitchTypeMapBySeasonData = {
	manualPitchTypeMapsBySeason?: IManualPitchTypeMapBySeasonApiResponse[] | null;
};

type TManualPitchTypeMapBySeasonModalProps = {
	playerId: number;
	isOpen: boolean;
	setIsOpen: TUseBooleanReturn[1];
	maxSeason?: number;
	minSeason?: number;
	data?: TManualPitchTypeMapBySeasonData;
};

const ManualPitchTypeMapBySeasonModal = ({
	playerId,
	isOpen,
	setIsOpen,
	maxSeason,
	minSeason,
	data
}: TManualPitchTypeMapBySeasonModalProps) => {
	const toast = useToast();
	const { setManualPitchTypeMapsBySeason } = useContext(PlayerPageContext);
	const [current, send] = useMachine(createManualPitchTypeMapBySeasonMachine(playerId, data, toast));
	const { manualPitchTypeMapsBySeason } = current.context as TManualPitchTypeMapsBySeasonContext;

	const fetchingManualPitchTypeMapsBySeasonData = current.matches(FETCHING_MANUAL_PITCH_TYPE_MAPS_BYSEASON);

	const [selectedPitchTypeFrom, setSelectedPitchTypeFrom] = useState<
		MultiValue<TOption<string>> | SingleValue<TOption<string>> | null
	>(null);
	const [selectedPitchTypeTo, setSelectedPitchTypeTo] = useState<
		MultiValue<TOption<string>> | SingleValue<TOption<string>> | null
	>(null);
	const [selectedSeasons, setSelectedSeasons] = useState<
		MultiValue<TOption<string>> | SingleValue<TOption<string>> | null
	>(null);
	const [editStates, setEditStates] = useState<{ [key: string]: boolean }>({});
	const [editedPitchTypesTo, setEditedPitchTypesTo] = useState<{ [key: string]: TOption<string> | null }>({});

	const handleEditClick = (key: string) => {
		setEditStates((prevState: { [key: string]: boolean }) => ({
			...prevState,
			[key]: !prevState[key]
		}));
	};

	// Update machine context when props change
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_MANUAL_PITCH_TYPE_MAPS_BYSEASON, data: manualPitchTypeMapsBySeason });
	}, [manualPitchTypeMapsBySeason, send]);

	// Update player page context when overall data changes
	useEffect(() => {
		if (manualPitchTypeMapsBySeason !== undefined) {
			setManualPitchTypeMapsBySeason(manualPitchTypeMapsBySeason);
		}
	}, [manualPitchTypeMapsBySeason, setManualPitchTypeMapsBySeason]);

	const pitchTypeMapData = useMemo<{ [key: string]: IManualPitchTypeMapBySeasonApiResponse }>(() => {
		return (
			manualPitchTypeMapsBySeason?.reduce((acc, pitchTypeMap) => {
				const key = `${pitchTypeMap.playerId}_${pitchTypeMap.season}_${pitchTypeMap.pitchTypeFrom}`;
				acc[key] = pitchTypeMap;
				return acc;
			}, {} as { [key: string]: IManualPitchTypeMapBySeasonApiResponse }) || {}
		);
	}, [manualPitchTypeMapsBySeason]);

	// Handle machine events
	const handleUpdateManualPitchTypeMapBySeason = (key: string) => {
		const pitchTypeMap = pitchTypeMapData[key];
		if (pitchTypeMap) {
			// Defaults to current pitch type to
			const pitchTypeTo = (editedPitchTypesTo[key] as TOption<string>).value ?? pitchTypeMap.pitchTypeTo;

			send({
				type: UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON,
				data: {
					playerId: pitchTypeMap.playerId,
					season: pitchTypeMap.season,
					pitchTypeFrom: pitchTypeMap.pitchTypeFrom,
					pitchTypeTo: pitchTypeTo
				},
				callback: () => {
					handleEditClick(key);
				}
			});
		}
	};

	const handlePostManualPitchTypeMapBySeason = () => {
		if (manualPitchTypeMapsBySeason) {
			const pitchTypeFrom = (selectedPitchTypeFrom as TOption<string>).value;
			const pitchTypeTo = (selectedPitchTypeTo as TOption<string>).value;
			const seasons = Array.isArray(selectedSeasons)
				? selectedSeasons.map(season => season.value)
				: [(selectedSeasons as TOption<string>).value];

			send({
				type: POST_MANUAL_PITCH_TYPE_MAP_BYSEASON,
				data: seasons.map(season => ({
					playerId: playerId,
					season: season,
					pitchTypeFrom: pitchTypeFrom,
					pitchTypeTo: pitchTypeTo
				})),
				callback: () => {
					setSelectedPitchTypeFrom(null);
					setSelectedPitchTypeTo(null);
					setSelectedSeasons(null);
				}
			});
		}
	};

	const handleDeleteManualPitchTypeMapBySeason = (key: string) => {
		const pitchTypeMap = pitchTypeMapData[key];

		if (pitchTypeMap) {
			send({
				type: DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON,
				value: {
					playerId: pitchTypeMap.playerId,
					season: pitchTypeMap.season,
					pitchTypeFrom: pitchTypeMap.pitchTypeFrom
				}
			});
		}
	};

	return (
		<Modal size="md" isOpen={isOpen} onClose={() => setIsOpen.off()} finalFocusRef={undefined}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Retag Pitch Types</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex flexDirection="column" alignItems="start" gap={6}>
						<VStack alignItems="start" width="100%">
							<Text fontSize="lg" fontWeight="bold">
								Existing Retags
							</Text>

							{!manualPitchTypeMapsBySeason && !fetchingManualPitchTypeMapsBySeasonData && (
								<Text>Could not find the pitch type retag data.</Text>
							)}
							{fetchingManualPitchTypeMapsBySeasonData && <Text>Loading...</Text>}
							{manualPitchTypeMapsBySeason &&
							manualPitchTypeMapsBySeason.length === 0 &&
							!fetchingManualPitchTypeMapsBySeasonData ? (
								<Text>No existing pitch type retags.</Text>
							) : (
								Object.entries(
									pitchTypeMapData as { [key: string]: IManualPitchTypeMapBySeasonApiResponse }
								).map(
									(
										[key, pitchTypeMap]: [string, IManualPitchTypeMapBySeasonApiResponse],
										index,
										array
									) => {
										// Display season only if it's the first pitch type tag of the season
										const showSeason =
											index === 0 || pitchTypeMap.season !== array[index - 1][1].season;
										const isEditing = editStates[key] || false;

										return (
											<HStack key={key} width="100%" gap={4}>
												<Text fontWeight="bold">{showSeason && pitchTypeMap.season}</Text>
												<HStack width="100%" gap={0}>
													<Text pl={showSeason ? 0 : 42}>
														{parsePitchTypeLabel(pitchTypeMap.pitchTypeFromRelationship)}{" "}
														&rarr;&nbsp;
													</Text>
													{!isEditing ? (
														<>{parsePitchTypeLabel(pitchTypeMap.pitchTypeToRelationship)}</>
													) : (
														<PitchTypeSelectContainer>
															<LkSelect<string>
																lkName={"lk_pitch_type"}
																lkFilters={[
																	{
																		key: "value",
																		value: [
																			"FF",
																			"SI",
																			"CT",
																			"CB",
																			"SL",
																			"SW",
																			"SV",
																			"CH",
																			"SP"
																		]
																	}
																]}
																defaultValue={
																	editedPitchTypesTo[key]
																		? editedPitchTypesTo[key]
																		: ({
																				value: pitchTypeMap.pitchTypeTo,
																				label: parsePitchTypeLabel(
																					pitchTypeMap.pitchTypeToRelationship
																				)
																		  } as TOption<string>)
																}
																onChange={(
																	selected:
																		| MultiValue<TOption<string>>
																		| SingleValue<TOption<string>>
																		| null
																) =>
																	setEditedPitchTypesTo(
																		(prevState: {
																			[key: string]: TOption<string> | null;
																		}) => ({
																			...prevState,
																			[key]: selected as TOption<string>
																		})
																	)
																}
																placeholder="Pitch Type..."
															/>
														</PitchTypeSelectContainer>
													)}
													<Spacer />
													{!isEditing ? (
														<>
															<Button
																variant="ghost"
																minWidth={0}
																height={0}
																p={0}
																onClick={() => handleEditClick(key)}
															>
																<Edit boxSize={6} />
															</Button>
															<Button
																variant="ghost"
																minWidth={0}
																p={0}
																height={0}
																onClick={() =>
																	handleDeleteManualPitchTypeMapBySeason(key)
																}
															>
																<Delete boxSize={6} />
															</Button>
														</>
													) : (
														<>
															<Button
																variant="ghost"
																size="xs"
																onClick={() => {
																	handleEditClick(key);
																	setEditedPitchTypesTo(
																		(prevState: {
																			[key: string]: TOption<string> | null;
																		}) => ({
																			...prevState,
																			[key]: null
																		})
																	);
																}}
															>
																Cancel
															</Button>
															<Button
																colorScheme="blue"
																size="xs"
																onClick={() => {
																	handleUpdateManualPitchTypeMapBySeason(key);
																}}
																isDisabled={
																	editedPitchTypesTo[key] == null ||
																	(editedPitchTypesTo[key] as TOption<string>)
																		?.value === pitchTypeMap.pitchTypeTo ||
																	(editedPitchTypesTo[key] as TOption<string>)
																		?.value === pitchTypeMap.pitchTypeFrom
																}
															>
																Save
															</Button>
														</>
													)}
												</HStack>
											</HStack>
										);
									}
								)
							)}
						</VStack>
						<VStack alignItems="start" width="100%">
							<HStack width="100%">
								<Text fontSize="lg" fontWeight="bold">
									Add New Retag
								</Text>
								<Spacer />
								<SeasonSelectContainer>
									<StyledSelect
										options={getSeasonOptions(maxSeason, minSeason)}
										value={selectedSeasons}
										isClearable={true}
										isMulti={true}
										onChange={(
											selected: MultiValue<TOption<string>> | SingleValue<TOption<string>>
										) => {
											setSelectedSeasons(selected);
										}}
										placeholder="Season(s)..."
									/>
								</SeasonSelectContainer>
							</HStack>
							<HStack>
								<Text>From Pitch Type:</Text>
								<PitchTypeSelectContainer>
									<LkSelect<string>
										lkName={"lk_pitch_type"}
										value={selectedPitchTypeFrom}
										lkFilters={[{ key: "is_valid", value: [1] }]}
										onChange={(
											selected: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null
										) => setSelectedPitchTypeFrom(selected as TOption<string>)}
										placeholder="Pitch Type..."
									/>
								</PitchTypeSelectContainer>
							</HStack>
							<HStack>
								<Text pr={5}>To Pitch Type:</Text>
								<PitchTypeSelectContainer>
									<LkSelect<string>
										lkName={"lk_pitch_type"}
										value={selectedPitchTypeTo}
										lkFilters={[{ key: "is_valid", value: [1] }]}
										onChange={(
											selected: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null
										) => setSelectedPitchTypeTo(selected as TOption<string>)}
										placeholder="Pitch Type..."
									/>
								</PitchTypeSelectContainer>
							</HStack>
							<Text>
								{"Retag all pitches in "}
								<Text as="span" fontWeight="bold">
									{selectedSeasons
										? Array.isArray(selectedSeasons)
											? selectedSeasons.map(option => option.value).join(", ")
											: (selectedSeasons as TOption<string>).value
										: "Season"}
								</Text>{" "}
								from{" "}
								<Text as="span" fontWeight="bold">
									{selectedPitchTypeFrom && !Array.isArray(selectedPitchTypeFrom)
										? (selectedPitchTypeFrom as TOption<string>).label
										: "Pitch Type"}
								</Text>{" "}
								to{" "}
								<Text as="span" fontWeight="bold">
									{selectedPitchTypeTo && !Array.isArray(selectedPitchTypeTo)
										? (selectedPitchTypeTo as TOption<string>).label
										: "Pitch Type"}
								</Text>
								?
							</Text>
						</VStack>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="ghost"
						onClick={() => {
							setIsOpen.off();
							setSelectedPitchTypeFrom(null);
							setSelectedPitchTypeTo(null);
							setSelectedSeasons(null);
						}}
					>
						Cancel
					</Button>
					<Button
						colorScheme="blue"
						marginRight="3"
						onClick={() => handlePostManualPitchTypeMapBySeason()}
						isDisabled={
							selectedPitchTypeFrom == null ||
							selectedPitchTypeTo == null ||
							selectedSeasons == null ||
							(Array.isArray(selectedSeasons) && selectedSeasons.length === 0) ||
							selectedPitchTypeTo === selectedPitchTypeFrom ||
							fetchingManualPitchTypeMapsBySeasonData
						}
					>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ManualPitchTypeMapBySeasonModal;
