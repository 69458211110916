import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import {
	IPlayerSeasonHitterGradesByTeamApiResponse,
	IPlayerSeasonHitterGradesSchema,
	IThreeGradesThresholdApiResponse
} from "_react/shared/data_models/hitter_grades/_types";

// player_season hitter grades

export type TPlayerSeasonHitterGradesGetQueryParams = {
	playerId?: number;
	season?: number;
	playingLevel?: string;
	bats?: string;
	throws?: string;
	gameType?: string;
	pitches?: number;
	"pitches[gte]"?: number;
	"pitches[lte]"?: number;
	swings?: number;
	"swings[gte]"?: number;
	"swings[lte]"?: number;
	bips?: number;
	"bips[gte]"?: number;
	"bips[lte]"?: number;
	pa?: number;
	"pa[gte]"?: number;
	"pa[lte]"?: number;
	batSpeedGrade?: number;
	"batSpeedGrade[gte]"?: number;
	"batSpeedGrade[lte]"?: number;
	batToBallGrade?: number;
	"batToBallGrade[gte]"?: number;
	"batToBallGrade[lte]"?: number;
	swingDecisionsGrade?: number;
	"swingDecisionsGrade[gte]"?: number;
	"swingDecisionsGrade[lte]"?: number;
	attackAngleVertical?: number;
	"attackAngleVertical[gte]"?: number;
	"attackAngleVertical[lte]"?: number;
	isMeasuredSwingMetrics?: boolean;
	// There are more, add them as necessary
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
	schema?: string;
};

export const fetchPlayerSeasonHitterGrades = (
	queryParams: TPlayerSeasonHitterGradesGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPlayerSeasonHitterGradesGetQueryParams, Array<IPlayerSeasonHitterGradesSchema>>(
		"/hitter_grades/player_season",
		queryParams,
		cancelToken
	);

export type TPlayerSeasonHitterGradesByTeamGetQueryParams = {
	playerId?: number;
	season?: number;
	playingLevel?: string;
	teamId?: number;
	"teamId[in]"?: string;
	bats?: string;
	throws?: string;
	gameType?: string;
	pitches?: number;
	"pitches[gte]"?: number;
	"pitches[lte]"?: number;
	swings?: number;
	"swings[gte]"?: number;
	"swings[lte]"?: number;
	bips?: number;
	"bips[gte]"?: number;
	"bips[lte]"?: number;
	pa?: number;
	"pa[gte]"?: number;
	"pa[lte]"?: number;
	batSpeedGrade?: number;
	"batSpeedGrade[gte]"?: number;
	"batSpeedGrade[lte]"?: number;
	batToBallGrade?: number;
	"batToBallGrade[gte]"?: number;
	"batToBallGrade[lte]"?: number;
	swingDecisionsGrade?: number;
	"swingDecisionsGrade[gte]"?: number;
	"swingDecisionsGrade[lte]"?: number;
	attackAngleVertical?: number;
	"attackAngleVertical[gte]"?: number;
	"attackAngleVertical[lte]"?: number;
	isMeasuredSwingMetrics?: boolean;
	// There are more, add them as necessary
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchPlayerSeasonHitterGradesByTeam = (
	queryParams: TPlayerSeasonHitterGradesByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPlayerSeasonHitterGradesByTeamGetQueryParams, Array<IPlayerSeasonHitterGradesByTeamApiResponse>>(
		"/hitter_grades/player_season/team",
		queryParams,
		cancelToken
	);

// three_grades_threshold

export const fetchThreeGradesThreshold = (cancelToken?: CancelToken) =>
	fetchResource<undefined, Array<IThreeGradesThresholdApiResponse>>(
		"/three_grades_threshold",
		undefined,
		cancelToken
	);
