import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	SPLIT_TYPE_TOTAL,
	SPLIT_TYPE_V_LEFT,
	SPLIT_TYPE_V_RIGHT
} from "_react/shared/data_models/ama_stats/_constants";
import { ICollegeSplitsPlayerBattingStatsByTeam } from "_react/shared/data_models/ama_stats/_types";
import { THROWS_L, THROWS_R, GAME_TYPE_OVERALL } from "_react/shared/data_models/arsenal_scores/_constants";
import { fetchCollegeSplitsPlayerBattingStatsByTeam } from "_react/shared/data_models/ama_stats/_network";

import { TPositionPlayerCollegesplitsTableData } from "_react/shared/ui/data/tables/PositionPlayerCollegesplitsTable/_types";

export const COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_CANCEL_SOURCE = "collegeSplitsPlayerBattingStatsByTeam";

export type TPositionPlayerCollegesplitsTableCancelSource = {
	[COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPositionPlayerCollegesplitsTableFilters = {
	throws: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPositionPlayerCollegesplitsTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TPositionPlayerCollegesplitsTableFilters;
	collegeSplitsPlayerBattingStatsByTeam?: Array<ICollegeSplitsPlayerBattingStatsByTeam> | null;
	cancelSources: TPositionPlayerCollegesplitsTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPositionPlayerCollegesplitsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all collegesplits batting stats by player season team
				collegeSplitsPlayerBattingStatsByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM = {
	initialized: { collegeSplitsPlayerBattingStatsByTeam: "fetching" }
};

const FETCH_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_DONE =
	"done.invoke.fetchCollegeSplitsPlayerBattingStatsByTeam:invocation[0]";
const FETCH_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_ERROR =
	"error.platform.fetchCollegeSplitsPlayerBattingStatsByTeam:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM = "SET_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM";
export const SET_FILTERS = "SET_FILTERS";

type TFetchCollegeSplitsPlayerBattingStatsByTeamEvent = {
	type: typeof FETCH_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_DONE;
	data: Array<ICollegeSplitsPlayerBattingStatsByTeam> | undefined;
};
type TFetchCollegeSplitsPlayerBattingStatsByTeamErrorEvent = {
	type: typeof FETCH_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetCollegeSplitsPlayerBattingStatsByTeamEvent = {
	type: typeof SET_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM;
	value?: Array<ICollegeSplitsPlayerBattingStatsByTeam>;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TPositionPlayerCollegesplitsTableFilters;
};

type TPositionPlayerCollegesplitsTableEvent =
	| TFetchCollegeSplitsPlayerBattingStatsByTeamEvent
	| TFetchCollegeSplitsPlayerBattingStatsByTeamErrorEvent
	| TSetPlayerIdEvent
	| TSetCollegeSplitsPlayerBattingStatsByTeamEvent
	| TSetFiltersEvent;

export type TPositionPlayerCollegesplitsTableSend = Interpreter<
	TPositionPlayerCollegesplitsTableContext,
	IPositionPlayerCollegesplitsTableStateSchema,
	TPositionPlayerCollegesplitsTableEvent
>["send"];

const PositionPlayerCollegesplitsTableMachine = (
	playerIdProp?: number,
	data?: TPositionPlayerCollegesplitsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TPositionPlayerCollegesplitsTableContext,
		IPositionPlayerCollegesplitsTableStateSchema,
		TPositionPlayerCollegesplitsTableEvent
	>(
		{
			id: "PositionPlayerCollegesplitsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					throws: [THROWS_L, THROWS_R],
					levels: undefined
				},
				collegeSplitsPlayerBattingStatsByTeam: data?.collegeSplitsPlayerBattingStatsByTeam,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: {
							actions: ["setPlayerId", "clearCollegeSplitsPlayerBattingStatsByTeam"],
							cond: "shouldSetPlayerId"
						},
						SET_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM: {
							actions: "setCollegeSplitsPlayerBattingStatsByTeam"
						},
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						collegeSplitsPlayerBattingStatsByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchCollegeSplitsPlayerBattingStatsByTeam",
												cond: "shouldFetchCollegeSplitsPlayerBattingStatsByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchCollegeSplitsPlayerBattingStatsByTeam",
									entry: ["refreshCollegeSplitsPlayerBattingStatsByTeamCancelSource"],
									invoke: {
										src: "fetchCollegeSplitsPlayerBattingStatsByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchCollegeSplitsPlayerBattingStatsByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchCollegeSplitsPlayerBattingStatsByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (
					context: TPositionPlayerCollegesplitsTableContext,
					event: TPositionPlayerCollegesplitsTableEvent
				) => event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchCollegeSplitsPlayerBattingStatsByTeam: (
					context: TPositionPlayerCollegesplitsTableContext,
					_event: TPositionPlayerCollegesplitsTableEvent
				) =>
					context.collegeSplitsPlayerBattingStatsByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearCollegeSplitsPlayerBattingStatsByTeam: assign<
					TPositionPlayerCollegesplitsTableContext,
					TPositionPlayerCollegesplitsTableEvent
				>({
					collegeSplitsPlayerBattingStatsByTeam: (
						_context: TPositionPlayerCollegesplitsTableContext,
						_event: TPositionPlayerCollegesplitsTableEvent
					) => undefined,
					cancelSources: (
						context: TPositionPlayerCollegesplitsTableContext,
						_event: TPositionPlayerCollegesplitsTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TPositionPlayerCollegesplitsTableContext, TPositionPlayerCollegesplitsTableEvent>({
					playerId: (
						context: TPositionPlayerCollegesplitsTableContext,
						event: TPositionPlayerCollegesplitsTableEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					}
				}),
				setCollegeSplitsPlayerBattingStatsByTeam: assign<
					TPositionPlayerCollegesplitsTableContext,
					TPositionPlayerCollegesplitsTableEvent
				>({
					collegeSplitsPlayerBattingStatsByTeam: (
						context: TPositionPlayerCollegesplitsTableContext,
						event: TPositionPlayerCollegesplitsTableEvent
					) => {
						if (event.type !== SET_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM)
							return context.collegeSplitsPlayerBattingStatsByTeam;
						return event.value;
					},
					cancelSources: (
						context: TPositionPlayerCollegesplitsTableContext,
						event: TPositionPlayerCollegesplitsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM) return cancelSources;
						cancelSources[COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TPositionPlayerCollegesplitsTableContext, TPositionPlayerCollegesplitsTableEvent>({
					filters: (
						context: TPositionPlayerCollegesplitsTableContext,
						event: TPositionPlayerCollegesplitsTableEvent
					) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshCollegeSplitsPlayerBattingStatsByTeamCancelSource: assign<
					TPositionPlayerCollegesplitsTableContext,
					TPositionPlayerCollegesplitsTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerCollegesplitsTableContext,
						_event: TPositionPlayerCollegesplitsTableEvent
					) => {
						if (context.cancelSources[COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[
							COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_CANCEL_SOURCE
						] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchCollegeSplitsPlayerBattingStatsByTeamSuccess: assign<
					TPositionPlayerCollegesplitsTableContext,
					TPositionPlayerCollegesplitsTableEvent
				>({
					collegeSplitsPlayerBattingStatsByTeam: (
						context: TPositionPlayerCollegesplitsTableContext,
						event: TPositionPlayerCollegesplitsTableEvent
					) => {
						if (event.type !== FETCH_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_DONE)
							return context.collegeSplitsPlayerBattingStatsByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchCollegeSplitsPlayerBattingStatsByTeamErrored: (
					context: TPositionPlayerCollegesplitsTableContext,
					event: TPositionPlayerCollegesplitsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Position Player Collegesplits",
						"Error fetching CollegeSplits batting by player, season, and team"
					);
				}
			},
			services: {
				fetchCollegeSplitsPlayerBattingStatsByTeam: (
					context: TPositionPlayerCollegesplitsTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchCollegeSplitsPlayerBattingStatsByTeam(
							{
								playerId: playerId,
								gameType: GAME_TYPE_OVERALL,
								"splitType[in]": `${SPLIT_TYPE_TOTAL},${SPLIT_TYPE_V_LEFT},${SPLIT_TYPE_V_RIGHT}`,
								isUseCache: true
							},
							context.cancelSources[COLLEGESPLITS_PLAYER_BATTING_STATS_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PositionPlayerCollegesplitsTableMachine;
