import { PITCH_TYPE_OVERALL } from "_react/shared/data_models/arsenal_scores/_constants";
import { METRIC_MAP } from "_react/shared/data_models/metric_reliability/_constants";
import { IMetricReliability, IMetricReliabilityLkPitchType } from "_react/shared/data_models/metric_reliability/_types";

export type TMetricReliabilityThresholds = { [index: string]: number | null };

// Transform metric reliability thresholds into object w/ keys being pitch types
export function transformMetricReliability(metricReliability?: Array<IMetricReliability> | null) {
	const transformedThresholds: { [index: string]: TMetricReliabilityThresholds } = {};
	metricReliability?.forEach((threshold: IMetricReliability) => {
		const metric = threshold.metric;
		const metricKey = METRIC_MAP[metric];
		if (threshold.pitchTypeGrouping === PITCH_TYPE_OVERALL) {
			if (!Object.keys(transformedThresholds).includes(PITCH_TYPE_OVERALL)) {
				transformedThresholds[PITCH_TYPE_OVERALL] = {};
			}
			if (metricKey) {
				transformedThresholds[PITCH_TYPE_OVERALL][metricKey] = threshold.sample;
			}
		}
		if (threshold.pitchGroupPitchTypes) {
			threshold.pitchGroupPitchTypes.forEach((pitchType: IMetricReliabilityLkPitchType) => {
				if (!Object.keys(transformedThresholds).includes(pitchType.value)) {
					transformedThresholds[pitchType.value] = {};
				}
				if (metricKey) {
					transformedThresholds[pitchType.value][metricKey] = threshold.sample;
				}
			});
		}
	});
	return transformedThresholds;
}
