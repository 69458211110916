import React from "react";
import dayjs from "dayjs";
import { useToast } from "@chakra-ui/react";

import { $TSFixMeEval } from "_react/evals/shared/_types";

import { TPlayerPageCombinedPlayer, TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import PlayerPageIntlContent from "_react/playerpage/intl/content/PlayerPageIntlContent";
import PlayerPageIntlProvider from "_react/playerpage/intl/PlayerPageIntlProvider";
import { TIntlBioDocument } from "_react/playerpage/bio/_types";

type PlayerPageIntlProps = {
	player?: TPlayerPageCombinedPlayer | null;
	evals?: $TSFixMeEval[] | null;
	setViewClassification?: (viewClassification?: TPlayerPagePlayerClassification) => void;
	viewClassification: string | null;
	setView?: (key: string, isReplaceState?: boolean) => void;
	view?: string;
	hasProjectionsPermissions?: boolean;
	hasIntlBioEditPermissions?: boolean;
	hasPitchTypeMappingEditPermissions?: boolean;
	bioDisplayOnly: boolean;
	intlBioDocument: TIntlBioDocument | null;
	setIntlBioDocument: (newDocument: TIntlBioDocument) => void;
	setBioDisplayOnly: (bioDisplayOnly: boolean) => void;
	isBioSaving: boolean;
	submitIntlBioUpdate: () => Promise<void>;
};

const PlayerPageIntl = ({
	player,
	evals,
	setViewClassification,
	viewClassification,
	setView,
	view,
	hasProjectionsPermissions = false,
	hasIntlBioEditPermissions = false,
	hasPitchTypeMappingEditPermissions = false,
	bioDisplayOnly,
	intlBioDocument,
	setIntlBioDocument,
	setBioDisplayOnly,
	isBioSaving,
	submitIntlBioUpdate
}: PlayerPageIntlProps) => {
	const toast = useToast();
	const currentSeasonData = {
		currentSeason: dayjs().year(),
		isFetching: false
	};

	return (
		<PlayerPageIntlProvider playerId={player?.id} currentSeasonData={currentSeasonData} toast={toast}>
			<PlayerPageIntlContent
				player={player}
				evals={evals}
				setViewClassification={setViewClassification}
				viewClassification={viewClassification}
				setView={setView}
				view={view}
				hasProjectionsPermissions={hasProjectionsPermissions}
				hasIntlBioEditPermissions={hasIntlBioEditPermissions}
				hasPitchTypeMappingEditPermissions={hasPitchTypeMappingEditPermissions}
				bioDisplayOnly={bioDisplayOnly}
				intlBioDocument={intlBioDocument}
				setIntlBioDocument={setIntlBioDocument}
				setBioDisplayOnly={setBioDisplayOnly}
				isBioSaving={isBioSaving}
				submitIntlBioUpdate={submitIntlBioUpdate}
			/>
		</PlayerPageIntlProvider>
	);
};

export default PlayerPageIntl;
