import React, { useState, useCallback } from "react";
import dayjs from "dayjs";
import { HStack, Text } from "@chakra-ui/react";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { curveLinear } from "@visx/curve";
import { Group } from "@visx/group";
import { scaleLinear, scaleTime } from "@visx/scale";
import { LinePath, Circle } from "@visx/shape";
import { Threshold } from "@visx/threshold";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { NumberValue } from "d3";

import { DEFAULT_OPACITY, IS_ELSEWHERE_HOVERED_OPACITY } from "_react/shared/dataviz/_constants";
import { getExtent, getPlotDimensions } from "_react/shared/dataviz/_helpers";
import { useDataVizColors, useAxisLabelProps } from "_react/shared/dataviz/_hooks";
import { TAxisExtrema, TTooltipData } from "_react/shared/dataviz/_types";
import { KeysOfValue } from "_react/shared/_types/generics";

type TSparklinePlotProps<T extends object> = {
	plotData?: Array<T>;
	pointsOnLine?: { showPoints?: boolean; pointData?: Array<T> };
	baselineData?: {
		y: number;
		includeDifferenceFill?: boolean;
		positiveFillColor?: string;
		negativeFillColor?: string;
	};
	xValue: KeysOfValue<T, Date>;
	getXValueFunction?: (obj: T) => Date;
	xTickLabels?: Array<{ label: string; value: Date }>;
	yValue: KeysOfValue<T, number>;
	getYValueFunction?: (obj: T) => number;
	getYValueFormattedFunction?: (y: number) => string;
	xAxisExtrema: TAxisExtrema<Date>;
	yAxisExtrema: TAxisExtrema<number>;
	isReverseYAxis?: boolean;
	// TODO: Need to make this responsive to parent but want to wait until parent components are created
	width?: number;
	height?: number;
	margins?: { top?: number; bottom?: number; left?: number; right?: number };
};

export const SparklinePlot = <T extends object>({
	plotData,
	pointsOnLine,
	baselineData,
	xValue,
	getXValueFunction,
	xTickLabels,
	yValue,
	getYValueFunction,
	getYValueFormattedFunction,
	xAxisExtrema,
	yAxisExtrema,
	isReverseYAxis,
	width,
	height,
	margins
}: TSparklinePlotProps<T>) => {
	// STATE MANAGEMENT
	const [hoveredPoint, setHoveredPoint] = useState<T>();
	const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<
		TTooltipData<string | number>
	>();
	const { containerRef, TooltipInPortal } = useTooltipInPortal({
		scroll: true
	});

	// CHART SETUP
	const { backgroundColor, dataColorPrimaryBlue, dataColorPrimaryGray, dataColorSecondary } = useDataVizColors();
	const axisLabelProps = useAxisLabelProps();
	const {
		width: WIDTH,
		height: HEIGHT,
		margins: MARGIN,
		innerWidth: INNER_WIDTH,
		innerHeight: INNER_HEIGHT
	} = getPlotDimensions(width ?? 500, height, margins ?? { left: 15, right: 15, top: 0, bottom: 15 });

	// DATA SETUP
	const getXValue = useCallback(
		(datum: T) => (getXValueFunction ? getXValueFunction(datum) : datum[xValue]) as Date,
		[getXValueFunction, xValue]
	);
	const getYValue = useCallback(
		(datum: T) => (getYValueFunction ? getYValueFunction(datum) : datum[yValue]) as number,
		[getYValueFunction, yValue]
	);

	const [xMinData, xMaxData] = getExtent<Date>(
		new Date(dayjs(`${xAxisExtrema.min}-01-01`).format("YYYY-MM-DD")),
		new Date(dayjs(`${xAxisExtrema.max}-01-01`).format("YYYY-MM-DD")) ?? new Date(dayjs().format("YYYY-MM-DD")),
		plotData?.map((datum: T) => getXValue(datum) as Date)
	);

	const [xMin, xMax] = [
		xAxisExtrema.min ??
			new Date(
				dayjs()
					.subtract(1, "year")
					.format("YYYY-MM-DD")
			),
		xAxisExtrema.max ?? new Date(dayjs().format("YYYY-MM-DD"))
	];

	const [yMin, yMax] = [yAxisExtrema.min ?? 0, yAxisExtrema.max ?? 100];

	const yDomain = isReverseYAxis ? [yMax, yMin] : [yMin, yMax];

	const xScale = scaleTime({ domain: [xMin, xMax], range: [0, INNER_WIDTH] });
	const yScale = scaleLinear({ domain: yDomain, range: [INNER_HEIGHT, 0], nice: true });

	const baselineLineData = baselineData
		? [
				{ x: xMinData, y: baselineData.y },
				{ x: xMaxData, y: baselineData.y }
		  ]
		: undefined;

	const plotDataWithBaseline = baselineData?.y
		? plotData?.map((datum: T) => {
				return { baselineY0: baselineData.y, ...datum };
		  })
		: undefined;

	// INTERACTIVITY
	const handlePointHover = useCallback(
		(hoveredPoint: T) => {
			setHoveredPoint(hoveredPoint);
			const xValue = getXValue(hoveredPoint);
			const yValue = getYValue(hoveredPoint);
			const formattedYValue = getYValueFormattedFunction ? getYValueFormattedFunction(yValue) : yValue;
			showTooltip({
				tooltipLeft: xScale(xValue),
				tooltipTop: yScale(yValue),
				tooltipData: { subtitles: { x: `${xValue.getUTCFullYear()}`, y: formattedYValue }, title: "SV" }
			});
		},
		[getXValue, getYValue, getYValueFormattedFunction, xScale, yScale, showTooltip]
	);

	const handleMouseLeavePoint = () => {
		hideTooltip();
		setHoveredPoint(undefined);
	};

	return (
		<>
			<svg width={WIDTH} height={HEIGHT} ref={containerRef}>
				<Group left={MARGIN.left} top={MARGIN.top}>
					<AxisBottom
						scale={xScale}
						top={INNER_HEIGHT}
						labelProps={axisLabelProps}
						tickValues={
							xTickLabels
								? xTickLabels
										.filter(
											(tickLabel: { value: Date; label: string }) =>
												tickLabel.value >= xMin && tickLabel.value <= xMax
										)
										.map((tickLabel: { value: number | Date; label: string }) => tickLabel.value)
								: undefined
						}
						tickFormat={
							xTickLabels
								? (v: Date | NumberValue) =>
										xTickLabels.find(
											(tickLabel: { value: Date | number; label: string }) =>
												tickLabel.value === v
										)?.label
								: undefined
						}
						tickLength={0}
						hideTicks
						hideAxisLine
					/>
					<AxisLeft scale={yScale} numTicks={0} hideAxisLine />
					{baselineLineData && (
						<LinePath
							className="baseline"
							data={baselineLineData}
							x={(datum: { y: number; x: Date }) => xScale(datum.x)}
							y={(datum: { y: number; x: Date }) => yScale(datum.y)}
							stroke={dataColorSecondary}
							strokeWidth={1}
							strokeDasharray="6,3"
							strokeOpacity={IS_ELSEWHERE_HOVERED_OPACITY}
							shapeRendering="geometricPrecision"
						/>
					)}
					{plotDataWithBaseline && baselineData?.includeDifferenceFill && (
						<Threshold
							id={`${Math.random()}`}
							data={plotDataWithBaseline}
							x={(datum: unknown) => xScale(getXValue(datum as T))}
							y0={(datum: T & { baselineY0: number }) => yScale(datum.baselineY0)}
							y1={(datum: unknown) => yScale(getYValue(datum as T))}
							clipAboveTo={HEIGHT}
							clipBelowTo={0}
							curve={curveLinear}
							belowAreaProps={{
								fill: baselineData?.negativeFillColor ?? dataColorPrimaryBlue,
								fillOpacity: 0.25
							}}
							aboveAreaProps={{
								fill: baselineData?.positiveFillColor ?? dataColorPrimaryBlue,
								fillOpacity: 0.25
							}}
						/>
					)}
					<LinePath
						className="sparkline"
						key="sparkline"
						curve={curveLinear}
						data={plotData}
						x={(datum: T) => xScale(getXValue(datum))}
						y={(datum: T) => yScale(getYValue(datum))}
						stroke={dataColorPrimaryGray}
						strokeWidth={2}
						strokeOpacity={DEFAULT_OPACITY}
						shapeRendering="geometricPrecision"
					/>
					{pointsOnLine?.showPoints &&
						pointsOnLine?.pointData?.map((datum: T, index: number) => (
							<Circle
								key={`point-${getXValue(datum)}-${index}`}
								className="dot"
								cx={xScale(getXValue(datum as T))}
								cy={yScale(getYValue(datum as T))}
								r={hoveredPoint === datum ? 4 : 3}
								fill={baselineData?.includeDifferenceFill ? dataColorPrimaryGray : dataColorPrimaryBlue}
								opacity={baselineData?.includeDifferenceFill ? DEFAULT_OPACITY : 1}
								onMouseEnter={() => {
									handlePointHover(datum);
								}}
								onMouseLeave={() => {
									handleMouseLeavePoint();
								}}
							/>
						))}
				</Group>
			</svg>
			{tooltipOpen && tooltipData && (
				<TooltipInPortal
					// set this to random so it correctly updates with parent bounds
					key={Math.random()}
					top={tooltipTop}
					left={tooltipLeft}
					style={{ ...defaultStyles, backgroundColor: backgroundColor }}
				>
					<HStack spacing="1">
						<Text fontWeight="bold">{`${tooltipData.subtitles.x}:`}</Text>
						<Text>{`${tooltipData.subtitles.y}`}</Text>
					</HStack>
				</TooltipInPortal>
			)}
		</>
	);
};

export default SparklinePlot;
