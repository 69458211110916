import {
	IReport,
	IReportField,
	IReportSection,
	IReportCategory,
	IReportCollection
} from "_react/shared/data_models/reports/_types";
import { getField, isReportPublished } from "_react/reports/_helpers";
import {
	FIELD_DISPLAY_INPUT,
	FIELD_DISPLAY_REVIEW,
	FIELD_DISPLAY_COLLECTION_HEADER,
	FIELD_DISPLAY_REPORT_HEADER
} from "_react/reports/_constants";
import { TFieldDisplayPurpose } from "_react/reports/_types";
import { FB_PITCH_TYPES } from "_react/shared/_types/pitch_types";
import { SI } from "_react/shared/_constants/pitch_types";

/*
 * Field Display
 */

export const showField = (
	report: IReport,
	field: IReportField,
	value: string | null,
	collectionIndex = -1,
	purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
): boolean => {
	let show = true;

	// Custom Show
	if (field.show) {
		show = field.show
			.split(",")
			.reduce(
				(show: boolean, key: string) =>
					show &&
					SHOW_FIELD_FUNCTIONS[key] &&
					SHOW_FIELD_FUNCTIONS[key](report, field, value, collectionIndex, purpose),
				show
			);
	}

	// Check Populated Fields
	if (show && field.showWhenPopulated) {
		// Split string into individual fields (comma separated)
		show = field.showWhenPopulated.split(",").reduce((show: boolean, populatedString: string) => {
			// Extract the components (fieldKey:fieldValue)
			const populatedStringComponents = populatedString.split(":");
			// Get the field key
			const fieldKey = populatedStringComponents[0];

			// Find the field in the report
			const referenceField = getField(report, fieldKey, true, collectionIndex);

			// Utilize Helper
			return showHelper(referenceField, populatedStringComponents, show);
		}, true);
	}

	// Default Show
	return show;
};

const SHOW_FIELD_FUNCTIONS: {
	[index: string]: (
		report: IReport,
		field: IReportField,
		value: string | null,
		collectionIndex?: number,
		purpose?: TFieldDisplayPurpose
	) => boolean;
} = {
	demo: (
		report: IReport,
		_field: IReportField,
		_value: string | null,
		collectionIndex = -1,
		_purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		const referenceField = getField(report, "on_field_makeup", true, collectionIndex);
		if (referenceField?.value === "WAAVG") return true;

		return false;
	},
	fbPitchGrade: (
		report: IReport,
		_field: IReportField,
		_value: string | null,
		collectionIndex = -1,
		_purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		const referenceField = getField(report, "pitch_type", true, collectionIndex);
		if (referenceField?.value != null && FB_PITCH_TYPES.includes(referenceField.value)) return true;
		return false;
	},
	fbPrimaryPitchGrade: (
		report: IReport,
		_field: IReportField,
		_value: string | null,
		collectionIndex = -1,
		_purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		const referenceField = getField(report, "pitch_type", true, collectionIndex);
		if (referenceField?.value != null && FB_PITCH_TYPES.includes(referenceField.value) && collectionIndex === 0)
			return true;
		return false;
	},
	secondaryPitchOrSinkerGrade: (
		report: IReport,
		_field: IReportField,
		_value: string | null,
		collectionIndex = -1,
		_purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		const referenceField = getField(report, "pitch_type", true, collectionIndex);
		if (
			referenceField?.value != null &&
			(!FB_PITCH_TYPES.includes(referenceField.value) || SI === referenceField.value)
		)
			return true;
		return false;
	},
	pitchGradesPitchTypeLabel: (
		report: IReport,
		_field: IReportField,
		_value: string | null,
		collectionIndex = -1,
		purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		const referenceField = getField(report, "pre_populated_pitch", true, collectionIndex);
		if (referenceField && referenceField.value === "1" && purpose === FIELD_DISPLAY_INPUT) {
			return false;
		}
		return true;
	},
	secondaryPitchGrade: (
		report: IReport,
		_field: IReportField,
		_value: string | null,
		collectionIndex = -1,
		_purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		const referenceField = getField(report, "pitch_type", true, collectionIndex);
		if (referenceField?.value != null && !FB_PITCH_TYPES.includes(referenceField.value)) return true;
		return false;
	},
	ifPopulatedWhenPublished: (
		report: IReport,
		_field: IReportField,
		value: string | null,
		_collectionIndex = -1,
		_purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		return !isReportPublished(report) || value != null;
	},
	unpublishedOnly: (
		report: IReport,
		_field: IReportField,
		_value: string | null,
		_collectionIndex = -1,
		_purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		return !isReportPublished(report);
	},
	never: (
		_report: IReport,
		_field: IReportField,
		_value: string | null,
		_collectionIndex = -1,
		_purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		return false;
	},
	onlyInReportCollectionHeaderDisplay: (
		_report: IReport,
		_field: IReportField,
		_value: string | null,
		_collectionIndex = -1,
		purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		return purpose === FIELD_DISPLAY_COLLECTION_HEADER;
	},
	onlyInReportHeaderDisplay: (
		_report: IReport,
		_field: IReportField,
		_value: string | null,
		_collectionIndex = -1,
		purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		return purpose === FIELD_DISPLAY_REPORT_HEADER;
	},
	lessThanOneYearMLS: (
		report: IReport,
		_field: IReportField,
		_value: string | null,
		collectionIndex = -1,
		_purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		const referenceField = getField(report, "current_mls", true, collectionIndex);
		if (referenceField?.value != null && parseFloat(referenceField.value) < 1.0) {
			return true;
		}
		return false;
	}
};

export const showCategory = (report: IReport, category: IReportCategory): boolean => {
	let show = true;

	// Custom Show
	if (category.show) {
		show = category.show
			.split(",")
			.reduce(
				(show: boolean, key: string) =>
					show && SHOW_CATEGORY_FUNCTIONS[key] && SHOW_CATEGORY_FUNCTIONS[key](report, category),
				show
			);
	}

	// Check Populated Fields
	if (show && category.showWhenPopulated) {
		// Split string into individual fields (comma separated)
		show = category.showWhenPopulated.split(",").reduce((show: boolean, populatedString: string) => {
			// Extract the components (fieldKey:fieldValue)
			const populatedStringComponents = populatedString.split(":");
			// Get the field key
			const fieldKey = populatedStringComponents[0];

			// Find the field in the report
			const referenceField = getField(report, fieldKey, true);

			// Utilize Helper
			return showHelper(referenceField, populatedStringComponents, show);
		}, true);
	}

	// Default Show
	return show;
};

const SHOW_CATEGORY_FUNCTIONS: {
	[index: string]: (report: IReport, category: IReportCategory) => boolean;
} = {
	demo: (_report: IReport, _category: IReportCategory) => {
		return false;
	},
	unpublishedOnly: (report: IReport, _category: IReportCategory) => {
		return !isReportPublished(report);
	},
	publishedOnly: (report: IReport, _category: IReportCategory) => {
		return isReportPublished(report);
	}
};

export const showSection = (report: IReport, scetion: IReportSection): boolean => {
	let show = true;

	// Custom Show
	if (scetion.show) {
		show = scetion.show
			.split(",")
			.reduce(
				(show: boolean, key: string) =>
					show && SHOW_SECTION_FUNCTIONS[key] && SHOW_SECTION_FUNCTIONS[key](report, scetion),
				show
			);
	}

	// Check Populated Fields
	if (show && scetion.showWhenPopulated) {
		// Split string into individual fields (comma separated)
		show = scetion.showWhenPopulated.split(",").reduce((show: boolean, populatedString: string) => {
			// Extract the components (fieldKey:fieldValue)
			const populatedStringComponents = populatedString.split(":");
			// Get the field key
			const fieldKey = populatedStringComponents[0];

			// Find the field in the report
			const referenceField = getField(report, fieldKey, true);

			// Utilize Helper
			return showHelper(referenceField, populatedStringComponents, show);
		}, true);
	}

	// Default Show
	return show;
};

const SHOW_SECTION_FUNCTIONS: {
	[index: string]: (report: IReport, section: IReportSection) => boolean;
} = {
	demo: (_report: IReport, _section: IReportSection) => {
		return false;
	}
};

/*
 * Helpers
 */
export const showHelper = (
	referenceField: IReportField | undefined,
	populatedStringComponents: string[],
	show: boolean
): boolean => {
	if (populatedStringComponents.length > 1) {
		// The populatedString contains a key:value pair
		const populatedStringValue = populatedStringComponents[1];

		// Extract field values to analyze
		const fieldValues = populatedStringValue.split("|");
		// Filter to show positive field values (values without !)
		const positiveFieldValues = fieldValues.filter((value: string) => !value.includes("!"));
		// Filter to show negative field values (values with !, but then stripped)
		const negativeFieldValues = fieldValues
			.filter((value: string) => value.includes("!"))
			.map((value: string) => value.replace("!", ""));

		if (referenceField?.multi !== true) {
			// Check if reference field value is one of this show field's value(s) (pipe separated)
			return (
				show && // Should show field
				referenceField != null && // Value is present
				(positiveFieldValues.includes(referenceField.value ?? "") ||
					(!negativeFieldValues.includes(referenceField.value ?? "") && negativeFieldValues.length > 0)) // Value is (positive) or (not negative and there are negative values present)
			);
		} else {
			// Reference field is multiselect, check if any match
			const referenceFieldValues = referenceField?.value?.split(",") ?? [];
			// Placeholder for negative value check
			let containsNegativeValue: boolean | undefined;
			// Iterate over each value in the multi-select
			const fieldValuesCheck = referenceFieldValues.reduce(
				(includes: boolean, referenceFieldValue: string) => {
					// If a previous check had a negative value, hide no matter what
					if (containsNegativeValue === true) return false;

					if (negativeFieldValues.includes(referenceFieldValue)) {
						// Value is a negative value, hide no matter what
						containsNegativeValue = true;
						return false;
					} else if (negativeFieldValues.length > 0) {
						// If there are negative values, and this isn't one of them, explicitly flag as such
						containsNegativeValue = false;
					}
					// Check for positive value
					const includesPositiveValue = positiveFieldValues.includes(referenceFieldValue);

					// If previously should include, included in positive values, or not a negative value (if negative values are present), show
					return includes || includesPositiveValue || containsNegativeValue === false;
				},
				negativeFieldValues.length > 0 // Initial value is the presence of negative fields
			);

			return show && fieldValuesCheck;
		}
	}

	// The populatedString only contains a key, make sure reference field is populated
	return show && referenceField?.value != null;
};

/*
 * Field Display
 */

export const showCollectionEntry = (
	report: IReport,
	parentField: IReportField,
	collection: IReportCollection,
	purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
): boolean => {
	let show = true;

	// Custom Show
	if (parentField.showCollectionEntry) {
		show = parentField.showCollectionEntry
			.split(",")
			.reduce(
				(show: boolean, key: string) =>
					show &&
					SHOW_COLLECTION_ENTRY_FUNCTIONS[key] &&
					SHOW_COLLECTION_ENTRY_FUNCTIONS[key](report, parentField, collection, purpose),
				show
			);
	}

	// Default Show
	return show;
};

const SHOW_COLLECTION_ENTRY_FUNCTIONS: {
	[index: string]: (
		report: IReport,
		parentField: IReportField,
		collection: IReportCollection,
		purpose: TFieldDisplayPurpose
	) => boolean;
} = {
	pitchTypeDisagreement: (
		_report: IReport,
		_parentField: IReportField,
		collection: IReportCollection,
		purpose: TFieldDisplayPurpose = FIELD_DISPLAY_INPUT
	) => {
		for (const field of collection.fields) {
			if (field.code === "pitch_type_disagreement" && field.value === "1" && purpose === FIELD_DISPLAY_REVIEW)
				return false;
		}
		return true;
	}
};
