export const MAROON = "#6F263D";

export const GRAY100 = "#F5F5F5";
export const GRAY200 = "#E5E5E5";
export const GRAY500 = "#737373";
export const GRAY900 = "#171717";

const colors = {
	maroon: MAROON,
	powderBlue: "#4299E1",

	gray: {
		50: "#FAFAFA",
		100: GRAY100,
		200: GRAY200,
		300: "#D4D4D4",
		400: "#A3A3A3",
		500: GRAY500,
		600: "#525252",
		700: "#404040",
		800: "#262626",
		900: GRAY900
	}
};

export default colors;
