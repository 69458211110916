import React, { useMemo } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup, Menu, MenuButton, MenuItem, MenuList, Portal } from "@chakra-ui/react";

import { ONE, ZERO } from "_react/home/_constants";
import { THomepageIsBetaQueryParam } from "_react/home/_types";
import { HOMEPAGE_BETA_IAM_GROUP } from "_react/shared/data_models/iam_new/_constants";
import Check from "_react/shared/ui/icons/Check";
import ExpandMore from "_react/shared/ui/icons/ExpandMore";
import Home from "_react/shared/ui/icons/Home";
import { useIsGroupMember } from "_react/app/AppContents";
import { IS_HOMEPAGE_BETA_COOKIE, HOMEPAGE_BETA_OPTIONS } from "_react/app/header/_constants";
import { HomeFlex, HomeFlexWithMenu } from "_react/app/header/_styles";

const HomeMenu = () => {
	// Navigation
	const history = useHistory();
	const hasBetaPermissions = useIsGroupMember(HOMEPAGE_BETA_IAM_GROUP);

	const pushToHistory = (homepageBetaOption: THomepageIsBetaQueryParam, hasBetaPermissions: boolean) => {
		if (!hasBetaPermissions) history.push("/");
		else if (homepageBetaOption === ZERO) history.push("/?isBeta=0");
		else history.push("/?isBeta=1");
	};

	// Homepage beta options
	const [isHomepageBetaCookie, setIsHomepageBetaCookie] = useCookies([IS_HOMEPAGE_BETA_COOKIE]);
	const isHomepageBeta = useMemo(() => isHomepageBetaCookie[IS_HOMEPAGE_BETA_COOKIE], [isHomepageBetaCookie]);

	const setIsHomepageBeta = (homepageBetaOption: THomepageIsBetaQueryParam) => {
		setIsHomepageBetaCookie(IS_HOMEPAGE_BETA_COOKIE, homepageBetaOption);
	};
	const onHomepageBetaOptionSelected = (homepageBetaOption: THomepageIsBetaQueryParam) => () => {
		setIsHomepageBeta(homepageBetaOption);
		pushToHistory(homepageBetaOption, hasBetaPermissions);
	};

	return hasBetaPermissions ? (
		<HomeFlexWithMenu>
			<ButtonGroup size="sm" isAttached variant="outline">
				<Button borderColor="gray.700" onClick={() => pushToHistory(isHomepageBeta, hasBetaPermissions)}>
					<Home />
				</Button>
				<Menu>
					<MenuButton
						as={Button}
						h="8"
						bg="transparent"
						color="gray.700"
						borderColor="gray.700"
						fontSize="sm"
						fontWeight="semibold"
						paddingInlineStart="3"
						paddingInlineEnd="3"
					>
						<ExpandMore />
					</MenuButton>
					<Portal>
						<MenuList>
							{HOMEPAGE_BETA_OPTIONS.map(
								(option: { label: string; value: THomepageIsBetaQueryParam }) => (
									<MenuItem key={option.value} onClick={onHomepageBetaOptionSelected(option.value)}>
										{`${option.label}`}
										{(isHomepageBeta === option.value ||
											(isHomepageBeta == null && option.value === ONE)) && <Check />}
									</MenuItem>
								)
							)}
						</MenuList>
					</Portal>
				</Menu>
			</ButtonGroup>
		</HomeFlexWithMenu>
	) : (
		<HomeFlex>
			<Button borderColor="gray.700" onClick={() => history.push("")}>
				<Home />
			</Button>
		</HomeFlex>
	);
};

export default HomeMenu;
