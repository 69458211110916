import React from "react";
import { Flex, Tooltip } from "@chakra-ui/react";

import {
	TOOLTIP_BAT_SPEED_MEASURED,
	TOOLTIP_BAT_TO_BALL,
	TOOLTIP_SWING_DECISIONS,
	TOOLTIP_ATTACK_ANGLE_MEASURED,
	TOOLTIP_SPRINT_SPEED,
	TOOLTIP_COMPOSITE_HITTER_GRADE,
	TOOLTIP_BAT_SPEED_INFERRED,
	TOOLTIP_ATTACK_ANGLE_INFERRED
} from "_react/shared/_constants/tooltips";
import { round10 } from "_react/shared/_helpers/numbers";
import { getLevelDisplayFromLevels } from "_react/shared/_helpers/stats";
import OutlineWarning from "_react/shared/ui/icons/OutlineWarning";
import { TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { format2080Grade } from "_react/shared/data_models/seasonal_grades/_helpers";
import TeamColumn from "_react/shared/ui/data/tables/shared/TeamColumn";

import { getLevelsFromRow } from "_react/shared/ui/data/tables/PositionPlayerFoundationalSkillsTable/_helpers";
import { TPositionPlayerFoundationalSkillsRow } from "_react/shared/ui/data/tables/PositionPlayerFoundationalSkillsTable/_types";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerFoundationalSkillsRow) => row.playerSeasonHitterGrades.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPositionPlayerFoundationalSkillsRow) => {
		if ("teamBam" in row.playerSeasonHitterGrades)
			return <TeamColumn teamBam={row.playerSeasonHitterGrades.teamBam} childRowCount={row.childData?.length} />;
		return <TeamColumn childRowCount={row.childData?.length} />;
	}
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPositionPlayerFoundationalSkillsRow) =>
		getLevelDisplayFromLevels(getLevelsFromRow(row), NULL_FILLER_TEXT),
	getSortValueFunction: (row: TPositionPlayerFoundationalSkillsRow) => {
		// Child Rows
		if ("teamBam" in row.playerSeasonHitterGrades)
			return row.playerSeasonHitterGrades.teamBam?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
		// Parent Rows with nested data - highest level if multiple levels
		if (row.childData && row.childData.length > 1)
			return Math.min(
				...row.childData.map((childRow: TPositionPlayerFoundationalSkillsRow) => {
					if ("teamBam" in childRow.playerSeasonHitterGrades)
						return (
							childRow.playerSeasonHitterGrades.teamBam?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER
						);
					return Number.MAX_SAFE_INTEGER;
				})
			);
		return NULL_FILLER_TEXT;
	}
};

const PA_COLUMN = {
	id: "pa",
	value: "pa",
	label: "PA",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerFoundationalSkillsRow) => row.playerSeasonHitterGrades.pa ?? NULL_FILLER_TEXT
};

const BAT_SPEED_COLUMN = {
	id: "batSpeed",
	value: "batSpeed",
	label: "Bat Speed",
	tooltip: TOOLTIP_BAT_SPEED_MEASURED,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerFoundationalSkillsRow) => (
		<Tooltip
			hasArrow
			placement="top"
			label={
				row.playerSeasonHitterGrades.isMeasuredSwingMetrics ? "" : `This value is ${TOOLTIP_BAT_SPEED_INFERRED}`
			}
			whiteSpace="pre-wrap"
		>
			<Flex alignItems="center" gap="1" justifyContent="end">
				{format2080Grade(row.playerSeasonHitterGrades.batSpeedGrade, NULL_FILLER_TEXT)}
				{!row.playerSeasonHitterGrades.isMeasuredSwingMetrics && row.playerSeasonHitterGrades.batSpeedGrade ? (
					<OutlineWarning color="gray.500" />
				) : (
					""
				)}
			</Flex>
		</Tooltip>
	)
};

const BAT_TO_BALL_COLUMN = {
	id: "batToBall",
	value: "batToBall",
	label: "Bat to Ball",
	tooltip: TOOLTIP_BAT_TO_BALL,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonHitterGrades.batToBallGrade, NULL_FILLER_TEXT)
};

const SWING_DECISIONS_COLUMN = {
	id: "swingDecisions",
	value: "swingDecisions",
	label: "Swing Decisions",
	tooltip: TOOLTIP_SWING_DECISIONS,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonHitterGrades.swingDecisionsGrade, NULL_FILLER_TEXT)
};

const COMPOSITE_COLUMN = {
	id: "composite",
	value: "composite",
	label: "Composite",
	tooltip: TOOLTIP_COMPOSITE_HITTER_GRADE,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonHitterGrades.compositeGrade, NULL_FILLER_TEXT)
};

const VERTICAL_ATTACK_ANGLE_COLUMN = {
	id: "vaa",
	value: "vaa",
	label: "VAA",
	tooltip: TOOLTIP_ATTACK_ANGLE_MEASURED,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerFoundationalSkillsRow) => (
		<Tooltip
			hasArrow
			placement="top"
			label={
				row.playerSeasonHitterGrades.isMeasuredSwingMetrics
					? ""
					: `This value is ${TOOLTIP_ATTACK_ANGLE_INFERRED}`
			}
			whiteSpace="pre-wrap"
		>
			<Flex alignItems="center" gap="1" justifyContent="end">
				{round10(row.playerSeasonHitterGrades.attackAngleVertical, -1) ?? NULL_FILLER_TEXT}
				{!row.playerSeasonHitterGrades.isMeasuredSwingMetrics &&
				row.playerSeasonHitterGrades.attackAngleVertical ? (
					<OutlineWarning color="gray.500" />
				) : (
					""
				)}
			</Flex>
		</Tooltip>
	)
};

const SPRINT_SPEED_COLUMN = {
	id: "sprintSpeed",
	value: "sprintSpeed",
	label: "Sprint Speed",
	tooltip: TOOLTIP_SPRINT_SPEED,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonHitterGrades.sprintSpeedGrade, NULL_FILLER_TEXT)
};

export const POSITION_PLAYER_FOUNDATIONAL_SKILLS_COLUMNS: Array<TColumn<
	TPositionPlayerFoundationalSkillsRow,
	keyof TPositionPlayerFoundationalSkillsRow
>> = [
	SEASON_COLUMN,
	TEAM_COLUMN,
	LEVEL_COLUMN,
	PA_COLUMN,
	BAT_SPEED_COLUMN,
	BAT_TO_BALL_COLUMN,
	SWING_DECISIONS_COLUMN,
	COMPOSITE_COLUMN,
	VERTICAL_ATTACK_ANGLE_COLUMN,
	SPRINT_SPEED_COLUMN
];
