import React from "react";
import styled from "@emotion/styled";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

// Styles

export const ListTitleTextField = {
	marginRight: "10px",
	textAlign: "center"
} as React.CSSProperties;

export const LongStyle = {
	display: "inline",
	maxWidth: "130px",
	textOverflow: "ellipsis",
	overflow: "hidden"
};

export const ExtraLongStyle = {
	maxWidth: "220px"
};

export const PrintStyle = {
	cursor: "pointer"
};

export const HideStyle = {
	display: "none"
};

export const StartStyle = {
	borderLeft: "none"
};

export const EndStyle = {
	borderRight: "none"
};

export const CheckboxStyle = {
	margin: "0 5px 0 30px"
};

// Styled components

export const PageStyle = () => (
	<style>
		{`
					@page {
						margin-top: .4in;
						margin-bottom: .4in;
						margin-left: .3in;
						margin-right: .3in;
					}
				`}
	</style>
);

export const EvalListPageContainer = styled.div({
	margin: "15px 40px"
});

export const PageHeaderContainer = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	marginTop: "15px",
	marginBottom: "15px"
});

export const ListTitleContainer = styled.div({
	width: "300px",
	marginLeft: "24px"
});

export const EvalListPrintContainer = styled.div({});

export const EvalListContainer = styled.div({});

export const CircularProgressContainer = styled.div({
	width: "100%",
	textAlign: "center"
});

export const HeaderSpan = styled.span({
	margin: "5px"
});

export const EvalListCard = styled.div({
	height: "auto",
	backgroundColor: "white",
	fontSize: ".8em",
	fontWeight: 400,
	textAlign: "left",
	boxSizing: "border-box",
	overflow: "visible",
	breakInside: "avoid"
});

export const CheckboxContainer = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
});

type EvalListCardHeaderProps = { colorSchemeGroup?: ColorSchemeGroup };
export const EvalListCardHeader = styled.div<EvalListCardHeaderProps>(({ colorSchemeGroup = defaultColorScheme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	height: "36px",
	backgroundColor: colorSchemeGroup.secondary.color,
	color: "white",
	fontWeight: 600,
	fontSize: "16px"
}));

export const LeftDiv = styled.div({
	display: "flex",
	alignItems: "center"
});

export const RightDiv = styled.div({
	display: "flex",
	alignItems: "center"
});

export const NameDiv = styled.div({
	margin: "5px"
});

export const PositionDiv = styled.div({
	marginRight: "5px",
	fontWeight: 400,
	fontSize: ".85em"
});

export const BoxDiv = styled.div({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	height: "26px",
	width: "26px",
	backgroundColor: "#FFFBEC",
	border: `3px solid ${defaultColorScheme.primary.color}`,
	borderRadius: "3px",
	margin: "5px 0 5px 5px",
	color: `${defaultColorScheme.primary.color}`,
	fontWeight: 500,
	fontSize: "12px"
});

export const FollowBoxDiv = styled.div({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	height: "26px",
	width: "26px",
	backgroundColor: "#FFFBEC",
	border: `3px solid ${defaultColorScheme.primary.color}`,
	borderRadius: "3px",
	margin: "5px 0 5px 5px",
	color: `${defaultColorScheme.primary.color}`,
	fontWeight: 500,
	fontSize: "10px"
});

export const BoxLabel = styled.div({
	height: "5px",
	fontSize: "6px"
});

export const OrgTeamDiv = styled.div({
	marginRight: "5px",
	fontWeight: 400,
	fontSize: ".85em"
});

export const PlayerClassificationDiv = styled.div({
	margin: "0 5px"
});

export const LabelSpan = styled.span({
	fontWeight: 600,
	fontSize: ".8em",
	marginLeft: "2px"
});

export const IpDiv = styled.div({
	fontWeight: 400,
	fontSize: ".85em",
	textAlign: "right"
});

export const GamesDiv = styled.div({
	width: "30px",
	fontWeight: 400,
	fontSize: ".85em",
	textAlign: "right"
});

export const DateSeenDiv = styled.div({
	width: "80px",
	fontWeight: 400,
	fontSize: ".85em",
	textAlign: "right"
});

export const AmaDateSeenDiv = styled.div({
	fontWeight: 400,
	fontSize: ".85em",
	textAlign: "right"
});

export const ScoutDiv = styled.div({
	width: "130px",
	marginRight: "10px",
	fontWeight: 400,
	fontSize: ".85em",
	textAlign: "right"
});

export const EvalContainer = styled.div({
	breakInside: "avoid"
});

export const EvalContentDiv = styled.div({
	width: "100%",
	borderLeft: `1px solid lightgray`,
	borderRight: `1px solid lightgray`,
	borderBottom: `1px solid lightgray`,
	whiteSpace: "nowrap"
});

type GradeSectionProps = { width?: string; inlineFlex?: boolean };
export const GradeSection = styled.div<GradeSectionProps>(({ width = "100%", inlineFlex = false }) => ({
	display: inlineFlex ? "inline-flex" : "flex",
	justifyContent: "space-around",
	alignItems: "center",
	backgroundColor: "rgb(241,241,241)",
	borderBottom: `2px solid lightgray`,
	width: width
}));

export const SummarySection = styled.div({
	width: "100%",
	whiteSpace: "normal",
	borderRight: `1px solid lightgray`,
	borderLeft: `1px solid lightgray`,
	borderBottom: `1px solid lightgray`
});

export const EvalHeader = styled.div({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	height: "24px",
	backgroundColor: "#C5C5C5",
	width: "100%",
	fontSize: "16px"
});

type EvalHeaderAttrProps = { width?: string };
export const EvalHeaderAttr = styled.div<EvalHeaderAttrProps>(({ width }) => ({
	display: "flex",
	alignItems: "center",
	margin: "0 10px 0 5px",
	fontSize: ".85em",
	width: width
}));

export const EvalHeaderLabel = styled.div({
	display: "flex",
	alignItems: "center",
	marginRight: "5px",
	fontWeight: "bold"
});

export const EvalSection = styled.div({
	display: "inline-flex",
	justifyContent: "space-around",
	alignItems: "center",
	textAlign: "center",
	width: "100%",
	borderRight: `1px solid lightgray`,
	borderLeft: `1px solid lightgray`
});

export const EvalAttr = styled.div({
	padding: "3px",
	fontSize: "12px"
});

export const EvalLabel = styled.div({
	marginBottom: "3px",
	fontSize: "10px",
	fontWeight: 600
});
