import React from "react";
import { Text, Tooltip } from "@chakra-ui/react";
import dayjs from "dayjs";

import { getAgeFromBirthDate } from "utils/helpers";
import { AMA_LEVEL_ABBREVIATIONS } from "_react/shared/data_models/team/_constants";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

//
// Shared helper functions used for Stats Tables
//

export const aggregateStat = (
	initialTotal: number,
	initialStat: number | null,
	additionalTotal: number,
	additionalStat: number | null
): number | null => {
	// No stats to aggregate
	if (initialStat === null && additionalStat === null) return null;
	// No initial stat to aggregate
	if ((initialTotal === 0 || initialStat === null) && additionalStat !== null) return additionalStat;
	// No additional stat to aggregate
	if ((additionalTotal === 0 || additionalStat === null) && initialStat !== null) return initialStat;
	// Do the aggregations
	const combinedTotal = initialTotal + additionalTotal;
	return (initialTotal / combinedTotal) * initialStat! + (additionalTotal / combinedTotal) * additionalStat!;
};

// Display player ages
export const getAge = (birthDate: string | null, season: number, ageBaseDate: string): number | null => {
	const rawAge: number | null = birthDate
		? parseFloat(String(getAgeFromBirthDate(birthDate, dayjs(`${ageBaseDate}/${season}`))))
		: null;
	return rawAge === null || isNaN(rawAge) ? null : rawAge;
};

export const getAmaLevelDisplayText = (value?: string | null): string | null | undefined => {
	if (value && Object.keys(AMA_LEVEL_ABBREVIATIONS).includes(value)) return AMA_LEVEL_ABBREVIATIONS[value];
	return value;
};

// Display levels
export const getLevelDisplayFromLevels = (levels: Array<string>, nullFillerText: string, isAma = false) => {
	if (levels.length === 1)
		return <TeamLevelBadge level={levels[0]} displayName={isAma ? getAmaLevelDisplayText(levels[0]) : undefined} />;
	if (levels.length > 1) return `${levels.length} levels`;
	return nullFillerText;
};

// Table Filtering
export const updateFilters = (existingFilters: Array<string>, newFilter: string): Array<string> => {
	// Option 1: Remove it
	if (existingFilters.includes(newFilter))
		return existingFilters.filter((existingFilter: string) => existingFilter !== newFilter);
	// Option 2: Add it
	return [...existingFilters, newFilter];
};

export const getMinAndMaxSeason = <T extends { season: number }>(data: Array<T>): [number, number] => {
	const seasons: Array<number> = data.reduce((acc: Array<number>, curr: T) => {
		if ("season" in curr && typeof curr.season === "number") acc.push(curr.season);
		return acc;
	}, []);
	if (seasons.length === 0) seasons.push(dayjs().year());
	return [Math.min(...seasons), Math.max(...seasons)];
};

export const getSeasonFilters = (
	filtersMinSeason: number | undefined,
	filtersMaxSeason: number | undefined,
	maxSeason: number,
	numDisplaySeasons: number
): { minSeason: number; maxSeason: number } => {
	const maxSeasonFilter = filtersMaxSeason ?? maxSeason;
	const minSeasonFilter = filtersMinSeason ?? maxSeasonFilter - numDisplaySeasons + 1;
	return { minSeason: minSeasonFilter, maxSeason: maxSeasonFilter };
};

export const getSeasonOptions = (minSeason: number, maxSeason: number): Array<{ label: string; value: number }> => {
	const options: Array<{ label: string; value: number }> = [];
	for (let i = 0; i <= maxSeason - minSeason; i++) {
		const year = minSeason + i;
		options.push({ label: `${year}`, value: year });
	}
	return options;
};

export const getAmaTeamDisplay = (
	team: { name: string | null; nameDisplay: string | null; trackmanTeam?: { level: string | null } } | undefined
): string | React.JSX.Element => {
	return (
		team?.nameDisplay ??
		team?.name ??
		(team?.trackmanTeam?.level ? (
			<Tooltip label="Team name unknown" hasArrow>
				<Text fontStyle="italic">{team.trackmanTeam.level}</Text>
			</Tooltip>
		) : (
			""
		))
	);
};
