import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import {
	ITwentyEightyGradeDistribution,
	TDataVizScale,
	IDataVizPlotConfigApiResponse
} from "_react/shared/data_models/dataviz/_types";

// twenty eighty grade distribution response

export const fetchTwentyEightyGradeDistribution = (cancelToken?: CancelToken) =>
	fetchResource<undefined, Array<ITwentyEightyGradeDistribution>>(
		"/twenty_eighty_grade_distribution",
		undefined,
		cancelToken
	);

// data_viz_scale endpoints

export type TDataVizScaleGetQueryParams = {
	name?: string;
	"name[in]"?: string;
	rangeMin?: number;
	rangeMax?: number;
	scale?: string;
	"scale[in]"?: string;
	color?: string;
	"color[in]"?: string;
	scheme?: string;
	"scheme[in]"?: string;
	size?: number;

	// General API params
	offset?: number;
	limit?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchDataVizScale = (queryParams: TDataVizScaleGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TDataVizScaleGetQueryParams, Array<TDataVizScale>>("/data_viz_scale", queryParams, cancelToken);

// data_viz_plot_config endpoints

export type TDataVizPlotConfigGetQueryParams = {
	id?: number;
	"id[in]"?: string;
	plotType?: string;
	"plotType[in]"?: string;
	name?: string;
	url?: string;
	sortOrder?: number;
	"sortOrder[gte]"?: number;
	"sortOrder[lte]"?: number;
	dataHierarchyTier?: string;
	"dataHierarchyTier[in]"?: string;
	isPitcher?: boolean;
	isTeam?: boolean;

	// General API params
	offset?: number;
	limit?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchDataVizPlotConfig = (queryParams: TDataVizPlotConfigGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TDataVizPlotConfigGetQueryParams, Array<IDataVizPlotConfigApiResponse>>(
		"/data_viz_plot_config",
		queryParams,
		cancelToken
	);
