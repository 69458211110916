// Valid league names
export const LEAGUE_NAME_AMERICAN = "American League";
export const LEAGUE_NAME_NATIONAL = "National League";

// Valid division names
export const DIVISION_NAME_NL_EAST = "NL East";
export const DIVISION_NAME_NL_CENTRAL = "NL Central";
export const DIVISION_NAME_NL_WEST = "NL West";
export const DIVISION_NAME_AL_EAST = "AL East";
export const DIVISION_NAME_AL_CENTRAL = "AL Central";
export const DIVISION_NAME_AL_WEST = "AL West";
