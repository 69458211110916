import React, { useContext } from "react";
import { VStack, Box } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";

import { $TSFixMeEval } from "_react/evals/shared/_types";
import { EvaluationsTable } from "_react/playerpage/eval/EvaluationsTable";
import { ReportsTable } from "_react/playerpage/eval/ReportsTable";
import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import { useHasProReportAccess } from "_react/reports/_hooks";
import { PRO } from "_react/playerpage/_constants";
import { PlayerPageContext } from "_react/playerpage/PlayerPageProvider";
import { TPlayer } from "_react/playerpage/_types";
import { TPlayerPageState } from "_react/playerpage/_machine";

type TScoutingReportsTabProps = {
	evals: $TSFixMeEval[] | null | undefined;
	playerId?: number | null;
};

const ScoutingReportsTab = ({ evals, playerId }: TScoutingReportsTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	const hasProReportAccess = useHasProReportAccess();

	const playerPageContext = useContext(PlayerPageContext);
	const viewClassification: TPlayer | undefined = useSelector(
		playerPageContext.playerPageService,
		(state: TPlayerPageState) => state.context.viewClassification
	);

	return (
		<VStack marginBottom="10" gap="10">
			{viewClassification === PRO && hasProReportAccess && playerId && <ReportsTable playerId={playerId} />}
			<Box width="100%">
				<EvaluationsTable
					evaluations={evals}
					playerId={playerId}
					colorScheme={chakraColorSchemeGroup}
					style={{
						evalList: {
							eval: {
								evalContentDiv: {
									width: "100%"
								},
								summarySection: {
									width: "100%"
								},
								evalSection: {
									width: "100%"
								}
							}
						}
					}}
				/>
			</Box>
		</VStack>
	);
};

export default ScoutingReportsTab;
