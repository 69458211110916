import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import { IMetricReliability } from "_react/shared/data_models/metric_reliability/_types";

export type TMetricReliabilityGetQueryParams = {
	metric?: string;
	"metric[in]"?: string;
	playerType?: string;
	pitchTypeGrouping?: string;
	sample?: number;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
};

export const fetchMetricReliability = (queryParams: TMetricReliabilityGetQueryParams, cancelToken?: CancelToken) => {
	const path = `metric_reliability`;
	return fetchResource<TMetricReliabilityGetQueryParams, Array<IMetricReliability>>(path, queryParams, cancelToken);
};
