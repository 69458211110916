import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { fetchProModelPredictions } from "_react/shared/data_models/pro2_predictions/_network";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { extractFromEventDataArray } from "_react/shared/_helpers/xstate";

import { IPro2PredictionsSummary } from "_react/shared/data_models/pro2_predictions/_types";
import { TProModelStatCardData } from "_react/shared/ui/data/cards/ProModelStatCard/ProModelStatCard";

export type TProModelStatCardContext = {
	playerId: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	proModelPrediction?: IPro2PredictionsSummary | null;
	cancelSources: Record<string, CancelTokenSource>;
	toast?: CreateToastFnReturn;
};

interface IProModelStatCardStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches pro model data
				proModelPrediction: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PRO_MODEL_PREDICTION = "SET_PRO_MODEL_PREDICTION";
export const FETCHING_PRO_MODEL_PREDICTION = { initialized: { proModelPrediction: "fetching" } };

type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number;
};
type TSetProModelPredictionEvent = {
	type: typeof SET_PRO_MODEL_PREDICTION;
	data: IPro2PredictionsSummary | null | undefined;
};
const FETCH_PRO_MODEL_PREDICTION_DONE = "done.invoke.fetching:invocation[0]";
const FETCH_PRO_MODEL_PREDICTION_ERROR = "error.platform.fetching:invocation[0]";

type TFetchProModelPredictionEvent = {
	type: typeof FETCH_PRO_MODEL_PREDICTION_DONE;
	data?: Array<IPro2PredictionsSummary>;
};
type TFetchProModelPredictionErrorEvent = {
	type: typeof FETCH_PRO_MODEL_PREDICTION_ERROR;
	data?: AxiosError | Error;
};

type TProModelStatCardEvent =
	| TFetchProModelPredictionEvent
	| TSetPlayerIdEvent
	| TSetProModelPredictionEvent
	| TFetchProModelPredictionErrorEvent;

export type TProModelStatCardSend = Interpreter<
	TProModelStatCardContext,
	IProModelStatCardStateSchema,
	TProModelStatCardEvent
>["send"];

const ProModelStatCardMachine = (
	playerIdProp: number,
	shouldFetchData = true,
	data?: TProModelStatCardData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TProModelStatCardContext, IProModelStatCardStateSchema, TProModelStatCardEvent>(
		{
			id: "proModelStatCard",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				shouldFetchData: shouldFetchData,
				proModelPrediction: data?.proModelPrediction,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_PRO_MODEL_PREDICTION]: { actions: "setProModelPrediction" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						proModelPrediction: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: { target: "#fetching", cond: "shouldFetchProModelPrediction" },
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetching",
									entry: ["refreshProModelPredictionCancelSource"],
									invoke: {
										src: "fetchProModelPrediction",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchProModelPredictionSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchProModelPredictionErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (context: TProModelStatCardContext, _event: TProModelStatCardEvent) =>
					context.playerId !== context.lastPlayerId,
				shouldFetchProModelPrediction: (context: TProModelStatCardContext, _event: TProModelStatCardEvent) =>
					context.proModelPrediction === undefined && shouldFetchData
			},
			actions: {
				setPlayerId: assign<TProModelStatCardContext, TProModelStatCardEvent>({
					playerId: (context: TProModelStatCardContext, event: TProModelStatCardEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setProModelPrediction: assign<TProModelStatCardContext, TProModelStatCardEvent>({
					proModelPrediction: (context: TProModelStatCardContext, event: TProModelStatCardEvent) => {
						if (event.type !== SET_PRO_MODEL_PREDICTION) return context.proModelPrediction;
						return event.data;
					},
					cancelSources: (context: TProModelStatCardContext, event: TProModelStatCardEvent) => {
						if (event.type !== SET_PRO_MODEL_PREDICTION) return context.cancelSources;
						if (context.cancelSources["proModelPredictions"] != null)
							context.cancelSources["proModelPredictions"].cancel();
						delete context.cancelSources["proModelPredictions"];
						return context.cancelSources;
					}
				}),
				clearContext: assign<TProModelStatCardContext, TProModelStatCardEvent>({
					lastPlayerId: (context: TProModelStatCardContext, _event: TProModelStatCardEvent) =>
						context.playerId,
					proModelPrediction: (_context: TProModelStatCardContext, _event: TProModelStatCardEvent) =>
						undefined,
					cancelSources: (context: TProModelStatCardContext, _event: TProModelStatCardEvent) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				refreshProModelPredictionCancelSource: assign<TProModelStatCardContext, TProModelStatCardEvent>({
					cancelSources: (context: TProModelStatCardContext, _event: TProModelStatCardEvent) => {
						if (context.cancelSources["proModelPredictions"] != null)
							context.cancelSources["proModelPredictions"].cancel();
						context.cancelSources["proModelPredictions"] = getCancelSource();
						return context.cancelSources;
					}
				}),
				handleFetchProModelPredictionSuccess: assign<TProModelStatCardContext, TProModelStatCardEvent>({
					proModelPrediction: (context: TProModelStatCardContext, event: TProModelStatCardEvent) => {
						if (event.type !== FETCH_PRO_MODEL_PREDICTION_DONE) return context.proModelPrediction;
						return extractFromEventDataArray<TProModelStatCardEvent>(event);
					}
				}),
				handleFetchProModelPredictionErrored: (
					context: TProModelStatCardContext,
					event: TProModelStatCardEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PRO_MODEL_PREDICTION_ERROR ? event.data : undefined,
						context.toast,
						"Prospect Value",
						"Error fetching prospect value data."
					);
				}
			},
			services: {
				fetchProModelPrediction: (context: TProModelStatCardContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchProModelPredictions(
							{
								playerId: context.playerId,
								isCurrent: true,
								isUseCache: true
							},
							context.cancelSources["proModelPredictions"]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default ProModelStatCardMachine;
