export const LEVEL_4YR = "4YR";
export const LEVEL_JC = "JC";
export const LEVEL_HS = "HS";
export const LEVEL_AMA_SUMMER = "AMA_SUMMER";
export const LEVEL_AMA_SHOWCASE = "AMA_SHOWCASE";
export const LEVEL_AMA_OTH = "AMA_OTH";
export const LEVEL_NS = "NS";

export const VALID_AMA_LEVELS = [LEVEL_4YR, LEVEL_JC, LEVEL_HS, LEVEL_AMA_SUMMER, LEVEL_AMA_SHOWCASE, LEVEL_AMA_OTH];
export const AMA_LEVEL_ABBREVIATIONS: { [key: string]: string } = {
	[LEVEL_4YR]: LEVEL_4YR,
	[LEVEL_JC]: LEVEL_JC,
	[LEVEL_HS]: LEVEL_HS,
	[LEVEL_AMA_SUMMER]: "SUM",
	[LEVEL_AMA_SHOWCASE]: "SHW",
	[LEVEL_AMA_OTH]: "OTH"
};

export const NAME_NS = "No School";

export const PLAYER_CLASSIFICATION_PRO = "PRO";
export const PLAYER_CLASSIFICATION_AMA = "AMA";
export const PLAYER_CLASSIFICATION_INTL = "INTL";
