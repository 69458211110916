import { round10 } from "_react/shared/_helpers/numbers";
import { getAgeFromBirthDate } from "utils/helpers";
import { IPro2PredictionsSummary } from "_react/shared/data_models/pro2_predictions/_types";
import { getColorGradientFunctionBlueGreen, getColorGradientFunctionWhiteGreen } from "_react/shared/dataviz/_helpers";
import { VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import { TOOLTIP_ML_PERC, TOOLTIP_SURPLUS_VALUE, TOOLTIP_XWAR } from "_react/shared/_constants/tooltips";

import PlayerTeamCell from "_react/promodel/leaderboard/PlayerTeamCell";
import { get40ManR5Color } from "_react/promodel/leaderboard/_helpers";

//
// Query Params
//

export const VIEW = "view";

//
// Views
//

export const MAIN_VIEW = "main";

export const [COLOR_GRADIENT_FUNCTION_BLUE_GREEN_SV] = getColorGradientFunctionBlueGreen(-5, 125, 0);
export const [COLOR_GRADIENT_FUNCTION_BLUE_GREEN_XWAR] = getColorGradientFunctionWhiteGreen(-0.1, 30);

//
// Leaderboard Columns
//

export const NULL_VALUE = null;

export const F40_MAN_R5_COLUMN = {
	value: "40ManR5",
	label: "40 Man/R5",
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		get40ManR5Color({
			leaderboardPlayerProProfile: leaderboardData.player.proProfile
		})
};

export const PLAYER_COLUMN = {
	value: "playerName",
	label: "Player",
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		PlayerTeamCell({
			playerLastName: leaderboardData.player.lastName ?? NULL_VALUE,
			playerFirstName: leaderboardData.player.firstName ?? NULL_VALUE,
			teamName: leaderboardData.player.proProfile.organization?.name ?? NULL_VALUE
		}),
	sortFunction: (a: IPro2PredictionsSummary, b: IPro2PredictionsSummary) =>
		`${a.player.lastName ?? NULL_VALUE} ${a.player.firstName ?? NULL_VALUE}`.localeCompare(
			`${b.player.lastName ?? NULL_VALUE} ${b.player.firstName ?? NULL_VALUE}`,
			undefined,
			{ sensitivity: "accent" }
		),
	onClickFunction: (leaderboardData: IPro2PredictionsSummary, _index: number) => {
		window.open(`../player?philId=${leaderboardData.player?.playerProId}&view=summary`, "_blank");
	},
	style: { td: { width: "2xs", height: 12, overflow: "hidden" } },
	isMobile: true
};

export const XWAR_COLUMN = {
	value: "xWAR",
	label: "xWAR",
	tooltip: TOOLTIP_XWAR,
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		round10(leaderboardData.blendedXwar, -1) ?? NULL_VALUE,
	getSortValueFunction: (leaderboardData: IPro2PredictionsSummary) => leaderboardData.blendedXwar,
	getColorGradientValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		leaderboardData.blendedXwar ?? NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN_XWAR,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	style: {
		td: {
			borderLeft: "4px double #6f6f6f",
			borderRight: "4px double #6f6f6f"
		}
	},
	isMobile: true
};

export const SV_COLUMN = {
	value: "blendedSv",
	label: "Surplus Value",
	tooltip: TOOLTIP_SURPLUS_VALUE,
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		leaderboardData.player?.currentBlendedSurplusValue?.blendedSv != null
			? `$${round10(leaderboardData.player?.currentBlendedSurplusValue?.blendedSv, -1)}M`
			: NULL_VALUE,
	getSortValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		leaderboardData.player?.currentBlendedSurplusValue?.blendedSv != null
			? leaderboardData.player?.currentBlendedSurplusValue?.blendedSv
			: NULL_VALUE,
	getColorGradientValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		leaderboardData.player?.currentBlendedSurplusValue?.blendedSv != null
			? leaderboardData.player?.currentBlendedSurplusValue?.blendedSv
			: NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN_SV,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true
};

export const MLB_PROB_COLUMN = {
	value: "mlbProb",
	label: "MLB %",
	tooltip: TOOLTIP_ML_PERC,
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		`${Math.round(leaderboardData.mlbProb * 100) ?? NULL_VALUE}%`,
	getSortValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		leaderboardData.mlbProb ? leaderboardData.mlbProb : NULL_VALUE,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true
};

export const LEVEL_COLUMN = {
	value: "level",
	label: "Level",
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		TeamLevelBadge({ level: leaderboardData.player.team?.level ?? NULL_VALUE })
};

export const AGE_COLUMN = {
	value: "age",
	label: "Age",
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		leaderboardData.player.birthDate ? getAgeFromBirthDate(leaderboardData.player.birthDate) : NULL_VALUE,
	valueType: VALUE_TYPE_NUMBER as TValueType
};
const POSITION_COLUMN = {
	value: "position",
	label: "Position",
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) => leaderboardData.player.position ?? NULL_VALUE
};

export const BATS_COLUMN = {
	value: "bats",
	label: "Bats",
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) => leaderboardData.player.bats ?? NULL_VALUE
};

export const THROWS_COLUMN = {
	value: "throws",
	label: "Throws",
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) => leaderboardData.player.throws ?? NULL_VALUE
};

export const OPTIONS_COLUMN = {
	value: "options",
	label: "Options",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) =>
		`${
			leaderboardData.player?.proProfile?.optionsUsed != null
				? leaderboardData.player.proProfile.optionsUsed
				: "-"
		}/${
			leaderboardData.player?.proProfile?.optionsTotal != null
				? leaderboardData.player.proProfile.optionsTotal
				: "-"
		}`
};

export const PRO_MODEL_LEADERBOARD_COLUMNS = [
	F40_MAN_R5_COLUMN,
	PLAYER_COLUMN,
	XWAR_COLUMN,
	SV_COLUMN,
	MLB_PROB_COLUMN,
	LEVEL_COLUMN,
	AGE_COLUMN,
	POSITION_COLUMN,
	BATS_COLUMN,
	THROWS_COLUMN,
	OPTIONS_COLUMN
];
