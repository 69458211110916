import { chakra, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const FlexDiv = styled.div({
	display: "flex"
});

export const MinimalGridListContainerStyle = {
	borderTop: "1px solid",
	marginTop: 4,
	borderColor: "gray.300"
};

export const ToggleTopBreakpointValue = {
	base: undefined,
	md: "4px"
};

export const TogglePositionBreakpointValue = {
	base: undefined,
	md: "sticky"
	// Doing this so the value passes type checks as a valid `Position` and not a `string`
} as { [index: string]: "sticky" | undefined };

export const ToggleJustifyContentBreakpointValue = {
	base: "start",
	md: "end"
};
export const ToggleGapBreakpointValue = {
	base: "2",
	sm: "5"
};
export const ContentPaddingTopBreakpointValue = {
	base: "12",
	lg: "0"
};

// Chakra styles

export const AgentValueFlex = chakra(Flex, {
	baseStyle: {
		alignItems: "center",
		fontSize: "md",
		marginLeft: 1
	}
});

export const TrackmanDupeValueFlex = chakra(Flex, {
	baseStyle: {
		alignItems: "center",
		fontSize: "md",
		marginLeft: 1
	}
});

export const TOGGLE_STYLE = { container: { backgroundColor: "white", borderRadius: "base" } };
