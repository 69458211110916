import React from "react";

import { round10 } from "_react/shared/_helpers/numbers";
import { getAmaLevelDisplayText, getAmaTeamDisplay } from "_react/shared/_helpers/stats";
import { formatCollegesplitsPercentage } from "_react/shared/data_models/ama_stats/_helpers";
import { formatThreeDecimal } from "_react/shared/data_models/stats/_helpers";
import { TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { BATS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import { ICollegeSplitsPlayerPitchingStatsByTeam } from "_react/shared/data_models/ama_stats/_types";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => getAmaTeamDisplay(row.team),
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) =>
		`${row.team?.nameDisplay ?? row.team?.name}-${row.teamId}`
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => (
		<TeamLevelBadge
			level={row.team?.level}
			displayName={getAmaLevelDisplayText(row.team?.level)}
			nullFillerText={NULL_FILLER_TEXT}
		/>
	),
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) =>
		row.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER
};

const BATS_COLUMN = {
	id: "bats",
	value: "bats",
	label: "Bats",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.bats ?? NULL_FILLER_TEXT
};

const G_COLUMN = (batsFilter: string) => {
	return {
		id: "games",
		value: "g",
		label: "G",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) =>
			batsFilter !== BATS_OVERALL ? NULL_FILLER_TEXT : row.g ?? NULL_FILLER_TEXT
	};
};

const GS_COLUMN = (batsFilter: string) => {
	return {
		id: "gs",
		value: "gs",
		label: "GS",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) =>
			batsFilter !== BATS_OVERALL ? NULL_FILLER_TEXT : row.gs ?? NULL_FILLER_TEXT
	};
};

const IP_COLUMN = {
	id: "ip",
	value: "ip",
	label: "IP",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.ipDisplay ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.ip ?? NULL_FILLER_TEXT
};

const ERA_COLUMN = {
	id: "era",
	value: "era",
	label: "ERA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => round10(row.era, -2) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.era
};

const RA9_COLUMN = {
	id: "ra9",
	value: "ra9",
	label: "RA9",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => round10(row.ra9, -2) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.ra9
};

const WHIP_COLUMN = {
	id: "whip",
	value: "whip",
	label: "WHIP",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => round10(row.whip, -2) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.whip
};

const H9_COLUMN = {
	id: "h9",
	value: "h9",
	label: "H9",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => round10(row.h9, -2) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.h9
};

const HR9_COLUMN = {
	id: "hr9",
	value: "hr9",
	label: "HR9",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => round10(row.hr9, -2) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.hr9
};

const GB_PCT_COLUMN = {
	id: "gbPct",
	value: "gbPct",
	label: "GB%",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => formatCollegesplitsPercentage(row.gbPct),
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.gbPct
};

const BABIP_COLUMN = {
	id: "oppBabip",
	value: "oppBabip",
	label: "BABIP",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) =>
		formatThreeDecimal(row.oppBabip) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.oppBabip
};

const K_PCT_COLUMN = {
	id: "kPct",
	value: "kPct",
	label: "K%",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => formatCollegesplitsPercentage(row.kPct),
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.kPct
};

const BB_PCT_COLUMN = {
	id: "bbPct",
	value: "bbPct",
	label: "BB%",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => formatCollegesplitsPercentage(row.bbPct),
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.bbPct
};

const HBP_PCT_COLUMN = {
	id: "hbpPct",
	value: "hbpPct",
	label: "HBP%",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => formatCollegesplitsPercentage(row.hbpPct),
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.hbpPct
};

const OPP_ISO_COLUMN = {
	id: "oppIso",
	value: "oppIso",
	label: "OppISO",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) =>
		formatThreeDecimal(row.oppIso) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerPitchingStatsByTeam) => row.oppIso
};

export const getPitcherCollegesplitsColumns = (batsFilter: string) => {
	const columns: Array<TColumn<
		ICollegeSplitsPlayerPitchingStatsByTeam,
		keyof ICollegeSplitsPlayerPitchingStatsByTeam
	>> = [SEASON_COLUMN, TEAM_COLUMN, LEVEL_COLUMN];
	if (batsFilter !== BATS_OVERALL) {
		columns.push(BATS_COLUMN);
	}
	return columns.concat([
		G_COLUMN(batsFilter),
		GS_COLUMN(batsFilter),
		IP_COLUMN,
		ERA_COLUMN,
		RA9_COLUMN,
		WHIP_COLUMN,
		H9_COLUMN,
		HR9_COLUMN,
		GB_PCT_COLUMN,
		BABIP_COLUMN,
		K_PCT_COLUMN,
		BB_PCT_COLUMN,
		HBP_PCT_COLUMN,
		OPP_ISO_COLUMN
	]);
};
