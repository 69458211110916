// User IDs
export const ROSEN_USER_ID = 40074;
export const BETBEZE_USER_ID = 19523;
export const ROCKY_SWE_USER_ID = 41778;

// Team IDs
export const PHILLIES_TEAM_ID = 52953;

// Team BAM IDs
export const PHILLIES_BAM_ID = 143;
export const LEHIGH_BAM_ID = 1410;
export const READING_BAM_ID = 522;
export const JERSEY_SHORE_BAM_ID = 427;
export const CLEARWATER_BAM_ID = 566;
export const PHILLIES_MILB_BAM_IDS = [LEHIGH_BAM_ID, READING_BAM_ID, JERSEY_SHORE_BAM_ID, CLEARWATER_BAM_ID];
export const PHILLIES_ORG_BAM_IDS = [...PHILLIES_MILB_BAM_IDS, PHILLIES_BAM_ID];
