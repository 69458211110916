import React from "react";
import { Box, Tooltip } from "@chakra-ui/react";

import { round10 } from "_react/shared/_helpers/numbers";
import { THROWS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";
import { TColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import OutlineWarning from "_react/shared/ui/icons/OutlineWarning";

import { ISeasonalSwingMetricsInferredApiResponse } from "_react/shared/data_models/seasonal_grades/_types";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) => row.season ?? NULL_FILLER_TEXT
};

const BATS_COLUMN = {
	id: "bats",
	value: "bats",
	label: "Bats",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) => row.bats ?? NULL_FILLER_TEXT
};

const THROWS_COLUMN = {
	id: "throws",
	value: "throws",
	label: "Throws",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) => row.throws ?? NULL_FILLER_TEXT
};

const BAT_BALL_COLUMN = {
	id: "battedBalls",
	value: "battedBalls",
	label: "Bat. Balls",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) => (
		<Box>
			{Boolean(row.isRegressed) && (
				<Tooltip
					label={"Values are regressed to career averages due to low Batted Balls"}
					placement="top"
					hasArrow
				>
					<Box as="span" marginRight="2">
						<OutlineWarning boxSize="4" color="gray.500" />
					</Box>
				</Tooltip>
			)}
			{row.battedBalls ?? NULL_FILLER_TEXT}
		</Box>
	),
	getSortValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) => row.battedBalls ?? 0
};

const BAT_SPEED_COLUMN = {
	id: "batSpeed",
	value: "batSpeed",
	label: "Bat Speed",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) =>
		round10(row.batSpeedInferred, -1) ?? NULL_FILLER_TEXT
};

const VAA_COLUMN = {
	id: "vaa",
	value: "vaa",
	label: "VAA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) =>
		round10(row.attackAngleVerticalInferred, -1) ?? NULL_FILLER_TEXT
};

const HAA_COLUMN = {
	id: "haa",
	value: "haa",
	label: "HAA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) =>
		round10(row.attackAngleHorizontalInferred, -1) ?? NULL_FILLER_TEXT
};

const VBA_COLUMN = {
	id: "vba",
	value: "vba",
	label: "VBA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) =>
		round10(row.batAngleVerticalInferred, -1) ?? NULL_FILLER_TEXT
};

const HBA_COLUMN = {
	id: "hba",
	value: "hba",
	label: "HBA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) =>
		row.attackAngleHorizontalInferred != null && row.closureRateInferred != null
			? round10(row.attackAngleHorizontalInferred - row.closureRateInferred, -1) ?? NULL_FILLER_TEXT
			: NULL_FILLER_TEXT
};

const CLOSURE_COLUMN = {
	id: "closure",
	value: "closure",
	label: "Closure",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ISeasonalSwingMetricsInferredApiResponse) =>
		round10(row.closureRateInferred, -1) ?? NULL_FILLER_TEXT
};

export const getAmaSwingMetricsColumns = (
	throwsFilter: string
): Array<TColumn<ISeasonalSwingMetricsInferredApiResponse, keyof ISeasonalSwingMetricsInferredApiResponse>> => {
	const columns: Array<TColumn<
		ISeasonalSwingMetricsInferredApiResponse,
		keyof ISeasonalSwingMetricsInferredApiResponse
	>> = [SEASON_COLUMN, BATS_COLUMN];
	if (throwsFilter !== THROWS_OVERALL) {
		columns.push(THROWS_COLUMN);
	}
	return columns.concat([
		BAT_BALL_COLUMN,
		BAT_SPEED_COLUMN,
		VAA_COLUMN,
		HAA_COLUMN,
		VBA_COLUMN,
		HBA_COLUMN,
		CLOSURE_COLUMN
	]);
};
