import React from "react";
import { Flex } from "@chakra-ui/react";

import {
	LEAGUE_NAME_NATIONAL,
	LEAGUE_NAME_AMERICAN,
	DIVISION_NAME_NL_EAST,
	DIVISION_NAME_NL_CENTRAL,
	DIVISION_NAME_NL_WEST,
	DIVISION_NAME_AL_EAST,
	DIVISION_NAME_AL_CENTRAL,
	DIVISION_NAME_AL_WEST
} from "_react/shared/data_models/simulated_standings/_constants";

//
// isBeta query param values
//

export const ZERO = "0";
export const ONE = "1";

//
// Toggle Options
//

export const LEAGUE = "league";
export const RNI = "rni";

export const PULSE_TOGGLE_OPTIONS = [
	{
		value: LEAGUE,
		label: "League"
	},
	{
		value: RNI,
		label: "R&I"
	}
];

export const YTD = "ytd";
export const ONE_MONTH = "oneMonth";
export const ONE_WEEK = "oneWeek";
export const ONE_DAY = "oneDay";

export const TIME_PERIOD_TOGGLE_OPTIONS = [
	{
		value: YTD,
		label: "YTD"
	},
	{
		value: ONE_MONTH,
		label: "1M"
	},
	{
		value: ONE_WEEK,
		label: "1W"
	},
	{
		value: ONE_DAY,
		label: "1D"
	}
];

export const LEAGUE_OPTIONS = [
	{ value: LEAGUE_NAME_NATIONAL, label: "NL" },
	{ value: LEAGUE_NAME_AMERICAN, label: "AL" }
];

export const DIVISION_OPTIONS = [
	{ value: DIVISION_NAME_NL_EAST, label: "NL East" },
	{ value: DIVISION_NAME_NL_CENTRAL, label: "NL Central" },
	{ value: DIVISION_NAME_NL_WEST, label: "NL West" },
	{ value: DIVISION_NAME_AL_EAST, label: "AL East" },
	{ value: DIVISION_NAME_AL_CENTRAL, label: "AL Central" },
	{ value: DIVISION_NAME_AL_WEST, label: "AL West" }
];

// TODO don't hardcode this, but using for homepage tab select
export const TEAM_MLB_OPTIONS = [
	{
		division: "NL East",
		teams: [
			{ value: "143", label: "PHI" },
			{ value: "144", label: "ATL" },
			{ value: "146", label: "MIA" },
			{ value: "121", label: "NYM" },
			{ value: "120", label: "WSH" }
		]
	},
	{
		division: "NL Central",
		teams: [
			{ value: "112", label: "CHC" },
			{ value: "113", label: "CIN" },
			{ value: "158", label: "MIL" },
			{ value: "134", label: "PIT" },
			{ value: "138", label: "STL" }
		]
	},
	{
		division: "NL West",
		teams: [
			{ value: "109", label: "ARI" },
			{ value: "115", label: "COL" },
			{ value: "119", label: "LAD" },
			{ value: "135", label: "SD" },
			{ value: "137", label: "SF" }
		]
	},
	{
		division: "AL East",
		teams: [
			{ value: "110", label: "BAL" },
			{ value: "111", label: "BOS" },
			{ value: "147", label: "NYY" },
			{ value: "139", label: "TB" },
			{ value: "141", label: "TOR" }
		]
	},
	{
		division: "AL Central",
		teams: [
			{ value: "114", label: "CLE" },
			{ value: "145", label: "CWS" },
			{ value: "116", label: "DET" },
			{ value: "118", label: "KC" },
			{ value: "142", label: "MIN" }
		]
	},
	{
		division: "AL West",
		teams: [
			{ value: "133", label: "ATH" },
			{ value: "117", label: "HOU" },
			{ value: "108", label: "LAA" },
			{ value: "136", label: "SEA" },
			{ value: "140", label: "TEX" }
		]
	}
];

//
// Filler Content
//

export const TODO_CONTENT_GRID = {
	grid: {
		rows: 2,
		items: [
			{
				colSpan: 1,
				rowSpan: 1,
				content: (
					<Flex
						align="center"
						justify="center"
						height="40"
						backgroundColor="gray.300"
						color="gray.100"
					></Flex>
				)
			},
			{
				colSpan: 2,
				rowSpan: 1,
				content: (
					<Flex
						align="center"
						justify="center"
						height="40"
						backgroundColor="gray.300"
						color="gray.100"
					></Flex>
				)
			},
			{
				colSpan: 1,
				rowSpan: 1,
				content: (
					<Flex
						align="center"
						justify="center"
						height="40"
						backgroundColor="gray.300"
						color="gray.100"
					></Flex>
				)
			},
			{
				colSpan: 1,
				rowSpan: 1,
				content: (
					<Flex
						align="center"
						justify="center"
						height="40"
						backgroundColor="gray.300"
						color="gray.100"
					></Flex>
				)
			},
			{
				colSpan: 1,
				rowSpan: 1,
				content: (
					<Flex
						align="center"
						justify="center"
						height="40"
						backgroundColor="gray.300"
						color="gray.100"
					></Flex>
				)
			}
		]
	}
};
