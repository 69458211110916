import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";

import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	BATS_OVERALL,
	GAME_TYPE_OVERALL,
	PLAYING_LEVEL_AMA,
	THROWS_L,
	THROWS_R
} from "_react/shared/data_models/hitter_grades/_constants";
import {
	IPlayerSeasonHitterGrades,
	IPlayerSeasonHitterGradesByTeamApiResponse
} from "_react/shared/data_models/hitter_grades/_types";
import {
	fetchPlayerSeasonHitterGrades,
	fetchPlayerSeasonHitterGradesByTeam
} from "_react/shared/data_models/hitter_grades/_network";
import {
	PLAYER_TYPE_BATTER,
	METRIC_BAT_TO_BALL_GRADE,
	METRIC_SWING_DECISIONS_GRADE,
	METRIC_EXIT_VELO
} from "_react/shared/data_models/metric_reliability/_constants";
import { fetchMetricReliability } from "_react/shared/data_models/metric_reliability/_network";
import { IMetricReliability } from "_react/shared/data_models/metric_reliability/_types";
import { TAmaPositionPlayerFoundationalSkillsTableData } from "_react/shared/ui/data/tables/AmaPositionPlayerFoundationalSkillsTable/_types";
import { PITCH_TYPE_OVERALL } from "_react/shared/data_models/arsenal_scores/_constants";

export const PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE = "playerSeasonHitterGrades";
export const PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE = "playerSeasonHitterGradesByTeam";
export const METRIC_RELIABILITY_CANCEL_SOURCE = "metricReliability";

export type TAmaPositionPlayerFoundationalSkillsTableCancelSource = {
	[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[METRIC_RELIABILITY_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TAmaPositionPlayerFoundationalSkillsTableFilters = {
	throws: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TAmaPositionPlayerFoundationalSkillsTableContext = {
	playerId?: number;
	lastPlayerId?: number; // Not currently used
	shouldFetchData?: boolean;
	filters: TAmaPositionPlayerFoundationalSkillsTableFilters;
	playerSeasonHitterGrades?: Array<IPlayerSeasonHitterGrades> | null;
	playerSeasonHitterGradesByTeam?: Array<IPlayerSeasonHitterGradesByTeamApiResponse> | null;
	metricReliability?: Array<IMetricReliability> | null;
	cancelSources: TAmaPositionPlayerFoundationalSkillsTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IAmaPositionPlayerFoundationalSkillsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all hitter grades by player season
				playerSeasonHitterGrades: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all hitter grades by player season team
				playerSeasonHitterGradesByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches metric reliability thresholds
				metricReliability: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_PLAYER_SEASON_HITTER_GRADES = { initialized: { playerSeasonHitterGrades: "fetching" } };
export const FETCHING_PLAYER_SEASON_HITTER_GRADES_BYTEAM = {
	initialized: { playerSeasonHitterGradesByTeam: "fetching" }
};
export const FETCHING_METRIC_RELIABILITY = {
	initialized: { fetchMetricReliability: "fetching" }
};

const FETCH_PLAYER_SEASON_HITTER_GRADES_DONE = "done.invoke.fetchPlayerSeasonHitterGrades:invocation[0]";
const FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonHitterGradesByTeam:invocation[0]";
const FETCH_METRIC_RELIABILITY_DONE = "done.invoke.fetchMetricReliability:invocation[0]";
const FETCH_PLAYER_SEASON_HITTER_GRADES_ERROR = "error.platform.fetchPlayerSeasonHitterGrades:invocation[0]";
const FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_ERROR =
	"error.platform.fetchPlayerSeasonHitterGradesByTeam:invocation[0]";
const FETCH_METRIC_RELIABILITY_ERROR = "error.platform.fetchMetricReliability:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_SEASON_HITTER_GRADES = "SET_PLAYER_SEASON_HITTER_GRADES";
export const SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM = "SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM";
export const SET_METRIC_RELIABILITY = "SET_METRIC_RELIABILITY";
export const SET_FILTERS = "SET_FILTERS";

type TFetchPlayerSeasonHitterGradesEvent = {
	type: typeof FETCH_PLAYER_SEASON_HITTER_GRADES_DONE;
	data: Array<IPlayerSeasonHitterGrades> | undefined;
};
type TFetchPlayerSeasonHitterGradesByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_DONE;
	data: Array<IPlayerSeasonHitterGradesByTeamApiResponse> | undefined;
};
type TFetchMetricReliabilityEvent = {
	type: typeof FETCH_METRIC_RELIABILITY_DONE;
	data: Array<IMetricReliability> | undefined;
};

type TFetchPlayerSeasonHitterGradesErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_HITTER_GRADES_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonHitterGradesByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchMetricReliabilityErrorEvent = {
	type: typeof FETCH_METRIC_RELIABILITY_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetPlayerSeasonHitterGradesEvent = {
	type: typeof SET_PLAYER_SEASON_HITTER_GRADES;
	value?: Array<IPlayerSeasonHitterGrades>;
};
type TSetPlayerSeasonHitterGradesByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM;
	value?: Array<IPlayerSeasonHitterGradesByTeamApiResponse>;
};
type TSetMetricReliabilityEvent = {
	type: typeof SET_METRIC_RELIABILITY;
	value?: Array<IMetricReliability> | null;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TAmaPositionPlayerFoundationalSkillsTableFilters;
};

type TAmaPositionPlayerFoundationalSkillsTableEvent =
	| TFetchPlayerSeasonHitterGradesEvent
	| TFetchPlayerSeasonHitterGradesByTeamEvent
	| TFetchMetricReliabilityEvent
	| TFetchPlayerSeasonHitterGradesErrorEvent
	| TFetchPlayerSeasonHitterGradesByTeamErrorEvent
	| TFetchMetricReliabilityErrorEvent
	| TSetPlayerIdEvent
	| TSetPlayerSeasonHitterGradesEvent
	| TSetPlayerSeasonHitterGradesByTeamEvent
	| TSetMetricReliabilityEvent
	| TSetFiltersEvent;

export type TAmaPositionPlayerFoundationalSkillsTableSend = Interpreter<
	TAmaPositionPlayerFoundationalSkillsTableContext,
	IAmaPositionPlayerFoundationalSkillsTableStateSchema,
	TAmaPositionPlayerFoundationalSkillsTableEvent
>["send"];

const AmaPositionPlayerFoundationalSkillsTableMachine = (
	playerIdProp?: number,
	data?: TAmaPositionPlayerFoundationalSkillsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TAmaPositionPlayerFoundationalSkillsTableContext,
		IAmaPositionPlayerFoundationalSkillsTableStateSchema,
		TAmaPositionPlayerFoundationalSkillsTableEvent
	>(
		{
			id: "amaPositionPlayerFoundationalSkillsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					throws: [THROWS_L, THROWS_R],
					levels: undefined
				},
				playerSeasonHitterGrades: data?.playerSeasonHitterGrades,
				playerSeasonHitterGradesByTeam: data?.playerSeasonHitterGradesByTeam,
				metricReliability: data?.metricReliability,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: {
							actions: [
								"setPlayerId",
								"clearPlayerSeasonHitterGrades",
								"clearPlayerSeasonHitterGradesByTeam"
							],
							cond: "shouldSetPlayerId"
						},
						SET_PLAYER_SEASON_HITTER_GRADES: { actions: "setPlayerSeasonHitterGrades" },
						SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM: { actions: "setPlayerSeasonHitterGradesByTeam" },
						SET_METRIC_RELIABILITY: { actions: "setMetricReliability" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						playerSeasonHitterGrades: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonHitterGrades",
												cond: "shouldFetchPlayerSeasonHitterGrades"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonHitterGrades",
									entry: ["refreshPlayerSeasonHitterGradesCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonHitterGrades",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonHitterGradesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonHitterGradesErrored"
										}
									}
								}
							}
						},
						playerSeasonHitterGradesByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonHitterGradesByTeam",
												cond: "shouldFetchPlayerSeasonHitterGradesByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonHitterGradesByTeam",
									entry: ["refreshPlayerSeasonHitterGradesByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonHitterGradesByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonHitterGradesByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonHitterGradesByTeamErrored"
										}
									}
								}
							}
						},
						metricReliability: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchMetricReliability",
												cond: "shouldFetchMetricReliability"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchMetricReliability",
									entry: ["refreshMetricReliabilityCancelSource"],
									invoke: {
										src: "fetchMetricReliability",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchMetricReliabilitySuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchMetricReliabilityErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (
					context: TAmaPositionPlayerFoundationalSkillsTableContext,
					event: TAmaPositionPlayerFoundationalSkillsTableEvent
				) => event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchPlayerSeasonHitterGrades: (
					context: TAmaPositionPlayerFoundationalSkillsTableContext,
					_event: TAmaPositionPlayerFoundationalSkillsTableEvent
				) =>
					context.playerSeasonHitterGrades === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonHitterGradesByTeam: (
					context: TAmaPositionPlayerFoundationalSkillsTableContext,
					_event: TAmaPositionPlayerFoundationalSkillsTableEvent
				) =>
					context.playerSeasonHitterGradesByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchMetricReliability: (
					context: TAmaPositionPlayerFoundationalSkillsTableContext,
					_event: TAmaPositionPlayerFoundationalSkillsTableEvent
				) => context.metricReliability === undefined && context.shouldFetchData === true
			},
			actions: {
				clearPlayerSeasonHitterGrades: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGrades: (
						_context: TAmaPositionPlayerFoundationalSkillsTableContext,
						_event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => undefined,
					cancelSources: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						_event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				clearPlayerSeasonHitterGradesByTeam: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGradesByTeam: (
						_context: TAmaPositionPlayerFoundationalSkillsTableContext,
						_event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => undefined,
					cancelSources: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						_event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				clearMetricReliability: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					metricReliability: (
						_context: TAmaPositionPlayerFoundationalSkillsTableContext,
						_event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => undefined,
					cancelSources: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						_event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE]?.cancel();
						cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				// Set Context Actions
				setPlayerId: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					playerId: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						_event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setPlayerSeasonHitterGrades: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGrades: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_HITTER_GRADES) return context.playerSeasonHitterGrades;
						return event.value;
					},
					cancelSources: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_HITTER_GRADES) return cancelSources;
						cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonHitterGradesByTeam: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGradesByTeam: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM)
							return context.playerSeasonHitterGradesByTeam;
						return event.value;
					},
					cancelSources: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setMetricReliability: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					metricReliability: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_METRIC_RELIABILITY) return context.metricReliability;
						return event.value;
					},
					cancelSources: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_METRIC_RELIABILITY) return cancelSources;
						cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE]?.cancel();
						cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					filters: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonHitterGradesCancelSource: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					cancelSources: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						_event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonHitterGradesByTeamCancelSource: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					cancelSources: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						_event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshMetricReliabilityCancelSource: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					cancelSources: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						_event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (context.cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE] != null)
							context.cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE].cancel();
						context.cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonHitterGradesSuccess: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGrades: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_HITTER_GRADES_DONE)
							return context.playerSeasonHitterGrades;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonHitterGradesByTeamSuccess: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGradesByTeam: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_DONE)
							return context.playerSeasonHitterGradesByTeam;
						return event.data;
					}
				}),
				handleFetchMetricReliabilitySuccess: assign<
					TAmaPositionPlayerFoundationalSkillsTableContext,
					TAmaPositionPlayerFoundationalSkillsTableEvent
				>({
					metricReliability: (
						context: TAmaPositionPlayerFoundationalSkillsTableContext,
						event: TAmaPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== FETCH_METRIC_RELIABILITY_DONE) return context.metricReliability;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPlayerSeasonHitterGradesErrored: (
					context: TAmaPositionPlayerFoundationalSkillsTableContext,
					event: TAmaPositionPlayerFoundationalSkillsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_HITTER_GRADES_ERROR ? event.data : undefined,
						context.toast,
						"Ama Foundational Skills - Hitter Grades",
						"Error fetching hitter grades by player and season."
					);
				},
				handleFetchPlayerSeasonHitterGradesByTeamErrored: (
					context: TAmaPositionPlayerFoundationalSkillsTableContext,
					event: TAmaPositionPlayerFoundationalSkillsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Ama Foundational Skills - Hitter Grades by Team",
						"Error fetching hitter grades by player, season, and team"
					);
				},
				handleFetchMetricReliabilityErrored: (
					context: TAmaPositionPlayerFoundationalSkillsTableContext,
					event: TAmaPositionPlayerFoundationalSkillsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_METRIC_RELIABILITY_ERROR ? event.data : undefined,
						context.toast,
						"Foundational Skills - Metric Reliability",
						"Error fetching metric reliability thresholds"
					);
				}
			},
			services: {
				fetchPlayerSeasonHitterGrades: (
					context: TAmaPositionPlayerFoundationalSkillsTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonHitterGrades(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_AMA,
								gameType: GAME_TYPE_OVERALL,
								bats: BATS_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonHitterGradesByTeam: (
					context: TAmaPositionPlayerFoundationalSkillsTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonHitterGradesByTeam(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_AMA,
								gameType: GAME_TYPE_OVERALL,
								bats: BATS_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchMetricReliability: (
					context: TAmaPositionPlayerFoundationalSkillsTableContext,
					_event: AnyEventObject
				) => {
					const fetchFunc = () =>
						fetchMetricReliability(
							{
								"metric[in]": `${METRIC_BAT_TO_BALL_GRADE},${METRIC_SWING_DECISIONS_GRADE},${METRIC_EXIT_VELO}`,
								playerType: PLAYER_TYPE_BATTER,
								pitchTypeGrouping: PITCH_TYPE_OVERALL,
								isUseCache: true
							},
							context.cancelSources[METRIC_RELIABILITY_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default AmaPositionPlayerFoundationalSkillsTableMachine;
