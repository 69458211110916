import {
	BATS_L,
	BATS_OVERALL,
	BATS_R,
	THROWS_L,
	THROWS_OVERALL,
	THROWS_R
} from "_react/shared/data_models/seasonal_grades/_constants";
import { TToggleOption } from "_react/shared/ui/presentation/components/ToggleButton/_types";

import { getColorGradientFunctionBlueGreen } from "_react/shared/dataviz/_helpers";

export const AMA = "ama";
export const INTL = "intl";
export const PRO = "pro";

export const MAJOR = "major";
export const MINORS = "minors";

// Null value filler text

export const NULL_FILLER_TEXT_HEADER = "Unknown";
export const NULL_FILLER_TEXT_TEAM_NAME = "Team Unknown";
export const NULL_FILLER_TEXT_PROFILE = "-";
export const NULL_FILLER_TEXT_PROJECTIONS_TAB = "-";
export const NULL_FILLER_TEXT_PERCENTAGE_PROJECTIONS_TAB = "0%";

// Colors

const SV_MIN = -5;
const SV_MAX = 125;
export const SV_COLOR_GRADIENT = getColorGradientFunctionBlueGreen(SV_MIN, SV_MAX, 0);
export const [SV_COLOR_GRADIENT_FUNCTION] = SV_COLOR_GRADIENT;
export const SV_COLOR_GRADIENT_TEXT = getColorGradientFunctionBlueGreen(SV_MIN, SV_MAX, 0, true);
export const [SV_COLOR_GRADIENT_TEXT_FUNCTION] = SV_COLOR_GRADIENT_TEXT;

// Toggle Options

export const PitcherHandednessToggleOptions = [
	{ value: THROWS_OVERALL, label: "Overall" },
	{ value: THROWS_L, label: "vLHP" },
	{ value: THROWS_R, label: "vRHP" }
] as Array<TToggleOption<string>>;

export const BatterHandednessToggleOptions = [
	{ value: BATS_OVERALL, label: "Overall" },
	{ value: BATS_L, label: "vLHB" },
	{ value: BATS_R, label: "vRHB" }
] as Array<TToggleOption<string>>;

export const SHOHEI_PLAYER_ID = 514031;
export const SHOHEI_PHIL_ID = 514031;
