import { TStatFormat } from "_react/shared/ui/presentation/components/stat/shared/_types";
import {
	formatCurrencyMillions,
	formatPercentage
} from "_react/shared/ui/presentation/components/stat/shared/_helpers";

export const FORMAT_CURRENCY_MILLIONS = "FORMAT_CURRENCY_MILLIONS";
export const FORMAT_PERCENTAGE = "FORMAT_PERCENTAGE";

export const XL = "xl";
export const LG = "lg";
export const MD = "md";
export const SM = "sm";
export const XS = "xs";

export const FORMAT_FUNCTIONS: Record<
	TStatFormat,
	(value?: string | number | null) => string | number | undefined | null
> = {
	[FORMAT_CURRENCY_MILLIONS]: formatCurrencyMillions,
	[FORMAT_PERCENTAGE]: formatPercentage
};

export const LOADING_HEIGHT = {
	[XL]: 14,
	[LG]: 12,
	[MD]: 9,
	[SM]: 6,
	[XS]: 5
};

// Tooltip Targets
export const STAT_VALUE_TARGET = "STAT_VALUE_TARGET";
export const STAT_LABEL_TARGET = "STAT_LABEL_TARGET";
