import React from "react";
import { SystemStyleObject } from "@chakra-ui/react";

import { TEAM_COLORS } from "_react/shared/legacy/ui/Colors";

import Badge from "_react/shared/ui/presentation/components/Badge/Badge";

type TTeamBadgeProps = {
	teamAbbreviation?: string | null;
	nullFillerText?: string;
	style?: SystemStyleObject;
};

const TeamBadge = ({ teamAbbreviation, nullFillerText = "", style }: TTeamBadgeProps) => {
	// TODO convert this legacy TEAM_COLORS constant into chakra theme tokens
	const colorScheme = teamAbbreviation
		? TEAM_COLORS[teamAbbreviation.toLowerCase()]
		: { secondary: { color: "gray.700", text: "white" } };
	return (
		<Badge text={teamAbbreviation} nullFillerText={nullFillerText} color={colorScheme?.secondary} style={style} />
	);
};

export default TeamBadge;
