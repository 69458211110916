//
// Position Groups
//

export const POSITION_GROUP_POSITION_PLAYER = "POS";
export const POSITION_GROUP_IF = "IF";
export const POSITION_GROUP_OF = "OF";
export const POSITION_GROUP_C = "C";
export const POSITION_GROUP_1B_DH = "1B+";
export const POSITION_GROUP_SP = "SP";
export const POSITION_GROUP_RP = "RP";
export const POSITION_GROUP_PITCHER = "P";

export const POSITION_GROUPS_POSITION_PLAYER = [
	POSITION_GROUP_POSITION_PLAYER,
	POSITION_GROUP_IF,
	POSITION_GROUP_OF,
	POSITION_GROUP_C,
	POSITION_GROUP_1B_DH
];
export const POSITION_GROUPS_PITCHER = [POSITION_GROUP_PITCHER, POSITION_GROUP_SP, POSITION_GROUP_RP];
export const POSITION_GROUPS = [...POSITION_GROUPS_POSITION_PLAYER, ...POSITION_GROUPS_PITCHER];
export const POSITION_GROUP_LABELS = [
	...POSITION_GROUPS_PITCHER,
	POSITION_GROUP_POSITION_PLAYER,
	POSITION_GROUP_OF,
	POSITION_GROUP_IF,
	"1B/DH",
	POSITION_GROUP_C
];

//
// Positions
//

export const IF_POSITIONS = ["2B", "3B", "SS", "UTL", "IF"];
export const OF_POSITIONS = ["LF", "CF", "RF", "OF"];
export const C_POSITIONS = ["C"];
export const DH_1B_POSITIONS = ["1B", "DH"];
export const POSITION_PLAYER_POSITIONS = [...IF_POSITIONS, ...OF_POSITIONS, ...C_POSITIONS, ...DH_1B_POSITIONS];

export const SP_POSITIONS = ["RHS", "LHS", "SP", "LHP", "RHP", "P", "SHS"];
export const RP_POSITIONS = ["RHR", "LHR", "RP", "SHR"];
export const PITCHER_POSITIONS = [...SP_POSITIONS, ...RP_POSITIONS];

//
// Mapping Objects
//

export const POSITION_GROUPS_MAP_TO_POSITIONS: { [index: string]: Array<string> } = {
	[POSITION_GROUP_IF]: IF_POSITIONS,
	[POSITION_GROUP_OF]: OF_POSITIONS,
	[POSITION_GROUP_C]: C_POSITIONS,
	[POSITION_GROUP_1B_DH]: DH_1B_POSITIONS,
	[POSITION_GROUP_POSITION_PLAYER]: POSITION_PLAYER_POSITIONS,
	[POSITION_GROUP_SP]: SP_POSITIONS,
	[POSITION_GROUP_RP]: RP_POSITIONS,
	[POSITION_GROUP_PITCHER]: PITCHER_POSITIONS
};

// Only use when you cannot use the db-driven labels
export const POSITION_GROUPS_MAP_TO_LABELS: { [index: string]: string } = {
	[POSITION_GROUP_IF]: POSITION_GROUP_IF,
	[POSITION_GROUP_OF]: POSITION_GROUP_OF,
	[POSITION_GROUP_C]: POSITION_GROUP_C,
	[POSITION_GROUP_1B_DH]: "1B/DH",
	[POSITION_GROUP_POSITION_PLAYER]: POSITION_GROUP_POSITION_PLAYER,
	[POSITION_GROUP_SP]: POSITION_GROUP_SP,
	[POSITION_GROUP_RP]: POSITION_GROUP_RP,
	[POSITION_GROUP_PITCHER]: POSITION_GROUP_PITCHER
};
