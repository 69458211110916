import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { PREDICTION_TYPE_DY } from "_react/shared/data_models/phred/_constants";
import {
	TAmaProspectValueSimilarPlayersModelName,
	TAmaProspectValueSimilarPlayersPlayerType,
	fetchAmaProspectValueSimilarPlayers
} from "_react/shared/data_models/phred/_network";
import { IAmaProspectValuePlayer } from "_react/shared/data_models/phred/_types";
import { TAmaSurplusValueSimilarPlayersTableData } from "_react/shared/ui/data/tables/AmaSurplusValueSimilarPlayersTable/_types";
import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";

export type TAmaSurplusValueSimilarPlayersTableContext = {
	playerId?: number;
	lastPlayerId?: number; // Not currently used
	playerType?: TAmaProspectValueSimilarPlayersPlayerType;
	lastPlayerType?: TAmaProspectValueSimilarPlayersPlayerType; // Not currently used
	modelName?: TAmaProspectValueSimilarPlayersModelName;
	lastModelName?: TAmaProspectValueSimilarPlayersModelName; // Not currently used
	r4YearPlayer?: number;
	lastR4YearPlayer?: number; // Not currently used
	minR4Year?: number;
	lastMinR4Year?: number; // Not currently used
	maxR4Year?: number;
	lastMaxR4Year?: number; // Not currently used
	position?: string;
	lastPosition?: string; // Not currently used
	positionGroup?: string;
	lastPositionGroup?: string; // Not currently used
	shouldFetchData?: boolean;
	similarPlayers?: Array<IAmaProspectValuePlayer>;
	cancelSource?: CancelTokenSource | null;
	toast?: CreateToastFnReturn;
};

interface IAmaSurplusValueSimilarPlayersTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all similar players data
				similarPlayers: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_SIMILAR_PLAYERS = { initialized: { similarPlayers: "fetching" } };
export const FETCH_SIMILAR_PLAYERS_DONE = "done.invoke.fetchSimilarPlayers:invocation[0]";
export const FETCH_SIMILAR_PLAYERS_ERROR = "error.platform.fetchSimilarPlayers:invocation[0]";
export const SET_SIMILAR_PLAYERS = "SET_SIMILAR_PLAYERS";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_TYPE = "SET_PLAYER_TYPE";
export const SET_MODEL_NAME = "SET_MODEL_NAME";
export const SET_R4_YEAR_PLAYER = "SET_R4_YEAR_PLAYER";
export const SET_MIN_R4_YEAR = "SET_MIN_R4_YEAR";
export const SET_MAX_R4_YEAR = "SET_MAX_R4_YEAR";
export const SET_POSITION = "SET_POSITION";
export const SET_POSITION_GROUP = "SET_POSITION_GROUP";

type TFetchSimilarPlayersEvent = {
	type: typeof FETCH_SIMILAR_PLAYERS_DONE;
	data: Array<IAmaProspectValuePlayer> | undefined;
};
type TFetchSimilarPlayersErrorEvent = {
	type: typeof FETCH_SIMILAR_PLAYERS_ERROR;
	data?: AxiosError | Error;
};
type TSetSimilarPlayersEvent = { type: typeof SET_SIMILAR_PLAYERS; value?: Array<IAmaProspectValuePlayer> };
type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value?: number };
type TSetPlayerTypeEvent = { type: typeof SET_PLAYER_TYPE; value?: TAmaProspectValueSimilarPlayersPlayerType };
type TSetModelNameEvent = { type: typeof SET_MODEL_NAME; value?: TAmaProspectValueSimilarPlayersModelName };
type TSetR4YearPlayerEvent = { type: typeof SET_R4_YEAR_PLAYER; value?: number };
type TSetMinR4YearEvent = { type: typeof SET_MIN_R4_YEAR; value?: number };
type TSetMaxR4YearEvent = { type: typeof SET_MAX_R4_YEAR; value?: number };
type TSetPositionEvent = { type: typeof SET_POSITION; value?: string };
type TSetPositionGroupEvent = { type: typeof SET_POSITION_GROUP; value?: string };

type TAmaSurplusValueSimilarPlayersTableEvent =
	| TFetchSimilarPlayersEvent
	| TFetchSimilarPlayersErrorEvent
	| TSetSimilarPlayersEvent
	| TSetPlayerIdEvent
	| TSetPlayerTypeEvent
	| TSetModelNameEvent
	| TSetR4YearPlayerEvent
	| TSetMinR4YearEvent
	| TSetMaxR4YearEvent
	| TSetPositionEvent
	| TSetPositionGroupEvent;

export type TAmaSurplusValueSimilarPlayersTableSend = Interpreter<
	TAmaSurplusValueSimilarPlayersTableContext,
	IAmaSurplusValueSimilarPlayersTableStateSchema,
	TAmaSurplusValueSimilarPlayersTableEvent
>["send"];

const AmaSurplusValueSimilarPlayersTableMachine = (
	playerIdProp?: number,
	playerTypeProp?: TAmaProspectValueSimilarPlayersPlayerType,
	modelNameProp?: TAmaProspectValueSimilarPlayersModelName,
	r4YearPlayerProp?: number,
	minR4YearProp?: number,
	maxR4YearProp?: number,
	positionProp?: string,
	positionGroupProp?: string,
	data?: TAmaSurplusValueSimilarPlayersTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TAmaSurplusValueSimilarPlayersTableContext,
		IAmaSurplusValueSimilarPlayersTableStateSchema,
		TAmaSurplusValueSimilarPlayersTableEvent
	>(
		{
			id: "amaSurplusValueSimilarPlayersTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				playerType: playerTypeProp,
				lastPlayerType: playerTypeProp,
				modelName: modelNameProp,
				lastModelName: modelNameProp,
				r4YearPlayer: r4YearPlayerProp,
				lastR4YearPlayer: r4YearPlayerProp,
				minR4Year: minR4YearProp,
				lastMinR4Year: minR4YearProp,
				maxR4Year: maxR4YearProp,
				lastMaxR4Year: maxR4YearProp,
				position: positionProp,
				lastPosition: positionProp,
				positionGroup: positionGroupProp,
				lastPositionGroup: positionGroupProp,
				shouldFetchData: shouldFetchDataProp,
				similarPlayers: data?.similarPlayers,
				cancelSource: undefined,
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_SIMILAR_PLAYERS]: { actions: "setSimilarPlayers" },
						[SET_PLAYER_ID]: {
							actions: ["setPlayerId", "clearSimilarPlayers"],
							cond: "shouldSetPlayerId"
						},
						[SET_PLAYER_TYPE]: {
							actions: ["setPlayerType", "clearSimilarPlayers"],
							cond: "shouldSetPlayerType"
						},
						[SET_MODEL_NAME]: {
							actions: ["setModelName", "clearSimilarPlayers"],
							cond: "shouldSetModelName"
						},
						[SET_R4_YEAR_PLAYER]: {
							actions: ["setR4YearPlayer", "clearSimilarPlayers"],
							cond: "shouldSetR4YearPlayer"
						},
						[SET_MIN_R4_YEAR]: {
							actions: ["setMinR4Year", "clearSimilarPlayers"],
							cond: "shouldSetMinR4Year"
						},
						[SET_MAX_R4_YEAR]: {
							actions: ["setMaxR4Year", "clearSimilarPlayers"],
							cond: "shouldSetMaxR4Year"
						},
						[SET_POSITION]: {
							actions: ["setPosition", "clearSimilarPlayers"],
							cond: "shouldSetPosition"
						},
						[SET_POSITION_GROUP]: {
							actions: ["setPositionGroup", "clearSimilarPlayers"],
							cond: "shouldSetPositionGroup"
						}
					},
					states: {
						similarPlayers: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchSimilarPlayers",
												cond: "shouldFetchSimilarPlayers"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchSimilarPlayers",
									entry: ["refreshCancelSource"],
									invoke: {
										src: "fetchSimilarPlayers",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSimilarPlayersSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSimilarPlayersErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					_event: TAmaSurplusValueSimilarPlayersTableEvent
				) =>
					context.playerId !== context.lastPlayerId ||
					context.playerType !== context.lastPlayerType ||
					context.modelName !== context.lastModelName ||
					context.r4YearPlayer !== context.lastR4YearPlayer ||
					context.minR4Year !== context.lastMinR4Year ||
					context.maxR4Year !== context.lastMaxR4Year ||
					context.position !== context.lastPosition ||
					context.positionGroup !== context.lastPositionGroup,
				shouldSetPlayerId: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					event: TAmaSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldSetPlayerType: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					event: TAmaSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_PLAYER_TYPE && context.playerType !== event.value,
				shouldSetModelName: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					event: TAmaSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_MODEL_NAME && context.modelName !== event.value,
				shouldSetR4YearPlayer: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					event: TAmaSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_R4_YEAR_PLAYER && context.r4YearPlayer !== event.value,
				shouldSetMinR4Year: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					event: TAmaSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_MIN_R4_YEAR && context.minR4Year !== event.value,
				shouldSetMaxR4Year: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					event: TAmaSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_MAX_R4_YEAR && context.maxR4Year !== event.value,
				shouldSetPosition: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					event: TAmaSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_POSITION && context.position !== event.value,
				shouldSetPositionGroup: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					event: TAmaSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_POSITION_GROUP && context.positionGroup !== event.value,
				shouldFetchSimilarPlayers: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					_event: TAmaSurplusValueSimilarPlayersTableEvent
				) =>
					context.similarPlayers === undefined &&
					context.playerId !== undefined &&
					context.playerType !== undefined &&
					context.modelName !== undefined &&
					context.r4YearPlayer !== undefined &&
					(context.maxR4Year !== undefined ||
						(context.minR4Year === undefined && context.maxR4Year === undefined)) &&
					context.shouldFetchData === true
			},
			actions: {
				clearSimilarPlayers: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					similarPlayers: (
						_context: TAmaSurplusValueSimilarPlayersTableContext,
						_event: TAmaSurplusValueSimilarPlayersTableEvent
					) => undefined,
					cancelSource: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						_event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						context.cancelSource?.cancel();
						return undefined;
					}
				}),
				setSimilarPlayers: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					similarPlayers: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_SIMILAR_PLAYERS) return context.similarPlayers;
						return event.value;
					},
					cancelSource: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						_event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						context.cancelSource?.cancel();
						return undefined;
					}
				}),
				setPlayerId: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					playerId: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					}
				}),
				setPlayerType: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					playerType: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_PLAYER_TYPE) return context.playerType;
						return event.value;
					}
				}),
				setModelName: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					modelName: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_MODEL_NAME) return context.modelName;
						return event.value;
					}
				}),
				setR4YearPlayer: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					r4YearPlayer: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_R4_YEAR_PLAYER) return context.r4YearPlayer;
						return event.value;
					}
				}),
				setMinR4Year: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					minR4Year: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_MIN_R4_YEAR) return context.minR4Year;
						return event.value;
					}
				}),
				setMaxR4Year: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					maxR4Year: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_MAX_R4_YEAR) return context.maxR4Year;
						return event.value;
					}
				}),
				setPosition: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					position: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_POSITION) return context.position;
						return event.value;
					}
				}),
				setPositionGroup: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					positionGroup: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_POSITION_GROUP) return context.positionGroup;
						return event.value;
					}
				}),
				refreshCancelSource: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					cancelSource: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						_event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						context.cancelSource?.cancel();
						return getCancelSource();
					}
				}),
				handleFetchSimilarPlayersSuccess: assign<
					TAmaSurplusValueSimilarPlayersTableContext,
					TAmaSurplusValueSimilarPlayersTableEvent
				>({
					similarPlayers: (
						context: TAmaSurplusValueSimilarPlayersTableContext,
						event: TAmaSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== FETCH_SIMILAR_PLAYERS_DONE) return context.similarPlayers;
						return event.data;
					}
				}),
				handleFetchSimilarPlayersErrored: (
					context: TAmaSurplusValueSimilarPlayersTableContext,
					event: TAmaSurplusValueSimilarPlayersTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_SIMILAR_PLAYERS_ERROR ? event.data : undefined,
						context.toast,
						"Similar Players",
						"Error fetching similar players."
					);
				}
			},
			services: {
				fetchSimilarPlayers: (context: TAmaSurplusValueSimilarPlayersTableContext, _event: AnyEventObject) => {
					const {
						playerId,
						playerType,
						modelName,
						r4YearPlayer,
						minR4Year,
						maxR4Year,
						position,
						positionGroup
					} = context;
					if (
						playerId === undefined ||
						playerType === undefined ||
						modelName === undefined ||
						r4YearPlayer === undefined
					)
						return Promise.resolve(null);
					const fetchFunc = () =>
						fetchAmaProspectValueSimilarPlayers(
							{
								playerId: playerId,
								r4YearPlayer: r4YearPlayer,
								"r4Year[lte]": maxR4Year,
								"r4Year[gte]": minR4Year,
								modelName: modelName,
								predictionType: PREDICTION_TYPE_DY,
								playerType: playerType,
								position: position,
								positionGroup: positionGroup,
								isMostRecentEntry: true,
								isUseCache: true
							},
							context.cancelSource?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default AmaSurplusValueSimilarPlayersTableMachine;
