//
// `phil_data.iam_group` ID values
//
export const BASEBALL_OPERATIONS_IAM_GROUP = 1;
export const AMATEUR_SCOUTING_IAM_GROUP = 2;
export const INTERNATIONAL_SCOUTING_IAM_GROUP = 3;
export const MAJOR_LEAGUE_CLUB_IAM_GROUP = 5;
export const PRO_SCOUTING_IAM_GROUP = 6;
export const INTL_DIRECTOR_IAM_GROUP = 13;
export const COACH_IAM_GROUP = 19;
export const ML_STRATEGY_IAM_GROUP = 20;
export const FRONT_OFFICE_IAM_GROUP = 26;
export const RND_IAM_GROUP = 27;
export const SWE_IAM_GROUP = 41;
export const QA_IAM_GROUP = 42;
export const AMA_ADMIN_IAM_GROUP = 62;
export const INTL_SCOUTING_IAM_GROUP = 3;
export const AMA_EVAL_ADMIN_IAM_GROUP = 64;
export const INTL_VID_IAM_GROUP = 66;
export const PLAYER_DEVELOPMENT_IAM_GROUP = 69;
export const QUICK_LINKS_IAM_GROUP = 86;
export const PITCHER_IAM_GROUP = 111;
export const PROJECTIONS_NON_FRONT_OFFICE_IAM_GROUP = 123;
export const AMA_PROJECTIONS_NON_FRONT_OFFICE_IAM_GROUP = 147;
export const INTL_PROJECTIONS_NON_FRONT_OFFICE_IAM_GROUP = 161;
export const ROCKY_SEARCH_BETA_IAM_GROUP = 133;
export const HOMEPAGE_BETA_IAM_GROUP = 144;
export const PRO_STATS_TABLES_BETA_IAM_GROUP = 148;
export const AMA_MEDICAL_WORKBOOK_EDIT_IAM_GROUP = 149;
export const AMA_MEDICAL_WORKBOOK_VIEW_MEDICAL_FILES_IAM_GROUP = 150;
export const INTL_PLAYER_PAGES_BETA_IAM_GROUP = 154;
export const PITCH_TYPE_MAP_EDIT_IAM_GROUP = 155;
export const PRO_REPORT_ALPHA_GROUP = 170;
export const PRO_REPORT_ALPHA_SETTINGS_GROUP = 171;
export const AMA_STATS_TABLES_RND_ALPHA_GROUP = 172;
export const DRAFTBOARD_PREVIOUS_YEARS = 173;

//
// Arrays of IAM groups
//

// Projections Access
export const PROJECTIONS_ACCESS_IAM_GROUPS = [
	FRONT_OFFICE_IAM_GROUP,
	PROJECTIONS_NON_FRONT_OFFICE_IAM_GROUP,
	ML_STRATEGY_IAM_GROUP
];

// Player Pages Tab Permissions
export const PLAYER_PAGES_PRO_PROJECTIONS_IAM_GROUPS = PROJECTIONS_ACCESS_IAM_GROUPS;
export const PLAYER_PAGES_OBSERVED_IAM_GROUPS = [BASEBALL_OPERATIONS_IAM_GROUP];
export const PLAYER_PAGES_SCOUTING_REPORTS_IAM_GROUPS = [
	AMATEUR_SCOUTING_IAM_GROUP,
	INTERNATIONAL_SCOUTING_IAM_GROUP,
	PRO_SCOUTING_IAM_GROUP
];
export const PLAYER_PAGES_MANUELS_IAM_GROUPS = [
	MAJOR_LEAGUE_CLUB_IAM_GROUP,
	FRONT_OFFICE_IAM_GROUP,
	PLAYER_DEVELOPMENT_IAM_GROUP
];
export const PLAYER_PAGES_AMA_PROJECTIONS_IAM_GROUPS = [
	AMA_PROJECTIONS_NON_FRONT_OFFICE_IAM_GROUP,
	...PROJECTIONS_ACCESS_IAM_GROUPS
];

export const PLAYER_PAGES_INTL_PROJECTIONS_IAM_GROUPS = [
	INTL_PROJECTIONS_NON_FRONT_OFFICE_IAM_GROUP,
	...PROJECTIONS_ACCESS_IAM_GROUPS
];
export const PLAYER_PAGES_INTL_BETA_GROUPS = [SWE_IAM_GROUP, INTL_PLAYER_PAGES_BETA_IAM_GROUP];

export const PRO_STATS_TABLES_BETA_IAM_GROUPS = [PRO_STATS_TABLES_BETA_IAM_GROUP, SWE_IAM_GROUP, QA_IAM_GROUP];

export const AMA_STATS_TABLES_EARLY_ALPHA_IAM_GROUPS = [AMA_STATS_TABLES_RND_ALPHA_GROUP];

export const AMA_MEDICAL_WORKBOOK_EDIT_IAM_GROUPS = [SWE_IAM_GROUP, AMA_MEDICAL_WORKBOOK_EDIT_IAM_GROUP];
export const AMA_MEDICAL_WORKBOOK_VIEW_MEDICAL_FILES_IAM_GROUPS = [
	SWE_IAM_GROUP,
	AMA_MEDICAL_WORKBOOK_VIEW_MEDICAL_FILES_IAM_GROUP
];

export const PITCH_TYPE_MAPPING_IAM_GROUPS = [PITCH_TYPE_MAP_EDIT_IAM_GROUP];

// POE Standings
export const POE_STANDINGS_ACCESS_IAM_GROUPS = [BASEBALL_OPERATIONS_IAM_GROUP, PROJECTIONS_NON_FRONT_OFFICE_IAM_GROUP];

// Game Marquee onClick to MLB Research site
export const GAME_MARQUEE_ONCLICK_IAM_GROUPS = [COACH_IAM_GROUP, ML_STRATEGY_IAM_GROUP, FRONT_OFFICE_IAM_GROUP];
